import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, PageEvent, MatSnackBar, MatDialog, MatPaginator, Sort } from '@angular/material';
import { Subject, Subscription } from 'rxjs';
import { StreamersApiService } from '../services/streamers-api.service';
import { HttpResponse } from '@angular/common/http';
import { StreamersModel } from '../models/streamersModel';
import { Streamer } from '../models/streamer';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StreamerDialogData } from '../models/streamer-dialog-data';
import { StreamersDialogComponent } from '../streamers-dialog/streamers-dialog.component';
import { DialogType } from 'src/app/models/dialog/dialogType';

@Component({
    selector: 'app-streamers',
    templateUrl: './streamers.component.html',
    styleUrls: ['./streamers.component.sass'],
})
export class StreamersComponent implements OnInit {
    public pageSizeOptions: number[] = [20, 50, 100];
    public streamersDataSource = new MatTableDataSource<Streamer>();
    public searchCriteria = '';
    public searchCriteriaChanged = new Subject<string>();
    public streamerSubscription: Subscription;
    public currentSort: string;
    public currentDirection: string;
    public dialogSubscription: Subscription;
    public streamerUpdateSubscription: Subscription;
    public streamerCreateSubscription: Subscription;
    public streamerDeleteSubscription: Subscription;
    @ViewChild(MatPaginator) public paginator: MatPaginator;

    public streamersTableColumns: string[] = ['id', 'name', 'menu'];
    public currentPage = 0;
    public currentPageSize = 20;

    constructor(private snackBar: MatSnackBar, private streamersApiService: StreamersApiService, private dialog: MatDialog) {
        this.searchCriteriaChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value: string) => {
            this.paginator.firstPage();
            this.getStreamers(0, this.paginator.pageSize, this.currentSort, true, value);
            this.searchCriteria = value;
        });
    }

    ngOnInit() {
        this.getStreamers(0, this.pageSizeOptions[0]);
    }

    public onSearchInputChanged(text: string) {
        this.searchCriteriaChanged.next(text);
    }

    public handlePaginator(event: PageEvent) {
        (this.currentPage = event.pageIndex), (this.currentPageSize = event.pageSize);
        this.getStreamers(event.pageIndex, event.pageSize, this.currentSort, true, this.searchCriteria);
    }

    public getStreamers(page: number, pageSize: number, orderByColumn?: string, ascending?: boolean, searchCriteria?: string) {
        this.streamerSubscription = this.streamersApiService.getStreamers(page, pageSize, orderByColumn, ascending, searchCriteria).subscribe(
            (response: HttpResponse<StreamersModel>) => {
                this.streamersDataSource.data = response.body.streamers;
                this.paginator.length = response.body.streamersCount;
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public sortData(sort: Sort) {
        if (sort.active && sort.direction) {
            const isAscDirection = sort.direction === 'asc';

            this.currentDirection = sort.direction;
            this.currentSort = sort.active;
            this.getStreamers(this.paginator.pageIndex, this.paginator.pageSize, this.currentSort, isAscDirection, this.searchCriteria);
        } else {
            this.currentDirection = undefined;
            this.currentSort = undefined;
            this.getStreamers(this.paginator.pageIndex, this.paginator.pageSize);
        }
    }

    public openAddDialog(event: any) {
        this.openDialog(event, new Streamer(), DialogType.Add);
    }

    public openEditDialog(event: any, streamer: Streamer) {
        this.openDialog(event, streamer, DialogType.Edit);
    }

    public openDialog(event: any, streamer: Streamer, type: DialogType) {
        event.stopPropagation();

        const dialog = this.dialog.open(StreamersDialogComponent, {
            width: '400px',
            data: {
                dialogType: type,
                currentStreamer: streamer,
                updatedModel: {
                    id: streamer.id,
                    name: streamer.name,
                },
            },
        });

        this.dialogSubscription = dialog.afterClosed().subscribe((result: StreamerDialogData) => {
            if (result && result.isChangesConfirmed) {
                result.dialogType === DialogType.Edit
                    ? this.updateStreamer(result.currentStreamer, result.updatedModel)
                    : this.createStreamer(result.updatedModel);
            }
        });
    }

    public sortStreamersArray() {
        this.streamersDataSource.data = this.streamersDataSource.data.sort((a, b) => this.compareItems(a, b));

        if (this.currentDirection === 'desc') {
            this.streamersDataSource.data = this.streamersDataSource.data.reverse();
        }
    }

    public compareItems(a: Streamer, b: Streamer): number {
        const sortColumn = this.currentSort || 'id';

        return a[sortColumn] < b[sortColumn] ? -1 : a[sortColumn] > b[sortColumn] ? 1 : 0;
    }

    public updateStreamer(currentStreamerUpdated: Streamer, streamerToBeSaved: Streamer) {
        this.streamerUpdateSubscription = this.streamersApiService.updateStreamer(streamerToBeSaved, currentStreamerUpdated.id).subscribe(
            (res) => {
                const index = this.streamersDataSource.data.findIndex((x) => x.id === currentStreamerUpdated.id);
                const arr = this.streamersDataSource.data;
                arr[index] = res.body;
                this.streamersDataSource.data = arr;
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public createStreamer(streamerToBeSaved: Streamer) {
        this.streamerCreateSubscription = this.streamersApiService.createStreamer(streamerToBeSaved).subscribe(() => {
            this.getStreamers(this.currentPage, this.currentPageSize);
        });
    }

    public deleteStreamer(streamerId: number) {
        this.streamerDeleteSubscription = this.streamersApiService.deleteStreamer(streamerId).subscribe(() => {
            this.getStreamers(this.currentPage, this.currentPageSize);
        });
    }
}
