import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationSnackBarObserver } from 'src/app/services/notification-snack-bar-observer';
import { NotificationMessage } from 'src/app/models/notification/notificationMessage';
import { HttpResponse } from '@angular/common/http';
import { NotificationType } from 'src/app/models/notification/notificationType';
import { Players } from '../models/players';
import { PlayerApiService } from './player-api.service';
import { PlayerModel } from '../models/player';

@Injectable()
export class PlayerService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);

    constructor(private playerApiService: PlayerApiService, private notificationObserver: NotificationSnackBarObserver) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getAllPlayers(page = 0, pagesize = 10): Observable<Players> {
        return this.playerApiService.getPlayers(page, pagesize).pipe(
            map(
                (response: HttpResponse<Players>) => response.body as Players,
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public getPlayer(playerId: number): Observable<PlayerModel> {
        return this.playerApiService.getPlayer(playerId).pipe(
            map(
                (response: HttpResponse<PlayerModel>) => response.body as PlayerModel,
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public createPlayer(newPlayer: PlayerModel): Observable<PlayerModel> {
        return this.playerApiService.createPlayer(newPlayer).pipe(
            map(
                (response: HttpResponse<PlayerModel>) => {
                    this.notificationSubject.next(new NotificationMessage(`'${newPlayer.name}' player was added`, NotificationType.Adding));
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`'${newPlayer.name}' player was not added`, NotificationType.Error));
                }
            )
        );
    }

    public updatePlayer(updatedPlayer: PlayerModel): Observable<boolean> {
        return this.playerApiService.updatePlayer(updatedPlayer).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    this.notificationSubject.next(
                        new NotificationMessage(`'${updatedPlayer.name}' player was updated`, NotificationType.Updating)
                    );
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`'${updatedPlayer.name}' player was not updated`, NotificationType.Error)
                    );
                }
            )
        );
    }

    public deletePlayer(playerId: number): Observable<boolean> {
        return this.playerApiService.deletePlayer(playerId).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    this.notificationSubject.next(new NotificationMessage(`Player was removed`, NotificationType.Removing));
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Player was not removed`, NotificationType.Error));
                }
            )
        );
    }
}
