import { ContentDeliveryNetwork } from './content-delivery-network';

export class Stream {
    public streamId: number;
    public cdn: ContentDeliveryNetwork;
    public streamType: string;
    public friendlyName: string;
    public description: string;
    public streamLink: string;
    public videoBitRateKbps: number;
    public audioBitRateKbps: number;
    public streamWidth: number;
    public streamHeight: number;
    public isExternalStream: boolean;
}
