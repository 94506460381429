import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Role } from '../models/role';
import { Observable } from 'rxjs';
import { RoleBaseInfo } from '../../../../models/common/role-base-info';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class RoleApiService {
    routePrefix: string = '/api/role';

    constructor(private httpService: HttpInternalService) {}

    getRole(roleId: number): Observable<HttpResponse<Role>> {
        return this.httpService.getFullRequest<Role>(`${this.routePrefix}`, { roleId: roleId });
    }

    getRoles(): Observable<HttpResponse<Role[]>> {
        return this.httpService.getFullRequest<Role[]>(`${this.routePrefix}`);
    }

    getRolesBaseInfo(): Observable<HttpResponse<RoleBaseInfo[]>> {
        return this.httpService.getFullRequest<RoleBaseInfo[]>(`${this.routePrefix}/allBaseInfo`);
    }

    addRole(role: Role): Observable<HttpResponse<Role>> {
        return this.httpService.putFullRequest<Role>(`${this.routePrefix}`, role);
    }

    updateRole(role: Role): Observable<HttpResponse<boolean>> {
        return this.httpService.postFullRequest<boolean>(`${this.routePrefix}`, role);
    }

    deleteRole(roleId: number): Observable<HttpResponse<boolean>> {
        return this.httpService.deleteFullRequest<boolean>(`${this.routePrefix}/${roleId}`);
    }
}
