import { CustomerUser } from './customer-user';
import { DialogType } from '../../../../models/dialog/dialogType';
import { RcsUserRead } from '../../user/models/rcs-user-read';

export class CustomerDialogData {
    dialogType: DialogType;
    customerUser: CustomerUser;
    allUsers: RcsUserRead[];
    isChangesConfirmed: boolean = false;

    constructor(dialogType: DialogType, customerUser: CustomerUser, allUsers: RcsUserRead[], isChangesConfirmed?: boolean) {
        this.dialogType = dialogType;
        this.customerUser = customerUser;
        this.allUsers = allUsers;
        this.isChangesConfirmed = isChangesConfirmed;
    }
}
