import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Customer } from '../models/customer';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { CustomerBaseInfo } from '../../../../models/common/customer-base-info';
import { CustomerWithUid } from '../../../../models/common/customer-with-uid';

@Injectable()
export class CustomerApiService {
    public routePrefix = '/api/customer';

    constructor(private httpService: HttpInternalService) {}

    public getCustomer(customerId: number): Observable<HttpResponse<Customer>> {
        return this.httpService.getFullRequest<Customer>(`${this.routePrefix}/${customerId}`);
    }

    public getCustomersWithUid(): Observable<HttpResponse<CustomerWithUid[]>> {
        return this.httpService.getFullRequest<CustomerWithUid[]>(`${this.routePrefix}/customersWithUid`);
    }

    public getCustomers(): Observable<HttpResponse<Customer[]>> {
        return this.httpService.getFullRequest<Customer[]>(`${this.routePrefix}/all`);
    }

    public getCustomersBaseInfo(): Observable<HttpResponse<CustomerBaseInfo[]>> {
        return this.httpService.getFullRequest<CustomerBaseInfo[]>(`${this.routePrefix}/allBaseInfo`);
    }

    public getRcsCustomersBaseInfo(): Observable<HttpResponse<CustomerBaseInfo[]>> {
        return this.httpService.getFullRequest<CustomerBaseInfo[]>(`${this.routePrefix}/allRcsBaseInfo`);
    }

    public addCustomer(customer: Customer): Observable<HttpResponse<Customer>> {
        return this.httpService.putFullRequest<Customer>(`${this.routePrefix}`, customer);
    }

    public updateCustomer(customer: Customer): Observable<HttpResponse<boolean>> {
        return this.httpService.postFullRequest<boolean>(`${this.routePrefix}`, customer);
    }

    public deleteCustomer(customerId: number): Observable<HttpResponse<boolean>> {
        return this.httpService.deleteFullRequest<boolean>(`${this.routePrefix}/${customerId}`);
    }

    public deleteCustomerChannelAliases(customerId: number): Observable<HttpResponse<boolean>> {
        return this.httpService.deleteFullRequest<boolean>(`${this.routePrefix}/${customerId}/channel-aliases`);
    }

    public createCustomerChannelAliases(customerId: number): Observable<HttpResponse<boolean>> {
        return this.httpService.postFullRequest<boolean>(`${this.routePrefix}/${customerId}/channel-aliases`, null);
    }

    public downloadCustomersFile(): Observable<HttpResponse<Blob>> {
        return this.httpService.downloadFile(`${this.routePrefix}/download`);
    }
}
