import { Injectable } from '@angular/core';
import { HttpInternalService } from 'src/app/services/http.internal.service';
import { ManageEventsParams } from '../models/manage-events-params';
import { EventsData } from '../models/events-data';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FiltersDialogData } from '../models/filters-dialog-data';
import { CustomerStream } from 'src/app/models/common/customer-stream';
import { GeneratedStream } from '../models/generated-stream';
import { UserState } from 'src/app/states/user-state';

@Injectable({
    providedIn: 'root'
})
export class McrScheduleApiService {
    private routePrefix = '/api/manageEvents';

    constructor(private httpService: HttpInternalService, private userState: UserState) { }

    public getGroupedEvents(params: ManageEventsParams): Observable<HttpResponse<EventsData>> {
        return this.httpService.postFullRequest<EventsData>(`${this.routePrefix}`, params);
    }

    public getTwitchEvents(params: ManageEventsParams): Observable<HttpResponse<EventsData>> {
        return this.httpService.postFullRequest<EventsData>(`/api/twitchEvents`, params);
    }

    public getTwitchEventsCount(params: ManageEventsParams): Observable<HttpResponse<number>> {
        return this.httpService.postFullRequest<number>(`/api/twitchEvents/count`, params);
    }

    public getEventFiltersData(): Observable<HttpResponse<FiltersDialogData>> {
        return this.httpService.getFullRequest<FiltersDialogData>(`${this.routePrefix}/formData`);
    }

    public loadStream(eventId: string, streamId: number, customerId: number): Observable<HttpResponse<GeneratedStream>> {
        return this.httpService.getFullRequest<GeneratedStream>(`${this.routePrefix}/stream`, { eventId, streamId, customerId });
    }

    public getCustomerStreamsByEvent(eventId: string): Observable<HttpResponse<CustomerStream[]>> {
        return this.httpService.getFullRequest<CustomerStream[]>(`${this.routePrefix}/customerStreams`, { eventId });
    }

    public updateIsDesktopVerifiedEventProperty(eventId: string, value: boolean): Observable<HttpResponse<boolean>> {
        const email = this.userState.getAurhorizedUser().email;
        return this.httpService.putFullRequest<boolean>(`${this.routePrefix}/isDesktopVerified`, { eventId, value, email });
    }

    public updateIsMobileVerifiedEventProperty(eventId: string, value: boolean): Observable<HttpResponse<boolean>> {
        const email = this.userState.getAurhorizedUser().email;
        return this.httpService.putFullRequest<boolean>(`${this.routePrefix}/isMobileVerified`, { eventId, value, email });
    }

    public updateIsAutoUpdatingEventProperty(eventId: string, value: boolean): Observable<HttpResponse<boolean>> {
        return this.httpService.putFullRequest<boolean>(`${this.routePrefix}/isAutoUpdating`, { eventId, value });
    }

    public updateEventStatus(eventId: string, value: string): Observable<HttpResponse<boolean>> {
        return this.httpService.putFullRequest<boolean>(`${this.routePrefix}/status`, { eventId, value });
    }
}
