import { AuthorizedUser } from '../models/common/authorized-user';
export class UserState {
    private user: AuthorizedUser = new AuthorizedUser();

    public setName(name: string): UserState {
        this.user.name = name;
        return this;
    }

    public setEmail(email: string): UserState {
        this.user.email = email;
        return this;
    }

    public setRole(role: string): UserState {
        this.user.role = role;
        return this;
    }

    public getAurhorizedUser(): AuthorizedUser {
        return this.user;
    }

    public isEmptyState(): boolean {
        return !this.user.email;
    }

    public getRole(): string {
        return this.user.role;
    }

    public hasRole(role: string): boolean {
        return this.user.role.includes(role);
    }
}
