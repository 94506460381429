import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationSnackBarObserver } from 'src/app/services/notification-snack-bar-observer';
import { NotificationMessage } from 'src/app/models/notification/notificationMessage';
import { HttpResponse } from '@angular/common/http';
import { NotificationType } from 'src/app/models/notification/notificationType';
import { VirtualPlayers } from '../models/virtual-players';
import { VirtualPlayerApiService } from './virtual-player-api.service';
import { VirtualPlayer } from '../models/virtual-player';

@Injectable()
export class VirtualPlayerService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);

    constructor(private virtualPlayerApiService: VirtualPlayerApiService,
                private notificationObserver: NotificationSnackBarObserver) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getAllPlayers(page = 0, pagesize = 10): Observable<VirtualPlayers> {
        return this.virtualPlayerApiService.getVirtualPlayers(page, pagesize).pipe(
            map(
                (response: HttpResponse<VirtualPlayers>) => response.body as VirtualPlayers,
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public createPlayer(newPlayer: VirtualPlayer): Observable<VirtualPlayer> {
        return this.virtualPlayerApiService.addVirtualPlayer(newPlayer).pipe(
            map(
                (response: HttpResponse<VirtualPlayer>) => {
                    this.notificationSubject.next(new NotificationMessage(`'${newPlayer.name}' virtual player was added`, NotificationType.Adding));
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`'${newPlayer.name}' virtual player was not added`, NotificationType.Error));
                }
            )
        );
    }

    public updatePlayer(updatedPlayer: VirtualPlayer): Observable<boolean> {
        return this.virtualPlayerApiService.updateVirtualPlayer(updatedPlayer).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    this.notificationSubject.next(
                        new NotificationMessage(`'${updatedPlayer.name}' virtual player was updated`, NotificationType.Updating)
                    );
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`'${updatedPlayer.name}' virtual player was not updated`, NotificationType.Error)
                    );
                }
            )
        );
    }

    public deletePlayer(playerId: number): Observable<boolean> {
        return this.virtualPlayerApiService.deleteVirtualPlayer(playerId).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    this.notificationSubject.next(new NotificationMessage(`Virtual player was removed`, NotificationType.Removing));
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Virtual player was not removed`, NotificationType.Error));
                }
            )
        );
    }
}
