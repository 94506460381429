import { Component, AfterViewInit, Input, ViewEncapsulation, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import Hls from 'hls.js';
import videojs from 'video.js';
import PlayerOptions = videojs.PlayerOptions;

@Component({
    selector: 'hls-player',
    templateUrl: './hls-player.component.html',
    styleUrls: ['./hls-player.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class HlsPlayerComponent implements AfterViewInit, OnDestroy {
    @Input() public source: string;
    @Input() public streamType: string;
    @ViewChild('videoJs') private player: ElementRef;

    private videoJs: videojs.Player;

    public ngAfterViewInit() {
        const options: PlayerOptions = {
            muted: true,
            autoplay: true,
            controls: true,
            bigPlayButton: false,
            sources: [{src: this.source}],
        };

        this.videoJs = videojs(this.player.nativeElement, options, function onPlayerReady() {
            console.log('onPlayerReady', this);
        });
        // if (Hls.isSupported()) {
        //     const config = {
        //         maxBufferLength: 600,
        //         liveMaxLatencyDurationCount: 10
        //     };
        //     this.hls = new Hls(config);
        //     this.hls.loadSource(this.source);
        //     this.hls.attachMedia(this.player.nativeElement);
        //     this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        //         this.player.nativeElement.play();
        //         this.player.nativeElement.volume = 0;
        //     });
        // } else if (this.player.nativeElement.canPlayType('application/vnd.apple.mpegurl')) {
        //     this.player.nativeElement.src = this.source;
        //     this.player.nativeElement.addEventListener('loadedmetadata', () => {
        //         this.player.nativeElement.play();
        //         this.player.nativeElement.volume = 0;
        //     });
        // }
    }

    public ngOnDestroy() {
        if (this.videoJs) {
            this.videoJs.dispose();
        }
    }
}
