import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { CustomerDialogData } from '../models/customer-dialog-data';
import { UserBaseInfo } from '../../../../models/common/user-base-info';
import { UserView } from '../models/user-view';
import { UserRead } from 'src/app/pages/system/user/models/user-read';
import { RcsUserRead } from '../../user/models/rcs-user-read';

@Component({
    selector: 'app-add-edit-rcs-customer-dialog',
    templateUrl: './add-edit-rcs-customer-dialog.component.html',
    styleUrls: ['./add-edit-rcs-customer-dialog.component.sass'],
})
export class AddEditRcsCustomerDialogComponent {
    public userViews: UserView[] = [];

    constructor(public dialogRef: MatDialogRef<AddEditRcsCustomerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CustomerDialogData) {
        this.userViews = this.generateUserViews(data.allUsers, data.customerUser.users);
    }

    public onNo(): void {
        this.data.isChangesConfirmed = false;
        this.dialogRef.close(false);
    }

    public onYes(): void {
        const newCustomerUsers = this.generateSeletedUsersList();

        this.data.isChangesConfirmed = true;
        this.data.customerUser.users = newCustomerUsers;
        this.data.customerUser.customer.isThereAnyUsers = newCustomerUsers.length !== 0;
        this.dialogRef.close(_.cloneDeep(this.data));
    }

    public onChangeSelection(userView: UserView) {
        userView.hasCustomer = !userView.hasCustomer;
        this.data.allUsers.find(x => x.userId === userView.userId).hasCustomer = userView.hasCustomer;
    }

    private generateUserViews(allUsers: RcsUserRead[], customerUsers: UserBaseInfo[]): UserView[] {
        return allUsers.map(x => ({
            userId: x.userId,
            email: x.email,
            isUserSelected: customerUsers.some((u) => u.userId === x.userId),
            hasCustomer: x.hasCustomer
        }));
    }

    private generateSeletedUsersList(): UserBaseInfo[] {
        return this.userViews.filter(x => x.isUserSelected);
    }
}
