import { Component, Inject } from '@angular/core';
import { cloneDeep } from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-add-edit-player-dialog',
    templateUrl: './add-edit-player-dialog.component.html',
    styleUrls: ['./add-edit-player-dialog.component.sass']
})
export class AddEditPlayerDialogComponent {
    public playerStretch = ['exactfit', 'uniform', 'fill', 'none'];
    public playerTypes = ['BTV', 'CBTV'];

    constructor(public dialogRef: MatDialogRef<AddEditPlayerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.playerTypes = data.player.playerType
        ? [this.playerTypes.find(x => x === data.player.playerType)].concat(this.playerTypes.filter(x => x !== data.player.playerType))
        : this.playerTypes;
    }

    public onNo(): void {
        this.dialogRef.close(false);
        this.data.isChangesConfirmed = false;
    }

    public isInvalid(): boolean {
        return !(this.data.player.playerType && this.data.player.name && this.data.player.webPageUrl && this.data.player.stretch);
    }

    public onYes(): void {
        this.dialogRef.close();
        this.data.isChangesConfirmed = true;

        this.dialogRef.close(cloneDeep(this.data));
    }

    public compareStretching(stretch1: string, stretch2: string) {
        return stretch1 && stretch1 ? stretch1 === stretch2 : false;
    }
}
