import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { RcsRole } from '../models/rcs-role';
import { Observable } from 'rxjs';
import { RoleBaseInfo } from '../../../../models/common/role-base-info';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class RcsRoleApiService {
    routePrefix: string = '/api/rcs/role';

    constructor(private httpService: HttpInternalService) {}

    getRole(roleId: number): Observable<HttpResponse<RcsRole>> {
        return this.httpService.getFullRequest<RcsRole>(`${this.routePrefix}`, { roleId: roleId });
    }

    getRoles(): Observable<HttpResponse<RcsRole[]>> {
        return this.httpService.getFullRequest<RcsRole[]>(`${this.routePrefix}`);
    }

    getRolesBaseInfo(): Observable<HttpResponse<RoleBaseInfo[]>> {
        return this.httpService.getFullRequest<RoleBaseInfo[]>(`${this.routePrefix}/allBaseInfo`);
    }

    addRole(role: RcsRole): Observable<HttpResponse<RcsRole>> {
        return this.httpService.putFullRequest<RcsRole>(`${this.routePrefix}`, role);
    }

    updateRole(role: RcsRole): Observable<HttpResponse<boolean>> {
        return this.httpService.postFullRequest<boolean>(`${this.routePrefix}`, role);
    }

    deleteRole(roleId: number): Observable<HttpResponse<boolean>> {
        return this.httpService.deleteFullRequest<boolean>(`${this.routePrefix}/${roleId}`);
    }
}
