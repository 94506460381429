import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { LocationBaseInfo } from '../models/common/location-base-info';
import { HttpInternalService } from './http.internal.service';
import { LocationBaseInfos } from '../models/common/location-base-infos';

@Injectable({
    providedIn: 'root'
})
export class LocationsApiService {
    public routePrefix = '/api/eventLocation';

    constructor(private httpService: HttpInternalService) { }
    public getLocations(
        page = 0,
        pageSize = 30,
        searchWord = '',
        isTwitchEvent = false
    ): Observable<HttpResponse<LocationBaseInfos>> {
        return this.httpService.getFullRequest<LocationBaseInfos>(
            `${this.routePrefix}/baseInfo`,
            { page, pageSize, searchWord, isTwitchEvent }
        );
    }

    public getSortedData(
        dataFeedIdFilter,
        sportCodeFilter,
        countryCodeFilter,
        showUllEnabledLocations,
        searchWord
    ): Observable<HttpResponse<LocationBaseInfo[]>> {
        return this.httpService.getFullRequest<LocationBaseInfo[]>(
            `${this.routePrefix}/sorted`,
            {
                dataFeedIdFilter,
                sportCodeFilter,
                countryCodeFilter,
                showUllEnabledLocations,
                searchWord
            }
        );
    }
}
