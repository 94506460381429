import { NotificationType } from 'src/app/models/notification/notificationType';

export class NotificationMessage {
    public message: string;
    public type: NotificationType;

    constructor(message: string, type: NotificationType) {
        this.message = message;
        this.type = type;
    }
}
