import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSnackBar, MatDialog, PageEvent, Sort } from '@angular/material';
import { Sport } from '../models/sport';
import { Subject, Subscription } from 'rxjs';
import { SportsApiService } from '../service/sports-api.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { SportsModel } from '../models/sports-model';
import { DialogType } from 'src/app/models/dialog/dialogType';
import { SportsDialogComponent } from '../sports-dialog/sports-dialog.component';
import { SportDialogData } from '../models/sport-dialog-data';

@Component({
    selector: 'app-sports',
    templateUrl: './sports.component.html',
    styleUrls: ['./sports.component.sass'],
})
export class SportsComponent implements OnInit {
    public pageSizeOptions: number[] = [20, 50, 100];
    public sportsDataSource = new MatTableDataSource<Sport>();
    public searchCriteria = '';
    public searchCriteriaChanged = new Subject<string>();
    public sportsSubscription: Subscription;
    public currentSort: string;
    public currentDirection: string;
    public dialogSubscription: Subscription;
    public sportUpdateSubscription: Subscription;
    public sportCreateSubscription: Subscription;
    public sportDeleteSubscription: Subscription;
    @ViewChild(MatPaginator) public paginator: MatPaginator;

    public sportsTableColumns: string[] = ['id', 'name', 'code', 'menu'];
    public currentPage = 0;
    public currentPageSize = 20;

    constructor(private snackBar: MatSnackBar, private streamersApiService: SportsApiService, private dialog: MatDialog) {
        this.searchCriteriaChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value: string) => {
            this.paginator.firstPage();
            this.getSports(0, this.paginator.pageSize, this.currentSort, true, value);
            this.searchCriteria = value;
        });
    }

    ngOnInit() {
        this.getSports(0, this.pageSizeOptions[0]);
    }

    public onSearchInputChanged(text: string) {
        this.searchCriteriaChanged.next(text);
    }

    public handlePaginator(event: PageEvent) {
        (this.currentPage = event.pageIndex), (this.currentPageSize = event.pageSize);
        this.getSports(event.pageIndex, event.pageSize, this.currentSort, true, this.searchCriteria);
    }

    public getSports(page: number, pageSize: number, orderByColumn?: string, ascending?: boolean, searchCriteria?: string) {
        this.sportsSubscription = this.streamersApiService.getSports(page, pageSize, orderByColumn, ascending, searchCriteria).subscribe(
            (response: HttpResponse<SportsModel>) => {
                this.sportsDataSource.data = response.body.sports;
                this.paginator.length = response.body.sportsCount;
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public sortData(sort: Sort) {
        if (sort.active && sort.direction) {
            const isAscDirection = sort.direction === 'asc';

            this.currentDirection = sort.direction;
            this.currentSort = sort.active;
            this.getSports(this.paginator.pageIndex, this.paginator.pageSize, this.currentSort, isAscDirection, this.searchCriteria);
        } else {
            this.currentDirection = undefined;
            this.currentSort = undefined;
            this.getSports(this.paginator.pageIndex, this.paginator.pageSize);
        }
    }

    public openAddDialog(event: any) {
        this.openDialog(event, new Sport(), DialogType.Add);
    }

    public openEditDialog(event: any, sport: Sport) {
        this.openDialog(event, sport, DialogType.Edit);
    }

    public openDialog(event: any, sport: Sport, type: DialogType) {
        event.stopPropagation();

        const dialog = this.dialog.open(SportsDialogComponent, {
            width: '400px',
            data: {
                dialogType: type,
                currentSport: sport,
                updatedModel: {
                    id: sport.id,
                    name: sport.name,
                    code: sport.code,
                },
            },
        });

        this.dialogSubscription = dialog.afterClosed().subscribe((result: SportDialogData) => {
            if (result && result.isChangesConfirmed) {
                result.dialogType === DialogType.Edit
                    ? this.updateStreamer(result.currentSport, result.updatedModel)
                    : this.createStreamer(result.updatedModel);
            }
        });
    }

    public sortSportsArray() {
        this.sportsDataSource.data = this.sportsDataSource.data.sort((a, b) => this.compareItems(a, b));

        if (this.currentDirection === 'desc') {
            this.sportsDataSource.data = this.sportsDataSource.data.reverse();
        }
    }

    public compareItems(a: Sport, b: Sport): number {
        const sortColumn = this.currentSort || 'id';

        return a[sortColumn] < b[sortColumn] ? -1 : a[sortColumn] > b[sortColumn] ? 1 : 0;
    }

    public updateStreamer(currentSportUpdated: Sport, sportToBeSaved: Sport) {
        this.sportUpdateSubscription = this.streamersApiService.updateSport(sportToBeSaved, currentSportUpdated.id).subscribe(
            (res) => {
                const index = this.sportsDataSource.data.findIndex((x) => x.id === currentSportUpdated.id);
                const arr = this.sportsDataSource.data;
                arr[index] = res.body;
                this.sportsDataSource.data = arr;
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public createStreamer(sportToBeSaved: Sport) {
        this.sportCreateSubscription = this.streamersApiService.createSport(sportToBeSaved).subscribe(() => {
            this.getSports(this.currentPage, this.currentPageSize);
        });
    }

    public deleteStreamer(sportId: number) {
        this.sportDeleteSubscription = this.streamersApiService.deleteSport(sportId).subscribe(() => {
            this.getSports(this.currentPage, this.currentPageSize);
        });
    }
}
