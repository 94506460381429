import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInternalService } from 'src/app/services/http.internal.service';
import { Sport } from '../models/sport';
import { SportsModel } from '../models/sports-model';

@Injectable()
export class SportsApiService {
    public routePrefix = '/api/sports';

    constructor(private httpService: HttpInternalService) {}

    public getSport(streamerId: number): Observable<HttpResponse<Sport>> {
        return this.httpService.getFullRequest<Sport>(`${this.routePrefix}`, { streamerId });
    }

    public getSports(
        page?: number,
        pageSize?: number,
        orderByColumn?: string,
        ascending?: boolean,
        searchCriteria = ''
    ): Observable<HttpResponse<SportsModel>> {
        return this.httpService.getFullRequest<SportsModel>(`${this.routePrefix}`, {
            page,
            pageSize,
            orderByColumn,
            ascending,
            searchCriteria,
        });
    }

    public updateSport(sportModel: Sport, currentId: number): Observable<HttpResponse<Sport>> {
        return this.httpService.putFullRequest<Sport>(`${this.routePrefix}/${currentId}`, sportModel);
    }

    public createSport(sportModel: Sport): Observable<HttpResponse<Response>> {
        return this.httpService.postFullRequest<Response>(`${this.routePrefix}`, sportModel);
    }

    public deleteSport(sportId: number): Observable<HttpResponse<Response>> {
        return this.httpService.deleteFullRequest<Response>(`${this.routePrefix}/${sportId}`);
    }
}
