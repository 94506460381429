import { LocationBaseInfo } from '../../../../models/common/location-base-info';
export class CustomerLocation {
    public customerLocationId: number;
    public customerId: number;
    public customerIds: number[];
    public iGMLocationCode: string;
    public streamId: number;
    public streamFriendlyName: string;
    public playerId: number;
    public locationId: number;
    public uniqueStreamNameId: string;
    public uniqueStreamNames: string[];
    public isActive: boolean;
    public isRawEnabled: boolean;
    public applyChangesToCustomerIds: number[];

    public playerName: string;
    public streamName: string;
    public customerName: string;
    public locationName: string;
    public countryCode: string;
    public sport: string;
    public sportCode: string;

    public dataFeed: string;
    public geoAllowCountries: string;

    public locations: LocationBaseInfo[];
}
