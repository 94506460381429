import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class VersionInfoService {
    constructor(private httpService: HttpInternalService) {
    }

    public getPlayerDevVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://player-dev.igamemedia.com/api/version');
    }

    public getPlayerStagingVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://player-staging.igamemedia.com:42443/api/version');
    }

    public getPlayerTestVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://player-test.igamemedia.com/api/version');
    }

    public getPlayerLiveVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://player.igamemedia.com/api/version');
    }

    public getReaderPushEndpointDevVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://importer-dev.igamemedia.com/api/version');
    }

    public getReaderPushEndpointTestVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://importer-test.igamemedia.com/api/version');
    }

    public getReaderPushEndpointLiveVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://importer.igamemedia.com/api/version');
    }

    public getLegacyMcrDevVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://mcrportal-dev.igamemedia.com/api/version');
    }

    public getLegacyMcrTestVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://mcrportal-test.igamemedia.com/api/version');
    }

    public getLegacyMcrLiveVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://mcrportal.igamemedia.com/api/version');
    }

    public getMcrDevVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://mcrportalrefresh-dev.igamemedia.com/api/version');
    }

    public getMcrTestVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://mcrportalrefresh-test.igamemedia.com/api/version');
    }

    public getMcrLiveVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://mcrportalrefresh.igamemedia.com/api/version');
    }

    public getRcsTraderDevVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://racecardtrader-dev.igamemedia.com/api/version');
    }

    public getRcsTraderStagingVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://racecardtrader-staging.igamemedia.com:42443/api/version');
    }

    public getRcsTraderTestVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://racecardtrader-test.igamemedia.com/api/version');
    }

    public getRcsTraderLiveVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://racecardtrader.igamemedia.com/api/version');
    }

    public getRcsSignalHubDevVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://racecardsignalhub-dev.igamemedia.com/api/version');
    }

    public getRcsSignalHubTestVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://racecardsignalhub-test.igamemedia.com/api/version');
    }

    public getRcsSignalHubLiveVersion(): Observable<HttpResponse<string>> {
        return this.httpService.getFullRequest<string>('https://racecardsignalhub.igamemedia.com/api/version');
    }
}
