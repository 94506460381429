import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { CustomerDialogData } from '../models/customer-dialog-data';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-add-edit-customer-dialog',
    templateUrl: './add-edit-customer-dialog.component.html',
    styleUrls: ['./add-edit-customer-dialog.component.sass'],
})
export class AddEditCustomerDialogComponent implements OnInit {

    public customerNameControl: FormControl;
    public customerUIDControl: FormControl;
    public customerAliasControl: FormControl;

    constructor(public dialogRef: MatDialogRef<AddEditCustomerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CustomerDialogData) { }

    public onNo(): void {
        this.data.isChangesConfirmed = false;
        this.dialogRef.close(false);
    }

    public onYes(): void {
        this.data.isChangesConfirmed = true;
        this.dialogRef.close(_.cloneDeep(this.data));
    }

    public ngOnInit() {
        this.customerNameControl = new FormControl(this.data.customer.customerName);
        this.customerUIDControl = new FormControl(this.data.customer.customerGuid);
        this.customerAliasControl = new FormControl(this.data.customer.alias);
    }

    public isFormValid() {
        return this.customerNameControl.valid &&
            this.customerUIDControl.valid &&
            this.customerAliasControl.valid;
    }
}
