export class RaceRequest {
    public page: number;
    public pageSize: number;
    public startDate: Date;
    public endDate: Date;
    public countryCodes: string[];
    public dataFeedIds: number[];

    constructor(page: number, pageSize: number, startDate: Date, endDate: Date, countryCodes: string[], dataFeedIds: number[]) {
        this.page = page;
        this.pageSize = pageSize;
        this.startDate = startDate;
        this.endDate = endDate;
        this.countryCodes = countryCodes;
        this.dataFeedIds = dataFeedIds;
    }
}
