import { Customer } from '../../../customer/customer/models/customer';
import { UserBaseInfo } from '../../../../models/common/user-base-info';

export class CustomerUser {
    public customer: Customer;
    public users: UserBaseInfo[];

    constructor() {
        this.customer = new Customer();
        this.users = [];
    }
}
