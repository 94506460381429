import { Injectable } from '@angular/core';
import { RcsRoleApiService } from './rcs-role-api.service';
import { NotificationSnackBarObserver } from '../../../../services/notification-snack-bar-observer';
import { NotificationMessage } from '../../../../models/notification/notificationMessage';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { NotificationType } from '../../../../models/notification/notificationType';
import { RcsRole } from '../models/rcs-role';
import { RoleBaseInfo } from '../../../../models/common/role-base-info';

@Injectable({
    providedIn: 'root'
})
export class RcsRoleService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);

    constructor(private roleApiService: RcsRoleApiService, private notificationObserver: NotificationSnackBarObserver) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getRole(roleId: number): Observable<RcsRole> {
        return this.roleApiService.getRole(roleId).pipe(
            map(
                (response: HttpResponse<RcsRole>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject
                        .next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public getRolesBaseInfo(): Observable<RoleBaseInfo[]> {
        return this.roleApiService.getRolesBaseInfo().pipe(
            map(
                (response: HttpResponse<RoleBaseInfo[]>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject
                        .next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public getRoles(): Observable<RcsRole[]> {
        return this.roleApiService.getRoles().pipe(
            map(
                (response: HttpResponse<RcsRole[]>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject
                        .next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public addRole(newRole: RcsRole): Observable<RcsRole> {
        return this.roleApiService.addRole(newRole).pipe(
            map(
                (response: HttpResponse<RcsRole>) => {
                    this.notificationSubject
                        .next(new NotificationMessage(`Role '${newRole.roleName}' was added`, NotificationType.Adding));
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject
                        .next(new NotificationMessage(`Role '${newRole.roleName}' was not added`, NotificationType.Error));
                }
            )
        );
    }

    public updateRole(updatedRole: RcsRole): Observable<boolean> {
        return this.roleApiService.updateRole(updatedRole).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    if (response.body) {
                        this.notificationSubject.next(
                            new NotificationMessage(`Role '${updatedRole.roleName}' was updated`, NotificationType.Updating)
                        );
                    } else {
                        this.notificationSubject.next(
                            new NotificationMessage(`Role '${updatedRole.roleName}' was not updated`, NotificationType.Error)
                        );
                    }
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Role '${updatedRole.roleName}' was not updated`, NotificationType.Error)
                    );

                    return false;
                }
            )
        );
    }

    public deleteRole(removedRole: RcsRole): Observable<boolean> {
        return this.roleApiService.deleteRole(removedRole.roleId).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    if (response.body) {
                        this.notificationSubject.next(
                            new NotificationMessage(`Role '${removedRole.roleName}' was removed`, NotificationType.Removing)
                        );
                    } else {
                        this.notificationSubject.next(
                            new NotificationMessage(`Role '${removedRole.roleName}' was not removed`, NotificationType.Error)
                        );
                    }
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Role '${removedRole.roleName}' was not removed`, NotificationType.Error)
                    );

                    return false;
                }
            )
        );
    }
}
