import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { DataFeedInfo } from '../models/data-feeds';

@Injectable()
export class ProviderDataFeedApiService {
    public routePrefix = '/api/providerCode';
    private providerCodesCount = 0;

    constructor(private httpService: HttpInternalService) { }

    public countOfProviderCodes(): number {
        return this.providerCodesCount;
    }

    public getDataFeeds(): Observable<HttpResponse<DataFeedInfo[]>> {
        return this.httpService.getFullRequest<DataFeedInfo[]>(`${this.routePrefix}/all`);
    }
}
