import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { CustomerRace } from '../models/customer-race';
import { CustomerMeeting } from '../models/customer-meeting';
import { PushRaces } from '../models/push-races';
import { PushMeetings } from '../models/push-meetings';

@Injectable()
export class PushApiService {
    private routePrefix = '/api/rcs/push/';

    constructor(private httpService: HttpInternalService) {}

    public getSubscribedRaces(customerId: number, page: number, pageSize: number): Observable<HttpResponse<PushRaces>> {
        return this.httpService.getFullRequest<PushRaces>(`${this.routePrefix}races`, { customerId, page, pageSize });
    }

    public getSubscribedMeetings(customerId: number, page: number, pageSize: number): Observable<HttpResponse<PushMeetings>> {
        return this.httpService.getFullRequest<PushMeetings>(`${this.routePrefix}meetings`, { customerId, page, pageSize });
    }

    public pushRaceToCustomer(customerRace: CustomerRace): Observable<HttpResponse<boolean>> {
        return this.httpService.postFullRequest<boolean>(`${this.routePrefix}race`, customerRace);
    }

    public pushMeetingToCustomer(customerMeeting: CustomerMeeting): Observable<HttpResponse<boolean>> {
        return this.httpService.postFullRequest<boolean>(`${this.routePrefix}meeting`, customerMeeting);
    }
}
