import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-event-issues-dialog',
    templateUrl: './event-issues-dialog.component.html',
    styleUrls: ['./event-issues-dialog.component.sass']
})
export class EventIssuesDialogComponent {

    public eventsIssuesDataSource = [];

    public displayedColumns: string[] = [
        'category',
        'description',
        'modifiedBy',
        'modifiedDate',
        'sport',
        'menu'
    ];

    constructor(
        public dialogRef: MatDialogRef<EventIssuesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
}
