import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { DetailedStreamerSubscription, SubscriptionFormData } from './models';
import { Subject } from 'rxjs';
import { StreamerSubscriptionApiService } from './streamer-subscription-api.service';
import { CreateStreamerSubscriptionDialog } from './dialog/streamer-subscription-dialog';

@Component({
  selector: 'app-streamer-subscription',
  templateUrl: './streamer-subscription.component.html',
  styleUrls: ['./streamer-subscription.component.sass']
})
export class StreamerSubscriptionComponent implements OnInit {

  public pageSizeOptions: number[] = [20, 50, 100];
  public subscriptionsDataSource = new MatTableDataSource<DetailedStreamerSubscription>();
  public searchCriteria = '';
  public searchCriteriaChanged = new Subject<string>();
  public subscriptionsTableColumns: string[] = ['id', 'customerName', 'streamerName', 'menu'];
  public subscriptionFormData: SubscriptionFormData;

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  constructor(public apiService: StreamerSubscriptionApiService, public dialog: MatDialog) { }

  ngOnInit() {
    this.loadSubscriptions();
    this.loadFormData();
  }

  public loadSubscriptions() {
    this.apiService.getSubscriptions().subscribe(resp => {
      this.subscriptionsDataSource.data = resp.body;
    })
  }

  public loadFormData() {
    this.apiService.getFormData().subscribe(resp => {
      this.subscriptionFormData = resp.body;
    })
  }

  public deleteSubscription(id) {
    this.apiService.deleteSubscription(id).subscribe(resp => {
      if (resp.ok) {
        this.subscriptionsDataSource.data = this.subscriptionsDataSource.data.filter(x => x.id != id);
      }
    })
  }

  private createDialog() {
    return this.dialog.open(CreateStreamerSubscriptionDialog, {
      width: '500px',
      data: {
        isChangesConfirmed: false,
        formData: this.subscriptionFormData
      }
    });
  }

  public openAddDialog() {
    this.createDialog().afterClosed().subscribe((result) => {
      if (result && result.isChangesConfirmed === true) {
        this.apiService.createSubscription(result.object).subscribe(resp => {
          if (resp.ok) {
            this.loadSubscriptions();
          }
        });
      }
    });
  }
}
