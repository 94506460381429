import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { QueueMaintainApiService } from './services/queue-maintain-api.service';
import { QueueModel } from './models/queue-model';
import { MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { DialogConfirmationComponent } from 'src/app/components/dialog-confirmation/dialog-confirmation.component';
import { QueuesMaintainingDialogComponent } from './queues-maintaining-dialog/queues-maintaining-dialog.component';

@Component({
    selector: 'app-queues-maintaining',
    templateUrl: './queues-maintaining.component.html',
    styleUrls: ['./queues-maintaining.component.sass']
})
export class QueuesMaintainingComponent implements OnInit, OnDestroy {
    public subscription = new Subscription();
    public queueModels = new MatTableDataSource<QueueModel>();
    public columns: string[] = ['name', 'state', 'ready', 'unacked', 'total', 'incoming', 'deliver', 'ack', 'purge', 'push'];
    public timer: any;

    constructor(private queueMaintainApiService: QueueMaintainApiService, private snackBar: MatSnackBar, private dialog: MatDialog) {}

    public ngOnInit() {
        this.getQueues();
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public getQueues() {
        this.subscription.add(
            this.queueMaintainApiService.getQueues().subscribe(
                (resp) => {
                    this.queueModels.data = resp.body;
                },
                (error) => this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' })
            )
        );
    }

    public purgeQueue(vhost: string, queueName: string) {
        const dialog = this.dialog.open(DialogConfirmationComponent, {
            maxWidth: 500,
            data: { customMessage: `Are you sure you want to remove all messages from the ${queueName}` }
        });

        dialog.afterClosed().subscribe((observer) => {
            if (observer) {
                this.subscription.add(
                    this.queueMaintainApiService.purgeQueue(encodeURIComponent(vhost), queueName).subscribe(
                        (resp) => {
                            if (resp.body) {
                                this.snackBar.open('Successfully purged the queue', '', { duration: 3000, panelClass: 'usual-snack-bar' });
                            }
                        },
                        (error) => this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' })
                    )
                );
            }
        });
    }

    public pushToQueue(queueName: string) {
        const dialog = this.dialog.open(QueuesMaintainingDialogComponent, {
            data: { queueName },
            maxWidth: 500
        });

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.subscription.add(
                    this.queueMaintainApiService.pushToQueue(result).subscribe(
                        (resp) => {
                            if (resp.body) {
                                this.snackBar.open('Successfully pushed to the queue', '', { duration: 3000, panelClass: 'usual-snack-bar' });
                            }
                        },
                        (error) => this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' })
                    )
                );
            }
        });
    }

    public isQueueAllowedToBePushed = (queueName: string) => queueName.indexOf('PRM') === -1 && queueName.indexOf('ECS') === -1;

    public getQueueState(queue: QueueModel) {
        return queue.idle ? 'Idle since ' + new DatePipe('en-US').transform(queue.idle, 'short') : queue.state;
    }
}
