import { CustomerBaseInfo } from '../../../../models/common/customer-base-info';

export class Customer implements CustomerBaseInfo {
    public customerId: number;
    public customerGuid: string;
    public customerName: string;
    public alias: string;
    public isThereAnyUsers: boolean;
    public hasChannelAlias: boolean;
}
