import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentDeliveryNetworkApiService } from './content-delivery-network-api.service';
import { ContentDeliveryNetwork } from '../models/content-delivery-network';

@Injectable()
export class ContentDeliveryNetworkService {
    constructor(private contentDeliveryNetworkApiService: ContentDeliveryNetworkApiService) {}

    public getAllCDNCodes(): Observable<ContentDeliveryNetwork[]> {
        return this.contentDeliveryNetworkApiService.getAllContentDeliveryNetwork().pipe(
            map(
                (response) => {
                    return response.body as ContentDeliveryNetwork[];
                },
                (error) => {
                    console.log(`Error: ${error}`);
                }
            )
        );
    }
}
