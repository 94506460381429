import { Component, Inject } from '@angular/core';
import { cloneDeep } from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-add-virtual-edit-player-dialog',
    templateUrl: './add-edit-virtual-player-dialog.component.html',
    styleUrls: ['./add-edit-virtual-player-dialog.component.sass']
})
export class AddEditVirtualPlayerDialogComponent {
    public streamTypes = ['TRNI', 'HLS', 'DASH'];
    public quality = ['web', 'mobhi', 'adpt', 'moblo'];

    constructor(public dialogRef: MatDialogRef<AddEditVirtualPlayerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(this.data);
        this.streamTypes = this.data.virtualPlayer.streamType
            ? [this.streamTypes.find(x => x === this.data.virtualPlayer.streamType)]
                .concat(this.streamTypes.filter(x => x !== this.data.virtualPlayer.streamType))
            : this.streamTypes;
    }

    public onNo(): void {
        this.dialogRef.close(false);
        this.data.isChangesConfirmed = false;
    }

    public isInvalid(): boolean {
        return !(this.data.virtualPlayer.streamType
            && this.data.virtualPlayer.name
            && this.data.virtualPlayer.streamLink
            && this.data.virtualPlayer.sport);
    }

    public onYes(): void {
        this.dialogRef.close();
        this.data.isChangesConfirmed = true;
        this.dialogRef.close(cloneDeep(this.data));
    }

    public compareQuality(quality1: string, quality2: string) {
        return quality1 && quality1 ? quality1 === quality2 : false;
    }
}
