import { Component, OnInit } from '@angular/core';
import { CustomerDomain } from './models/customer-domain';
import { CustomerBaseInfo } from '../../../models/common/customer-base-info';
import { CustomerService } from '../customer/services/customer.service';
import { CustomerDomainService } from './services/customer-domain.service';
import { NotificationSnackBarObserver } from '../../../services/notification-snack-bar-observer';
import { MatDialog, MatPaginator } from '@angular/material';
import { CustomerDomainDialogData } from './models/customer-domain-dialog-data';
import { DialogType } from '../../../models/dialog/dialogType';
import { DialogConfirmationComponent } from '../../../components/dialog-confirmation/dialog-confirmation.component';
import { AddEditCustomerDomainDialogComponent } from './add-edit-customer-domain-dialog/add-edit-customer-domain-dialog.component';
import { cloneDeep, map, remove, concat } from 'lodash';
import { DialogConfirmationData } from '../../../models/dialog/dialog-confirmation-data';
import { MatTableDataSource } from '@angular/material';
import { CustomerDomainsWithTotal } from './models/customer-domains-with-total';
import { HttpResponse } from '@angular/common/http';
import { NotificationMessage } from 'src/app/models/notification/notificationMessage';
import { NotificationType } from 'src/app/models/notification/notificationType';
import { ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material';

@Component({
    selector: 'app-customer-domain',
    templateUrl: './customer-domain.component.html',
    styleUrls: ['./customer-domain.component.sass']
})
export class CustomerDomainComponent implements OnInit {
    public customersBaseInfo: CustomerBaseInfo[];
    public displayedColumns: string[] = ['CustomerDomainId', 'CustomerName', 'CustomerIP', 'Menu'];

    @ViewChild('pagination') public pagination: MatPaginator;
    public customerDomainDataSource = new MatTableDataSource<CustomerDomain>();

    public customerDomainsCount: number;

    public currentPage = 0;
    public currentPageSize = 10;

    public pageSizeOptions = [10, 15, 25];

    constructor(
        private customerService: CustomerService,
        private customerDomainService: CustomerDomainService,
        private notificationSnackBarObserver: NotificationSnackBarObserver,
        public dialog: MatDialog
    ) {}

    public ngOnInit() {
        this.getCustomerDomains();
        this.loadCustomersBaseInfo();
    }

    public getCustomerDomains(pageIndex = 0, pageSize = 10) {
        this.customerDomainService
            .getCustomerDomains(pageIndex, pageSize)
            .subscribe(
                (response: HttpResponse<CustomerDomainsWithTotal>) => {
                    this.customerDomainDataSource.data = response.body.customerDomainViewModels;
                    this.customerDomainsCount = response.body.customerDomainsCount;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSnackBarObserver.next(
                        new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                    );
                }
            );
    }

    public loadCustomersBaseInfo() {
        this.customerService
            .getCustomersBaseInfo()
            .subscribe(
                    (customers: CustomerBaseInfo[]) => (this.customersBaseInfo = customers)
                );
    }

    public updateCustomerDomain(updatedCustomerDomain: CustomerDomain) {
        this.customerDomainService
            .updateCustomerDomain(updatedCustomerDomain)
            .subscribe(
                (response: HttpResponse<boolean>) => {
                    if (response.body) {
                        this.customerDomainDataSource.data = map(
                            this.customerDomainDataSource.data,
                            (customerDomain) => {
                                return customerDomain.customerDomainId === updatedCustomerDomain.customerDomainId
                                    ? updatedCustomerDomain
                                    : customerDomain;
                            });
                        this.notificationSnackBarObserver
                            .next(new NotificationMessage('Customer Domain updated', NotificationType.Updating));
                    } else {
                        this.notificationSnackBarObserver
                            .next(new NotificationMessage('CustomerDomain was not updated', NotificationType.Error));
                    }
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSnackBarObserver
                        .next(new NotificationMessage('Customer was not updated', NotificationType.Error));
                }
            );
    }

    public addCustomerDomain(newCustomerDomain: CustomerDomain) {
        this.customerDomainService
            .addCustomerDomain(newCustomerDomain)
            .subscribe(
                (response: HttpResponse<CustomerDomain>) => {
                    if (Math.floor(this.customerDomainsCount / this.currentPageSize) === this.currentPage) {
                        this.customerDomainDataSource.data = concat(
                            this.customerDomainDataSource.data,
                            response.body
                        );
                    } else {
                        const page = Math.floor(this.customerDomainsCount / this.currentPageSize);
                        this.getCustomerDomains(page, this.currentPageSize);
                        this.pagination.pageIndex = page;
                    }
                    this.customerDomainsCount += 1;
                    this.notificationSnackBarObserver.next(
                        new NotificationMessage(
                            `Customer Domain for Customer '${newCustomerDomain.customer.customerName}' added`,
                            NotificationType.Adding
                        )
                    );
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSnackBarObserver.next(
                        new NotificationMessage(
                            `Customer Domain '${newCustomerDomain.customer.customerName}' was not added`,
                             NotificationType.Error
                        )
                    );
                }
            );
    }

    public removeCustomerDomain(removedCustomerDomain: CustomerDomain) {
        this.customerDomainService
            .deleteCustomerDomain(removedCustomerDomain.customerDomainId)
            .subscribe(
                (response: HttpResponse<boolean>) => {
                    if (response.body) {
                        this.notificationSnackBarObserver.next(
                            new NotificationMessage(
                                `Customer Domain for Customer '${removedCustomerDomain.customer.customerName}' removed`,
                                NotificationType.Removing
                            )
                        );
                        if (((this.customerDomainsCount % this.currentPageSize) - 1) !== 0) {
                        this.customerDomainDataSource.data = remove(
                                this.customerDomainDataSource.data,
                                (item) => item.customerDomainId !== removedCustomerDomain.customerDomainId
                            );
                        } else {
                            this.getCustomerDomains(this.currentPage - 1, this.currentPageSize);
                            this.pagination.pageIndex = this.currentPage;
                        }
                    } else {
                        this.notificationSnackBarObserver.next(
                            new NotificationMessage(
                                `Customer Domain for Customer '${removedCustomerDomain.customer.customerName}' was not removed`,
                                NotificationType.Error
                            )
                        );
                    }
                },
                (error) => {
                    this.notificationSnackBarObserver.next(
                        new NotificationMessage(
                            `Customer Domain for Customer '${removedCustomerDomain.customer.customerName}' was not removed`,
                            NotificationType.Error
                        )
                    );
                }
        );
    }

    public changeCustomerDomainPage(page: PageEvent) {
        this.getCustomerDomains(page.pageIndex, page.pageSize);
        this.currentPage = page.pageIndex;
        this.currentPageSize = page.pageSize;
    }

    public openAddDialog() {
        this.createAddEditDialog(new CustomerDomain(), DialogType.Add)
            .afterClosed()
            .subscribe((result: CustomerDomainDialogData) => {
                if (result && result.isChangesConfirmed) {
                    this.addCustomerDomain(result.customerDomain);
                }
            });
    }

    public openEditDialog(customerDomain: CustomerDomain) {
        this.createAddEditDialog(cloneDeep(customerDomain), DialogType.Edit)
            .afterClosed()
            .subscribe((result: CustomerDomainDialogData) => {
                if (result && result.isChangesConfirmed) {
                    this.updateCustomerDomain(result.customerDomain);
                }
            });
    }

    public openRemoveDialog(customerDomain: CustomerDomain) {
        this.dialog
            .open(DialogConfirmationComponent, {
                width: '400px',
                data: new DialogConfirmationData()
            })
            .afterClosed()
            .subscribe((isRemovingConfirmed: boolean) => {
                if (isRemovingConfirmed) {
                    this.removeCustomerDomain(customerDomain);
                }
            });
    }

    public createAddEditDialog(customerDomain: CustomerDomain, dialogType: DialogType) {
        return this.dialog.open(AddEditCustomerDomainDialogComponent, {
            width: '500px',
            data: new CustomerDomainDialogData(dialogType, customerDomain, this.customersBaseInfo)
        });
    }
}
