import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { Streams } from '../models/streams';
import { HttpResponse } from '@angular/common/http';
import { Stream } from '../models/stream';
import { IgmStreamInput } from '../../test-web-services/models/igm-stream-input';
import { EventStreamLink } from '../../test-web-services/models/event-stream-link';
import { EventStreamList } from '../../test-web-services/models/event-stream-list';
import { environment } from '../../../../../environments/environment';
@Injectable()
export class StreamApiService {
    private streamApiUrl = environment.streamApiUrl;
    private routePrefix = '/api/stream';
    constructor(private httpService: HttpInternalService) { }

    public getStreamsByEvent(customerUid: string, eventId: string): Observable<HttpResponse<EventStreamList>> {
        return this.httpService.getFullRequest(`${this.streamApiUrl}${this.routePrefix}/${customerUid}/${eventId}`, undefined);
    }

    public getStreamLink(igmStreamInput: IgmStreamInput): Observable<HttpResponse<EventStreamLink>> {
        const query = `customerUid=${igmStreamInput.customerUid}&eventId=${igmStreamInput.eventId}`
                     + `&redirectUrl=${igmStreamInput.redirectUrl}&streamName=${igmStreamInput.streamName}`
                     + `&userIp=${igmStreamInput.userIp}&userId=${igmStreamInput.userId}`;
        return this.httpService.postFullRequest(`${this.streamApiUrl}${this.routePrefix}/link?${query}`, null);
    }
    public getStreams(page = 0, pageSize = 10, searchWord?: string): Observable<HttpResponse<Streams>> {
        return this.httpService.getFullRequest(`${this.routePrefix}`, { page, pageSize, searchWord });
    }

    public getStream(streamId: number): Observable<HttpResponse<Stream>> {
        return this.httpService.getFullRequest(`${this.routePrefix}/${streamId}`);
    }

    public createStream(stream: Stream): Observable<HttpResponse<Stream>> {
        return this.httpService.postFullRequest(`${this.routePrefix}`, stream);
    }

    public updateStream(stream: Stream): Observable<HttpResponse<any>> {
        return this.httpService.putFullRequest(`${this.routePrefix}`, stream);
    }

    public deleteStream(streamId: number): Observable<HttpResponse<any>> {
        return this.httpService.deleteFullRequest(`${this.routePrefix}/${streamId}`);
    }

    public downloadStreamsFile(): Observable<HttpResponse<Blob>> {
        return this.httpService.downloadFile(`${this.routePrefix}/download`);
    }
}
