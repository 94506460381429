import { DialogType } from '../../../../models/dialog/dialogType';
import { CustomerDomain } from './customer-domain';
import { CustomerBaseInfo } from '../../../../models/common/customer-base-info';

export class CustomerDomainDialogData {
    dialogType: DialogType;
    customerDomain: CustomerDomain;
    allcustomers: CustomerBaseInfo[];
    isChangesConfirmed: boolean = false;

    constructor(dialogType: DialogType, customerDomain: CustomerDomain, allcustomers: CustomerBaseInfo[], isChangesConfirmed?: boolean) {
        this.dialogType = dialogType;
        this.customerDomain = customerDomain;
        this.allcustomers = allcustomers;
        this.isChangesConfirmed = isChangesConfirmed;
    }
}
