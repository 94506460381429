import { ManageEventsParams } from '../models/manage-events-params';
import { Injectable } from '@angular/core';
import { ManageEventsFilters } from '../models/manage-events-filters';

@Injectable()
export class FiltersStateService {
    public state: ManageEventsParams;

    constructor() {
        this.state = new ManageEventsParams();
        this.state.filters = new ManageEventsFilters();
    }
}
