import { Subscription } from 'rxjs/internal/Subscription';
import { GeneratedStream } from './../models/generated-stream';
import { CustomerBaseInfo } from './../../../../models/common/customer-base-info';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventHistoryApiService } from 'src/app/pages/event/event-history/services/event-history-api.service';
import { MatTableDataSource, MatSnackBar, MatCheckboxChange } from '@angular/material';
import { EventAuditModel } from 'src/app/pages/event/event-history/models/event-audit';
import { HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CustomerLocationApiService } from '../../customer-locations/services/customer-locations-api.service';
import { CustomerLocation } from '../../customer-locations/models/customer-location';
import { McrScheduleService } from '../services/mcr-schedule.service';
import { CustomerStream } from 'src/app/models/common/customer-stream';
import { StreamBaseInfo } from 'src/app/models/common/stream-base-info';
import { uniqBy } from 'lodash';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { IgmStreamInput } from 'src/app/pages/bet-n-watch/test-web-services/models/igm-stream-input';
import {StreamApiService} from "../../../bet-n-watch/stream/services/stream-api.service";
import {EventStreamLink} from "../../../bet-n-watch/test-web-services/models/event-stream-link";

@Component({
    selector: 'app-event-inspection',
    templateUrl: './event-inspection.component.html',
    styleUrls: ['./event-inspection.component.sass'],
})
export class EventInspectionComponent implements OnInit, OnDestroy {
    public eventId: string;
    public streamSource: SafeUrl;
    public showLeftSide = true;
    public showRightSide = true;
    public showInfo = true;
    public showOtherCustomers = false;
    public selectedCustomerId = -1;
    public selectedStreamId: number;
    public error;

    public stream: CustomerStream = new CustomerStream();

    public eventAuditSource = new MatTableDataSource<EventAuditModel>();
    public customerLocationsSource = new MatTableDataSource<CustomerLocation>();

    public customers: CustomerBaseInfo[] = [];
    public streams: StreamBaseInfo[] = [];
    public otherCustomers: CustomerBaseInfo[] = [];

    public customerStreams: CustomerStream[] = [];

    public eventAuditColumns = ['id', 'propertyName', 'value', 'timeChanged', 'autoUpdating', 'user'];
    public customerLocationsColumns = ['id', 'customer', 'dataFeed', 'location', 'streamId', 'stream', 'isActive'];

    private subscription: Subscription = new Subscription();

    constructor(
        private eventHistoryService: EventHistoryApiService,
        private scheduleService: McrScheduleService,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private customerLocationService: CustomerLocationApiService,
        private streamApiService: StreamApiService,
        private sanitizer: DomSanitizer
    ) { }

    public ngOnInit() {
        this.subscription.add(
            this.activatedRoute.params.subscribe((params: Params) => {
                this.eventId = params.id;
                this._loadAdits(this.eventId);
                this._loadCustomerLocations(this.eventId);
                this._loadCustomerStreams(this.eventId);
                this.streamSource = this.sanitizer.bypassSecurityTrustResourceUrl('');
            })
        );
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public onIsActiveChange(changeEvent: MatCheckboxChange, customerLocation: CustomerLocation) {
        this.subscription.add(
            this.customerLocationService.updateCustomerLocationIsActive(customerLocation.customerLocationId, changeEvent.checked).subscribe(
                (response: HttpResponse<boolean>) => {
                    if (response.body) {
                        customerLocation.isActive = !customerLocation.isActive;
                    }
                },
                (error) => {
                    this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
                    this.customerLocationsSource.data = [];
                }
            )
        );
    }

    public toggleLeftSide() {
        this.showLeftSide = !this.showLeftSide;
    }

    public toggleRightSide() {
        this.showRightSide = !this.showRightSide;
    }

    public toggleInfo() {
        this.showInfo = !this.showInfo;
    }

    public onSelectCustomer(id: number) {
        this.selectedCustomerId = id;
        this.streamSource = this.sanitizer.bypassSecurityTrustResourceUrl('');
        this.streams = uniqBy(
            this.customerStreams.filter(x => x.customerId == id).map(i => new StreamBaseInfo(i.streamId, i.streamType, i.streamName)),
            'id'
        );
        this.otherCustomers = this.customers;
        this.showOtherCustomers = true;
    }

    public onSelectStream(id: number) {
        if (this.selectedCustomerId === -1) {
            return;
        }
        this.selectedStreamId = id;
        this.stream = this.customerStreams.find(
            (x) => x.streamId === this.selectedStreamId && (x.customerId === this.selectedCustomerId || this.selectedCustomerId === 0)
        );

        if (this.stream.streamType === 'Twitch Stream') {
            return this._getStreamLink({
                userId: 'IGM-TEST',
                userIp: null,
                customerUid: this.stream.customerUID,
                streamName: this.stream.streamLink,
                eventId: this.eventId,
                redirectUrl: null,
            });
        }
        this._loadStream(this.eventId, this.stream.streamId, this.stream.customerId);
    }

    private _getStreamLink(params: IgmStreamInput) {
        this.subscription.add(
            this.streamApiService.getStreamLink(params).subscribe(
                (response: HttpResponse<EventStreamLink>) => {
                    if (!response.body.streamLink) {
                        this.error.emit(response.body.message || 'Unexpected error');
                    }
                    this.streamSource = this.sanitizer.bypassSecurityTrustResourceUrl(response.body.streamLink);
                    this.stream.streamLink = response.body.streamLink;
                },
                (error) => {
                    this.error.emit(error.message);
                }
            ));
    }

    private _loadAdits(eventId: string) {
        this.subscription.add(
            this.eventHistoryService.getEventAudits(eventId).subscribe(
                (response: HttpResponse<EventAuditModel[]>) => {
                    this.eventAuditSource.data = response.body as EventAuditModel[];
                },
                (error) => {
                    this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
                    this.eventAuditSource.data = [];
                }
            )
        );
    }

    private _loadCustomerStreams(eventId: string) {
        this.subscription.add(
            this.scheduleService.getCustomerStreamsByEvent(eventId).subscribe((data: CustomerStream[]) => {
                this.customerStreams = data;
                this.customers = uniqBy(
                    data.map((i) => new CustomerBaseInfo(i.customerId, i.customerName)),
                    'customerId'
                );
            })
        );
    }

    private _loadStream(eventId: string, streamId: number, customerId: number) {
        this.subscription.add(
            this.scheduleService.loadStream(eventId, streamId, customerId).subscribe((response: HttpResponse<GeneratedStream>) => {
                const data = response.body;
                this.streamSource = this.sanitizer.bypassSecurityTrustResourceUrl(data.fullLinkForLivePlayer);
                this.stream.streamLink = data.streamLink;
                console.log(this.streamSource);
                console.log(this.stream.streamLink);
            },
                (error) => {
                    this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
                })
        );
    }

    private _loadCustomerLocations(eventId: string) {
        this.subscription.add(
            this.customerLocationService.getCustomerLocationsByEvent(eventId).subscribe(
                (response: HttpResponse<CustomerLocation[]>) => {
                    this.customerLocationsSource.data = response.body as CustomerLocation[];
                },
                (error) => {
                    this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
                    this.customerLocationsSource.data = [];
                }
            )
        );
    }
}
