import { AccessNotAllowedComponent } from './pages/access-not-allowed/access-not-allowed.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AuthGuard } from './guards/auth.guard';
import { Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TestEventGeneratorComponent } from './pages/event/test-event-generator/test-event-generator.component';
import { VersionInfoComponent } from './pages/dev/version-info/version-info.component';
import { RoleComponent } from './pages/system/role/role.component';
import { UserComponent } from './pages/system/user/user.component';
import { CustomerComponent } from './pages/customer/customer/customer.component';
import { ToolsComponent } from './pages/tools/tools.component';
import { HomeComponent } from './components/home/home.component';
import { CustomerDomainComponent } from './pages/customer/customer-domain/customer-domain.component';
import { PushComponent } from './pages/race-card/push/push.component';
import { TestWebServicesComponent } from './pages/bet-n-watch/test-web-services/test-web-services.component';
import { UserDatafeedsComponent } from './pages/bet-n-watch/user-datafeeds/user-datafeeds.component';
import { RawImportComponent } from './pages/data-feed/raw-import/raw-import.component';
import { RaceAuditComponent } from './pages/race-card/race-audit/race-audit.component';
import { EventHistoryComponent } from './pages/event/event-history/event-history.component';
import { StreamMonitorComponent } from './pages/stream-monitor/stream-monitor.component';
import { LocationComponent } from './pages/event/location/location.component';
import { AutoGenerateCustomerLocationComponent } from './pages/bet-n-watch/auto-generate-customer-location/auto-generate-customer-location.component';
import { StreamComponent } from './pages/bet-n-watch/stream/stream.component';
import { CustomerSettingComponent } from './pages/race-card/customer-setting/customer-setting.component';
import { ProviderComponent } from './pages/3rd-party-services/provider-codes/provider-codes.component';
import { CustomerLocationsComponent } from './pages/mcr-monitoring/customer-locations/customer-locations.component';
import { PlayerComponent } from './pages/bet-n-watch/player/player.component';
import { VirtualPlayerComponent } from './pages/bet-n-watch/virtual-player/virtual-player.component';
import { LiveScheduleComponent } from './pages/mcr-monitoring/mcr-schedule/live-schedule.component';
import { EventFlightBoardComponent } from './pages/event/event-flight-board/event-flight-board.component';
import { QueuesMaintainingComponent } from './pages/data-feed/queues-maintaining/queues-maintaining.component';
import { EventInspectionComponent } from './pages/mcr-monitoring/mcr-schedule/event-inspection/event-inspection.component';
import { RoleGuard } from './guards/role.guard';
import { DataFeedComponent } from './pages/data-feed/data-feed-configuration/data-feed.component';
import { DataFeedsRawDataComponent } from './pages/race-card/datafeeds-raw-data/datafeeds-raw-data.component';
import { EventsComponent } from './pages/event/events/events.component';
import { StreamersComponent } from './pages/event/streamers/streamers/streamers.component';
import { SportsComponent } from './pages/event/sports/sports/sports.component';
import { StreamerSubscriptionComponent } from './pages/event/streamer-subscription/streamer-subscription.component';
import { RcsCustomerComponent } from './pages/race-card/customer/rcs-customer.component';
import { RcsDataFeedComponent } from './pages/race-card/data-feed/rcs-data-feed.component';
import { RcsRoleComponent } from './pages/race-card/role/rcs-role.component';
import { RcsUserComponent } from './pages/race-card/user/rcs-user.component';

const allowedRoles = (roles: string[]): {} => {
    return { allowedRoles: roles };
};
export const AppRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivateChild: [AuthGuard, RoleGuard],
        children: [
            { path: '', redirectTo: 'streaming/live-schedule', pathMatch: 'full' },
            {
                path: 'system',
                children: [
                    { path: '', redirectTo: 'users', pathMatch: 'full' },
                    { path: 'users', component: UserComponent },
                    { path: 'roles', component: RoleComponent },
                    {
                        path: 'customer',
                        children: [
                            { path: '', redirectTo: 'customers', pathMatch: 'full' },
                            { path: 'customers', component: CustomerComponent },
                            { path: 'customer-domains', component: CustomerDomainComponent },
                        ],
                    },
                    {
                        path: 'data-feed',
                        children: [
                            { path: '', redirectTo: 'import-history', pathMatch: 'full' },
                            { path: 'import-history', component: RawImportComponent },
                            { path: 'queues-maintaining', component: QueuesMaintainingComponent },
                            // { path: 'user-datafeeds', component: UserDatafeedsComponent },
                            { path: 'data-feeds', component: DataFeedComponent },
                        ],
                    },
                    {
                        path: '3rd-party-services',
                        children: [
                            { path: 'provider-codes', component: ProviderComponent }
                        ],
                    },
                ],
            },
            {
                path: 'race-card',
                children: [
                    { path: 'customer', component: RcsCustomerComponent },
                    { path: 'data-feed', component: RcsDataFeedComponent },
                    { path: 'role', component: RcsRoleComponent },
                    { path: 'user', component: RcsUserComponent },
                    { path: 'push', component: PushComponent },
                    { path: 'race-audit', component: RaceAuditComponent },
                    { path: 'customer-setting', component: CustomerSettingComponent },
                    { path: 'raw-data', component: DataFeedsRawDataComponent },
                ],
            },
            {
                path: 'streaming',
                children: [
                    { path: '', redirectTo: 'flight-board', pathMatch: 'full' },
                    { path: 'flight-board', component: EventFlightBoardComponent },
                    { path: 'live-schedule', component: LiveScheduleComponent },
                    { path: 'stream-monitor', component: StreamMonitorComponent },
                    {
                        path: 'events',
                        children: [
                            { path: '', component: EventsComponent },
                            { path: 'location', component: LocationComponent },
                            { path: 'customer-locations', component: CustomerLocationsComponent },
                            { path: 'history', component: EventHistoryComponent },
                            { path: 'test-event-generator', component: TestEventGeneratorComponent },
                            { path: 'streamers', component: StreamersComponent },
                            { path: 'sports', component: SportsComponent },
                            { path: 'streamer-subscription', component: StreamerSubscriptionComponent },
                        ],
                    },
                    {
                        path: 'bet-n-watch',
                        children: [
                            { path: '', redirectTo: 'test-web-services', pathMatch: 'full' },
                            { path: 'auto-generate-locations', component: AutoGenerateCustomerLocationComponent },
                            { path: 'streams', component: StreamComponent },
                            { path: 'players', component: PlayerComponent },
                            { path: 'virtual-players', component: VirtualPlayerComponent },
                            { path: 'test-web-services', component: TestWebServicesComponent },
                        ],
                    },
                ],
            },
            { path: 'tools', component: ToolsComponent },
            { path: 'dev/version-info', component: VersionInfoComponent },
            { path: 'not-allowed', component: AccessNotAllowedComponent },
        ],
    },
    { path: 'mcr-monitoring/live-schedule/:id', component: EventInspectionComponent },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: '**', redirectTo: '' },
];
