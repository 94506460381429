import { Injectable } from '@angular/core';

@Injectable()
export class TimeConverterService {
    public getDateWithTime(date: Date, symbolBetween = '/'): string {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return date.toLocaleDateString('en-GB', options).replace(/\//g, symbolBetween);
    }

    public getDate(date: Date, symbolBetween = '-'): string {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/\//g, symbolBetween);
    }

    public getDateYearMonthDay(date: Date, symbolBetween = '-'): string {
        return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}-${date.getDate()}`;
    }
}
