import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationMessage } from '../../../../models/notification/notificationMessage';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RaceApiService } from './race-api.service';
import { RaceRequest } from '../models/race-request';
import { RacesWithTotal } from '../models/races-with-total';
import { NotificationSnackBarObserver } from '../../../../services/notification-snack-bar-observer';
import { NotificationType } from 'src/app/models/notification/notificationType';

@Injectable({
    providedIn: 'root'
})
export class RaceService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);

    constructor(private raceApiService: RaceApiService, private notificationObserver: NotificationSnackBarObserver) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getRaces(raceRequest: RaceRequest): Observable<RacesWithTotal> {
        return this.raceApiService.getRaces(raceRequest).pipe(
            map(
                (response: HttpResponse<RacesWithTotal>) => {
                    console.log(`Status: ${response.status}, StatusText ${response.statusText}`);

                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                        );

                }
            )
        );
    }
}
