import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { NotificationMessage } from '../../../models/notification/notificationMessage';
import { NotificationSnackBarObserver } from '../../../services/notification-snack-bar-observer';
import { StreamMonitorApiService } from './stream-monitor-api.service';
import { StreamBaseInfo } from '../models/stream-base-info';
import { StreamWithStreamLink } from '../models/stream-with-stream-link';
import { StreamFilter } from '../models/stream-filter';

@Injectable({
    providedIn: 'root'
})
export class StreamMonitorService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);

    constructor(private streamMonitorApiService: StreamMonitorApiService, private notificationObserver: NotificationSnackBarObserver) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getStreamsBaseInfo(): Observable<StreamBaseInfo[]> {
        return this.streamMonitorApiService.getStreamsBaseInfo().pipe(
            map(
                (response: HttpResponse<StreamBaseInfo[]>) => {
                    if (response.ok) {
                        return response.body;
                    } else {
                        console.log(`Status: ${response.status}, StatusText ${response.statusText}`);

                        return undefined;
                    }
                },
                (error) => {
                    console.log(`Error: ${error}`);

                    return undefined;
                }
            )
        );
    }

    public getStreamsDetails(streamFilter: StreamFilter): Observable<StreamWithStreamLink[]> {
        return this.streamMonitorApiService.getStreamsDetails(streamFilter).pipe(
            map(
                (response: HttpResponse<StreamWithStreamLink[]>) => {
                    if (response.ok) {
                        console.log(`Status: ${response.status}, StatusText ${response.statusText}`);
                        return response.body;
                    } else {
                        return undefined;
                    }
                },
                (error) => {
                    console.log(`Error: ${error}`);

                    return undefined;
                }
            )
        );
    }
}
