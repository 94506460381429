import { AutoGenerateCustomerLocationModel } from './auto-generate-customer-location';
import { DialogType } from 'src/app/models/dialog/dialogType';
import { AutoGenerateCustomerLocationRelatedCollectionsModel } from './auto-generate-customer-location-collections';
import { DataFeedBaseInfo } from 'src/app/models/common/data-feed-base-info';
import { PlayerBaseInfo } from 'src/app/models/common/player-base-info';
import { StreamBaseInfo } from 'src/app/models/common/stream-base-info';
import { CustomerWithUid } from 'src/app/models/common/customer-with-uid';

export class AutoGenerateCustomerLocationDialogData {
    public locationModel: AutoGenerateCustomerLocationModel;
    public isChangesConfirmed: boolean;
    public typeOfDialog: DialogType;
    public relatedCollections: AutoGenerateCustomerLocationRelatedCollectionsModel;

    public selectedCustomer: CustomerWithUid;
    public selectedDataFeed: DataFeedBaseInfo;
    public selectedUniqueStream: string;
    public selectedPlayer: PlayerBaseInfo;
    public selectedStream: StreamBaseInfo;
}
