import { EventHistoryModel } from '../models/event-history';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInternalService } from 'src/app/services/http.internal.service';
import { EventAuditModel } from '../models/event-audit';
import { EventIssueModel } from '../models/event-issue';

@Injectable()
export class EventHistoryApiService {
    public routePrefix = '/api/eventHistory';

    constructor(private httpService: HttpInternalService) {}

    public getEventHistory(eventId: string): Observable<HttpResponse<EventHistoryModel>> {
        return this.httpService.getFullRequest<EventHistoryModel>(`${this.routePrefix}`, { eventId });
    }

    public getEventAudits(eventId: string): Observable<HttpResponse<EventAuditModel[]>> {
        return this.httpService.getFullRequest<EventAuditModel[]>(`${this.routePrefix}/audit`, { eventId });
    }

    public getEventIssues(eventId: string): Observable<HttpResponse<EventIssueModel[]>> {
        return this.httpService.getFullRequest<EventIssueModel[]>(`${this.routePrefix}/issue`, { eventId });
    }
}
