import { Component, OnInit } from '@angular/core';
import { CustomerUsers } from './models/customer-users';
import { UserDatafeedsService } from './services/user-datafeeds.service';
import { UserDataFeeds } from './models/user-data-feeds';
import { DataFeedView } from './models/data-feed-view';
import { DataFeedBaseInfo } from '../../../models/common/data-feed-base-info';

@Component({
    selector: 'app-user-datafeeds',
    templateUrl: './user-datafeeds.component.html',
    styleUrls: ['./user-datafeeds.component.sass']
})
export class UserDatafeedsComponent implements OnInit {
    public customerUsers: CustomerUsers[] = [];
    public selectedCustomerDataFeeds: DataFeedBaseInfo[] = [];
    public selectedUsers: UserDataFeeds[] = [];
    public selectedUser: UserDataFeeds;
    public userDataFeeds: DataFeedView[] = [];
    public selectedUserId: number;

    constructor(private userDatafeedsService: UserDatafeedsService) {}

    public ngOnInit() {
        this.userDatafeedsService.getAllUsersDatafeeds().subscribe((cu) => {
            this.customerUsers = cu;
        });
    }

    public onSelectedCustomer(event) {
        const selectedCustomerUsers = this.customerUsers.filter((cu) => event.source.value === cu.customerId)[0];
        this.selectedUsers = selectedCustomerUsers.userDataFeeds;
        this.selectedCustomerDataFeeds = selectedCustomerUsers.dataFeeds;

        // reset
        this.userDataFeeds = [];
        this.selectedUserId = undefined;
    }

    public onSelectedUser(event) {
        this.selectedUser = this.selectedUsers.filter((cu) => event.source.value === cu.userId)[0];
        const userDataFeedIds = this.selectedUser.dataFeedIds;

        this.userDataFeeds = (userDataFeedIds.length === 0) ?  this.selectedCustomerDataFeeds.map((d) => {
            return new DataFeedView(d.id, d.name, true);
        }) : this.selectedCustomerDataFeeds.map((d) => {
                return new DataFeedView(d.id, d.name, userDataFeedIds.includes(d.id));
        });

    }

    public update() {
        const newUserDataFeedIds = this.userDataFeeds.filter((d) => d.isSelected).map((d) => d.id);

        this.userDatafeedsService
            .updateUserDataFeeds(new UserDataFeeds(this.selectedUser.userId, this.selectedUser.email, newUserDataFeedIds))
            .subscribe((result) => {
                if (result === true) {
                    this.selectedUser.dataFeedIds = newUserDataFeedIds;
                }
            });
    }

    public selectAllDataFeed() {
        this.userDataFeeds = this.userDataFeeds.map((d) => {
            d.isSelected = true;
            return d;
        });
    }

    public deselectAllDataFeed() {
        this.userDataFeeds = this.userDataFeeds.map((d) => {
            d.isSelected = false;
            return d;
        });
    }

}
