import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInternalService } from 'src/app/services/http.internal.service';
import { Streamer } from '../models/streamer';
import { StreamersModel } from '../models/streamersModel';

@Injectable()
export class StreamersApiService {
    public routePrefix = '/api/streamers';

    constructor(private httpService: HttpInternalService) {}

    public getStreamer(streamerId: number): Observable<HttpResponse<Streamer>> {
        return this.httpService.getFullRequest<Streamer>(`${this.routePrefix}`, { streamerId });
    }

    public getStreamers(
        page?: number,
        pageSize?: number,
        orderByColumn?: string,
        ascending?: boolean,
        searchCriteria = ''
    ): Observable<HttpResponse<StreamersModel>> {
        return this.httpService.getFullRequest<StreamersModel>(`${this.routePrefix}`, {
            page,
            pageSize,
            orderByColumn,
            ascending,
            searchCriteria,
        });
    }

    public updateStreamer(streamerModel: Streamer, currentId: number): Observable<HttpResponse<Streamer>> {
        return this.httpService.putFullRequest<Streamer>(`${this.routePrefix}/${currentId}`, streamerModel);
    }

    public createStreamer(streamerModel: Streamer): Observable<HttpResponse<Response>> {
        return this.httpService.postFullRequest<Response>(`${this.routePrefix}`, streamerModel);
    }

    public deleteStreamer(streamerId: number): Observable<HttpResponse<Response>> {
        return this.httpService.deleteFullRequest<Response>(`${this.routePrefix}/${streamerId}`);
    }
}
