import { BehaviorSubject, Observer, Observable } from 'rxjs';
import { NotificationMessage } from '../../../../models/notification/notificationMessage';
import { PushApiService } from './push-api.service';
import { HttpResponse } from '@angular/common/http';
import { CustomerRace } from '../models/customer-race';
import { NotificationType } from '../../../../models/notification/notificationType';
import { CustomerMeeting } from '../models/customer-meeting';
import { map } from 'rxjs/internal/operators/map';
import { CustomerBaseInfo } from 'src/app/models/common/customer-base-info';
import { PushRaces } from '../models/push-races';
import { PushMeetings } from '../models/push-meetings';

export class PushService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);

    constructor(private pushApiService: PushApiService, private notificationObserver: Observer<NotificationMessage>) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getSubscribedRaces(customer: CustomerBaseInfo, page = 0, pageSize = 10): Observable<PushRaces>  {
        return this.pushApiService.getSubscribedRaces(customer.customerId, page, pageSize)
            .pipe(
                map(
                    (response: HttpResponse<PushRaces>) => {
                        if (response.body.count === 0) {
                            this.notificationSubject.next(
                                new NotificationMessage(`There is no Subscribed Race for customer '${customer.customerName}'`, NotificationType.Other)
                            );
                        }
                        return response.body;
                    },
                    (error) => {
                        console.log(`Error: ${error}`);
                        this.notificationSubject.next(
                            new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                        );
                    }
            ));
    }

    public getSubscribedMeetings(customer: CustomerBaseInfo, page = 0, pageSize = 10): Observable<PushMeetings> {
        return this.pushApiService.getSubscribedMeetings(customer.customerId, page, pageSize)
            .pipe(
                map(
                (response: HttpResponse<PushMeetings>) => {
                    if (response.body.count === 0) {
                        this.notificationSubject.next(
                            new NotificationMessage(
                                    `There is no Subscribed Meeting for customer '${customer.customerName}'`,
                                    NotificationType.Other
                                )
                        );
                    }
                    return response.body;
                },
                (error) => {
                    this.notificationSubject.next(
                        new NotificationMessage(`Error: ${error}`, NotificationType.Other)
                    );
                }
            ));
    }

    public pushRaceToCustomer(customerRace: CustomerRace) {
        this.pushApiService.pushRaceToCustomer(customerRace).subscribe(
            (response: HttpResponse<boolean>) => {
                if (response.body === true) {
                    this.notificationSubject
                        .next(new NotificationMessage('Race was pushed', NotificationType.Other));
                } else {
                    this.notificationSubject
                        .next(new NotificationMessage('Race was not pushed', NotificationType.Error));
                }
            },
            (error) => {
                console.log(`Error: ${error}`);
                this.notificationSubject.next(new NotificationMessage('Race was not pushed', NotificationType.Error));
            }
        );
    }

    public pushMeetingToCustomer(customerMeeting: CustomerMeeting) {
        this.pushApiService.pushMeetingToCustomer(customerMeeting).subscribe(
            (response: HttpResponse<boolean>) => {
                if (response.body === true) {
                    this.notificationSubject
                        .next(new NotificationMessage('Meeting was pushed', NotificationType.Other));
                } else {
                    this.notificationSubject
                        .next(new NotificationMessage('Meeting was not pushed', NotificationType.Error));
                }
            },
            (error) => {
                console.log(`Error: ${error}`);
                this.notificationSubject.next(new NotificationMessage('Meeting was not pushed', NotificationType.Error));
            }
        );
    }
}
