import { Component, OnInit, Inject } from '@angular/core';
import { AutoGenerateCustomerLocationDialogData } from '../models/auto-generate-customer-location-dialog-data';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogType } from 'src/app/models/dialog/dialogType';
import { AutoGenerateCustomerLocationModel } from '../models/auto-generate-customer-location';

@Component({
    selector: 'app-auto-generate-customer-location-dialog',
    templateUrl: './auto-generate-customer-location-dialog.component.html',
    styleUrls: ['./auto-generate-customer-location-dialog.component.sass']
})
export class AutoGenerateCustomerLocationDialogComponent implements OnInit {
    public formGroup: FormGroup;
    public isActive: boolean;
    public rawEnabled: boolean;
    public isUsingExternalPlayer: boolean;

    public title: string;

    constructor(
        public dialogRef: MatDialogRef<AutoGenerateCustomerLocationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AutoGenerateCustomerLocationDialogData,
        private formBuilder: FormBuilder
    ) {}

    public ngOnInit() {
        this.title = this.data.typeOfDialog === DialogType.Add ? 'Add new location' : `Edit location with id ${this.data.locationModel.id}`;

        this.formGroup = this.formBuilder.group({
            customersControl: new FormControl(this.data.relatedCollections.customers),
            streamsControl: new FormControl(this.data.relatedCollections.streams),
            playersControl: new FormControl(this.data.relatedCollections.players),
            dataFeedsControl: new FormControl(this.data.relatedCollections.dataFeeds),
            uniqueStreamsControl: new FormControl(this.data.relatedCollections.uniqueStreams),
            isActive: new FormControl(false),
            rawEnabled: new FormControl(false)
        });

        if (this.data.typeOfDialog === DialogType.Edit) {
            this.formGroup.controls.isActive.setValue(this.data.locationModel.isActive);
            this.formGroup.controls.rawEnabled.setValue(this.data.locationModel.rawEnabled);

            this.data.selectedCustomer = this.data.relatedCollections.customers.find((x) => x.customerId === this.data.locationModel.customer.customerId);
            this.data.selectedDataFeed = this.data.relatedCollections.dataFeeds.find((x) => x.id === this.data.locationModel.dataFeed.id);
            this.data.selectedUniqueStream = this.data.relatedCollections.uniqueStreams.find((x) => x === this.data.locationModel.uniqueStreamId);
            this.data.selectedPlayer = this.data.relatedCollections.players.find((x) => x.id === this.data.locationModel.player.id);
            this.data.selectedStream = this.data.relatedCollections.streams.find((x) => x.id === this.data.locationModel.stream.id);
         
        } else {
            this.data.locationModel = new AutoGenerateCustomerLocationModel();
        }
    }

    public onYes() {
        this.data.isChangesConfirmed = true;

        this.data.locationModel.customer = this.data.selectedCustomer;
        this.data.locationModel.stream = this.data.selectedStream;
        this.data.locationModel.player = this.data.selectedPlayer;
        this.data.locationModel.dataFeed = this.data.selectedDataFeed;
        // tslint:disable-next-line:max-line-length
        this.data.locationModel.uniqueStreamId = this.data.selectedUniqueStream;

        this.data.locationModel.isActive = this.formGroup.value.isActive;
        this.data.locationModel.rawEnabled = this.formGroup.value.rawEnabled;

        this.dialogRef.close(this.data);
    }

    public onNo(): void {
        this.dialogRef.close(false);
    }
}
