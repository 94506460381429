import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Players } from '../models/players';
import { PlayerModel } from '../models/player';

@Injectable()
export class PlayerApiService {
    private routePrefix = '/api/players';

    constructor(private httpService: HttpInternalService) {}

    public getPlayers(page = 0, pageSize = 10): Observable<HttpResponse<Players>> {
        return this.httpService.getFullRequest(`${this.routePrefix}`, { page, pageSize });
    }

    public getPlayer(playerId: number): Observable<HttpResponse<PlayerModel>> {
        return this.httpService.getFullRequest(`${this.routePrefix}`, { playerId });
    }

    public createPlayer(player: PlayerModel): Observable<HttpResponse<PlayerModel>> {
        return this.httpService.postFullRequest(`${this.routePrefix}`, player);
    }

    public updatePlayer(player: PlayerModel): Observable<HttpResponse<any>> {
        return this.httpService.putFullRequest(`${this.routePrefix}`, player);
    }

    public deletePlayer(playerId: number): Observable<HttpResponse<any>> {
        return this.httpService.deleteFullRequest(`${this.routePrefix}`, { playerId });
    }
}
