import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from './services/user.service';
import * as _ from 'lodash';
import { MatDialog, MatPaginator, MatTableDataSource, Sort, MatSnackBar } from '@angular/material';
import { DialogConfirmationComponent } from '../../../components/dialog-confirmation/dialog-confirmation.component';
import { UserAddEditDialogData } from './models/user-add-edit-dialog-data';
import { RoleService } from '../role/services/role.service';
import { DialogType } from '../../../models/dialog/dialogType';
import { DialogConfirmationData } from '../../../models/dialog/dialog-confirmation-data';
import { UserRead } from './models/user-read';
import { UserCustomer } from './models/user-customer';
import { UserRole } from './models/user-role';
import { UserEdit } from './models/user-edit';
import { AddEditUserDialogComponent } from './add-edit-user-dialog/add-edit-user-dialog.component';
import { CustomerService } from '../../customer/customer/services/customer.service';
import { CustomerBaseInfo } from '../../../models/common/customer-base-info';
import { RoleBaseInfo } from '../../../models/common/role-base-info';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.sass']
})
export class UserComponent implements OnInit {
    @ViewChild('tablePaginator') public tablePaginator: MatPaginator;

    public usersSource = new MatTableDataSource<UserRead>();
    public rcsCustomers: UserCustomer[] = [];
    public roles: UserRole[] = [];
    public displayedColumns: string[] = [
        'UserName',
        'Email',
        'FirstName',
        'LastName',
        'Menu'
    ];

    constructor(
        private userService: UserService,
        private dialog: MatDialog,
        private roleService: RoleService,
        private customerService: CustomerService,
        private snackBar: MatSnackBar
    ) {}

    public ngOnInit() {
        this.userService.getUsers().subscribe((value: UserRead[]) => (this.usersSource.data = _.orderBy(value, ['lastName'], ['asc'])));

        this.customerService.getCustomersBaseInfo().subscribe((customers: CustomerBaseInfo[]) => {
            if (customers) {
                customers.forEach((x) => {
                    this.rcsCustomers.push(
                        new UserCustomer({
                            customerId: x.customerId,
                            customerName: x.customerName,
                            isUserHaveCustomer: false
                        })
                    );
                });
            }
        });

        this.roleService.getRoles().subscribe((roles: RoleBaseInfo[]) => {
            if (roles) {
                roles.forEach((x) => {
                    this.roles.push(new UserRole(x.roleId, x.roleName, false));
                });
            }
        });

        this.usersSource.paginator = this.tablePaginator;
    }

    public openAddDialog() {
        const addDialog = this.createEditDialog(
            new UserEdit({
                customers: _.cloneDeep(this.rcsCustomers),
                roles: _.cloneDeep(this.roles)
            }),
            DialogType.Add
        );

        addDialog.afterClosed().subscribe((result: UserAddEditDialogData) => {
            if (result && result.isChangesConfirmed) {
                this.addUser(result.user);
            }
        });
    }

    public openUpdateDialog(user: UserRead) {
        this.userService.getUser(user.userId).subscribe((u: UserEdit) => {
            const updateDialog = this.createEditDialog(u, DialogType.Edit);

            updateDialog.afterClosed().subscribe((result: UserAddEditDialogData) => {
                if (result && result.isChangesConfirmed) {
                    this.updateUser(result.user);
                }
            });
        });
    }

    public openRemoveDialog(user: UserRead) {
        const removeDialog = this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: new DialogConfirmationData(user.userName)
        });

        removeDialog.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed) {
                this.deleteUser(user);
            }
        });
    }

    public sortData(sort: Sort) {
        let isAscDirection = true;
        let column = 'LastName';

        if (sort.active && sort.direction) {
            isAscDirection = sort.direction === 'asc';
            column = sort.active;
        }

        this.usersSource.data = this.usersSource.data.sort((a, b) => {
            switch (column) {
                case 'FirstName':
                    return this.compare(a.firstName, b.firstName, isAscDirection);
                case 'UserName':
                    return this.compare(a.userName, b.userName, isAscDirection);
                default:
                    return this.compare(a.lastName, b.lastName, isAscDirection);
            }
        });
    }

    private addUser(user: UserEdit) {
        this.userService.addUser(user).subscribe(
            (u: UserRead) => {this.usersSource.data = _.concat(this.usersSource.data, u);},                   
            (error) => { this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });}
        );
    }
    
    private updateUser(user: UserEdit) {
        this.userService.updateUser(user).subscribe((updatedUser: UserRead) => {
            if (updatedUser) {
                this.usersSource.data = this.usersSource.data.map((u) => {
                    return u.userId !== user.userId ? u : updatedUser;
                });
            }
        });
    }

    private deleteUser(user: UserRead) {
        this.userService.deleteUser(user).subscribe((isRemoved: boolean) => {
            if (isRemoved) {
                this.usersSource.data = _.remove(this.usersSource.data, (r) => {
                    return r.userId !== user.userId;
                });
            }
        });
    }

    private createEditDialog(user: UserEdit, dialogType: DialogType) {
        return this.dialog.open(AddEditUserDialogComponent, {
            width: '500px',
            data: { dialogType, user, isChangesConfirmed: false, rcsCustomers: this.rcsCustomers }
        });
    }

    private compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
