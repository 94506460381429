import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent } from '@angular/material';
import * as _ from 'lodash';
import { FormControl, Validators } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { CustomerBaseInfo } from '../../../../models/common/customer-base-info';
import { RcsUserAddEditDialogData } from '../models/rcs-user-add-edit-dialog-data';
import { RcsUserCustomer } from '../models/rcs-user-customer';

@Component({
    selector: 'app-edit-rcs-user-dialog',
    templateUrl: './add-edit-rcs-user-dialog.component.html',
    styleUrls: ['./add-edit-rcs-user-dialog.component.sass']
})
export class AddEditRcsUserDialogComponent implements OnInit {
    public email = new FormControl('', [Validators.required, Validators.email]);
    public firstName = new FormControl('', [Validators.required]);
    public lastName = new FormControl('', [Validators.required]);
    public password = new FormControl('', [Validators.minLength(6)]);

    public rcsCustomer: RcsUserCustomer;
    public hide = true;
    public whiteIps: string[] = [];
    public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    constructor(public dialogRef: MatDialogRef<AddEditRcsUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: RcsUserAddEditDialogData) {}

    public ngOnInit() {
        this.email.setValue(this.data.user.email || '');
        this.firstName.setValue(this.data.user.firstName || '');
        this.lastName.setValue(this.data.user.lastName || '');
        this.password.setValue(this.data.user.password || '');

        if (this.data.user.rcsCustomer) {
            this.rcsCustomer = new RcsUserCustomer({
                customerId: this.data.user.rcsCustomer.customerId,
                customerName: this.data.user.rcsCustomer.customerName
            });
        }
    }

    public add(event: MatChipInputEvent) {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            this.whiteIps.push(value.trim());
        }

        if (input) {
            input.value = '';
        }
    }

    public remove(ip: string) {
        const index = this.whiteIps.indexOf(ip);

        if (index >= 0) {
            this.whiteIps.splice(index, 1);
        }
    }

    public getErrorMessage(formControl: FormControl) {
        if (formControl.hasError('required')) {
            return 'You must enter a value';
        }

        if (formControl.hasError('email')) {
            return 'Not a valid email';
        }

        if (formControl.hasError('minlength')) {
            return 'Min length is 6 symbols';
        }
    }

    public onNo(): void {
        this.data.isChangesConfirmed = false;
        this.dialogRef.close(false);
    }

    public onYes(): void {
        this.data.user.email = this.email.value;
        this.data.user.firstName = this.firstName.value;
        this.data.user.lastName = this.lastName.value;
        this.data.user.password = this.password.value;

        if (this.rcsCustomer) {
            this.data.user.rcsCustomer = new CustomerBaseInfo(this.rcsCustomer.customerId, this.rcsCustomer.customerName);
        } else {
            this.data.user.rcsCustomer = undefined;
        }

        this.data.isChangesConfirmed = true;
        this.dialogRef.close(_.cloneDeep(this.data));
    }

    public isFormValid() {
        return this.email.valid && this.firstName.valid && this.lastName.valid && this.password.valid;
    }

    public comparing(customer1: RcsUserCustomer, customer2: RcsUserCustomer) {
        return customer1 && customer2 ? customer1.customerId === customer2.customerId : customer1 === customer2;
    }
}
