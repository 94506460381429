import { Injectable } from '@angular/core';
import { EventFlightBoardApiService } from '../services/event-flight-board-api.service';
import { EventFlightBoard } from '../models/event-flight-board';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { NotificationMessage } from '../../../../models/notification/notificationMessage';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EventFlightBoardService {
    constructor(private eventFlightBoardApiService: EventFlightBoardApiService) {}

    public getFlightBoardEvents(): Observable<EventFlightBoard[]> {
        return this.eventFlightBoardApiService.getFlightBoardEvents().pipe(
            map(
                (response: any) => {
                    if (response.ok) {
                        console.log(`Status: ${response.status}, StatusText ${response.statusText}`);

                        return response.body;
                    } else {
                        return null;
                    }
                },
                (error) => {
                    console.log(`Error: ${error}`);

                    return null;
                }
            )
        );
    }
}
