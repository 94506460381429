import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { StreamerDialogData } from '../models/streamer-dialog-data';
import * as _ from 'lodash';
import { DialogType } from 'src/app/models/dialog/dialogType';

@Component({
    selector: 'app-streamers-dialog',
    templateUrl: './streamers-dialog.component.html',
    styleUrls: ['./streamers-dialog.component.sass'],
})
export class StreamersDialogComponent implements OnInit {
    public streamerIdControl: FormControl;
    public streamerNameControl: FormControl;

    public isActive: boolean;

    constructor(public dialogRef: MatDialogRef<StreamersDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: StreamerDialogData) {}

    public ngOnInit() {
        this.streamerIdControl = new FormControl(this.data.currentStreamer.id);
        this.streamerNameControl = new FormControl(this.data.currentStreamer.name);
    }

    public onYes(): void {
        this.data.isChangesConfirmed = true;
        this.data.updatedModel.id = this.streamerIdControl.value;
        this.data.updatedModel.name = this.streamerNameControl.value;

        this.dialogRef.close(_.cloneDeep(this.data));
    }

    public onNo(): void {
        this.dialogRef.close(false);
    }

    public isFormValid() {
        return this.streamerNameControl.valid;
    }
}
