import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Country } from '../../../../models/common/country';

@Injectable({
    providedIn: 'root'
})
export class CountryApiService {
    routePrefix: string = '/api/rcs/country';

    constructor(private httpService: HttpInternalService) {}

    getCountries(): Observable<HttpResponse<Country[]>> {
        return this.httpService.getFullRequest<Country[]>(`${this.routePrefix}/ofTracks`);
    }
}
