import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogConfirmationData } from '../../models/dialog/dialog-confirmation-data';

@Component({
    selector: 'app-dialog-confirmation',
    templateUrl: './dialog-confirmation.component.html',
    styleUrls: ['./dialog-confirmation.component.sass']
})
export class DialogConfirmationComponent {
    public dialogMessage: string;

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogConfirmationData
    ) {
        this.dialogMessage = data.nameOfEntity ? `${data.customMessage} '${data.nameOfEntity}'?` : `${data.customMessage}?`;
    }

    public onNo(): void {
        this.dialogRef.close(false);
    }

    public onYes(): void {
        this.dialogRef.close(true);
    }
}
