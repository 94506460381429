export class AuthorizedUser {
    public name: string;
    public email: string;
    public role: string;

    constructor(name = '', email = '', role = '') {
        this.name = name;
        this.email = email;
        this.role = role;
    }
}
