import { Injectable } from '@angular/core';
import { UserApiService } from './user-api.service';
import { NotificationMessage } from '../../../../models/notification/notificationMessage';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationSnackBarObserver } from '../../../../services/notification-snack-bar-observer';
import { UserEdit } from '../models/user-edit';
import { map } from 'rxjs/operators';
import { UserBaseInfo } from '../../../../models/common/user-base-info';
import { HttpResponse } from '@angular/common/http';
import { UserRead } from '../models/user-read';
import { NotificationType } from '../../../../models/notification/notificationType';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);

    constructor(private userApiService: UserApiService, private notificationObserver: NotificationSnackBarObserver) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getUser(userId: number): Observable<UserEdit> {
        return this.userApiService.getUser(userId).pipe(
            map(
                (response: HttpResponse<UserEdit>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public getUsersBaseInfo(): Observable<UserBaseInfo[]> {
        return this.userApiService.getUsersBaseInfo().pipe(
            map(
                (response: HttpResponse<UserBaseInfo[]>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public getUsers(): Observable<UserRead[]> {
        return this.userApiService.getUsers().pipe(
            map(
                (response: any) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public addUser(newUser: UserEdit): Observable<UserRead> {
        return this.userApiService.addUser(newUser).pipe(
            map(
                (response: HttpResponse<UserRead>) => {
                    this.notificationSubject.next(new NotificationMessage(`User '${newUser.email}' was added`, NotificationType.Adding));
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`User '${newUser.email}' was not added`, NotificationType.Error));
                }
            )
        );
    }

    public updateUser(updatedUser: UserEdit): Observable<UserRead> {
        return this.userApiService.updateUser(updatedUser).pipe(
            map(
                (response: HttpResponse<UserRead>) => {
                    this.notificationSubject.next(new NotificationMessage(`User '${updatedUser.email}' was updated`, NotificationType.Updating));

                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`User '${updatedUser.email}' was not updated`, NotificationType.Error));
                }
            )
        );
    }

    public deleteUser(removedUser: UserRead): Observable<boolean> {
        return this.userApiService.deleteUser(removedUser.userId).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    this.notificationSubject.next(new NotificationMessage(`User '${removedUser.email}' was removed`, NotificationType.Removing));

                    return true;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`User '${removedUser.email}' was not removed`, NotificationType.Error));

                    return false;
                }
            )
        );
    }
}
