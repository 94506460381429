import { Component, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { EventStreamLink } from 'src/app/pages/bet-n-watch/test-web-services/models/event-stream-link';
import { Subscription } from 'rxjs';
import { IgmStreamInput } from 'src/app/pages/bet-n-watch/test-web-services/models/igm-stream-input';
import { StreamApiService } from 'src/app/pages/bet-n-watch/stream/services/stream-api.service';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-stream-link',
    templateUrl: './stream-link.component.html',
    styleUrls: ['./stream-link.component.sass']
})
export class StreamLinkComponent implements OnDestroy {
    @Input()
    public showToken: boolean;
    @Output()
    public error = new EventEmitter<string>();

    public streamLink: EventStreamLink;

    private subscription = new Subscription();

    public constructor(private streamApiService: StreamApiService) { }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public getStreamLink(params: IgmStreamInput) {
        this.subscription.add(
            this.streamApiService.getStreamLink(params).subscribe(
                (response: HttpResponse<EventStreamLink>) => {
                    if (!response.body.streamLink) {
                        this.error.emit(response.body.message || 'Unexpected error');
                    }
                    this.streamLink = response.body;
                },
                (error) => {
                    this.error.emit(error.message);
                }
            ));
    }
}
