import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { InitData } from '../models/init-data';
import { GeneratedEvent } from '../models/generated-event';
import { ImportData } from '../models/import-data';
import { EventGeneratorParams } from '../models/event-generator-params';

@Injectable()
export class TestEventGeneratorService {
    constructor(private httpService: HttpInternalService) {}

    public getInitData(): Observable<InitData> {
        return this.httpService.getRequest<InitData>('/api/event/testEventGenerator/init');
    }

    public generateTestEvents(eventGeneratorParams: EventGeneratorParams): Observable<GeneratedEvent[]> {
        return this.httpService.getRequest<GeneratedEvent[]>('/api/event/testEventGenerator/generate', eventGeneratorParams);
    }

    public importTestEvents(importData: ImportData) {
        return this.httpService.postFullRequest('/api/event/testEventGenerator/import', importData);
    }
}
