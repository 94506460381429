import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
    title = 'MCR';
    showDevBanner = false;
    showTestBanner = false;
    bannerText: string;

    ngOnInit() {
        // if (environment.type === Environments.Dev) {
        //     this.showDevBanner = true;
        //     this.bannerText = 'You are on the DEVELOPMENT SITE!';
        // }

        // if (environment.type === Environments.Test) {
        //     this.showTestBanner = true;
        //     this.bannerText = 'You are on the TEST SITE!';
        // }
    }
}
