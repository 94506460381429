import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Customer } from '../models/customer';
import { CustomerUser } from '../models/customer-user';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { CustomerBaseInfo } from '../../../../models/common/customer-base-info';
import { CustomerWithUid } from '../../../../models/common/customer-with-uid';

@Injectable()
export class RcsCustomerApiService {
    public routePrefix = '/api/rcs/customer';

    constructor(private httpService: HttpInternalService) {}

    public getCustomer(customerId: number): Observable<HttpResponse<CustomerUser>> {
        return this.httpService.getFullRequest<CustomerUser>(`${this.routePrefix}/${customerId}`);
    }

    public getCustomersWithUid(): Observable<HttpResponse<CustomerWithUid[]>> {
        return this.httpService.getFullRequest<CustomerWithUid[]>(`${this.routePrefix}/customersWithUid`);
    }

    public getCustomers(): Observable<HttpResponse<Customer[]>> {
        return this.httpService.getFullRequest<Customer[]>(`${this.routePrefix}`);
    }

    public getCustomersBaseInfo(): Observable<HttpResponse<CustomerBaseInfo[]>> {
        return this.httpService.getFullRequest<CustomerBaseInfo[]>(`${this.routePrefix}/allBaseInfo`);
    }

    public addCustomer(customerUser: CustomerUser): Observable<HttpResponse<Customer>> {
        return this.httpService.putFullRequest<Customer>(`${this.routePrefix}`, customerUser);
    }

    public updateCustomer(customerUser: CustomerUser): Observable<HttpResponse<boolean>> {
        return this.httpService.postFullRequest<boolean>(`${this.routePrefix}`, customerUser);
    }

    public deleteCustomer(customerId: number): Observable<HttpResponse<boolean>> {
        return this.httpService.deleteFullRequest<boolean>(`${this.routePrefix}/${customerId}`);
    }
}
