import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-events',
    templateUrl: './upload-event-error.component.html',
    styleUrls: ['./upload-event-error.component.sass']
})

export class UploadEventErrorComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        private snackBarRef: MatSnackBarRef<UploadEventErrorComponent>,

    ) { }

    dismiss() {
        this.snackBarRef.dismiss();
    }
}
