import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SportDialogData } from '../models/sport-dialog-data';
import * as _ from 'lodash';
import { DialogType } from 'src/app/models/dialog/dialogType';

@Component({
    selector: 'app-sports-dialog',
    templateUrl: './sports-dialog.component.html',
    styleUrls: ['./sports-dialog.component.sass'],
})
export class SportsDialogComponent implements OnInit {
    public sportIdControl: FormControl;
    public sportNameControl: FormControl;
    public sportCodeControl: FormControl;

    public isActive: boolean;

    constructor(public dialogRef: MatDialogRef<SportsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: SportDialogData) {}

    public ngOnInit() {
        this.sportIdControl = new FormControl(this.data.currentSport.id);
        this.sportNameControl = new FormControl(this.data.currentSport.name);
        this.sportCodeControl = new FormControl(this.data.currentSport.code);
    }

    public onYes(): void {
        this.data.isChangesConfirmed = true;
        this.data.updatedModel.id = this.sportIdControl.value;
        this.data.updatedModel.name = this.sportNameControl.value;
        this.data.updatedModel.code = this.sportCodeControl.value;

        this.dialogRef.close(_.cloneDeep(this.data));
    }

    public onNo(): void {
        this.dialogRef.close(false);
    }

    public isFormValid() {
        return this.sportNameControl.valid && this.sportCodeControl.valid;
    }
}
