import { Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { MatAccordion, MatSidenav } from '@angular/material';
import { RouteService } from '../../services/route.service';
import { Route } from '../../models/navigation/route';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from '../../../environments/environment';
import { Environments } from '../../../environments/environments-enum';

@Component({
    selector: 'app-main-navigation',
    templateUrl: './main-navigation.component.html',
    styleUrls: ['./main-navigation.component.sass']
})
export class MainNavigationComponent implements OnInit, OnDestroy {
    @ViewChild('mainAccordion')
    public mainAccordion: MatAccordion;
    @ViewChild('innerAccordion')
    public innerAccordion: MatAccordion;

    @ViewChild('drawer')
    public sidenav: MatSidenav;

    public navigationSubscription: Subscription;
    public routes: Route[];
    public currentRouteName: string;
    public currentEnvTitle = '';
    public currentEnvStyle = '';
    public parentMenuExpanded = '';
    public viewParentMenuItem = false;
    public childMenuExpanded = '';
    public viewChildMenuItem = false;
    public mcrTimeLocal = undefined;
    public mcrTimeUtc = undefined;

    constructor(private routeService: RouteService, private titleService: Title, private router: Router) {}

    public ngOnInit() {
        this.routes = this.routeService.getAllRoutes();
        this.setPageTime();
        this.clockManagement();

        this.setPageTitle();

        this.navigationSubscription = this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd && this.router.url) {
                this.closeAccordionAndSidenav();
                this.setPageTitle();
            }
        });

        if (environment.type === Environments.Dev) {
            this.currentEnvTitle = 'DEVELOPMENT ENVIRONMENT';
            this.currentEnvStyle = 'env-title-dev';
        } else if (environment.type === Environments.Test) {
            this.currentEnvTitle = 'TEST ENVIRONMENT';
            this.currentEnvStyle = 'env-title-test';
        } else if (environment.type === Environments.Local) {
            this.currentEnvTitle = 'LOCAL ENVIRONMENT';
            this.currentEnvStyle = 'env-title-local';
        }
    }

    public ngOnDestroy(): void {
        this.navigationSubscription.unsubscribe();
    }

    public viewParentMenu(parentMenu) {
        this.viewParentMenuItem = true;
        this.parentMenuExpanded = parentMenu;

        this.viewChildMenuItem = false;
        this.childMenuExpanded = '';
    }

    public viewChildMenu(childMenu) {
        this.viewChildMenuItem = true;
        this.childMenuExpanded = childMenu;
    }

    public clockManagement() {
        setInterval(() => {
            this.setPageTime();
        }, 1000);
    }

    private setPageTime() {
        this.mcrTimeLocal = new Date();
        this.mcrTimeUtc = new Date(this.mcrTimeLocal.getTime() + this.mcrTimeLocal.getTimezoneOffset() * 60000);
    }

    private setPageTitle() {
        this.currentRouteName = this.routeService.getRouteNameByPath(this.router.url);
        const title = this.currentRouteName ? `MCR - ${this.currentRouteName}` : 'MCR';
        this.titleService.setTitle(title);
    }

    private closeAccordionAndSidenav() {
        this.viewChildMenuItem = false;
        this.mainAccordion.closeAll();
        this.innerAccordion.closeAll();
        this.sidenav.close();
    }
}
