import { DetailedEvent } from '../../../../models/common/detailed-event';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CustomerLocationsService } from 'src/app/pages/mcr-monitoring/customer-locations/services/customer-locations.service';
import { LocationBaseInfo } from 'src/app/models/common/location-base-info';
import { LocationBaseInfos } from 'src/app/models/common/location-base-infos';
import { Subscription } from 'rxjs';
import { McrScheduleService } from 'src/app/pages/mcr-monitoring/mcr-schedule/services/mcr-schedule.service';
import { FiltersDialogData } from 'src/app/pages/mcr-monitoring/mcr-schedule/models/filters-dialog-data';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Country } from '../../../../models/common/country';
import { forEach } from '@angular/router/src/utils/collection';

interface ModalProps {
    dialogType: string;
    object: DetailedEvent;
    isChangesConfirmed: boolean;
}
@Component({
    selector: 'app-events-create-update',
    templateUrl: './events-create-update-dialog.html',
    styleUrls: ['./events-create-update-dialog.sass']
})
export class EventsCreateUpdateDialog implements OnInit {

    public countriesFilters = new FormControl([]);
    public eventId: FormControl = new FormControl();
    public search: FormControl = new FormControl();
    public eventNumber: FormControl = new FormControl(1);
    public title: FormControl = new FormControl();
    public runningTime: FormControl = new FormControl(0, [Validators.min(0), Validators.max(9999999)]);

    public form: FormGroup = this.getDefaultDatesGroup();

    public locationFilter: FormControl = new FormControl();
    public dataFeedFilter = new FormControl();
    public countryFilter = new FormControl();
    public sportsFilter = new FormControl();
    public competitionFilter = new FormControl();
    public statusFilter = new FormControl();

    public statuses = ['Closed', 'Open', 'Pending', 'Void'];
    public locations: LocationBaseInfo[] = [];
    public locationsCount: number;
    public isAutoUpdating: boolean;
    public searchWord = '';
    public countries: Country[];
    public locationCountries: Country[];

    public currentPage = 0;
    public sub: Subscription = new Subscription();

    public filteredCountries: Country[];
    public countriesSearchCriteria: string;
    private initLocationBaseInfo: LocationBaseInfo;

    constructor(
        private locationService: CustomerLocationsService,
        public dialogRef: MatDialogRef<EventsCreateUpdateDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ModalProps) { }

    public ngOnInit() {
        // this.data.object.geoBlockCountries = this.joinCountryCodes(this.data.object.geoBlockCountries, this.data.object.locationGeoBlockCountries)
        this.getLocations();
        this.initializeSearch();

        if (this.data.dialogType === 'Edit') {
            this.setupInitForm();
        }
        else {
            this.eventNumber.valueChanges.subscribe((selectedValue: string) => {
                if (this.form.controls.startDate.value && this.locationFilter.value) {
                    this.eventId.setValue(
                        this.generateEventId(this.locationFilter.value.iGMLocationCode, this.form.controls.startDate.value, selectedValue));
                }
            });
            this.form.controls.startDate.valueChanges.subscribe((value: Date) => {
                if (this.locationFilter.value) {
                    this.eventId.setValue(this.generateEventId(this.locationFilter.value.iGMLocationCode, value, this.eventNumber.value));
                }
            });
            this.locationFilter.valueChanges.subscribe((value: LocationBaseInfo) => {
                if (this.form.controls.startDate.value) {
                    this.eventId.setValue(this.generateEventId(value.iGMLocationCode, this.form.controls.startDate.value, this.eventNumber.value));
                }
            });

            this.statusFilter = new FormControl(this.statuses[2]);
        }
    }

    public getDefaultDatesGroup() {
        return new FormGroup({
            startDate: new FormControl(),
            startTime: new FormControl(),
            actualStartTime: new FormControl(),
            actualStartDate: new FormControl()
        });
    }

    public ngOnDestroy() {
        this.sub.unsubscribe();
    }

    public setupInitForm() {

        this.initLocationBaseInfo = {
            iGMLocationCode: this.data.object.igmLocationCode,
            locationID: this.data.object.locationId,
            name: this.data.object.location,
            geoBlockCountries: this.data.object.locationGeoBlockCountries,
            actualLocationName: null
        };

        const { object: event } = this.data;
        this.eventId = new FormControl(event.eventId);
        this.statusFilter = new FormControl(this.statuses.find((x) => x.includes(event.eventStatusCode[0])));
        this.eventNumber = new FormControl(event.eventNumber);
        this.title = new FormControl(event.title);
        this.isAutoUpdating = event.isAutoUpdating;
        this.locationFilter = new FormControl(this.initLocationBaseInfo);
        this.runningTime = new FormControl(event.runningTime, [Validators.min(0), Validators.max(9999999)]);

        this.statuses = [this.statuses.find(x => x === this.statusFilter.value)].concat(this.statuses.filter(x => x !== this.statusFilter.value));

        if (event.startTime) {
            this.form.controls.startDate.setValue(event.startTime);
            this.form.controls.startTime.setValue(moment.utc(event.startTime).format('HH:mm'));
        }
        if (event.actualStartTime) {
            this.form.controls.actualStartDate.setValue(event.actualStartTime);
            this.form.controls.actualStartTime.setValue(moment.utc(event.actualStartTime).format('HH:mm'));
        }
    }

    public generateEventId(igmLocationCode: string, date: Date, eventNumber = ''): string {
        return `${igmLocationCode}${moment(date).format('YYYYMMDD')}-${eventNumber || ''}`;
    }

    public showSelectedOnTop() {
        const alreadySelectedCountries = this.countriesFilters.value;
        //console.log(this.filteredCountries.filter(x => !alreadySelectedCountries.includes(x) && !x.disabled));
        this.filteredCountries = alreadySelectedCountries.concat(this.filteredCountries.filter(x => !alreadySelectedCountries.includes(x)));
    }

    public selectAllCountries() {
        this.countriesFilters.setValue(this.countries);
    }

    public deselectAllCountries() {
        this.countriesFilters.setValue(this.locationCountries);
    }

    public handleCountriesSearch(text: string) {
        const newFilteredCountries = this.countries.filter(x => x.alpha2Code.toLowerCase().includes(text.toLowerCase())
            || x.englishName.toLowerCase().includes(text.toLowerCase()));

        const alreadySelectedCountries = this.countriesFilters.value;
        // this.locationCountries.concat(alreadySelectedCountries);
        this.filteredCountries = alreadySelectedCountries.concat(newFilteredCountries.filter(x => !alreadySelectedCountries.includes(x)));
    }

    public onYes() {
        const { actualStartDate, actualStartTime, startDate, startTime, endDate, endTime  } = this.form.controls;

        this.data.isChangesConfirmed = true;
        this.data.object.eventId = this.eventId.value;
        this.data.object.eventStatusCode = this.statusFilter.value;
        this.data.object.eventNumber = this.eventNumber.value;
        this.data.object.title = this.title.value;
        this.data.object.isAutoUpdating = this.isAutoUpdating;
        this.data.object.locationId = this.locationFilter.value.locationID;
        this.data.object.location = this.locationFilter.value.actualLocationName || this.locationFilter.value.name;
        this.data.object.igmLocationCode = this.locationFilter.value.iGMLocationCode;
        this.data.object.competitionId = 2;
        this.data.object.runningTime = this.runningTime.value;
        this.data.object.geoBlockCountries = this.countriesFilters.value.filter(x => !x.disabled).map(x => x.alpha2Code).join(' ');
        this.data.object.locationGeoBlockCountries = this.locationFilter.value.geoBlockCountries;

        if (this.form.controls.actualStartDate.value) {
            this.data.object.actualStartTime = this.getDateUtcInCorrectFormat(actualStartDate.value, actualStartTime.value);
        }

        this.data.object.startTime = this.getDateUtcInCorrectFormat(startDate.value, startTime.value);
        this.data.object.estimatedEndTime = moment(this.data.object.startTime).add(this.runningTime.value, 'minutes').toDate();

        this.dialogRef.close(_.cloneDeep(this.data));
    }

    public onCancel() {
        this.dialogRef.close(false);
    }

    public isFormValid() {
        return this.form.controls.startDate.value
            && this.eventId.value
            && this.statusFilter.value
            && this.eventNumber.value
            && this.title.value
            && this.locationFilter.value.locationID
            && this.form.controls.startTime.value
            && !this.runningTime.invalid;
    }

    public loadNextLocations() {
        this.currentPage += 1;
        this.getLocations(this.currentPage, undefined, this.searchWord);
    }

    public compare(location1: LocationBaseInfo, location2: LocationBaseInfo) {
        return location1 && location2 ? location1.locationID === location2.locationID : location1 === location2;
    }

    public compareString(str1: string, str2: string) {
        return str1 && str2 ? str1 === str2 : false;
    }

    private getDateUtcInCorrectFormat(dateValue: Date, timeValue: any) {
        let dateTime = moment(dateValue).utc(true);
        const [hoursStart, minutesStart] =  timeValue ? timeValue.split(':') : [0, 0];

        dateTime = dateTime.hours(hoursStart);
        dateTime = dateTime.minutes(minutesStart);

        return dateTime.toDate();
    }

    private initializeSearch() {
        this.sub = this.search.valueChanges.pipe(
            debounceTime(1000),
            switchMap((value) => {
                this.searchWord = value;
                this.locations = [];
                this.getLocations(undefined, undefined, this.searchWord);
                return value;
            }
            )
        ).subscribe();
    }

    public onLocationSelectedChange(event) {

        this.data.object.geoBlockCountries = event.source.value.geoBlockCountries ? event.source.value.geoBlockCountries : '';
        const selectedAlphaCodes = this.data.object.geoBlockCountries.split(' ');
        const selectedCountries = this.filteredCountries.filter(x => selectedAlphaCodes.includes(x.alpha2Code));
        const resultCountries = this.locationCountries.concat(selectedCountries);
        this.countriesFilters.setValue(resultCountries);
    }

    private joinCountryCodes(codes1: string, codes2: string): string {
        codes1 = codes1 || '';
        codes2 = codes2 || '';

        const allCodes = codes1.split(' ').concat(codes2.split(' '));
        const uniqueCodes = Array.from(new Set(allCodes));
        return uniqueCodes.join(' ');
    }

    private getLocations(pageIndex = 0, pageSize = 100, searchWord = '') {
        const isTwitch = this.data.object.eventId && this.data.object.eventId.indexOf('TWIWO') >= 0;
        this.locationService
            .getEventLocations(pageIndex, pageSize, searchWord, isTwitch)
            .subscribe((data: LocationBaseInfos) => {
                this.locationsCount = data.count;
                if (this.data.dialogType === 'Edit') {
                    const initLocation: LocationBaseInfo = {
                        iGMLocationCode: this.data.object.igmLocationCode,
                        locationID: this.data.object.locationId,
                        name: this.data.object.location,
                        geoBlockCountries: this.data.object.locationGeoBlockCountries,
                        actualLocationName: this.data.object.location
                    };
                    this.locations = [initLocation];
                }

                this.locations = this.locations.concat(data.locationBaseInfos.filter(x => x.name !== this.data.object.location));
                this.countries = data.countries;
                this.filteredCountries = data.countries;

                let selectedLocationCountries: Country[] = [];
                let selectedCountries: Country[] = [];
                if (this.data.dialogType === 'Edit' && this.data.object.geoBlockCountries) {
                    const selectedAlphaCodes = this.data.object.geoBlockCountries.split(' ');
                    selectedCountries = this.filteredCountries.filter(x => selectedAlphaCodes.includes(x.alpha2Code));
                }

                if (this.data.dialogType === 'Edit' && this.data.object.locationGeoBlockCountries) {
                    const selectedAlphaCodes = this.data.object.locationGeoBlockCountries.split(' ');
                    selectedLocationCountries = this.filteredCountries.filter(x => selectedAlphaCodes.includes(x.alpha2Code));
                    selectedLocationCountries.forEach(x => x.disabled = true);
                }

                this.locationCountries = selectedLocationCountries;
                const resultCountries = selectedLocationCountries.concat(selectedCountries);
                if(resultCountries.length !== 0)
                    this.countriesFilters.setValue(resultCountries);
            });
    }
}
