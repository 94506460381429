import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LocationDialogData } from '../models/location-dialog-data';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { Country } from '../../../../models/common/country';

@Component({
    selector: 'app-location-dialog',
    templateUrl: './location-dialog.component.html',
    styleUrls: ['./location-dialog.component.sass']
})
export class LocationDialogComponent implements OnInit {

    public countriesFilters = new FormControl([]);
    public igmLocationCodeControl: FormControl;
    public locationNameControl: FormControl;
    public countryCodeControl: FormControl;
    public dataFeeds: FormControl;
    public sports: FormControl;
    public dfLocationNameControl: FormControl;
    public dfLocationCodeControl: FormControl;

    public filteredCountries: Country[];
    public countriesSearchCriteria: string;

    constructor(public dialogRef: MatDialogRef<LocationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: LocationDialogData) { }

    public ngOnInit() {
        this.igmLocationCodeControl = new FormControl(this.data.currentLocation.igmLocationCode);
        this.locationNameControl = new FormControl(this.data.currentLocation.locationName);
        this.countryCodeControl = new FormControl(this.data.currentLocation.country.countryId);
        this.dataFeeds = new FormControl(this.data.currentLocation.dataFeed.id);
        this.sports = new FormControl(this.data.currentLocation.sport.id);
        this.dfLocationNameControl = new FormControl(this.data.currentLocation.dataFeedLocationName);
        this.dfLocationCodeControl = new FormControl(this.data.currentLocation.dataFeedLocationCode);
        this.filteredCountries = this.data.countries;

        if(this.data.dialogType === 'Edit' && this.data.currentLocation.geoBlockCountries){
            const selectedAlphaCodes = this.data.currentLocation.geoBlockCountries.split(' ');
            const selectedCountries = this.filteredCountries.filter(x => selectedAlphaCodes.includes(x.alpha2Code));
            this.countriesFilters.setValue(selectedCountries);
        }
    }

    public onYes(): void {
        this.data.isChangesConfirmed = true;
        const country = this.data.countries.find(x => x.countryId === this.countryCodeControl.value);
        this.data.currentLocation.country.alpha2Code = country.alpha2Code;
        this.data.currentLocation.country.englishName = country.englishName;
        this.data.currentLocation.country.countryId = this.data.updatedModel.countryId = country.countryId;

        const sport = this.data.sports.find(x => x.id === this.sports.value);
        this.data.currentLocation.sport.name = sport.name;
        this.data.currentLocation.sport.code = sport.code;
        this.data.currentLocation.sport.id = this.data.updatedModel.sportId = sport.id;

        const dataFeed = this.data.dataFeeds.find(x => x.id === this.dataFeeds.value);
        this.data.currentLocation.dataFeed.id = this.data.updatedModel.dataFeedId = dataFeed.id;
        this.data.currentLocation.dataFeed.name = dataFeed.name;

        this.data.currentLocation.igmLocationCode = this.data.updatedModel.igmLocationCode = this.igmLocationCodeControl.value;
        this.data.currentLocation.locationName = this.data.updatedModel.name = this.locationNameControl.value;

        this.data.currentLocation.dataFeedLocationName = this.data.updatedModel.dataFeedLocationName = this.dfLocationNameControl.value;
        this.data.currentLocation.dataFeedLocationCode = this.data.updatedModel.dataFeedLocationCode = this.dfLocationCodeControl.value;
        this.data.updatedModel.ullEnabled = this.data.currentLocation.ullEnabled;
        this.data.currentLocation.dataFeedCountryCode = this.data.updatedModel.dataFeedCountryCode = country.alpha2Code;
        this.data.currentLocation.dataFeedSportCode = this.data.updatedModel.dataFeedSportCode = sport.code;
        this.data.currentLocation.geoBlockCountries = this.data.updatedModel.geoBlockCountries
            = this.countriesFilters.value.map(x => x.alpha2Code).join(' ');
        this.dialogRef.close(_.cloneDeep(this.data));
    }

    public onNo(): void {
        this.dialogRef.close(false);
    }

    public isFormValid() {
        return this.locationNameControl.valid &&
            this.countryCodeControl.valid &&
            this.dataFeeds.valid &&
            this.sports.valid &&
            this.dfLocationNameControl.valid &&
            this.dfLocationCodeControl.valid;
    }

    public showSelectedsOnTop() {
        const alreadySelectedCountries = this.countriesFilters.value;
        this.filteredCountries = alreadySelectedCountries.concat(this.filteredCountries.filter(x => !alreadySelectedCountries.includes(x)));
    }

    public selectAllCountries() {
        this.countriesFilters.setValue(this.data.countries);
    }

    public deselectAllCountries() {
        this.countriesFilters.setValue([]);
    }

    public handleCountriesSearch(text: string) {
        const newFilteredCountries = this.data.countries.filter(x => x.alpha2Code.toLowerCase().includes(text.toLowerCase())
            || x.englishName.toLowerCase().includes(text.toLowerCase()));
        const alreadySelectedCountries = this.countriesFilters.value;
        this.filteredCountries = alreadySelectedCountries.concat(newFilteredCountries.filter(x => !alreadySelectedCountries.includes(x)));
    }

    updateIgmLocationCode() {
        const dataFeed = this.data.dataFeeds.find(x => x.id === this.dataFeeds.value);
        const country = this.data.countries.find(x => x.countryId === this.countryCodeControl.value);
        const sport = this.data.sports.find(x => x.id === this.sports.value);
        const locationCode = this.dfLocationCodeControl.value;
        // TODO: fix when migrate to new Angular and Typescript version
        let igmLocationCode = '';
        if (dataFeed) {
            igmLocationCode += dataFeed.code;
        }
        if (country) {
            igmLocationCode += country.alpha2Code;
        }
        if (sport) {
            igmLocationCode += sport.code;
        }
        if (locationCode) {
            igmLocationCode += locationCode;
        }
        this.igmLocationCodeControl.setValue(igmLocationCode);
    }
}
