
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { MatTableDataSource, MatDialog, Sort } from '@angular/material';
import { RcsCustomerService } from '../customer/services/rcs-customer.service';
import { CustomerBaseInfo } from '../../../models/common/customer-base-info';
import { CustomerSetting } from './models/customer-setting';
import { CustomerSettingWithTotal } from './models/customer-setting-with-total';
import { CustomerSettingService } from './services/сustomer-setting.service';
import { DialogType } from 'src/app/models/dialog/dialogType';
import { AddEditCustomerSettingDialogComponent } from './add-edit-customer-setting-dialog/add-edit-customer-setting-dialog.component';
import { DialogConfirmationComponent } from 'src/app/components/dialog-confirmation/dialog-confirmation.component';
import { DialogConfirmationData } from 'src/app/models/dialog/dialog-confirmation-data';
import { PageEvent } from '@angular/material';
import { XmlSchemaApiService } from 'src/app/services/xml-schema.service';
import { XmlSchema } from 'src/app/models/common/xml-schema';
import { HttpResponse } from '@angular/common/http';
@Component({
  selector: 'app-customer-setting',
  templateUrl: './customer-setting.component.html',
  styleUrls: ['./customer-setting.component.sass']
})
export class CustomerSettingComponent implements OnInit {

    public customerSettingsSource = new MatTableDataSource<CustomerSetting>();
    public customerSettingsCount: number;

    public customers: CustomerBaseInfo[] = [];
    public schemas: XmlSchema[] = [];

    public page = 0;
    public pageSize = 10;

    public displayedColumns: string[] = [
        'id',
        'customerName',
        'externalPushServiceUrl',
        'secretKey',
        'xmlVersion',
        'menu'
    ];

    constructor(
        private customerSettingsService: CustomerSettingService,
        private customerService: RcsCustomerService,
        private schemaService: XmlSchemaApiService,
        public dialog: MatDialog
    ) {}

    public ngOnInit() {
        this._loadCustomersSetting();
        this._loadSchemas();
        this._loadCustomersBaseInfo();
    }

    public openAddDialog() {
        const addDialog = this._createEditDialog(DialogType.Add, new CustomerSetting());

        addDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed === true) {
                this.addCustomerSetting(result.customerSetting);
            }
        });
    }

    public openUpdateDialog(customerSetting: CustomerSetting) {
        const updateDialog = this._createEditDialog(DialogType.Edit, _.cloneDeep(customerSetting));

        updateDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed === true) {
                this.updateCustomerSetting(result.customerSetting);
            }
        });
    }

    public openRemoveDialog(customerSetting: CustomerSetting) {
        const removeDialog = this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: new DialogConfirmationData(`Setting for '${customerSetting.customerInfo.customerName}' customer`)
        });

        removeDialog.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed && isRemovingConfirmed === true) {
                this.deleteUser(customerSetting);
            }
        });
    }

    public addCustomerSetting(customerSetting: CustomerSetting) {
        this.customerSettingsService
            .create(customerSetting)
            .subscribe((cSetting: CustomerSetting) => {
                if (cSetting) {
                    this._loadCustomersSetting(this.page, this.pageSize);
                }
            });
    }

    public updateCustomerSetting(customerSetting: CustomerSetting) {
        this.customerSettingsService
            .update(customerSetting)
            .subscribe(
                (isUpdate: boolean) => {
                    if (isUpdate) {
                        this._loadCustomersSetting(this.page, this.pageSize);
                    }
                }
            );
    }

    public deleteUser(customerSetting: CustomerSetting) {
        this.customerSettingsService
            .delete(customerSetting.id)
            .subscribe(
                (isRemoved: boolean) => {
                    if (isRemoved) {
                        this._loadCustomersSetting(this.page, this.pageSize);
                    }
                }
            );
    }

    public changeCustomerSettingPage(event: PageEvent) {
        this.page = event.pageIndex;
        this.pageSize = event.pageSize;
        this._loadCustomersSetting(this.page, this.pageSize);
    }

    public sortData(sort: Sort) {

        let isAscDirection = true;
        let column = 'id';

        if (sort.active && sort.direction) {
            isAscDirection = sort.direction === 'asc';
            column = sort.active;
        }

        this.customerSettingsSource.data = this.customerSettingsSource.data
            .sort((a, b) => {
                switch (column) {
                    case 'customerName':
                        return this._compare(
                            a.customerInfo.customerName,
                            b.customerInfo.customerName,
                            isAscDirection
                        );
                    case 'externalPushServiceUrl':
                        return this._compare(
                            a.externalPushServiceUrl,
                            b.externalPushServiceUrl,
                            isAscDirection
                        );
                    case 'xmlVersion':
                        return this._compare(
                            a.xmlVersion,
                            b.xmlVersion,
                            isAscDirection
                        );
                    default:
                        return this._compare(a.id, b.id, isAscDirection);
                }
            });
    }

    private _loadSchemas() {
        this.schemaService.getAllSchemas()
            .subscribe(
                (response: HttpResponse<XmlSchema[]>) => {
                    this.schemas = response.body;
                },
                (error) => console.log(`Error: ${error}`)
            );
    }

    private _loadCustomersBaseInfo() {
        this.customerService
            .getCustomersBaseInfo()
            .subscribe(
                (customers: CustomerBaseInfo[]) => {
                    this.customers = customers;
                },
                (error) => console.log(`Error: ${error}`)
            );
    }

    private _loadCustomersSetting(page = 0, pageIndex = 10) {
        this.customerSettingsService
            .getAll(page, pageIndex)
            .subscribe((data: CustomerSettingWithTotal) => {
                    this.customerSettingsSource.data = data.customersSetting;
                    this.customerSettingsCount = data.count;
                });
    }

    private _compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    private _createEditDialog(dialogType: DialogType, customerSetting: CustomerSetting) {
        return this.dialog.open(AddEditCustomerSettingDialogComponent, {
            width: '500px',
            data: {
                dialogType,
                customerSetting,
                isChangesConfirmed: false,
                customers: this.customers,
                schemas: this.schemas
            }
        });
    }
}
