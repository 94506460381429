import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import * as _ from 'lodash';
import { RcsRoleAddEditDialogData } from '../models/rcs-role-add-edit-dialog-data';

@Component({
    selector: 'add-edit-rcs-role-dialog',
    templateUrl: './add-edit-rcs-role-dialog.component.html',
    styleUrls: ['./add-edit-rcs-role-dialog.component.sass']
})
export class AddEditRcsRoleDialogComponent {
    constructor(public dialogRef: MatDialogRef<AddEditRcsRoleDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: RcsRoleAddEditDialogData) {}

    onNo(): void {
        this.data.isChangesConfirmed = false;
        this.dialogRef.close(false);
    }

    onYes(): void {
        this.data.isChangesConfirmed = true;
        this.dialogRef.close(_.cloneDeep(this.data));
    }
}
