import { RcsUserRole } from './rcs-user-role';
import { RcsUserCustomer } from './rcs-user-customer';
import { CustomerBaseInfo } from '../../../../models/common/customer-base-info';

export class RcsUserEdit {
    userId: number;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    rcsCustomer: CustomerBaseInfo;
    roles: RcsUserRole[];
    customers: RcsUserCustomer[];

    constructor(args?: {
        userId?: number;
        email?: string;
        firstName?: string;
        lastName?: string;
        approved?: boolean;
        lockedOut?: boolean;
        password?: string;
        rcsCustomer?: CustomerBaseInfo;
        roles?: RcsUserRole[];
        customers?: RcsUserCustomer[];
    }) {
        this.userId = args.userId;
        this.email = args.email;
        this.firstName = args.firstName;
        this.lastName = args.lastName;
        this.password = args.password;
        this.rcsCustomer = args.rcsCustomer;
        this.roles = args.roles;
        this.customers = args.customers;
    }
}
