import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { SubscriptionFormData } from "../models";
import { FormControl } from "@angular/forms";

interface DialogParams {
    isChangesConfirmed: boolean,
    formData: SubscriptionFormData
}
@Component({
    selector: 'streamer-subscription-dialog',
    templateUrl: './streamer-subscription-dialog.html',
    styleUrls: ['./streamer-subscription-dialog.sass']
})
export class CreateStreamerSubscriptionDialog {

    public streamerSelect = new FormControl();
    public customerSelect = new FormControl();

    constructor(
        public dialogRef: MatDialogRef<CreateStreamerSubscriptionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogParams) { }

    public onSave() {
        this.dialogRef.close({
            isChangesConfirmed: true,
            object: { streamerId: this.streamerSelect.value, customerId: this.customerSelect.value }
        });
    }

    public onCancel() {
        this.dialogRef.close(false);
    }
}