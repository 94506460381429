import { Component, OnInit } from '@angular/core';
import { CustomerService } from './services/customer.service';
import { Customer } from './models/customer';
import { AddEditCustomerDialogComponent } from './add-edit-customer-dialog/add-edit-customer-dialog.component';
import { CustomerDialogData } from './models/customer-dialog-data';
import { DialogConfirmationComponent } from '../../../components/dialog-confirmation/dialog-confirmation.component';
import { MatDialog, MatTableDataSource } from '@angular/material';
import * as _ from 'lodash';
import { DialogType } from '../../../models/dialog/dialogType';
import { DialogConfirmationData } from '../../../models/dialog/dialog-confirmation-data';

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.sass']
})
export class CustomerComponent implements OnInit {
    public customersDataSource = new MatTableDataSource<Customer>();
    public displayedColumns: string[] = ['CustomerId', 'CustomerName', 'CustomerGuid', 'Alias', 'Menu'];

    constructor(
        private customerService: CustomerService,
        public dialog: MatDialog
    ) { }

    public ngOnInit() {
        this.loadCustomers();
    }

    public loadCustomers() {
        this.customerService.getCustomers().subscribe((data: Customer[]) => {
            this.customersDataSource.data = data;
        });
    }

    public updateCustomer(updatedCustomer: Customer) {
        this.customerService.updateCustomer(updatedCustomer).subscribe((isCustomerUpdated: boolean) => {
            if (isCustomerUpdated) {
                this.customersDataSource.data = _.map(
                    this.customersDataSource.data,
                    (c) => c.customerId === updatedCustomer.customerId
                        ? updatedCustomer
                        : c
                );
            }
        });
    }

    public addCustomer(newCustomer: Customer) {
        this.customerService.addCustomer(newCustomer).subscribe((customer: Customer) => {
            this.customersDataSource.data = _.concat(this.customersDataSource.data, customer);
        });
    }

    public removeCustomer(removedCustomer: Customer) {
        this.customerService.deleteCustomer(removedCustomer).subscribe((isCustomerRemoved: boolean) => {
            if (isCustomerRemoved) {
                this.customersDataSource.data = _.remove(
                    this.customersDataSource.data,
                    (customer) => customer.customerId !== removedCustomer.customerId
                );
            }
        });
    }

    public removeCustomerChannelAliases(customer: Customer) {
        this.customerService.deleteCustomerChannelAliases(customer).subscribe((isChannelAliasesRemoved: boolean) => {
            if (isChannelAliasesRemoved)
                customer.hasChannelAlias = false;
        });
    }

    public createCustomerChannelAliases(customer: Customer) {
        this.customerService.createCustomerChannelAliases(customer).subscribe((isChannelAliasesAdded: boolean) => {
            if (isChannelAliasesAdded)
                customer.hasChannelAlias = true;
        });
    }

    public openAddDialog() {
        const dialogRemoving = this.createAddEditDialog(new Customer(), DialogType.Add);

        dialogRemoving.afterClosed().subscribe((result: CustomerDialogData) => {
            if (result && result.isChangesConfirmed) {
                this.addCustomer(result.customer);
            }
        });
    }

    public openEditDialog(customer: Customer) {
        this.customerService.getCustomer(customer.customerId).subscribe((customer: Customer) => {
            if (customer) {
                const dialogRemoving = this.createAddEditDialog(_.cloneDeep(customer), DialogType.Edit);

                dialogRemoving.afterClosed().subscribe((result: CustomerDialogData) => {
                    if (result && result.isChangesConfirmed) {
                        this.updateCustomer(result.customer);
                    }
                });
            }
        });
    }

    public openRemoveDialog(customer: Customer) {
        const dialogRemoving = this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: new DialogConfirmationData(customer.customerName)
        });

        dialogRemoving.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed) {
                this.removeCustomer(customer);
            }
        });
    }

    public createAddEditDialog(customer: Customer, dialogType: DialogType) {
        return this.dialog.open(AddEditCustomerDialogComponent, {
            width: '500px',
            data: new CustomerDialogData(dialogType, customer)
        });
    }
}
