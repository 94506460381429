import { Injectable } from '@angular/core';
import { HttpInternalService } from './http.internal.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Token } from '../models/common/token';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    public static logout() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }

    constructor(private httpService: HttpInternalService) {}

    public login(email: string, password: string): Observable<HttpResponse<Token>> {
        return this.httpService.postFullRequest(`/api/login`, {
            email,
            password
        });
    }

    public refresh(): Observable<HttpResponse<Token>> {
        return this.httpService.getFullRequest(`/api/refresh`);
    }
}
