import { DataProvider } from '../../../../models/common/data-provider';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTabChangeEvent } from '@angular/material';
import * as _ from 'lodash';

@Component({
  selector: 'add-edit-rcs-datafeed-dialog',
  templateUrl: './add-edit-rcs-datafeed-dialog.component.html',
  styleUrls: ['./add-edit-rcs-datafeed-dialog.component.sass']
})
export class AddEditRcsDataFeedDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<AddEditRcsDataFeedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    public onNo(): void {
        this.dialogRef.close(false);
        this.data.isChangesConfirmed = false;
    }

    public onYes(): void {
        this.data.isChangesConfirmed = true;
        this.dialogRef.close(_.cloneDeep(this.data));
    }

    public comparing(dp1: DataProvider, dp2: DataProvider): boolean {
        return dp1 && dp2 ? dp1.name === dp2.name : dp1 === dp2;
    }
}
