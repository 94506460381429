import { CustomerBaseInfo } from './customer-base-info';

export class CustomerWithUid implements CustomerBaseInfo {
    public customerId: number;
    public customerName: string;
    public customerUid: string;

    constructor(customerId: number, customerName: string, customerUid: string) {
        this.customerId = customerId;
        this.customerName = customerName;
        this.customerUid = customerUid;
    }
}
