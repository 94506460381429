export class UserDataFeeds {
    userId: number;
    email: string;
    dataFeedIds: number[];

    constructor(id: number, email: string, dataFeedIds: number[]) {
        this.userId = id;
        this.email = email;
        this.dataFeedIds = dataFeedIds;
    }
}
