import { HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DataFeedApiService } from './services/data-feed-api.sevice';
import { MatSnackBar, MatTableDataSource, MatDialog, MatPaginator, PageEvent } from '@angular/material';
import { DataFeed } from './models/data-feed';
import { DataFeedParameter } from './models/data-feed-parameter';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { UserState } from 'src/app/states/user-state';
import { DialogType } from 'src/app/models/dialog/dialogType';
import { DialogConfirmationComponent } from 'src/app/components/dialog-confirmation/dialog-confirmation.component';
import * as _ from 'lodash';
import { DialogConfirmationData } from 'src/app/models/dialog/dialog-confirmation-data';
import { AddEditDataFeedDialogComponent } from './add-edit-data-feed-dialog/add-edit-data-feed-dialog.component';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { DataProvider } from 'src/app/models/common/data-provider';
import { DataFeeds } from './models/data-feeds';

@Component({
  selector: 'app-data-feed',
  templateUrl: './data-feed.component.html',
  styleUrls: ['./data-feed.component.sass'],
  animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class DataFeedComponent implements OnInit, OnDestroy {
    public displayedCfgColumns: string[] = [
        'id',
        'provider',
        'name',
        'code',
        'importTimer',
        'pullDatatimer',
        'autoActiveLocations',
        'credentialStore',
        'isActive',
    ];

    public displayedParamColumns: string[] = ['id', 'key', 'value'];

    public expandedConfig: DataFeed;

    public dataFeedsDataSource = new MatTableDataSource<DataFeed>();
    public paramsDataSource = new MatTableDataSource<DataFeedParameter>();
    public dataProviders: DataProvider[] = [];

    public isAdmin = false;
    public configsCount = 0;
    public page = 0;
    public pageSize = 20;

    private subscription: Subscription = new Subscription();

    constructor(
        private apiService: DataFeedApiService,
        private snackBar: MatSnackBar,
        private userState: UserState,
        private dialog: MatDialog,
        private dataProviderService: DataProviderService) { }

    public ngOnInit() {
        this._configPermissions();
        this._loadDataFeeds();
        this._loadDataProviders();
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public expandCollapse(data: DataFeed) {
        if (data.parameters) {
            this.expandedConfig = this.expandedConfig === data ? undefined : data;
            this.paramsDataSource.data = data.parameters;
        }
    }

    public openAddDialog() {
        const addDialog = this._createEditDialog(DialogType.Add, new DataFeed());

        addDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed) {
                const { config } = result;
                this._addConfig(config);
            }
        });
    }

    public openUpdateDialog(config: DataFeed) {
        const updateDialog = this._createEditDialog(DialogType.Edit, _.cloneDeep(config));

        updateDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed) {
                const { config : updatedConfig } = result;
                this._updateConfig(updatedConfig);
            }
        });
    }

    public openRemoveDialog(config: DataFeed) {
        const removeDialog = this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: new DialogConfirmationData(`data feed for ${config.name}`)
        });

        removeDialog.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed && isRemovingConfirmed) {
                this._deleteConfig(config.id);
            }
        });
    }

    public changeConfigPage(event: PageEvent) {
        this.page = event.pageIndex;
        this.pageSize = event.pageSize;
        this._loadDataFeeds(this.page, this.pageSize);
    }

    private _createEditDialog(dialogType: DialogType, config: DataFeed) {
        return this.dialog.open(AddEditDataFeedDialogComponent, {
            width: '600px',
            data: {
                dialogType,
                isChangesConfirmed: false,
                config,
                dataProviders: this.dataProviders
            }
        });
    }

    private _loadDataProviders() {
        this.subscription.add(
            this.dataProviderService
                .getDataProvidersBaseInfo()
                .subscribe(
                    (resp: HttpResponse<DataProvider[]>) => {
                        if (resp.body) {
                            this.dataProviders = resp.body;
                            return;
                        }
                        this._onError('Failed to get data providers.');
                    },
                    (error) => this._onError(error)
                )
            );
    }

    private _loadDataFeeds(page = 0, pageSize = 20) {
        this.subscription.add(
            this.apiService.getDataFeeds(page, pageSize).subscribe(
                (resp: HttpResponse<DataFeeds>) => {
                    if (resp.body) {
                        this.dataFeedsDataSource.data = resp.body.dataFeeds;
                        this.configsCount = resp.body.count;
                        return;
                    }
                    this._onError('Failed to get data feeds.');
                },
                (error) => this._onError(error)
            )
        );
    }

    private _addConfig(config: DataFeed) {
        this.subscription.add(
            this.apiService.addDataFeed(config).subscribe(
                (resp: HttpResponse<DataFeed>) => {
                    if (resp.body) {
                        this._loadDataFeeds(this.page, this.pageSize);
                        return;
                    }
                    this._onError('Failed to add new data feed');
                },
                (error) => this._onError(error)
            )
        );
    }

    private _updateConfig(config: DataFeed) {
        this.subscription.add(
            this.apiService.updateDataFeed(config).subscribe(
                (resp: HttpResponse<boolean>) => {
                    if (resp.body) {
                        this._loadDataFeeds(this.page, this.pageSize);
                    } else {
                        this._onError('Failed to update data feed');
                    }
                },
                (error) => this._onError(error)
            )
        );
    }

    private _deleteConfig(configId: number) {
        this.subscription.add(
            this.apiService.deleteDataFeed(configId).subscribe(
                (resp: HttpResponse<boolean>) => {
                    if (resp.body) {
                        this._loadDataFeeds(0, this.pageSize);
                    } else {
                        this._onError('Failed to delete data feed');
                    }
                },
                (error) => this._onError(error)
            )
        );
    }

    private _configPermissions() {
        this.isAdmin = this.userState.hasRole('Admin');
        if (this.isAdmin) {
            this.displayedCfgColumns = this.displayedCfgColumns.concat('menu');
        }
    }

    private _onError(error: string) {
        this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
    }

}
