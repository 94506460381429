import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError, empty } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { ErrorCode } from '../models/common/error-code';
import { Token } from '../models/common/token';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private authService: AuthenticationService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err) => {
                if (err.status === 401 && !err.url.endsWith('refresh')) {
                    return this.authService.refresh().pipe(
                        catchError((innerError) => {
                            AuthenticationService.logout();
                            this.router.navigate(['/login']);
                            return throwError(innerError);
                        }),
                        switchMap((response) => {
                            localStorage.setItem('accessToken', response.body.accessToken);
                            localStorage.setItem('refreshToken', response.body.refreshToken);
                            req = req.clone({
                                setHeaders: {
                                    Authorization: `Bearer ${response.body.accessToken}`
                                }
                            });

                            return next.handle(req);
                        })
                    );
                }

                console.log(err);
                const error = err.error || err.error.message || err.message || `${err.status} ${err.statusText}`;

                return throwError(error);
            })
        );
    }
}
