import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { CustomerDomain } from '../models/customer-domain';
import { CustomerDomainsWithTotal } from '../models/customer-domains-with-total';

@Injectable()
export class CustomerDomainService {
    public routePrefix = '/api/customerDomain/';

    constructor(private httpService: HttpInternalService) {}

    public getCustomerDomain(customerDomainId: number): Observable<HttpResponse<CustomerDomain>> {
        return this.httpService.getFullRequest<CustomerDomain>(`${this.routePrefix}${customerDomainId}`);
    }

    public getCustomerDomains(page: number, pageSize: number): Observable<HttpResponse<CustomerDomainsWithTotal>> {
        return this.httpService.getFullRequest<CustomerDomainsWithTotal>(
            `${this.routePrefix}all`,
            new HttpParams().set('page', page.toString()).set('pageSize', pageSize.toString())
        );
    }

    public addCustomerDomain(customerDomain: CustomerDomain): Observable<HttpResponse<CustomerDomain>> {
        return this.httpService.putFullRequest<CustomerDomain>(`${this.routePrefix}`, customerDomain);
    }

    public updateCustomerDomain(customerDomain: CustomerDomain): Observable<HttpResponse<boolean>> {
        return this.httpService.postFullRequest<boolean>(`${this.routePrefix}`, customerDomain);
    }

    public deleteCustomerDomain(customerDomainId: number): Observable<HttpResponse<boolean>> {
        return this.httpService.deleteFullRequest<boolean>(`${this.routePrefix}${customerDomainId}`);
    }
}
