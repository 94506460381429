import { EventLocationUpdateModel } from './event-location-update';
import { EventLocationModel } from './event-location';
import { DataFeedBaseInfo } from 'src/app/models/common/data-feed-base-info';
import { Sport } from 'src/app/models/common/sport';
import { Country } from 'src/app/models/common/country';

export class LocationDialogData {
    public currentLocation: EventLocationModel;
    public isChangesConfirmed: boolean;
    public updatedModel: EventLocationUpdateModel;
    public sports: Sport[];
    public countries: Country[];
    public dataFeeds: DataFeedBaseInfo[];
    public dialogType: string;
}
