import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationMessage } from '../../../../models/notification/notificationMessage';
import { NotificationSnackBarObserver } from '../../../../services/notification-snack-bar-observer';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RaceAuditApiService } from './race-audit-api.service';
import { RaceAudit } from '../models/race-audit';
import { NotificationType } from 'src/app/models/notification/notificationType';

@Injectable({
    providedIn: 'root'
})
export class RaceAuditService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);

    constructor(private raceAuditApiService: RaceAuditApiService, private notificationObserver: NotificationSnackBarObserver) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getRaceAudits(raceId: number): Observable<RaceAudit[]> {
        return this.raceAuditApiService.getRaceAudits(raceId).pipe(
            map(
                (response: HttpResponse<RaceAudit[]>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject
                        .next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                    return undefined;
                }
            )
        );
    }
}
