import { DataFeed } from './../models/data-feed';
import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { MeetingBaseInfo } from 'src/app/models/common/meeting-base-info';
import { IRawDataRequest } from 'src/app/models/common/raw-data-request.interface';
import { RawImportModel } from 'src/app/models/common/raw-import';
import { DataFeedBaseInfo } from 'src/app/models/common/data-feed-base-info';
import { DataFeeds } from '../models/data-feeds';

@Injectable()
export class RcsDataFeedApiService {
    public routePrefix = '/api/rcs/dataFeed';

    constructor(private httpService: HttpInternalService) {}

    public getAllDataFeeds(page = 0, pageSize = 20): Observable<HttpResponse<DataFeeds>> {
        return this.httpService.getFullRequest(`${this.routePrefix}/all`, { page, pageSize });
    }

    public addDataFeed(config: DataFeed): Observable<HttpResponse<DataFeed>> {
        return this.httpService.postFullRequest<DataFeed>(`${this.routePrefix}`, config);
    }

    public updateDataFeed(config: DataFeed): Observable<HttpResponse<boolean>> {
        return this.httpService.putFullRequest<boolean>(`${this.routePrefix}`, config);
    }

    public deleteDataFeed(configId: number): Observable<HttpResponse<boolean>> {
        return this.httpService.deleteFullRequest<boolean>(`${this.routePrefix}`, { configId });
    }

    public getDataFeedsBaseInfo(): Observable<HttpResponse<DataFeedBaseInfo[]>> {
        return this.httpService.getFullRequest(`${this.routePrefix}`);
    }

    public getMeetingsByDate(providerId: number, date: Date): Observable<HttpResponse<MeetingBaseInfo[]>> {
        return this.httpService.getFullRequest('/api/rcs/meeting/meetingsByDate', { providerId, date: date.toDateString() });
    }

    public getSpecificRawData(request: IRawDataRequest): Observable<HttpResponse<RawImportModel[]>> {
        return this.httpService.getFullRequest(`${this.routePrefix}/rawData`, request);
    }
}
