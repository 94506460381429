import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { VirtualPlayers } from '../models/virtual-players';
import { VirtualPlayer } from '../models/virtual-player';

@Injectable()
export class VirtualPlayerApiService {
    private routePrefix = '/api/virtual';

    constructor(private httpService: HttpInternalService) {}

    public getVirtualPlayers(page = 0, pageSize = 10): Observable<HttpResponse<VirtualPlayers>> {
        return this.httpService.getFullRequest(`${this.routePrefix}`, { page, pageSize });
    }

    public addVirtualPlayer(player: VirtualPlayer): Observable<HttpResponse<VirtualPlayer>> {
        return this.httpService.postFullRequest(`${this.routePrefix}`, player);
    }

    public updateVirtualPlayer(player: VirtualPlayer): Observable<HttpResponse<any>> {
        return this.httpService.putFullRequest(`${this.routePrefix}`, player);
    }

    public deleteVirtualPlayer(playerId: number): Observable<HttpResponse<any>> {
        return this.httpService.deleteFullRequest(`${this.routePrefix}`, { playerId });
    }
}
