import { CustomerLocationsService } from '../../customer-locations/services/customer-locations.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { ManageEventsFilters } from '../models/manage-events-filters';
import { FiltersStateService } from '../services/filters-state.service';
import { LocationBaseInfo } from 'src/app/models/common/location-base-info';
import { LocationBaseInfos } from 'src/app/models/common/location-base-infos';
import { debounceTime, switchMap } from 'rxjs/operators';
@Component({
    selector: 'app-events-filters-dialog',
    templateUrl: './events-filters-dialog.component.html',
    styleUrls: ['./events-filters-dialog.component.sass']
})
export class EventsFiltersDialogComponent implements OnInit {

    public statuses = ['Closed', 'Open', 'Pending', 'Void'];

    public form: FormGroup = this._getDefaultDates();

    public locationFilters = new FormControl([]);
    public customerFilters = new FormControl([]);
    public dataFeedFilters = new FormControl([]);
    public countryFilters = new FormControl([]);
    public sportsFilters = new FormControl([]);
    public statusFilters = new FormControl([]);
    public search: FormControl = new FormControl();
    public locations: LocationBaseInfo[] = [];
    public locationsCount: number;

    private searchWord = '';
    private currentPage = 0;

    constructor(
        public dialogRef: MatDialogRef<EventsFiltersDialogComponent>,
        public locationService: CustomerLocationsService,
        private stateService: FiltersStateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    public ngOnInit() {
        this._setInitialValues();
        this._getLocations();
        this._initializeSearch();
    }

    public isFormValid() {
        return this.form.controls.startDate.value
            && this.form.controls.endDate.value
            && this.form.controls.endTime.value
            && this.form.controls.startTime.value;
    }

    public onApply() {
        const filters = new ManageEventsFilters();

        filters.countryCodes = this.countryFilters.value.map((x) => x.alpha2Code);
        filters.customerIds = this.customerFilters.value.map((x) => x.customerId);
        filters.sportCodes = this.sportsFilters.value.map((x) => x.code);
        filters.locationIds = this.locationFilters.value.map((x) => x.locationID);
        filters.statusCodes = this.statusFilters.value.map((x) => x[0]);
        filters.dataFeedIds = this.dataFeedFilters.value.map((x) => x.id);

        const start: Date = this.form.controls.startDate.value as Date;
        const [hoursStart, minutesStart] = this.form.controls.startTime.value.split(':');

        start.setHours(hoursStart);
        start.setMinutes(minutesStart);

        const end: Date = this.form.controls.endDate.value as Date;
        const [hoursEnd, minutesEnd] = this.form.controls.endTime.value.split(':');

        end.setHours(hoursEnd);
        end.setMinutes(minutesEnd);

        filters.startTimeUtc = this._getDateWithoutUTCOffset(start);
        filters.endTimeUtc = this._getDateWithoutUTCOffset(end);

        this.dialogRef.close(filters);
    }

    public onReset() {
        this.locationFilters = new FormControl([]);
        this.customerFilters = new FormControl([]);
        this.dataFeedFilters = new FormControl([]);
        this.countryFilters = new FormControl([]);
        this.sportsFilters = new FormControl([]);
        this.statusFilters = new FormControl([]);
        this.form = this._getDefaultDates();
        this.stateService.state.filters = new ManageEventsFilters();
    }

    public selectAllCustomers() {
        this.customerFilters.setValue(this.data.filtersData.customers);
    }

    public selectAllDataFeeds() {
        this.dataFeedFilters.setValue(this.data.filtersData.dataFeeds);
    }

    public selectAllCountries() {
        this.countryFilters.setValue(this.data.filtersData.countries);
    }

    public selectAllSports() {
        this.sportsFilters.setValue(this.data.filtersData.sports);
    }

    public selectAllLocations() {
        this.locationFilters.setValue(this.locations);
    }

    public selectAllStatuses() {
        this.statusFilters.setValue(this.statuses);
    }

    public deselectAllCustomers() {
        this.customerFilters.setValue([]);
    }

    public deselectAllDataFeeds() {
        this.dataFeedFilters.setValue([]);
    }

    public deselectAllCountries() {
        this.countryFilters.setValue([]);
    }

    public deselectAllSports() {
        this.sportsFilters.setValue([]);
    }

    public deselectAllLocations() {
        this.locationFilters.setValue([]);
    }

    public deselectAllStatuses() {
        this.statusFilters.setValue([]);
    }

    public loadNextLocations() {
        this._getLocations(this.currentPage, undefined, this.searchWord);
        this.currentPage += 1;
    }

    private _setInitialValues() {
        const filters = this.stateService.state.filters;

        this.customerFilters.setValue(
            this.data.filtersData.customers.filter((x) => filters.customerIds.includes(x.customerId))
        );
        this.dataFeedFilters.setValue(
            this.data.filtersData.dataFeeds.filter((x) => filters.dataFeedIds.includes(x.id))
        );
        this.countryFilters.setValue(
            this.data.filtersData.countries.filter((x) => filters.countryCodes.includes(x.alpha2Code))
        );
        this.sportsFilters.setValue(
            this.data.filtersData.sports.filter((x) => filters.sportCodes.includes(x.code))
        );
        this.locationFilters.setValue(
            this.locations.filter((x) => filters.locationIds.includes(x.locationID))
        );
        this.statusFilters.setValue(
            this.statuses.filter((x) => filters.statusCodes.includes(x[0]))
        );

        if (filters.startTimeUtc) {
            filters.startTimeUtc = this._getDateWithUTCOffset(filters.startTimeUtc);
            this.form.controls.startDate.setValue(filters.startTimeUtc);
            this.form.controls.startTime.setValue(`${filters.startTimeUtc.getHours()}:${filters.startTimeUtc.getMinutes()}`);
        }
        if (filters.endTimeUtc) {
            filters.endTimeUtc = this._getDateWithUTCOffset(filters.endTimeUtc);
            this.form.controls.endDate.setValue(filters.endTimeUtc);
            this.form.controls.endTime.setValue(`${filters.endTimeUtc.getHours()}:${filters.endTimeUtc.getMinutes()}`);
        }
    }

    private _getDefaultDates(): FormGroup {
        const endDate = moment().endOf('day');

        if (moment().isAfter(moment().startOf('day').add(20, 'hours'))) {
            endDate.add(1, 'day');
        }

        return new FormGroup({
            startDate: new FormControl(new Date()),
            startTime: new FormControl(
                moment()
                    .utc()
                    .format('HH:mm')),
            endDate: new FormControl(endDate.toDate()),
            endTime: new FormControl(
                moment()
                    .utc()
                    .hours(23)
                    .minutes(59)
                    .format('HH:mm'))
        });
    }

    private _getDateWithoutUTCOffset(date: Date): Date {
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    }

    private _getDateWithUTCOffset(date: Date): Date {
        return new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
    }

    private _getLocations(pageIndex = 0, pageSize = 100, searchWord = '') {
        this.locationService
            .getEventLocations(pageIndex, pageSize, searchWord)
            .subscribe((data: LocationBaseInfos) => {
                this.locationsCount = data.count;
                this.locations = this.locations.concat(data.locationBaseInfos);
            });
    }

    private _initializeSearch() {
        this.search.valueChanges.pipe(
            debounceTime(1000),
            switchMap((value) => {
                    this.searchWord = value;
                    this.locations = [];
                    this._getLocations(undefined, undefined, this.searchWord);
                    return value;
                }
            )
        )
        .subscribe();
    }
}
