import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationMessage } from '../../../../models/notification/notificationMessage';
import { HttpResponse } from '@angular/common/http';
import { NotificationType } from '../../../../models/notification/notificationType';
import { map } from 'rxjs/internal/operators/map';
import { Injectable } from '@angular/core';
import { NotificationSnackBarObserver } from 'src/app/services/notification-snack-bar-observer';
import { CustomerLocationApiService } from './customer-locations-api.service';
import { CustomerLocationsWithTotal } from '../models/customer-location-with-total';
import { CustomerLocation } from '../models/customer-location';
import { LocationBaseInfo } from 'src/app/models/common/location-base-info';
import { CustomerLocationsFormData } from '../models/customer-location-form-data';
import { FilterCriteria } from '../models/filter-criteria';
import { LocationsApiService } from 'src/app/services/locations.service';
import { LocationBaseInfos } from 'src/app/models/common/location-base-infos';
import { CustomerLocationFilter } from '../models/customer-location-filter';

@Injectable()
export class CustomerLocationsService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(
        undefined
    );
    constructor(
        private customerLocationsApiService: CustomerLocationApiService,
        private locationsApiService: LocationsApiService,
        private notificationObserver: NotificationSnackBarObserver
    ) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getAll(filterModel: CustomerLocationFilter,
    ): Observable<CustomerLocationsWithTotal> {
        return this.customerLocationsApiService
            .getCustomerLocations(filterModel)
            .pipe(
                map(
                    (response: HttpResponse<CustomerLocationsWithTotal>) => {
                        return response.body;
                    },
                    (error) => {
                        console.log(`Error: ${error}`);
                        this.notificationSubject.next(
                            new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                        );
                    }
                )
            );
    }

    public getFormData(): Observable<CustomerLocationsFormData> {
        return this.customerLocationsApiService.getFormData().pipe(
            map(
                (response: HttpResponse<CustomerLocationsFormData>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                    );
                }
            )
        );
    }

    public getSortedEventLocations(filters: FilterCriteria) {
        return this.locationsApiService
            .getSortedData(
                filters.dataFeedIdFilter,
                filters.sportCodeFilter,
                filters.countryCodeFilter,
                filters.showUllEnabledLocations,
                filters.searchWord
            )
            .pipe(
                map(
                    (response: HttpResponse<LocationBaseInfo[]>) => {
                        return response.body;
                    },
                    (error) => {
                        console.log(`Error: ${error}`);
                        this.notificationSubject.next(
                            new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                        );
                    }
                )
            );
    }

    public getEventLocations(
        page = 0,
        pageSize = 30,
        searchWord: string,
        isTwitchEvent = false
    ): Observable<LocationBaseInfos> {
        return this.locationsApiService
            .getLocations(page, pageSize, searchWord, isTwitchEvent)
            .pipe(
                map(
                    (response: HttpResponse<LocationBaseInfos>) => {
                        return response.body;
                    },
                    (error) => {
                        console.log(`Error: ${error}`);
                        this.notificationSubject.next(
                            new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                        );
                    }
                )
            );
    }

    public get(customerLocationId: number): Observable<CustomerLocation> {
        return this.customerLocationsApiService
            .getCustomerLocation(customerLocationId)
            .pipe(
                map(
                    (response: HttpResponse<CustomerLocation>) => {
                        return response.body;
                    },
                    (error) => {
                        console.log(`Error: ${error}`);
                        this.notificationSubject.next(
                            new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                        );
                    }
                )
            );
    }

    public create(newCustomerLocations: CustomerLocation): Observable<boolean> {
        return this.customerLocationsApiService
            .createCustomerLocation(newCustomerLocations)
            .pipe(
                map(
                    (response: HttpResponse<boolean>) => {
                        this.notificationSubject.next(
                            new NotificationMessage(
                                `'${newCustomerLocations.customerName}' Location was added`,
                                NotificationType.Adding
                            )
                        );
                        return response.body;
                    },
                    (error) => {
                        console.log(`Error: ${error}`);
                        this.notificationSubject.next(
                            new NotificationMessage(
                                `'${newCustomerLocations.customerName}' Location was not added`,
                                NotificationType.Error
                            )
                        );
                    }
                )
            );
    }

    public delete(customerLocationId: number): Observable<boolean> {
        return this.customerLocationsApiService
            .deleteCustomerLocation(customerLocationId)
            .pipe(
                map(
                    (response: HttpResponse<boolean>) => {
                        this.notificationSubject.next(
                            new NotificationMessage(`Customer Location was removed`, NotificationType.Removing)
                        );
                        return response.body;
                    },
                    (error) => {
                        console.log(`Error: ${error}`);
                        this.notificationSubject.next(
                            new NotificationMessage(`Customer Location was not removed`, NotificationType.Error)
                        );
                    }
                )
            );
    }
}
