import { MeetingBaseInfo } from '../../../models/common/meeting-base-info';
import { Subscription } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { RcsDataFeedApiService } from '../data-feed/services/rcs-data-feed-api.sevice';
import { Component, OnInit, HostListener } from '@angular/core';
import { RawImportModel } from '../../../models/common/raw-import';
import { MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RaceApiService } from '../race-audit/services/race-api.service';
import { RaceBaseInfo } from 'src/app/models/common/race-base-info';
import { IRawDataRequest } from 'src/app/models/common/raw-data-request.interface';
import { TimeConverterService } from 'src/app/services/time.converter.service';
import { includes } from 'lodash';
import { RawDataDialogComponent } from '../../data-feed/raw-import/raw-data-dialog/raw-data-dialog.component';
import { DataFeed } from '../data-feed/models/data-feed';
import { DataFeedBaseInfo } from 'src/app/models/common/data-feed-base-info';
@Component({
    selector: 'app-datafeeds-raw-data',
    templateUrl: './datafeeds-raw-data.component.html',
    styleUrls: ['./datafeeds-raw-data.component.sass']
})
export class DataFeedsRawDataComponent implements OnInit {
    public pdwColumns = ['id', 'rawData', 'importDateTime'];
    public iasColumns = [...this.pdwColumns, 'date', 'type', 'meetingId', 'raceIds'];
    public currentColumns: string[];
    public rawDataSource = new MatTableDataSource<RawImportModel>();
    public dataFeeds: DataFeedBaseInfo[] = [];
    public meetings: MeetingBaseInfo[] = [];
    public races: RaceBaseInfo[] = [];

    public filters: FormGroup;
    public windowWidth: number;

    private subscription: Subscription = new Subscription();

    constructor(
        private dataFeedService: RcsDataFeedApiService,
        private raceService: RaceApiService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private timeConverterService: TimeConverterService,
        private dialog: MatDialog
    ) {}

    @HostListener('window:resize', ['$event'])
    public onResize(event) {
        this.windowWidth = window.innerWidth;
    }

    public ngOnInit(): void {
        this.filters = this.formBuilder.group({
            dataFeed: ['', Validators.required],
            date: [new Date(), Validators.required],
            meeting: [{ disabled: true }, Validators.required],
            race: [{ disabled: true }, Validators.required]
        });
        this.filters.controls.meeting.disable({ onlySelf: !this.filters.controls.dataFeed.valid });
        this.filters.controls.race.disable({ onlySelf: !this.filters.controls.meeting.valid });
        this.filters.controls.date.disable({ onlySelf: !this.filters.controls.dataFeed.valid });
        this._loadDataFeeds();

        this.windowWidth = window.innerWidth;
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public onDataFeedSelect() {
        this.cleanUp();
        this.filters.controls.meeting.enable({ onlySelf: this.filters.controls.dataFeed.valid });
        this.filters.controls.date.enable({ onlySelf: this.filters.controls.dataFeed.valid });
        this._loadMeetingsByDate(this.filters.value.dataFeed.id, this.filters.controls.date.value);
        this.changeCurrentColumns(this.filters.value.dataFeed.code);
    }

    public onMeetingSelect() {
        this.races = [];
        this.rawDataSource.data = [];
        this.filters.controls.race.enable({ onlySelf: this.filters.controls.meeting.valid });
        this._loadRacesByMeetingId(this.filters.value.meeting.id);
    }

    public onRaceSelect() {
        this.rawDataSource.data = [];
        this._getSpecificRawData({
            meetingId: this.filters.value.meeting.id,
            dataFeedId: this.filters.value.dataFeed.id,
            raceNumber: this.filters.value.race.raceNumber,
            selectedDate: this.timeConverterService.getDateYearMonthDay(this.filters.value.date)
        });
    }

    public onDateChange() {
        this.cleanUp();
        this._loadMeetingsByDate(this.filters.value.dataFeed.id, this.filters.value.date);
    }

    public isColumnIncluded = (columnName: string) => includes(this.currentColumns, columnName);

    public handleLongString(rawData: string) {
        return rawData.length > this.windowWidth / 15 ? `${rawData.slice(0, this.windowWidth / 15)}...` : rawData;
    }

    public createDialog(rawRecord: string, recId: number) {
        return this.dialog.open(RawDataDialogComponent, {
            data: { rawData: rawRecord, recordId: recId }
        });
    }

    private _loadDataFeeds() {
        this.subscription.add(
            this.dataFeedService.getDataFeedsBaseInfo().subscribe(
                (data: HttpResponse<DataFeedBaseInfo[]>) => {
                    this.dataFeeds = this._dataFeedsFilter(data.body);
                },
                (error) => this._onError(error)
            )
        );
    }

    private _loadMeetingsByDate(dataFeedId: number, date: Date) {
        this.subscription.add(
            this.dataFeedService.getMeetingsByDate(dataFeedId, date).subscribe(
                (data: HttpResponse<MeetingBaseInfo[]>) => {
                    this.meetings = data.body;
                },
                (error) => this._onError(error)
            )
        );
    }

    private _loadRacesByMeetingId(meetingId: number) {
        this.subscription.add(
            this.raceService.getRacesBaseInfoByMeetingId(meetingId).subscribe(
                (data: HttpResponse<RaceBaseInfo[]>) => {
                    this.races = data.body;
                },
                (error) => this._onError(error)
            )
        );
    }

    private _getSpecificRawData(request: IRawDataRequest) {
        this.subscription.add(
            this.dataFeedService.getSpecificRawData(request).subscribe(
                (data: HttpResponse<RawImportModel[]>) => {
                    this.rawDataSource.data = data.body;
                },
                (error) => this._onError(error)
            )
        );
    }

    private _dataFeedsFilter(dataFeeds: DataFeedBaseInfo[]): DataFeedBaseInfo[] {
        return dataFeeds.filter((p) => p.code === 'PDW' || p.code === 'IAS');
    }

    private _onError(error: string) {
        this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
    }

    private changeCurrentColumns(dataFeedEnumCode: string) {
        switch (dataFeedEnumCode) {
            case 'IAS':
                this.currentColumns = this.iasColumns;
                break;
            default:
                this.currentColumns = this.pdwColumns;
                break;
        }
    }

    private cleanUp() {
        this.races = [];
        this.meetings = [];
        this.rawDataSource.data = [];
    }
}
