import { Component, ViewChild } from '@angular/core';
import { PlayerModel } from './models/player';
import { MatTableDataSource, MatPaginator, MatDialog, PageEvent, Sort } from '@angular/material';
import { PlayerService } from './services/player.service';
import { DialogType } from 'src/app/models/dialog/dialogType';
import * as _ from 'lodash';
import { Players } from './models/players';
import { AddEditPlayerDialogComponent } from './add-edit-player-dialog/add-edit-player-dialog.component';
import { DialogConfirmationComponent } from 'src/app/components/dialog-confirmation/dialog-confirmation.component';
import { DialogConfirmationData } from 'src/app/models/dialog/dialog-confirmation-data';

@Component({
    selector: 'app-player',
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.sass']
})
export class PlayerComponent {
    @ViewChild('pagination') public pagination: MatPaginator;

    public playersSource = new MatTableDataSource<PlayerModel>();
    public playersCount: number;

    public currentPage = 0;
    public currentPageSize = 10;

    public displayedColumns: string[] = [
        'id',
        'type',
        'playerName',
        'webUrl',
        'playerWidth',
        'playerHeight',
        'playerStretch',
        'fixedSize',
        'autoStart',
        'fullscreenEnabled',
        'menu'
    ];

    constructor(private playersService: PlayerService, public dialog: MatDialog) {}

    public ngOnInit() {
        this.loadPlayers();
    }

    public changePlayerPage(event: PageEvent) {
        this.currentPageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadPlayers(event.pageIndex, event.pageSize);
    }

    public sortData(sort: Sort) {
        let isAscDirection = true;
        let column = 'id';

        if (sort.active && sort.direction) {
            isAscDirection = sort.direction === 'asc';
            column = sort.active;
        }

        this.playersSource.data = this.playersSource.data.sort((a, b) => {
            switch (column) {
                case 'type':
                    return this.compare(a.playerType, b.playerType, isAscDirection);
                case 'playerStretch':
                    return this.compare(a.stretch, b.stretch, isAscDirection);
                case 'name':
                    return this.compare(a.name, b.name, isAscDirection);
                default:
                    return this.compare(a.id, b.id, isAscDirection);
            }
        });
    }

    public openAddDialog() {
        const addDialog = this.createEditDialog(DialogType.Add, new PlayerModel());

        addDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed === true) {
                this.addPlayer(result.player);
            }
        });
    }

    public openUpdateDialog(player: PlayerModel) {
        const updateDialog = this.createEditDialog(DialogType.Edit, _.cloneDeep(player));

        updateDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed === true) {
                this.updatePlayer(result.player);
            }
        });
    }

    public openRemoveDialog(player: PlayerModel) {
        const removeDialog = this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: new DialogConfirmationData(`${player.name} player`)
        });

        removeDialog.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed && isRemovingConfirmed === true) {
                this.deletePlayer(player);
            }
        });
    }

    private createEditDialog(dialogType: DialogType, player: PlayerModel) {
        return this.dialog.open(AddEditPlayerDialogComponent, {
            width: '500px',
            data: {
                dialogType,
                player
            }
        });
    }

    private addPlayer(player: PlayerModel) {
        this.playersService.createPlayer(player).subscribe((newItem: PlayerModel) => {
            if (newItem) {
                const page = Math.floor(this.playersCount / this.currentPageSize);
                this.loadPlayers(page, this.currentPageSize);
                this.pagination.pageIndex = page;
            }
        });
    }

    private updatePlayer(player: PlayerModel) {
        this.playersService.updatePlayer(player).subscribe((isUpdate: boolean) => {
            if (isUpdate) {
                this.loadPlayers(this.currentPage, this.currentPageSize);
            }
        });
    }

    private deletePlayer(player: PlayerModel) {
        this.playersService.deletePlayer(player.id).subscribe((isRemoved: boolean) => {
            if (isRemoved) {
                this.loadPlayers(this.currentPage, this.currentPageSize);
            }
        });
    }

    private loadPlayers(page = 0, pageIndex = 10) {
        this.playersService.getAllPlayers(page, pageIndex).subscribe((data: Players) => {
            this.playersSource.data = data.players;
            this.playersCount = data.playersCount;
        });
    }

    private compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
