import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { UserState } from '../states/user-state';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivateChild, CanActivate {
    private tokenHelper = new JwtHelperService();

    constructor(private router: Router, private userState: UserState) { }

    public canActivate(route: ActivatedRouteSnapshot,
                       state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this._isActivate(route.data as { allowedRoles: string[] });
    }

    public canActivateChild(route: ActivatedRouteSnapshot,
                            state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this._isActivate(route.data as { allowedRoles: string[] });
    }

    private _isActivate(data: { allowedRoles: string[] }) {
        this._handleEmptyUserState();
        const userRole = this.userState.getRole();
        // allow for everyone if there no allowedRoles;
        if (!data.allowedRoles) {
            return true;
        }
        if (data.allowedRoles.includes(userRole)) {
            return true;
        }
        this.router.navigate(['/not-allowed']);
        return false;
    }

    private _handleEmptyUserState() {
        if (this.userState.isEmptyState()) {
            const decodedToken = this.tokenHelper.decodeToken(localStorage.getItem('accessToken'));
            this.userState
                .setName(decodedToken.name)
                .setEmail(decodedToken.email)
                .setRole(decodedToken.role);
        }
    }
}
