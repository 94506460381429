import { Component, OnInit, Inject } from '@angular/core';
import { QueueDialogData } from '../models/queue-dialog-data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MessageType } from '../models/message-type';

@Component({
    selector: 'app-queues-maintaining-dialog',
    templateUrl: './queues-maintaining-dialog.component.html',
    styleUrls: ['./queues-maintaining-dialog.component.sass']
})
export class QueuesMaintainingDialogComponent implements OnInit {
    public messageType = MessageType;

    public messageFormats: string[];
    public messageTypes: string[];

    constructor(public dialogRef: MatDialogRef<QueuesMaintainingDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: QueueDialogData) {}

    public ngOnInit() {
        this.messageTypes = Object.keys(this.messageType).filter(Number);
    }

    public onYes() {
        this.dialogRef.close(this.data);
    }

    public onNo() {
        this.dialogRef.close('');
    }
}
