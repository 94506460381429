import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContentDeliveryNetwork } from '../models/content-delivery-network';

@Injectable()
export class ContentDeliveryNetworkApiService {
    public routePrefix = '/api/contentDeliveryNetwork';

    constructor(private httpService: HttpInternalService) {}

    public getAllContentDeliveryNetwork(): Observable<HttpResponse<ContentDeliveryNetwork[]>> {
        return this.httpService.getFullRequest<ContentDeliveryNetwork[]>(`${this.routePrefix}/all`);
    }
}
