// tslint:disable:max-line-length
import 'hammerjs';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { MainNavigationComponent } from './components/main-navigation/main-navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSliderModule } from '@angular/material/slider';
import { ClipboardModule } from 'ngx-clipboard';

import { AppComponent } from './app.component';

import { AppRoutes } from './app.routes';
import { MaterialComponentsModule } from './components/material-components/material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AuthenticationService } from './services/authentication.service';
import { UserApiService } from './pages/system/user/services/user-api.service';
import { RoleApiService } from './pages/system/role/services/role-api.service';
import { CustomerApiService } from './pages/customer/customer/services/customer-api.service';
import { NotificationSnackBarObserver } from './services/notification-snack-bar-observer';
import { RouteService } from './services/route.service';
import { CustomerDomainService } from './pages/customer/customer-domain/services/customer-domain.service';
import { PushApiService } from './pages/race-card/push/services/push-api.service';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TestEventGeneratorComponent } from './pages/event/test-event-generator/test-event-generator.component';
import { VersionInfoComponent } from './pages/dev/version-info/version-info.component';
import { RoleComponent } from './pages/system/role/role.component';
import { UserComponent } from './pages/system/user/user.component';
import { HttpInternalService } from './services/http.internal.service';
import { TestEventGeneratorService } from './pages/event/test-event-generator/services/test-event-generator.service';
import { DialogConfirmationComponent } from './components/dialog-confirmation/dialog-confirmation.component';
import { CustomerComponent } from './pages/customer/customer/customer.component';
import { AddEditCustomerDialogComponent } from './pages/customer/customer/add-edit-customer-dialog/add-edit-customer-dialog.component';
import { VersionInfoService } from './pages/dev/version-info/services/version-info.service';
import { ToolsComponent } from './pages/tools/tools.component';
import { CustomerDomainComponent } from './pages/customer/customer-domain/customer-domain.component';
import { HomeComponent } from './components/home/home.component';
import { MatSortModule, MAT_DATE_LOCALE, MatSlideToggleModule } from '@angular/material';
import { PushComponent } from './pages/race-card/push/push.component';
import { ScheduleService } from './pages/bet-n-watch/test-web-services/services/schedule.service';
import { RestScheduleService } from './pages/bet-n-watch/test-web-services/services/rest-schedule.service';
import { TestWebServicesComponent } from './pages/bet-n-watch/test-web-services/test-web-services.component';
import { TimeConverterService } from './services/time.converter.service';
import { UserDatafeedsApiService } from './pages/bet-n-watch/user-datafeeds/services/user-datafeeds-api.service';
import { UserDatafeedsService } from './pages/bet-n-watch/user-datafeeds/services/user-datafeeds.service';
import { UserDatafeedsComponent } from './pages/bet-n-watch/user-datafeeds/user-datafeeds.component';
import { AddEditRoleDialogComponent } from './pages/system/role/add-edit-role-dialog/add-edit-role-dialog.component';
import { AddEditUserDialogComponent } from './pages/system/user/add-edit-user-dialog/add-edit-user-dialog.component';
import { CustomerService } from './pages/customer/customer/services/customer.service';
import { RoleService } from './pages/system/role/services/role.service';
import { RawImportComponent } from './pages/data-feed/raw-import/raw-import.component';
import { RawImportApiService } from './pages/data-feed/raw-import/services/raw-import-api.service';
import { DataProviderService } from './services/data-provider.service';
import { RawDataDialogComponent } from './pages/data-feed/raw-import/raw-data-dialog/raw-data-dialog.component';
import { RaceAuditComponent } from './pages/race-card/race-audit/race-audit.component';
import { CountryApiService } from './pages/race-card/race-audit/services/country-api.service';
import { CountryService } from './pages/race-card/race-audit/services/country.service';
import { RaceApiService } from './pages/race-card/race-audit/services/race-api.service';
import { RaceService } from './pages/race-card/race-audit/services/race.service';
import { EventHistoryComponent } from './pages/event/event-history/event-history.component';
import { EventHistoryApiService } from './pages/event/event-history/services/event-history-api.service';
import { StreamMonitorComponent } from './pages/stream-monitor/stream-monitor.component';
import { HammerConfig } from './helpers/hammer-config';
import { SafePipe } from './helpers/safe-pipe';
import { LocationComponent } from './pages/event/location/location.component';
import { LocationDialogComponent } from './pages/event/location/location-dialog/location-dialog.component';
import { EventLocationApiService } from './pages/event/location/services/location-api.service';
import { AutoGenerateCustomerLocationComponent } from './pages/bet-n-watch/auto-generate-customer-location/auto-generate-customer-location.component';
import { AutoGenerateCustomerLocationDialogComponent } from './pages/bet-n-watch/auto-generate-customer-location/auto-generate-customer-location-dialog/auto-generate-customer-location-dialog.component';
import { AutoGenerateCustomerLocationApiService } from './pages/bet-n-watch/auto-generate-customer-location/services/auto-generate-customer-location-api.service';
import { StreamComponent } from './pages/bet-n-watch/stream/stream.component';
import { CustomerSettingComponent } from './pages/race-card/customer-setting/customer-setting.component';
import { CustomerSettingService } from './pages/race-card/customer-setting/services/сustomer-setting.service';
import { CustomerSettingApiService } from './pages/race-card/customer-setting/services/customer-setting-api.service';
import { AddEditCustomerSettingDialogComponent } from './pages/race-card/customer-setting/add-edit-customer-setting-dialog/add-edit-customer-setting-dialog.component';
import { ProviderComponent } from './pages/3rd-party-services/provider-codes/provider-codes.component';
import { ProviderDataFeedApiService } from './pages/3rd-party-services/provider-codes/services/data-feed-api.service';
import { ProviderDataFeedsService } from './pages/3rd-party-services/provider-codes/services/data-feed.service';
import { StreamService } from './pages/bet-n-watch/stream/services/stream.service';
import { StreamApiService } from './pages/bet-n-watch/stream/services/stream-api.service';
import { CustomerLocationsComponent } from './pages/mcr-monitoring/customer-locations/customer-locations.component';
import { CustomerLocationsService } from './pages/mcr-monitoring/customer-locations/services/customer-locations.service';
import { AddEditCustomerLocationDialogComponent } from './pages/mcr-monitoring/customer-locations/add-edit-customer-location-dialog/add-edit-customer-location-dialog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LocationFilteringState } from './pages/mcr-monitoring/customer-locations/services/locations-filtering-state.service';
import { LocationsApiService } from './services/locations.service';
import { AddEditStreamDialogComponent } from './pages/bet-n-watch/stream/add-edit-stream-dialog/add-edit-stream-dialog.component';
import { ContentDeliveryNetworkService } from './pages/bet-n-watch/stream/services/content-delivery-network.service';
import { ContentDeliveryNetworkApiService } from './pages/bet-n-watch/stream/services/content-delivery-network-api.service';
import { PlayerComponent } from './pages/bet-n-watch/player/player.component';
import { AddEditPlayerDialogComponent } from './pages/bet-n-watch/player/add-edit-player-dialog/add-edit-player-dialog.component';
import { PlayerService } from './pages/bet-n-watch/player/services/player.service';
import { PlayerApiService } from './pages/bet-n-watch/player/services/player-api.service';

import { VirtualPlayerComponent } from './pages/bet-n-watch/virtual-player/virtual-player.component';
import { AddEditVirtualPlayerDialogComponent } from './pages/bet-n-watch/virtual-player/add-edit-virtual-player-dialog/add-edit-virtual-player-dialog.component';
import { VirtualPlayerService } from './pages/bet-n-watch/virtual-player/services/virtual-player.service';
import { VirtualPlayerApiService } from './pages/bet-n-watch/virtual-player/services/virtual-player-api.service';

import { LiveScheduleComponent } from './pages/mcr-monitoring/mcr-schedule/live-schedule.component';
import { McrScheduleApiService } from './pages/mcr-monitoring/mcr-schedule/services/mcr-schedule-api.service';
import { McrScheduleService } from './pages/mcr-monitoring/mcr-schedule/services/mcr-schedule.service';
import { EventsFiltersDialogComponent } from './pages/mcr-monitoring/mcr-schedule/events-filters-dialog/events-filters-dialog.component';
import { FiltersStateService } from './pages/mcr-monitoring/mcr-schedule/services/filters-state.service';
import { EventIssuesDialogComponent } from './pages/mcr-monitoring/mcr-schedule/event-issues/event-issues-dialog.component';
import { EventFlightBoardComponent } from './pages/event/event-flight-board/event-flight-board.component';
import { EventFlightBoardApiService } from './pages/event/event-flight-board/services/event-flight-board-api.service';
import { EventFlightBoardService } from './pages/event/event-flight-board/services/event-flight-board.service';
import { QueuesMaintainingComponent } from './pages/data-feed/queues-maintaining/queues-maintaining.component';
import { QueueMaintainApiService } from './pages/data-feed/queues-maintaining/services/queue-maintain-api.service';
import { QueuesMaintainingDialogComponent } from './pages/data-feed/queues-maintaining/queues-maintaining-dialog/queues-maintaining-dialog.component';
import { EventInspectionComponent } from './pages/mcr-monitoring/mcr-schedule/event-inspection/event-inspection.component';
import { TestWebServicesDialogComponent } from './pages/mcr-monitoring/mcr-schedule/test-web-services-dialog/test-web-services-dialog.component';
import { StreamLinkComponent } from './components/stream-link/stream-link.component';
import { UserState } from './states/user-state';
import { AccessNotAllowedComponent } from './pages/access-not-allowed/access-not-allowed.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { ContextMenuModule } from 'ngx-contextmenu';
import { DataFeedComponent } from './pages/data-feed/data-feed-configuration/data-feed.component';
import { DataFeedApiService } from './pages/data-feed/data-feed-configuration/services/data-feed-api.sevice';
import { AddEditDataFeedDialogComponent } from './pages/data-feed/data-feed-configuration/add-edit-data-feed-dialog/add-edit-data-feed-dialog.component';
import { XmlSchemaApiService } from './services/xml-schema.service';
import { DataFeedsRawDataComponent } from './pages/race-card/datafeeds-raw-data/datafeeds-raw-data.component';
import { HlsPlayerComponent } from './pages/stream-monitor/hls-player/hls-player.component';
import { SignalRService } from './services/signalr.service';
import { EventsComponent } from './pages/event/events/events.component';
import { UploadEventErrorComponent } from './pages/event/events/dialogs/upload-event-error-component/upload-event-error.component';
import { EventsApiService } from './pages/event/events/services/events-api.service';
import { EventsCreateUpdateDialog } from './pages/event/events/dialogs/events-create-update-dialog';
import { TwitchLocationDialogComponent } from './pages/event/location/twitch-location-dialog/twitch-location-dialog.component';
import { StreamersComponent } from './pages/event/streamers/streamers/streamers.component';
import { StreamersApiService } from './pages/event/streamers/services/streamers-api.service';
import { StreamersDialogComponent } from './pages/event/streamers/streamers-dialog/streamers-dialog.component';
import { SportsComponent } from './pages/event/sports/sports/sports.component';
import { SportsDialogComponent } from './pages/event/sports/sports-dialog/sports-dialog.component';
import { SportsApiService } from './pages/event/sports/service/sports-api.service';
import { StreamerSubscriptionComponent } from './pages/event/streamer-subscription/streamer-subscription.component';
import { StreamerSubscriptionApiService } from './pages/event/streamer-subscription/streamer-subscription-api.service';
import { CreateStreamerSubscriptionDialog } from './pages/event/streamer-subscription/dialog/streamer-subscription-dialog';
import { RcsCustomerApiService } from './pages/race-card/customer/services/rcs-customer-api.service';
import { RcsCustomerService } from './pages/race-card/customer/services/rcs-customer.service';
import { RcsDataFeedApiService } from './pages/race-card/data-feed/services/rcs-data-feed-api.sevice';
import { AddEditRcsCustomerDialogComponent } from './pages/race-card/customer/add-edit-rcs-customer-dialog/add-edit-rcs-customer-dialog.component';
import { RcsCustomerComponent } from './pages/race-card/customer/rcs-customer.component';
import { AddEditRcsDataFeedDialogComponent } from './pages/race-card/data-feed/add-edit-rcs-datafeed-dialog/add-edit-rcs-datafeed-dialog.component';
import { RcsDataFeedComponent } from './pages/race-card/data-feed/rcs-data-feed.component';
import { RcsRoleComponent } from './pages/race-card/role/rcs-role.component';
import { AddEditRcsRoleDialogComponent } from './pages/race-card/role/add-edit-rcs-role-dialog/add-edit-rcs-role-dialog.component';
import { AddEditRcsUserDialogComponent } from './pages/race-card/user/add-edit-rcs-user-dialog/add-edit-rcs-user-dialog.component';
import { RcsUserComponent } from './pages/race-card/user/rcs-user.component';
import { RcsUserApiService } from './pages/race-card/user/services/rcs-user-api.service';
import { RcsRoleApiService } from './pages/race-card/role/services/rcs-role-api.service';
import { AddEditCustomerDomainDialogComponent } from './pages/customer/customer-domain/add-edit-customer-domain-dialog/add-edit-customer-domain-dialog.component';
import { MultipleEventsCreationDialogComponent } from './pages/event/events/dialogs/multiple-events-creation-dialog/multiple-events-creation-dialog.component';
import { DialogInformationComponent } from './components/dialog-information/dialog-information.component';
import { UploadCustomerLocationErrorComponent } from './pages/mcr-monitoring/customer-locations/dialogs/upload-customer-location-error-component/upload-customer-location-error.component';

@NgModule({
    declarations: [
        SafePipe,
        AppComponent,
        LoginComponent,
        LogoutComponent,
        MainNavigationComponent,
        DashboardComponent,
        TestEventGeneratorComponent,
        ToolsComponent,
        VersionInfoComponent,
        RoleComponent,
        UserComponent,
        DialogConfirmationComponent,
        AddEditRoleDialogComponent,
        CustomerComponent,
        AddEditCustomerDialogComponent,
        AddEditUserDialogComponent,
        CustomerDomainComponent,
        HomeComponent,
        PushComponent,
        RcsRoleComponent,
        AddEditRcsRoleDialogComponent,
        TestWebServicesComponent,
        UserDatafeedsComponent,
        RcsDataFeedComponent,
        RawImportComponent,
        RawDataDialogComponent,
        RaceAuditComponent,
        EventHistoryComponent,
        StreamMonitorComponent,
        LocationComponent,
        LocationDialogComponent,
        AutoGenerateCustomerLocationComponent,
        AutoGenerateCustomerLocationDialogComponent,
        StreamComponent,
        AddEditCustomerSettingDialogComponent,
        CustomerSettingComponent,
        RcsCustomerComponent,
        ProviderComponent,
        CustomerLocationsComponent,
        AddEditCustomerLocationDialogComponent,
        AddEditStreamDialogComponent,
        PlayerComponent,
        AddEditPlayerDialogComponent,

        VirtualPlayerComponent,
        AddEditVirtualPlayerDialogComponent,

        LiveScheduleComponent,
        RcsUserComponent,
        EventsFiltersDialogComponent,
        AddEditRcsUserDialogComponent,
        EventIssuesDialogComponent,
        EventFlightBoardComponent,
        EventInspectionComponent,
        TestWebServicesDialogComponent,
        AddEditRcsDataFeedDialogComponent,
        StreamLinkComponent,
        QueuesMaintainingComponent,
        QueuesMaintainingDialogComponent,
        AddEditCustomerDomainDialogComponent,
        EventInspectionComponent,
        AccessNotAllowedComponent,
        DataFeedComponent,
        AddEditDataFeedDialogComponent,
        AddEditRcsCustomerDialogComponent,
        DataFeedsRawDataComponent,
        HlsPlayerComponent,
        EventsComponent,
        UploadEventErrorComponent,
        UploadCustomerLocationErrorComponent,
        EventsCreateUpdateDialog,
        TwitchLocationDialogComponent,
        StreamersComponent,
        StreamersDialogComponent,
        SportsComponent,
        SportsDialogComponent,
        StreamerSubscriptionComponent,
        CreateStreamerSubscriptionDialog,
        MultipleEventsCreationDialogComponent,
        DialogInformationComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatDialogModule,
        MatSnackBarModule,
        MatSliderModule,
        MatSlideToggleModule,
        ScrollDispatchModule,
        LayoutModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LoadingBarHttpClientModule,
        ClipboardModule,
        MatSortModule,
        MaterialComponentsModule,
        InfiniteScrollModule,
        RouterModule.forRoot(AppRoutes),
        MatSelectInfiniteScrollModule,
        ContextMenuModule.forRoot(),
    ],
    exports: [MaterialComponentsModule],
    providers: [
        StreamersApiService,
        AuthenticationService,
        UserApiService,
        TestEventGeneratorService,
        VersionInfoService,
        RoleApiService,
        RoleService,
        CustomerApiService,
        CustomerService,
        RcsCustomerService,
        RcsCustomerApiService,
        HttpInternalService,
        NotificationSnackBarObserver,
        RouteService,
        CustomerDomainService,
        PushApiService,
        ScheduleService,
        RcsUserApiService,
        RcsRoleApiService,
        TimeConverterService,
        UserDatafeedsApiService,
        UserDatafeedsService,
        RawImportApiService,
        DataProviderService,
        CountryApiService,
        CountryService,
        RcsDataFeedApiService,
        DataFeedApiService,
        RaceApiService,
        RaceService,
        EventHistoryApiService,
        EventLocationApiService,
        CustomerSettingService,
        CustomerSettingApiService,
        AutoGenerateCustomerLocationApiService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        ProviderDataFeedsService,
        ProviderDataFeedApiService,
        StreamService,
        StreamApiService,
        CustomerLocationsService,
        LocationFilteringState,
        LocationsApiService,
        ContentDeliveryNetworkService,
        ContentDeliveryNetworkApiService,
        PlayerApiService,
        PlayerService,
        McrScheduleApiService,
        McrScheduleService,
        RestScheduleService,
        RcsDataFeedApiService,
        FiltersStateService,
        EventFlightBoardApiService,
        EventFlightBoardService,
        QueueMaintainApiService,
        UserState,
        XmlSchemaApiService,
        SignalRService,
        EventsApiService,
        SportsApiService,
        StreamerSubscriptionApiService,

        VirtualPlayerService,
        VirtualPlayerApiService
    ],
    entryComponents: [
        RoleComponent,
        DialogConfirmationComponent,
        DialogInformationComponent,
        AddEditRoleDialogComponent,
        AddEditCustomerDialogComponent,
        AddEditRcsCustomerDialogComponent,
        AddEditUserDialogComponent,
        RawDataDialogComponent,
        LocationDialogComponent,
        AutoGenerateCustomerLocationDialogComponent,
        AddEditCustomerSettingDialogComponent,
        AddEditCustomerLocationDialogComponent,
        AddEditStreamDialogComponent,
        AddEditRcsRoleDialogComponent,
        AddEditCustomerDomainDialogComponent,
        AddEditRcsUserDialogComponent,
        AddEditPlayerDialogComponent,
        AddEditVirtualPlayerDialogComponent,
        EventsFiltersDialogComponent,
        EventIssuesDialogComponent,
        TestWebServicesDialogComponent,
        AddEditRcsDataFeedDialogComponent,
        QueuesMaintainingDialogComponent,
        AddEditDataFeedDialogComponent,
        EventsCreateUpdateDialog,
        TwitchLocationDialogComponent,
        StreamersDialogComponent,
        SportsDialogComponent,
        CreateStreamerSubscriptionDialog,
        MultipleEventsCreationDialogComponent,
        UploadEventErrorComponent,
        UploadCustomerLocationErrorComponent
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
