import { Injectable } from '@angular/core';
import { CustomerApiService } from './customer-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationMessage } from '../../../../models/notification/notificationMessage';
import { NotificationSnackBarObserver } from '../../../../services/notification-snack-bar-observer';
import { Customer } from '../models/customer';
import { HttpResponse } from '@angular/common/http';
import { CustomerWithUid } from '../../../../models/common/customer-with-uid';
import { map } from 'rxjs/internal/operators/map';
import { CustomerBaseInfo } from '../../../../models/common/customer-base-info';
import { NotificationType } from '../../../../models/notification/notificationType';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);

    constructor(private customerApiService: CustomerApiService, private notificationObserver: NotificationSnackBarObserver) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getCustomer(customerId: number): Observable<Customer> {
        return this.customerApiService.getCustomer(customerId).pipe(
            map(
                (response: HttpResponse<Customer>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                    );
                }
            )
        );
    }

    public getCustomersWithUid(): Observable<CustomerWithUid[]> {
        return this.customerApiService.getCustomersWithUid().pipe(
            map(
                (response: HttpResponse<CustomerWithUid[]>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                    );
                }
            )
        );
    }

    public getCustomersBaseInfo(): Observable<CustomerBaseInfo[]> {
        return this.customerApiService.getCustomersBaseInfo().pipe(
            map(
                (response: HttpResponse<CustomerBaseInfo[]>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                    );
                }
            )
        );
    }

    public getRcsCustomersBaseInfo(): Observable<CustomerBaseInfo[]> {
        return this.customerApiService.getRcsCustomersBaseInfo().pipe(
            map(
                (response: HttpResponse<CustomerBaseInfo[]>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                    );
                }
            )
        );
    }

    public getCustomers(): Observable<Customer[]> {
        return this.customerApiService.getCustomers().pipe(
            map(
                (response: HttpResponse<Customer[]>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                    );
                }
            )
        );
    }

    public addCustomer(newCustomer: Customer): Observable<Customer> {
        return this.customerApiService.addCustomer(newCustomer).pipe(
            map(
                (response: HttpResponse<Customer>) => {
                    this.notificationSubject.next(
                        new NotificationMessage(`Customer '${newCustomer.customerName}' was added`, NotificationType.Adding)
                    );
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Customer '${newCustomer.customerName}' was not added`, NotificationType.Error)
                    );
                }
            )
        );
    }

    public updateCustomer(updatedCustomer: Customer): Observable<boolean> {
        return this.customerApiService.updateCustomer(updatedCustomer).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    if (response.body === true) {
                        this.notificationSubject.next(
                            new NotificationMessage(
                                `Customer '${updatedCustomer.customerName}' was updated`,
                                NotificationType.Updating
                            )
                        );
                    } else {
                        this.notificationSubject.next(
                            new NotificationMessage(
                                `Customer '${updatedCustomer.customerName}' was not updated`,
                                NotificationType.Error
                            )
                        );
                    }
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Customer '${updatedCustomer.customerName}' was not updated`, NotificationType.Error)
                    );

                    return false;
                }
            )
        );
    }

    public deleteCustomer(removedCustomer: Customer): Observable<boolean> {
        return this.customerApiService.deleteCustomer(removedCustomer.customerId).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    if (response.body) {
                        this.notificationSubject.next(
                            new NotificationMessage(`Customer '${removedCustomer.customerName}' was removed`, NotificationType.Removing)
                        );
                    } else {
                        this.notificationSubject.next(
                            new NotificationMessage(`Customer '${removedCustomer.customerName}' was not removed`, NotificationType.Error)
                        );
                    }
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Customer '${removedCustomer.customerName}' was not removed`, NotificationType.Error)
                    );

                    return false;
                }
            )
        );
    }

    public createCustomerChannelAliases(customer: Customer): Observable<boolean> {
        return this.customerApiService.createCustomerChannelAliases(customer.customerId).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    if (response.body) {
                        this.notificationSubject.next(
                            new NotificationMessage(`Customer '${customer.customerName}' channel aliases was created`, NotificationType.Removing)
                        );
                    } else {
                        this.notificationSubject.next(
                            new NotificationMessage(`Customer '${customer.customerName}' channel aliases was not created`, NotificationType.Error)
                        );
                    }
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Customer '${customer.customerName}' channel aliases was not created`, NotificationType.Error)
                    );

                    return false;
                }
            )
        );
    }

    public deleteCustomerChannelAliases(customer: Customer): Observable<boolean> {
        return this.customerApiService.deleteCustomerChannelAliases(customer.customerId).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    if (response.body) {
                        this.notificationSubject.next(
                            new NotificationMessage(`Customer '${customer.customerName}' channel aliases was removed`, NotificationType.Removing)
                        );
                    } else {
                        this.notificationSubject.next(
                            new NotificationMessage(`Customer '${customer.customerName}' channel aliases was not removed`, NotificationType.Error)
                        );
                    }
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`Customer '${customer.customerName}' channel aliases was not removed`, NotificationType.Error)
                    );

                    return false;
                }
            )
        );
    }
}
