import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { RawDataDialog } from '../models/raw-data-dialog';
import * as formatter from 'xml-formatter';

@Component({
    selector: 'app-raw-data-dialog',
    templateUrl: './raw-data-dialog.component.html',
    styleUrls: ['./raw-data-dialog.component.sass']
})
export class RawDataDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<RawDataDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RawDataDialog,
        private snackBar: MatSnackBar
    ) {}

    public onOk(): void {
        this.dialogRef.close(false);
    }

    public onCopy(textarea: any) {
        textarea.value = this.data.rawData;
        textarea.select();
        document.execCommand('copy');
        this.snackBar.open('Copied to the clipboard', '', { duration: 3000, panelClass: 'usual-snack-bar' });
    }
}
