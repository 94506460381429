import { Component, OnInit, ViewChild } from '@angular/core';
import { AutoGenerateCustomerLocationApiService } from './services/auto-generate-customer-location-api.service';
import { MatSnackBar, MatDialog, MatTableDataSource, MatPaginator, PageEvent, Sort } from '@angular/material';
import { AutoGenerateCustomerLocationModel } from './models/auto-generate-customer-location';
import { Subscription } from 'rxjs';
import { AutoGenerateCustomerLocationsModel } from './models/auto-generate-customer-locations';
import { HttpResponse } from '@angular/common/http';
// tslint:disable-next-line:max-line-length
import { AutoGenerateCustomerLocationDialogComponent } from './auto-generate-customer-location-dialog/auto-generate-customer-location-dialog.component';
import { AutoGenerateCustomerLocationDialogData } from './models/auto-generate-customer-location-dialog-data';
import { DialogType } from 'src/app/models/dialog/dialogType';
import { DialogConfirmationComponent } from 'src/app/components/dialog-confirmation/dialog-confirmation.component';
import { DialogConfirmationData } from 'src/app/models/dialog/dialog-confirmation-data';
import { AutoGenerateCustomerLocationRelatedCollectionsModel } from './models/auto-generate-customer-location-collections';
import * as _ from 'lodash';

@Component({
    selector: 'app-auto-generate-customer-location',
    templateUrl: './auto-generate-customer-location.component.html',
    styleUrls: ['./auto-generate-customer-location.component.sass']
})
export class AutoGenerateCustomerLocationComponent implements OnInit {
    public customerLocationDataSource = new MatTableDataSource<AutoGenerateCustomerLocationModel>();
    public pageSizeOptions = [20, 50, 100];

    public locationTableColumns: string[] = [
        'id',
        'customerName',
        'dataFeedName',
        'uniqueStreamId',
        'playerName',
        'streamName',
        'isActive',
        'rawEnabled',
        'menu'
    ];

    public customerLocationsSubscription: Subscription;
    public customerLocationSubscription: Subscription;
    public customerLocationUpdateSubscription: Subscription;
    public customerLocationDeleteSubscription: Subscription;
    public customerLocationAddSubscription: Subscription;
    public relatedCollectionSubscription: Subscription;
    public dialogSubscription: Subscription;

    public relatedCollections: AutoGenerateCustomerLocationRelatedCollectionsModel;

    @ViewChild(MatPaginator) public paginator: MatPaginator;

    public currentSort: string;
    public ascendingDirection = true;

    constructor(
        private snackBar: MatSnackBar,
        private customerLocationApiService: AutoGenerateCustomerLocationApiService,
        private dialog: MatDialog
    ) {}

    public ngOnInit() {
        this.getCustomerLocations(0, this.pageSizeOptions[0]);
        this.getRelatedCollections();
    }

    public getRelatedCollections() {
        this.relatedCollectionSubscription = this.customerLocationApiService.getRelatedCollections().subscribe(
            (response: HttpResponse<AutoGenerateCustomerLocationRelatedCollectionsModel>) => {
                this.relatedCollections = response.body;
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public handlePaginator(event: PageEvent) {
        this.getCustomerLocations(event.pageIndex, event.pageSize);
    }

    public getCustomerLocations(page: number, pageSize: number, message?: string) {
        this.customerLocationsSubscription = this.customerLocationApiService
            .getAutoGenerateCustomerLocations(page, pageSize, this.ascendingDirection)
            .subscribe(
                (response: HttpResponse<AutoGenerateCustomerLocationsModel>) => {
                    this.customerLocationDataSource.data = response.body.autoGenerateCustomerLocations;
                    this.paginator.length = response.body.count;

                    if (message) {
                        this.snackBar.open(message, '', { duration: 3000, panelClass: 'usual-snack-bar' });
                    }
                },
                (error) => {
                    this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
                }
            );
    }

    public updateCustomerLocation(customerLocation: AutoGenerateCustomerLocationModel) {
        this.customerLocationUpdateSubscription = this.customerLocationApiService.updateAutoGenerateCustomerLocation(customerLocation).subscribe(
            () => {
                this.getCustomerLocations(this.paginator.pageIndex, this.paginator.pageSize, 'Location was updated');
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public addCustomerLocation(location: AutoGenerateCustomerLocationModel) {
        this.customerLocationAddSubscription = this.customerLocationApiService.addAutoGenerateCustomerLocation(location).subscribe(
            () => {
                this.getCustomerLocations(this.paginator.pageIndex, this.paginator.pageSize, 'Location was added');
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public deleteCustomerLocation(locationId: number) {
        this.customerLocationDeleteSubscription = this.customerLocationApiService.deleteAutoGenerateCustomerLocation(locationId).subscribe(
            () => {
                this.getCustomerLocations(this.paginator.pageIndex, this.paginator.pageSize, 'Location was removed');
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public openUpdateDialog(location: AutoGenerateCustomerLocationModel) {
        const dialog = this.dialog.open(AutoGenerateCustomerLocationDialogComponent, {
            width: '500px',
            data: { locationModel: _.cloneDeep(location), typeOfDialog: DialogType.Edit, relatedCollections: this.relatedCollections }
        });

        this.dialogSubscription = dialog.afterClosed().subscribe((result: AutoGenerateCustomerLocationDialogData) => {
            if (result && result.isChangesConfirmed) {
                this.updateCustomerLocation(result.locationModel);
            }
        });
    }

    public openAddDialog() {
        const dialog = this.dialog.open(AutoGenerateCustomerLocationDialogComponent, {
            width: '500px',
            data: { typeOfDialog: DialogType.Add, relatedCollections: this.relatedCollections }
        });

        dialog.afterClosed().subscribe((result: AutoGenerateCustomerLocationDialogData) => {
            if (result && result.isChangesConfirmed === true) {
                this.addCustomerLocation(result.locationModel);
            }
        });
    }

    public openRemoveDialog(locationId: number) {
        const removeDialog = this.dialog.open(DialogConfirmationComponent, {
            data: new DialogConfirmationData(undefined, `Are you sure you want to remove record with Id ${locationId}`)
        });

        removeDialog.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed) {
                this.deleteCustomerLocation(locationId);
            }
        });
    }

    public sortData(sort: Sort) {
        if (sort.active && sort.direction) {
            this.ascendingDirection = sort.direction === 'asc';
            this.getCustomerLocations(this.paginator.pageIndex, this.paginator.pageSize);
        } else {
            this.ascendingDirection = true;
            this.getCustomerLocations(this.paginator.pageIndex, this.paginator.pageSize);
        }
    }

    public ngOnDestroy() {
        this.unsubscribe(this.customerLocationSubscription);
        this.unsubscribe(this.customerLocationsSubscription);
        this.unsubscribe(this.customerLocationUpdateSubscription);
        this.unsubscribe(this.dialogSubscription);
        this.unsubscribe(this.relatedCollectionSubscription);
    }

    private unsubscribe(subscription: Subscription) {
        if (subscription) {
            subscription.unsubscribe();
        }
    }
}
