import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { cloneDeep } from 'lodash';
import { ContentDeliveryNetwork } from '../models/content-delivery-network';

@Component({
    selector: 'app-add-edit-stream-dialog',
    templateUrl: './add-edit-stream-dialog.component.html',
    styleUrls: ['./add-edit-stream-dialog.component.sass']
})
export class AddEditStreamDialogComponent {
    public streamTypes = ['HLS', 'ULL'];

    constructor(public dialogRef: MatDialogRef<AddEditStreamDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.streamTypes = this.data.stream.streamType
            ? [this.streamTypes.find(x => x === this.data.stream.streamType)].concat(this.streamTypes.filter(x => x !== this.data.stream.streamType))
            : this.streamTypes;
    }


    public onNo(): void {
        this.dialogRef.close(false);
        this.data.isChangesConfirmed = false;
    }

    public isValid(): boolean {
        return this.data.stream.cdn && this.data.stream.streamType && this.data.stream.friendlyName && this.data.stream.streamLink;
    }

    public onYes(): void {
        this.dialogRef.close();
        this.data.isChangesConfirmed = true;

        this.dialogRef.close(cloneDeep(this.data));
    }

    public compareCdn(cdn1: ContentDeliveryNetwork, cdn2: ContentDeliveryNetwork) {
        return cdn1 && cdn2 ? cdn1.name === cdn2.name : cdn1 === cdn2;
    }
}
