import { DataProvider } from 'src/app/models/common/data-provider';
import { Injectable } from '@angular/core';
import { HttpInternalService } from './http.internal.service';
import { HttpResponse } from '@angular/common/http';
import { ProviderWithDataFeedsModel } from '../pages/data-feed/raw-import/models/provider-with-data-feeds';
import { Observable } from 'rxjs';
import { MeetingBaseInfo } from '../models/common/meeting-base-info';
import { RawImportModel } from '../models/common/raw-import';
import { IRawDataRequest } from '../models/common/raw-data-request.interface';

@Injectable({ providedIn: 'root' })
export class DataProviderService {
    private routePrefix = '/api/dataProvider';

    constructor(private httpService: HttpInternalService) {}

    public getDataProvidersWithRelatedDataFeeds(): Observable<HttpResponse<ProviderWithDataFeedsModel[]>> {
        return this.httpService.getFullRequest(`${this.routePrefix}/dataFeedInclude/`);
    }

    public getDataProvidersBaseInfo(): Observable<HttpResponse<DataProvider[]>> {
        return this.httpService.getFullRequest(`${this.routePrefix}/baseInfo`);
    }    
}
