import { DataProvider } from './../../../../models/common/data-provider';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTabChangeEvent } from '@angular/material';
import * as _ from 'lodash';
import { DataFeedParameter } from '../models/data-feed-parameter';

@Component({
  selector: 'app-add-edit-data-feed-dialog',
  templateUrl: './add-edit-data-feed-dialog.component.html',
  styleUrls: ['./add-edit-data-feed-dialog.component.sass']
})
export class AddEditDataFeedDialogComponent {

    public tab = 0;
    public lastIndex = -1;

    constructor(
        public dialogRef: MatDialogRef<AddEditDataFeedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    public ngOnInit() {
        if (!!this.data.config.parameters) {
            this.lastIndex = length - 1;
            this.data.config.parameters = this._removeWhitespacesFromParamValues(this.data.config.parameters);
        } else {
            this.data.config.parameters = [];
        }
        this.addParam();
    }

    public onNo(): void {
        this.dialogRef.close(false);
        this.data.isChangesConfirmed = false;
    }

    public onYes(): void {
        this.data.isChangesConfirmed = true;
        this.data.config.parameters.pop();
        this.dialogRef.close(_.cloneDeep(this.data));
    }

    public onNext() {
        this.tab += 1;
    }

    public onTabChange(data: MatTabChangeEvent) {
        this.tab = data.index;
    }

    public addParam() {
        this.data.config.parameters = this.data.config.parameters.concat(new DataFeedParameter());
        this._calculateLastIndex();
    }

    public removeParam(itemIndex: number) {
        this.data.config.parameters = this.data.config.parameters.filter((item, index) => index !== itemIndex);
        if (this.data.config.parameters.length === 0) {
            this.addParam();
        }
        this._calculateLastIndex();
    }

    public comparing(dp1: DataProvider, dp2: DataProvider): boolean {
        return dp1 && dp2 ? dp1.name === dp2.name : dp1 === dp2;
    }

    private _calculateLastIndex() {
        this.lastIndex = this.data.config.parameters.length - 1;
    }

    private _removeWhitespacesFromParamValues(params: DataFeedParameter[]) {
        params.forEach((p) => {
            p.parameterKey = p.parameterKey.trim();
            p.parameterValue = p.parameterValue.trim();
        });
        return params;
    }

}
