import { DetailedEvent } from '../../../models/common/detailed-event';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, MatDialog, MatTableDataSource, PageEvent, MatSnackBar, Sort } from '@angular/material';
import { Subject, Subscription } from 'rxjs';
import { EventsApiService } from './services/events-api.service';
import { HttpResponse } from '@angular/common/http';
import { DetailedEventsModel } from './models/events.model';
import { DialogType } from 'src/app/models/dialog/dialogType';
import { EventsCreateUpdateDialog } from './dialogs/events-create-update-dialog';
import { DialogConfirmationData } from 'src/app/models/dialog/dialog-confirmation-data';
import { DialogConfirmationComponent } from 'src/app/components/dialog-confirmation/dialog-confirmation.component';
import { UploadEventErrorComponent } from 'src/app/pages/event/events/dialogs/upload-event-error-component/upload-event-error.component';
import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MultipleEventsCreationDialogComponent } from './dialogs/multiple-events-creation-dialog/multiple-events-creation-dialog.component';
import * as moment from 'moment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EventLocationApiService } from '../location/services/location-api.service';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.sass']
})
export class EventsComponent implements OnInit {
    @ViewChild('pagination') public pagination: MatPaginator;

    public eventsSource = new MatTableDataSource<DetailedEvent>();
    public eventsCount: number;
    public searchCriteriaSubject = new Subject<string>();
    public searchCriteria = '';

    public currentPageSize = 25;
    public currentPage = 0;
    public currentDirection = undefined;
    public currentSort = undefined;

    public displayedColumns: string[] = [
        'id',
        'locationName',
        'status',
        'locationCode',
        'eventNumber',
        'eventTitle',
        'startTime',
        'esr',
        'ast',
        'is_auto',
        'dateModified',
        'menu'
    ];

    constructor(
        private eventLocationApiService: EventLocationApiService,
        private apiService: EventsApiService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        public multipleDialog: MatDialog,
        public sanitizer: DomSanitizer
    ) {}

    public ngOnInit() {
        this.setupSearchEvent();
        this.loadEvents(this.currentPage, this.currentPageSize);
    }

    public searchCriteriaHandler = (input: any) => {
        this.searchCriteriaSubject.next(input);
        this.searchCriteria = input;
    };

    public setupSearchEvent = () => {
        this.searchCriteriaSubject.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe((value: string) => {
                this.searchCriteria = value;
                if (this.pagination) {
                    this.pagination.firstPage();
                }
                this.loadEvents(0, this.currentPageSize, this.searchCriteria);

            });
    };

    public changeEventsPage(event: PageEvent) {
        this.currentPageSize =  event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadEvents(event.pageIndex, event.pageSize, this.searchCriteria, this.currentSort, this.currentDirection);
    }

    public sortData(sort: Sort) {
        if (sort.active && sort.direction) {
            const isAscDirection = sort.direction === 'asc';

            this.currentDirection = sort.direction;
            this.currentSort = sort.active;

            this.loadEvents(this.pagination.pageIndex, this.pagination.pageSize, this.searchCriteria, this.currentSort, isAscDirection);
        } else {
            this.currentDirection = undefined;
            this.currentSort = undefined;
            this.loadEvents(this.pagination.pageIndex, this.pagination.pageSize, this.searchCriteria);
        }
    }

    public loadEvents(page?: number, pageSize?: number, searchWord?: string, sortFilter?: string, isAsc?: boolean) {
        if (!page) {
            page = this.currentPage;
        }

        if (!pageSize) {
            pageSize = this.currentPageSize;
        }

        this.apiService.getAll(page, pageSize, searchWord, sortFilter, isAsc).subscribe(
            (response: HttpResponse<DetailedEventsModel>) => {
                this.eventsSource.data = response.body.events;
                this.eventsCount = response.body.count;

                if (this.pagination) {
                    this.pagination.length = response.body.count;
                }
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public openAddDialog() {
        const addDialog = this.createEditDialog(DialogType.Add, new DetailedEvent());

        addDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed) {
                this.addEvent(result.object);
            }
        });
    }

    public openAddMultipleDialog() {
        const addDialog = this.createMultipleAddDialog();

        addDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed) {
                this.addEvents(result.objects);
            }
        });
    }

    public openUpdateDialog(event: DetailedEvent) {
        const updateDialog = this.createEditDialog(DialogType.Edit, _.cloneDeep(event));

        updateDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed) {
                this.updateEvent(result.object);
            }
        });
    }

    public openRemoveDialog(eventId: string) {
        const removeDialog = this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: new DialogConfirmationData(`${eventId} event`)
        });

        removeDialog.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed && isRemovingConfirmed) {
                this.deleteEvent(eventId);
            }
        });
    }

    public onDownloadLocations() {
        this.eventLocationApiService.downloadLocationsFile().subscribe((response) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
            downloadLink.download = 'locations.csv';
            downloadLink.click();
        });
    }

    public onUploadEvents() {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.csv';
        input.onchange = (event: any) => {
            const file = event.target.files[0];
            console.log(file);

            const formData = new FormData();
            formData.append('file', file);

            this.apiService.uploadEventsCsvFile(formData).subscribe(
                ({ body: result }) => {
                    const { data } = this.eventsSource;
                    this.eventsSource.data = [...result.addedEvents, ...data];

                    const htmlWithLineBreaks = result.message.replace(/(\r\n|\n|\r)/g, '<br>');

                    this.snackBar.openFromComponent(UploadEventErrorComponent, {
                        duration: 15000, panelClass: 'usual-snack-bar',
                        data: htmlWithLineBreaks
                    });
                },
                (error) => {
                    this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
                }
            );
        };
        input.click();
    }

    public getLocaleDateTime(stringDate: string) {
        if (stringDate) {
            const adjustedDate = moment.utc(stringDate);
            const formattedDateTime = adjustedDate.format('YYYY-MM-DD HH:mm:ss');
            return formattedDateTime;
        }
    }

    private createEditDialog(dialogType: DialogType, event: DetailedEvent) {
        return this.dialog.open(EventsCreateUpdateDialog, {
            width: '500px',
            data: {
                dialogType,
                object: _.cloneDeep(event),
                isChangesConfirmed: false,
            }
        });
    }

    private createMultipleAddDialog() {
        return this.multipleDialog.open(MultipleEventsCreationDialogComponent, {
            width: '900px',
            data: {
                objects: [new DetailedEvent()],
                isChangesConfirmed: false,
            }
        });
    }

    private deleteEvent(eventId: string) {
        this.apiService
            .delete(eventId)
            .subscribe(
                ({ body: isRemoved}) => {
                    if (isRemoved) {
                        this.eventsSource.data = this.eventsSource.data.filter((x) => x.eventId !== eventId);
                    }
                }
            );
    }

    private addEvent(event: DetailedEvent) {
        this.apiService.create(event).subscribe(
                    () => {
                        const { data } = this.eventsSource;
                        this.eventsSource.data = [event, ...data];
                    },
                (error) => {
                    this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
                }
            );
    }

    private addEvents(events: DetailedEvent[]) {
        this.apiService.createMany(events).subscribe(
            () => {
                const { data } = this.eventsSource;
                this.eventsSource.data = [...events, ...data];
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
            );
    }

    private updateEvent(event: DetailedEvent) {
        this.apiService
            .update(event)
            .subscribe(
                ({body: newEvent}) => {
                    const eventIndex = this.eventsSource.data.map((x) => x.eventId).indexOf(event.eventId);
                    const newList = [...this.eventsSource.data];
                    newList[eventIndex] = event;
                    this.eventsSource.data = newList;
                }
            );
    }
}
