import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StreamMonitorService } from './services/stream-monitor.service';
import { StreamBaseInfo } from './models/stream-base-info';
import { StreamWithStreamLink } from './models/stream-with-stream-link';
import { tap } from 'rxjs/internal/operators/tap';
import { interval } from 'rxjs/internal/observable/interval';
import { Subscription, Subject } from 'rxjs';
import { MatSelectChange } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { StreamFilter } from './models/stream-filter';

@Component({
    selector: 'app-stream-monitor',
    templateUrl: './stream-monitor.component.html',
    styleUrls: ['./stream-monitor.component.sass']
})
export class StreamMonitorComponent implements OnInit, OnDestroy {
    public streams: StreamBaseInfo[];
    public selectedStreams: StreamBaseInfo[] = [];
    public unselectedStreams: StreamBaseInfo[] = [];

    public allSelectedStreamsWithStreamLink: StreamWithStreamLink[];
    public streamsWithStreamLinkOnCurrentPage: StreamWithStreamLink[];

    public generatorOptions: FormGroup;
    public isControlButtonsDisabled = true;
    public columns = 0;
    public isRotationStopedManually = false;
    public dropdownOpened = false;

    private currentPageNumber: number;
    private streamsPerPage: number;

    private rotationInterval: number;
    private rotationSubscription: Subscription;
    private destroy$: Subject<void> = new Subject<void>();

    constructor(private streamMonitorService: StreamMonitorService, private formBuilder: FormBuilder) {}

    public ngOnInit() {
        this.streamMonitorService
            .getStreamsBaseInfo()
            .pipe(takeUntil(this.destroy$))
            .subscribe((streams: StreamBaseInfo[]) => {
                this.streams = streams;
                this.unselectedStreams = streams;
            });
        this.generatorOptions = this.formBuilder.group({
            streams: [[], Validators.required],
            isRotationActive: [true, Validators.required],
            streamsPerPage: [6, Validators.required],
            rotationInterval: [30, Validators.required]
        });
        this.columns = this._calculateStreamsGridColumns(window.innerWidth);
    }

    public ngOnDestroy() {
        this.destroy$.next();
        this.unsubscribe(this.destroy$);
        this.unsubscribe(this.rotationSubscription);
    }

    public unsubscribe(subs: Subscription | Subject<any>) {
        if (subs) {
            subs.unsubscribe();
        }
    }

    public apply() {
        if (!this.generatorOptions.valid) {
            return;
        }

        this.isControlButtonsDisabled = false;
        this.isRotationStopedManually = !this.generatorOptions.value.isRotationActive;

        this.currentPageNumber = 1;
        this.streamsPerPage = this.generatorOptions.value.streamsPerPage;
        this.rotationInterval = this.generatorOptions.value.rotationInterval;

        const filterModel = this.getStreamFilterModel();
        this.loadSelectedStreams(filterModel);

        if (this.rotationSubscription) {
            this.rotationSubscription.unsubscribe();
        }

        this.pauseOrContinueRotation();
    }

    public inverseFlagAndPauseOrContinueRotation() {
        this.isRotationStopedManually = !this.isRotationStopedManually;
        this.pauseOrContinueRotation();
    }

    public loadPreviousPage() {
        this.streamsWithStreamLinkOnCurrentPage = this.paginator(
            this.allSelectedStreamsWithStreamLink,
            --this.currentPageNumber,
            this.streamsPerPage
        );
    }

    public loadNextPage() {
        this.streamsWithStreamLinkOnCurrentPage = this.paginator(
            this.allSelectedStreamsWithStreamLink,
            ++this.currentPageNumber,
            this.streamsPerPage
        );
    }

    public refreshTokenOfSelectedStreams() {
        const filterModel = this.getStreamFilterModel();
        this.loadSelectedStreams(filterModel);
    }

    public loadSelectedStreams(streamFilter: StreamFilter) {
        this.streamMonitorService
            .getStreamsDetails(streamFilter)
            .pipe(takeUntil(this.destroy$))
            .subscribe((allSelectedStreamsWithStreamLink: StreamWithStreamLink[]) => {
                this.allSelectedStreamsWithStreamLink = allSelectedStreamsWithStreamLink;

                this.streamsWithStreamLinkOnCurrentPage = this.paginator(
                    this.allSelectedStreamsWithStreamLink,
                    this.currentPageNumber,
                    this.streamsPerPage
                );
            });
    }

    public selectAllStreams() {
        this.generatorOptions.controls.streams.setValue(this.streams);
        this.selectedStreams = this.streams;
        this.unselectedStreams = [];
    }

    public deselectAllStreams() {
        this.generatorOptions.controls.streams.setValue([]);
        this.selectedStreams = [];
        this.unselectedStreams = this.streams;
    }

    public onSelect(data: MatSelectChange) {
        this.selectedStreams = data.value;
        const selectedIds = data.value.map(({ id }) => id);
        this.unselectedStreams = this.streams.filter(({ id }) => !selectedIds.includes(id));
        this.generatorOptions.value.streams = data.value;
    }

    private compare(first: string, second: string, isAsc: boolean) {
        return (first < second ? -1 : 1) * (isAsc ? 1 : -1);
    }

    public onOpenDropdown(event: boolean) {
        this.dropdownOpened = event;
    }

    public onResize(event: any) {
        const size = event.target.innerWidth;
        this.columns = this._calculateStreamsGridColumns(size);
    }


    private getStreamFilterModel() {

        const filter = new StreamFilter();

        var result = new StreamFilter().streamIdModels = [];
        result = this.selectedStreams.map((x) => ({isVirtual: x.isVirtual, id: x.id}));
        filter.streamIdModels = result;

        return filter;
    }

    private pauseOrContinueRotation() {
        if (this.isRotationStopedManually) {
            if (this.rotationSubscription) {
                this.rotationSubscription.unsubscribe();
            }
        } else {
            this.rotationSubscription = interval(this.rotationInterval * 1000)
                .pipe(
                    tap(() => this.loadNextPage()),
                    takeUntil(this.destroy$)
                )
                .subscribe();
        }
    }

    private paginator(streams: StreamWithStreamLink[], currentPage: number, perPage: number) {
        const totalPageNumbers = Math.ceil(streams.length / perPage);

        if (currentPage <= 0) {
            currentPage = totalPageNumbers;
        } else if (currentPage > totalPageNumbers) {
            currentPage = 1;
        }

        this.currentPageNumber = currentPage;

        const startIndex = (currentPage - 1) * perPage;
        const endtIndex = currentPage * perPage;

        return streams.slice(startIndex, endtIndex);
    }

    private _calculateStreamsGridColumns(size: number): number {
        if (size > 2350) {
            return 5;
        } else if (size < 2350 && size > 1800) {
            return 4;
        } else if (size < 1800 && size > 1200) {
            return 3;
        } else if (size < 1200 && size > 600) {
            return 2;
        } else {
            return 1;
        }
    }
}
