import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { HttpInternalService } from './http.internal.service';
import { XmlSchema } from '../models/common/xml-schema';

@Injectable({
    providedIn: 'root'
})
export class XmlSchemaApiService {
    public routePrefix = '/api/xmlSchema';

    constructor(private httpService: HttpInternalService) { }

    public getAllSchemas(): Observable<HttpResponse<XmlSchema[]>> {
        return this.httpService.getFullRequest(`${this.routePrefix}`);
    }
}
