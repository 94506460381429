export class Route {
    public name: string;
    public path: string;
    public childRoutes: Route[];

    constructor(name: string, path = '', childRoutes: Route[] = []) {
        this.name = name;
        this.path = path;
        this.childRoutes = childRoutes;
    }
}
