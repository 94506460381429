import { environment } from 'src/environments/environment';
import * as signalR from '@microsoft/signalr';

export class SignalRService {
    public connection: signalR.HubConnection;

    public initialize(hubName: string) {
        const accessToken = localStorage.getItem('accessToken');
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.signalHubUrl}/${hubName}`, { accessTokenFactory: () => accessToken })
            .withAutomaticReconnect()
            .build();
    }

    public connect() {
        this.connection.stop()
            .then(() => this.connection.start().catch((err) => console.log('Could not connect to SignalHub: ' + err)))
            .catch((err) => console.log('Could not connect to SignalHub: ' + err));
    }

    public disconnect() {
        if(this.connection) {
            return this.connection.stop();
        }
    }

    public on(eventName: string, callback: (data: any) => void) {
        this.connection.on(eventName, callback);
    }

    public invoke(eventName: string, callback: () => void) {
        this.connection.invoke(eventName).then(callback);
    }
}
