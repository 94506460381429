import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { RaceAudit } from '../models/race-audit';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RaceAuditApiService {
    routePrefix: string = '/api/rcs/raceAudit';

    constructor(private httpService: HttpInternalService) {}

    getRaceAudits(raceId: number): Observable<HttpResponse<RaceAudit[]>> {
        return this.httpService.getFullRequest<RaceAudit[]>(`${this.routePrefix}/${raceId}`);
    }
}
