import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatPaginator, MatTable } from '@angular/material';
import * as _ from 'lodash';
import { EventFlightBoard } from './models/event-flight-board';
import { EventFlightBoardService } from './services/event-flight-board.service';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-event-flight-board',
    templateUrl: './event-flight-board.component.html',
    styleUrls: ['./event-flight-board.component.sass']
})
export class EventFlightBoardComponent implements OnInit {
    @ViewChild('tablePaginator') public tablePaginator: MatPaginator;
    @ViewChild(MatTable) public dataTable: MatTable<any>;

    public subscription: Subscription;
    public currentSystemDate = undefined;
    public currentSystemDateUtc = undefined;
    public convertedDateToCompare = undefined;

    public eventFlightBoard = new MatTableDataSource<EventFlightBoard>();

    public displayedColumns = ['EventId', 'DataFeed', 'Country', 'Sport', 'Location', 'Title', 'StartTime'];

    public pageSizeOptions = [20, 50, 100];

    constructor(private eventFlightBoardService: EventFlightBoardService, public dialog: MatDialog) {}

    public ngOnInit() {
        this.getFlightBoardEvents();
        this.eventFlightBoard.paginator = this.tablePaginator;
    }

    public getFlightBoardEvents() {
        this.subscription = timer(0, 30000)
            .pipe(switchMap(() => this.eventFlightBoardService.getFlightBoardEvents()))
            .subscribe((value: EventFlightBoard[]) => (this.eventFlightBoard.data = value));
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public lateEventCheck(dateToCompare) {
        this.currentSystemDate = new Date();
        this.currentSystemDateUtc = new Date(this.currentSystemDate.getTime() + this.currentSystemDate.getTimezoneOffset() * 60000);
        this.convertedDateToCompare = new Date(dateToCompare);

        if (this.convertedDateToCompare < this.currentSystemDate) {
            return true;
        } else {
            return false;
        }
    }
}
