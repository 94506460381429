import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationMessage } from '../../../../models/notification/notificationMessage';
import { HttpResponse } from '@angular/common/http';
import { NotificationType } from '../../../../models/notification/notificationType';
import { CustomerSettingApiService } from './customer-setting-api.service';
import { CustomerSetting } from '../models/customer-setting';
import { CustomerSettingWithTotal } from '../models/customer-setting-with-total';
import { map } from 'rxjs/internal/operators/map';
import { Injectable } from '@angular/core';
import { NotificationSnackBarObserver } from 'src/app/services/notification-snack-bar-observer';

@Injectable()
export class CustomerSettingService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);
    constructor(
        private сustomerSettingsApiService: CustomerSettingApiService,
        private notificationObserver: NotificationSnackBarObserver
    ) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getAll(page = 0, pagesize = 10): Observable<CustomerSettingWithTotal> {
        return this.сustomerSettingsApiService
            .getCustomersSetting(page, pagesize)
            .pipe(
                map(
                    (response: HttpResponse<CustomerSettingWithTotal>) => {
                        return response.body;
                    },
                    (error) => {
                        console.log(`Error: ${error}`);
                        this.notificationSubject.next(
                            new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                        );
                    }
                )
            );
    }

    public get(customerSettingsId: number): Observable<CustomerSetting> {
        return this.сustomerSettingsApiService
            .getCustomerSetting(customerSettingsId)
            .pipe(
                map(
                    (response: HttpResponse<CustomerSetting>) => {
                        return response.body;
                    },
                    (error) => {
                        console.log(`Error: ${error}`);
                        this.notificationSubject.next(
                            new NotificationMessage(`Error: ${error}`, NotificationType.Error)
                        );
                    }
                )
            );
    }

    public create(newCustomerSettings: CustomerSetting): Observable<CustomerSetting> {
        return this.сustomerSettingsApiService
            .createCustomerSetting(newCustomerSettings)
            .pipe(
                map(
                    (response: HttpResponse<CustomerSetting>) => {
                        this.notificationSubject.next(
                            new NotificationMessage(
                                `'${newCustomerSettings.customerInfo.customerName}' setting was added`,
                                NotificationType.Adding
                            )
                        );
                        return response.body;
                    },
                    (error) => {
                        console.log(`Error: ${error}`);
                        this.notificationSubject.next(
                            new NotificationMessage(
                                `'${newCustomerSettings.customerInfo.customerName}' setting was not added`,
                                NotificationType.Error
                            )
                        );
                    }
        ));
    }

    public update(updatedCustomerSettings: CustomerSetting): Observable<boolean> {
        return this.сustomerSettingsApiService
            .updateCustomerSetting(updatedCustomerSettings)
            .pipe(
                map(
                    (response: HttpResponse<boolean>) => {
                        this.notificationSubject.next(
                            new NotificationMessage(
                                `'${updatedCustomerSettings.customerInfo.customerName}' setting was updated`,
                                NotificationType.Updating
                            )
                        );
                        return response.body;
                    },
                    (error) => {
                        console.log(`Error: ${error}`);
                        this.notificationSubject.next(
                            new NotificationMessage(
                                `'${updatedCustomerSettings.customerInfo.customerName}' setting was not updated`,
                                NotificationType.Error
                            )
                        );
                    }
        ));
    }

    public delete(customerSettingsId: number): Observable<boolean> {
        return this.сustomerSettingsApiService
            .deleteCustomerSetting(customerSettingsId)
            .pipe(
                map(
                    (response: HttpResponse<boolean>) => {
                        this.notificationSubject.next(
                            new NotificationMessage(
                                `Customer setting was removed`,
                                NotificationType.Removing
                            )
                        );
                        return response.body;
                    },
                    (error) => {
                        console.log(`Error: ${error}`);
                        this.notificationSubject.next(
                            new NotificationMessage(
                                `Customer setting was not removed`,
                                NotificationType.Error
                            )
                        );
                    }
        ));
    }
}
