export class PlayerModel {
    public id: number;
    public playerType: string;
    public name: string;
    public webPageUrl: string;
    public width: number;
    public height: number;
    public stretch: string;
    public fixedSize: boolean;
    public autoStart: boolean;
    public fullscreenEnabled: boolean;
}
