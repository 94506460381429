import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInternalService } from 'src/app/services/http.internal.service';
import { StreamerSubscription, DetailedStreamerSubscription, SubscriptionFormData } from './models';

@Injectable()
export class StreamerSubscriptionApiService {
    public routePrefix = '/api/streamerSubscriptions';

    constructor(private httpService: HttpInternalService) { }

    public getSubscriptions(): Observable<HttpResponse<DetailedStreamerSubscription[]>> {
        return this.httpService.getFullRequest<DetailedStreamerSubscription[]>(`${this.routePrefix}`);
    }

    public getFormData(): Observable<HttpResponse<SubscriptionFormData>> {
        return this.httpService.getFullRequest<SubscriptionFormData>(`${this.routePrefix}/formData`);
    }

    public createSubscription(subscription: StreamerSubscription): Observable<HttpResponse<Response>> {
        return this.httpService.postFullRequest<Response>(`${this.routePrefix}`, subscription);
    }

    public deleteSubscription(subscriptionId: number): Observable<HttpResponse<Response>> {
        return this.httpService.deleteFullRequest<Response>(`${this.routePrefix}/${subscriptionId}`);
    }
}
