import { CustomerBaseInfo } from '../../../../models/common/customer-base-info';

export class UserCustomer implements CustomerBaseInfo {
    public customerId: number;
    public customerName: string;
    public isUserHaveCustomer: boolean;

    constructor(args?: { customerId?: number; customerName?: string; isUserHaveCustomer?: boolean }) {
        this.customerId = args.customerId;
        this.customerName = args.customerName;
        this.isUserHaveCustomer = args.isUserHaveCustomer;
    }
}
