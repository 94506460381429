import { CustomerWithUid } from 'src/app/models/common/customer-with-uid';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { EventStreamList } from 'src/app/pages/bet-n-watch/test-web-services/models/event-stream-list';
import { EventStream } from 'src/app/pages/bet-n-watch/test-web-services/models/event-stream';
import { StreamLinkComponent } from 'src/app/components/stream-link/stream-link.component';
import {StreamApiService} from "../../../bet-n-watch/stream/services/stream-api.service";

@Component({
    selector: 'app-test-web-services-dialog',
    templateUrl: './test-web-services-dialog.component.html',
    styleUrls: ['./test-web-services-dialog.component.sass']
})
export class TestWebServicesDialogComponent {

    public streamsSource = new MatTableDataSource<EventStream>();
    public streamColumns = ['name', 'streamType', 'generate'];
    public selectedCustomerUid = '';
    public selectedStream = '';
    @ViewChild('streamLinkChild') public streamLinkChild: StreamLinkComponent;

    private subscription: Subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<TestWebServicesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private streamApiService: StreamApiService,
        private snackBar: MatSnackBar,
    ) { }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public onCustomerSelect(customer: CustomerWithUid) {
        this._getStreams(customer.customerUid, this.data.eventId);
    }

    public onGenerateStreamLink(stream: EventStream) {
        this.selectedStream = stream.uniqueStreamName;

        this.streamLinkChild.getStreamLink({
            customerUid: this.selectedCustomerUid,
            streamName: this.selectedStream,
            eventId: this.data.eventId,
            userIp: '10.10.10.10',
            userId: 'IGM-TEST',
            redirectUrl: window.location.href
        });
    }

    public onError(error: string) {
        this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
    }

    private _getStreams(customerUid: string, eventId: string) {
        this.subscription.add(this.streamApiService.getStreamsByEvent(customerUid, eventId).subscribe(
            (response: HttpResponse<EventStreamList>) => {
                this.streamsSource.data = response.body.eventStreams;
                this.selectedCustomerUid = customerUid;
            },
            (error) => {
                this.onError(error);
            }
        ));
    }
}
