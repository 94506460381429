import { Component, OnInit } from '@angular/core';
import { RcsCustomerService } from './services/rcs-customer.service';
import { Customer } from './models/customer';
import { AddEditRcsCustomerDialogComponent } from './add-edit-rcs-customer-dialog/add-edit-rcs-customer-dialog.component';
import { CustomerDialogData } from './models/customer-dialog-data';
import { DialogConfirmationComponent } from '../../../components/dialog-confirmation/dialog-confirmation.component';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { CustomerUser } from './models/customer-user';
import * as _ from 'lodash';
import { RcsUserService } from '../user/services/rcs-user.service';
import { DialogType } from '../../../models/dialog/dialogType';
import { DialogConfirmationData } from '../../../models/dialog/dialog-confirmation-data';
import { RcsUserRead } from '../user/models/rcs-user-read';

@Component({
    selector: 'app-rcs-customer',
    templateUrl: './rcs-customer.component.html',
    styleUrls: ['./rcs-customer.component.sass']
})
export class RcsCustomerComponent implements OnInit {
    public customersDataSource = new MatTableDataSource<Customer>();
    public users: RcsUserRead[];
    public displayedColumns: string[] = ['CustomerId', 'CustomerName', 'CustomerGuid', 'Alias', 'Menu'];

    constructor(
        private customerService: RcsCustomerService,
        private userService: RcsUserService,
        public dialog: MatDialog
    ) {}

    public ngOnInit() {
        this.loadCustomers();
        this.loadUsers();
    }

    public loadUsers() {
        this.userService.getUsers().subscribe((users: RcsUserRead[]) => {
            this.users = users;
        });
    }

    public loadCustomers() {
        this.customerService.getCustomers().subscribe((data: Customer[]) => {
            this.customersDataSource.data = data;
        });
    }

    public updateCustomer(updatedCustomerUser: CustomerUser) {
        this.customerService.updateCustomer(updatedCustomerUser).subscribe((isCustomerUpdated: boolean) => {
            if (isCustomerUpdated) {
                this.customersDataSource.data = _.map(
                        this.customersDataSource.data,
                        (c) => c.customerId === updatedCustomerUser.customer.customerId
                                                ? updatedCustomerUser.customer
                                                : c
                    );
            }
        });
    }

    public addCustomer(newCustomerUser: CustomerUser) {
         this.customerService.addCustomer(newCustomerUser).subscribe((customer: Customer) => {
             this.customersDataSource.data = _.concat(this.customersDataSource.data, customer);
         });
    }

    public removeCustomer(removedCustomer: Customer) {
        this.customerService.deleteCustomer(removedCustomer).subscribe((isCustomerRemoved: boolean) => {
            if (isCustomerRemoved) {
                this.customersDataSource.data = _.remove(
                        this.customersDataSource.data,
                        (customer) => customer.customerId !== removedCustomer.customerId
                    );
            }
        });
    }

    public openAddDialog() {
        const dialogRemoving = this.createAddEditDialog(new CustomerUser(), DialogType.Add);

        dialogRemoving.afterClosed().subscribe((result: CustomerDialogData) => {
            if (result && result.isChangesConfirmed) {
                this.addCustomer(result.customerUser);
            }
        });
    }

    public openEditDialog(customer: Customer) {
        this.customerService.getCustomer(customer.customerId).subscribe((customerUser: CustomerUser) => {
            if (customerUser) {
                const dialogRemoving = this.createAddEditDialog(_.cloneDeep(customerUser), DialogType.Edit);

                dialogRemoving.afterClosed().subscribe((result: CustomerDialogData) => {
                    if (result && result.isChangesConfirmed) {
                        this.updateCustomer(result.customerUser);
                    }
                });
            }
        });
    }

    public openRemoveDialog(customer: Customer) {
        const dialogRemoving = this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: new DialogConfirmationData(customer.customerName)
        });

        dialogRemoving.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed) {
                this.removeCustomer(customer);
            }
        });
    }

    public createAddEditDialog(customerUser: CustomerUser, dialogType: DialogType) {
        return this.dialog.open(AddEditRcsCustomerDialogComponent, {
            width: '500px',
            data: new CustomerDialogData(dialogType, customerUser, this.users)
        });
    }
}
