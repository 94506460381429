import { EventsData } from '../models/events-data';
import { HttpResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, Observer } from 'rxjs';
import { ManageEventsParams } from '../models/manage-events-params';
import { NotificationMessage } from 'src/app/models/notification/notificationMessage';
import { McrScheduleApiService } from './mcr-schedule-api.service';
import { map } from 'rxjs/operators';
import { NotificationType } from 'src/app/models/notification/notificationType';
import { Injectable } from '@angular/core';
import { NotificationSnackBarObserver } from 'src/app/services/notification-snack-bar-observer';
import { FiltersDialogData } from '../models/filters-dialog-data';
import { CustomerStream } from 'src/app/models/common/customer-stream';
import { GeneratedStream } from '../models/generated-stream';

@Injectable()
export class McrScheduleService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);

    constructor(private mcrScheduleApiService: McrScheduleApiService, private notificationObserver: NotificationSnackBarObserver) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getEvents(params: ManageEventsParams): Observable<EventsData> {
        return this.mcrScheduleApiService.getGroupedEvents(params).pipe(
            map(
                (response: HttpResponse<EventsData>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public getTwitchEvents(params: ManageEventsParams): Observable<EventsData> {
        return this.mcrScheduleApiService.getTwitchEvents(params).pipe(
            map(
                (response: HttpResponse<EventsData>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public countTwitchEvents(params: ManageEventsParams): Observable<number> {
        return this.mcrScheduleApiService.getTwitchEventsCount(params).pipe(
            map(
                (response: HttpResponse<number>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public getCustomerStreamsByEvent(eventId: string): Observable<CustomerStream[]> {
        return this.mcrScheduleApiService.getCustomerStreamsByEvent(eventId).pipe(
            map(
                (response: HttpResponse<CustomerStream[]>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public loadStream(eventId: string, streamId: number, customerId: number): Observable<HttpResponse<GeneratedStream>> {
        return this.mcrScheduleApiService.loadStream(eventId, streamId, customerId);
    }

    public getEventFiltersData(): Observable<FiltersDialogData> {
        return this.mcrScheduleApiService.getEventFiltersData().pipe(
            map(
                (response: HttpResponse<FiltersDialogData>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public updateIsDesktopVerifiedEventProperty(eventId: string, value: boolean): Observable<boolean> {
        return this.mcrScheduleApiService.updateIsDesktopVerifiedEventProperty(eventId, value).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public updateIsMobileVerifiedEventProperty(eventId: string, value: boolean): Observable<boolean> {
        return this.mcrScheduleApiService.updateIsMobileVerifiedEventProperty(eventId, value).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public updateIsAutoUpdatingEventProperty(eventId: string, value: boolean): Observable<boolean> {
        return this.mcrScheduleApiService.updateIsAutoUpdatingEventProperty(eventId, value).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public updateEventStatus(eventId: string, status: string): Observable<boolean> {
        return this.mcrScheduleApiService.updateEventStatus(eventId, status).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }
}
