import { HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { RcsDataFeedApiService } from './services/rcs-data-feed-api.sevice';
import { MatSnackBar, MatTableDataSource, MatDialog, PageEvent } from '@angular/material';
import { DataFeed } from './models/data-feed';
import { UserState } from 'src/app/states/user-state';
import { DialogType } from 'src/app/models/dialog/dialogType';
import { DialogConfirmationComponent } from 'src/app/components/dialog-confirmation/dialog-confirmation.component';
import * as _ from 'lodash';
import { DialogConfirmationData } from 'src/app/models/dialog/dialog-confirmation-data';
import { AddEditRcsDataFeedDialogComponent } from './add-edit-rcs-datafeed-dialog/add-edit-rcs-datafeed-dialog.component';
import { DataFeeds } from './models/data-feeds';

@Component({
  selector: 'app-rcs-data-feed',
  templateUrl: './rcs-data-feed.component.html',
  styleUrls: ['./rcs-data-feed.component.sass']
})
export class RcsDataFeedComponent implements OnInit, OnDestroy {
    public displayedCfgColumns: string[] = [
        'id',
        'name',
        'code',
        'importTimer',
        'isActive'
    ];

    public dataFeedsDataSource = new MatTableDataSource<DataFeed>();

    public isAdmin = false;
    public dataFeedsCount = 0;
    public page = 0;
    public pageSize = 20;

    private subscription: Subscription = new Subscription();

    constructor(
        private apiService: RcsDataFeedApiService,
        private snackBar: MatSnackBar,
        private userState: UserState,
        private dialog: MatDialog) { }

    public ngOnInit() {
        this._dataFeedPermissions();
        this._loadDataFeeds();
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public openAddDialog() {
        const addDialog = this._createEditDialog(DialogType.Add, new DataFeed());

        addDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed === true) {
                const { dataFeed } = result;
                this._addDataFeed(dataFeed);
            }
        });
    }

    public openUpdateDialog(dataFeed: DataFeed) {
        const updateDialog = this._createEditDialog(DialogType.Edit, _.cloneDeep(dataFeed));

        updateDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed === true) {
                const { dataFeed : updatedDataFeed } = result;
                this._updateDataFeed(updatedDataFeed);
            }
        });
    }

    public openRemoveDialog(dataFeed: DataFeed) {
        const removeDialog = this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: new DialogConfirmationData(`data feed for ${dataFeed.name}`)
        });

        removeDialog.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed && isRemovingConfirmed === true) {
                this._deleteDataFeed(dataFeed.id);
            }
        });
    }

    public changeDataFeedPage(event: PageEvent) {
        this.page = event.pageIndex;
        this.pageSize = event.pageSize;
        this._loadDataFeeds(this.page, this.pageSize);
    }

    private _createEditDialog(dialogType: DialogType, dataFeed: DataFeed) {
        return this.dialog.open(AddEditRcsDataFeedDialogComponent, {
            width: '600px',
            data: {
                dialogType,
                isChangesConfirmed: false,
                dataFeed
            }
        });
    }

    private _loadDataFeeds(page = 0, pageSize = 20) {
        this.subscription.add(
            this.apiService.getAllDataFeeds(page, pageSize).subscribe(
                (resp: HttpResponse<DataFeeds>) => {
                    if (resp.body) {
                        this.dataFeedsDataSource.data = resp.body.dataFeeds;
                        this.dataFeedsCount = resp.body.count;
                        return;
                    }
                    this._onError('Failed to get data feeds.');
                },
                (error) => this._onError(error)
            )
        );
    }

    private _addDataFeed(dataFeed: DataFeed) {
        this.subscription.add(
            this.apiService.addDataFeed(dataFeed).subscribe(
                (resp: HttpResponse<DataFeed>) => {
                    if (resp.body) {
                        this._loadDataFeeds(undefined, this.pageSize);
                        return;
                    }
                    this._onError('Failed to add new data feed');
                },
                (error) => this._onError(error)
            )
        );
    }

    private _updateDataFeed(dataFeed: DataFeed) {
        this.subscription.add(
            this.apiService.updateDataFeed(dataFeed).subscribe(
                (resp: HttpResponse<boolean>) => {
                    if (resp.body) {
                        this._loadDataFeeds(this.page, this.pageSize);
                    } else {
                        this._onError('Failed to update data feed');
                    }
                },
                (error) => this._onError(error)
            )
        );
    }

    private _deleteDataFeed(dataFeedId: number) {
        this.subscription.add(
            this.apiService.deleteDataFeed(dataFeedId).subscribe(
                (resp: HttpResponse<boolean>) => {
                    if (resp.body) {
                        this._loadDataFeeds(undefined, this.pageSize);
                    } else {
                        this._onError('Failed to delete data feed');
                    }
                },
                (error) => this._onError(error)
            )
        );
    }

    private _dataFeedPermissions() {
        this.isAdmin = this.userState.hasRole('Admin');
        if (this.isAdmin) {
            this.displayedCfgColumns = this.displayedCfgColumns.concat('menu');
        }
    }

    private _onError(error: string) {
        this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
    }

}
