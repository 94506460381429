import { Component, ViewChild } from '@angular/core';
import { VirtualPlayer } from './models/virtual-player';
import { MatSnackBar, MatTableDataSource, MatPaginator, MatDialog, PageEvent, Sort } from '@angular/material';
import { VirtualPlayerService } from './services/virtual-player.service';
import { DialogType } from 'src/app/models/dialog/dialogType';
import * as _ from 'lodash';
import { VirtualPlayers } from './models/virtual-players';
import {
    AddEditVirtualPlayerDialogComponent
} from './add-edit-virtual-player-dialog/add-edit-virtual-player-dialog.component';
import { DialogConfirmationComponent } from 'src/app/components/dialog-confirmation/dialog-confirmation.component';
import { DialogConfirmationData } from 'src/app/models/dialog/dialog-confirmation-data';
import { CustomerService } from '../../customer/customer/services/customer.service';
import { CustomerBaseInfo } from '../../../models/common/customer-base-info';
import { DialogInformationData } from '../../../models/dialog/dialog-information-data';
import { DialogInformationComponent } from '../../../components/dialog-information/dialog-information.component';

@Component({
    selector: 'app-virtual-player',
    templateUrl: './virtual-player.component.html',
    styleUrls: ['./virtual-player.component.sass']
})
export class VirtualPlayerComponent {
    @ViewChild('pagination') public pagination: MatPaginator;

    public playersSource = new MatTableDataSource<VirtualPlayer>();
    public customers: CustomerBaseInfo[];
    public playersCount: number;

    public currentPage = 0;
    public currentPageSize = 10;

    public displayedColumns: string[] = [
        'playerId',
        'customerId',
        'name',
        'streamLink',
        'streamType',
        'sport',
        'quality',
        'menu'
    ];

    private playerToAdd = new VirtualPlayer();

    constructor(private playersService: VirtualPlayerService, private customerService: CustomerService,
                public dialog: MatDialog, private snackBar: MatSnackBar) {
    }

    public ngOnInit() {
        this.loadPlayers();
        this.loadCustomers();
    }

    public changePlayerPage(event: PageEvent) {
        this.currentPageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadPlayers(event.pageIndex, event.pageSize);
    }

    public sortData(sort: Sort) {
        let isAscDirection = true;
        let column = 'id';

        if (sort.active && sort.direction) {
            isAscDirection = sort.direction === 'asc';
            column = sort.active;
        }

        this.playersSource.data = this.playersSource.data.sort((a, b) => {
            switch (column) {
                case 'streamType':
                    return this.compare(a.streamType, b.streamType, isAscDirection);
                case 'streamLink':
                    return this.compare(a.streamLink, b.streamLink, isAscDirection);
                case 'name':
                    return this.compare(a.name, b.name, isAscDirection);
                default:
                    return this.compare(a.playerId, b.playerId, isAscDirection);
            }
        });
    }

    public openAddDialog() {
        const addDialog = this.createEditDialog(DialogType.Add, this.playerToAdd);

        addDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed === true) {
                this.addPlayer(result.virtualPlayer);
            }
        });
    }

    public openUpdateDialog(player: VirtualPlayer) {
        const updateDialog = this.createEditDialog(DialogType.Edit, _.cloneDeep(player));

        updateDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed === true) {
                this.updatePlayer(result.virtualPlayer);
            }
        });
    }

    public openRemoveDialog(player: VirtualPlayer) {
        const removeDialog = this.dialog.open(DialogConfirmationComponent, {
            width: '600px',
            data: new DialogConfirmationData(`${player.name} player`)
        });

        removeDialog.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed && isRemovingConfirmed === true) {
                this.deletePlayer(player);
            }
        });
    }

    public openInformationDialog(player: VirtualPlayer) {
        const playerLink = `https://player.igamemedia.com/vplayer?c=${player.customerAlias}&s=${player.sport}&q=${player.quality}`;
        this.dialog.open(DialogInformationComponent, {
            width: '600px',
            data: new DialogInformationData(playerLink)
        });
    }

    private createEditDialog(dialogType: DialogType, virtualPlayer: VirtualPlayer) {
        return this.dialog.open(AddEditVirtualPlayerDialogComponent, {
            width: '500px',
            data: {
                dialogType,
                virtualPlayer,
                customers: this.customers
            }
        });
    }

    private addPlayer(virtualPlayer: VirtualPlayer) {
        this.playersService.createPlayer(virtualPlayer).subscribe(
            (newItem: VirtualPlayer) => {
                const page = Math.floor(this.playersCount / this.currentPageSize);
                // this.playersSource.data = [...this.playersSource.data, newItem];
                this.loadPlayers(page, this.currentPageSize);
                this.pagination.pageIndex = page;
                this.currentPage = page;
            },
            (error) => {
                console.log(error);
                this.snackBar.open(error, '', {duration: 3000, panelClass: 'error-snack-bar'});
            }
        );
    }

    private updatePlayer(player: VirtualPlayer) {
        this.playersService.updatePlayer(player).subscribe((isUpdate: boolean) => {
                this.loadPlayers(this.currentPage, this.currentPageSize);
            },
            (error) => {
                this.snackBar.open(error, '', {duration: 3000, panelClass: 'error-snack-bar'});
            }
        );
    }

    private deletePlayer(player: VirtualPlayer) {
        this.playersService.deletePlayer(player.playerId).subscribe((isRemoved: boolean) => {
                this.loadPlayers(this.currentPage, this.currentPageSize);
        });
    }

    private loadPlayers(page = 0, pageIndex = 10) {
        this.playersService.getAllPlayers(page, pageIndex).subscribe((data: any) => {
            this.playersSource.data = data.virtualPlayers;
            this.playersCount = data.playersTotal;
        });
    }

    private loadCustomers() {
        this.customerService.getCustomersBaseInfo().subscribe((data: CustomerBaseInfo[]) => {
            this.customers = data;
        });
    }

    private compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
