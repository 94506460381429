import { Component, OnInit } from '@angular/core';
import { CountryService } from './services/country.service';
import { RaceAuditService } from './services/race-audit.service';
import { Country } from '../../../models/common/country';
import { DataFeedBaseInfo } from '../../../models/common/data-feed-base-info';
import { RacesWithTotal } from './models/races-with-total';
import { RaceAudit } from './models/race-audit';
import { PageEvent } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RaceRequest } from './models/race-request';
import { RaceService } from './services/race.service';
import { MatTableDataSource } from '@angular/material';
import { Race } from './models/race';
import { RcsDataFeedApiService } from '../data-feed/services/rcs-data-feed-api.sevice';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-race-audit',
    templateUrl: './race-audit.component.html',
    styleUrls: ['./race-audit.component.sass']
})
export class RaceAuditComponent implements OnInit {
    public countries: Country[];
    public rcsDataFeeds: DataFeedBaseInfo[];
    public racesWithTotal: RacesWithTotal;

    public racesDataSource = new MatTableDataSource<Race>();
    public raceAuditsDataSource = new MatTableDataSource<RaceAudit>();

    public racesCount: number;
    public raceAuditsCount: number;

    public generatorOptions: FormGroup;

    public pageSizeOptions = [10, 25, 50, 150];
    public displayedRaceColumns: string[] = [
        'raceId',
        'dataFeedEventId',
        'meetingName',
        'title',
        'raceNumber',
        'statusCode',
        'scheduled',
        'startTime',
        'distance',
        'modifiedBy',
        'dateModified',
        'auditButton'
    ];
    public displayedRaceAuditsColumns: string[] = [
        'raceAuditId',
        'raceNumber',
        'startTime',
        'scheduled',
        'offTime',
        'officialTime',
        'cachedTime',
        'statusCode',
        'going',
        'manuallyOffed',
        'runnersCount',
        'surface',
        'modifiedBy',
        'dateModified'
    ];

    constructor(
        private coutryService: CountryService,
        private dataFeedService: RcsDataFeedApiService,
        private raceAuditService: RaceAuditService,
        private formBuilder: FormBuilder,
        public raceService: RaceService
    ) {}

    public ngOnInit() {
        this.coutryService.getCountries().subscribe(
            (countries: Country[]) => (this.countries = countries),
            (error) => console.log(`Error: ${error}`),
            () => {
                this.selectAllCountries();
            }
        );
        this.dataFeedService.getDataFeedsBaseInfo().subscribe(
            (rcsDataFeeds: HttpResponse<DataFeedBaseInfo[]>) => (this.rcsDataFeeds = rcsDataFeeds.body),
            (error) => console.log(`Error: ${error}`),
            () => {
                this.selectAllDataFeeds();
            }
        );

        this.generatorOptions = this.formBuilder.group({
            dataFeedIds: ['', Validators.required],
            countriesCode: ['', Validators.required],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required]
        });

        this.setDefaultFilters();
    }

    public getRaces(page = 0, pageSize = 10) {
        if (!this.generatorOptions.valid) {
            return;
        }

        const raceRequest = new RaceRequest(
            page,
            pageSize,
            this.generatorOptions.value.startDate.toUTCString(),
            this.generatorOptions.value.endDate.toUTCString(),
            this.generatorOptions.value.countriesCode,
            this.generatorOptions.value.dataFeedIds
        );

        this.raceService.getRaces(raceRequest).subscribe((racesWithTotal: RacesWithTotal) => {
            if (racesWithTotal) {
                this.racesDataSource.data = racesWithTotal.races;
                this.racesCount = racesWithTotal.racesTotal;
            }
        });
    }

    public getRaceAudit(raceId: number, el?: HTMLElement) {
        this.raceAuditService.getRaceAudits(raceId).subscribe((raceAudits: RaceAudit[]) => {
            this.raceAuditsDataSource.data = raceAudits;
            this.raceAuditsCount = raceAudits.length;
            this.scrollTo(el);
        });
    }

    public selectAllCountries() {
        this.generatorOptions.controls.countriesCode.setValue(this.countries.map((c) => c.alpha2Code));
    }

    public deselectAllCountries() {
        this.generatorOptions.controls.countriesCode.setValue([]);
    }

    public selectAllDataFeeds() {
        this.generatorOptions.controls.dataFeedIds.setValue(this.rcsDataFeeds.map((c) => c.id));
    }

    public deselectAllDataFeeds() {
        this.generatorOptions.controls.dataFeedIds.setValue([]);
    }

    public getServiceInfo() {
        this.coutryService
            .getCountries()
            .subscribe((countries: Country[]) => (this.countries = countries), (error) => console.log(`Error: ${error}`));
        this.dataFeedService.getDataFeedsBaseInfo().subscribe((rcsDataFeeds: HttpResponse<DataFeedBaseInfo[]>) => (this.rcsDataFeeds = rcsDataFeeds.body));
    }

    public setDefaultFilters() {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 2);

        this.generatorOptions.get('startDate').setValue(startDate);
        this.generatorOptions.get('endDate').setValue(new Date());
    }

    public dataFeedsSelectHandle() {
        if (this.generatorOptions.value.dataFeedIds.length === 1) {
            return this.rcsDataFeeds.find((x) => x.id === this.generatorOptions.value.dataFeedIds[0]).name;
        }

        return '';
    }

    public countriesSelectHandle() {
        if (this.generatorOptions.value.countriesCode.length === 1) {
            return this.countries.find((x) => x.alpha2Code === this.generatorOptions.value.countriesCode[0]).englishName;
        }

        return '';
    }

    public changeRacePage(page: PageEvent) {
        this.getRaces(page.pageIndex, page.pageSize);
    }

    public getFullStatus(statusCode: string) {
        switch (statusCode) {
            case 'P':
                return 'Pending';
            case 'G':
                return 'Open/running';
            case 'A':
                return 'At the post';
            case 'O':
                return 'Off race running';
            case 'R':
                return 'Official results';
            case 'V':
                return 'Void';
            default:
                break;
        }
    }

    private scrollTo(el: HTMLElement) {
        el.scrollIntoView({ behavior: 'smooth' });
    }
}
