import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, PageEvent, Sort, MatPaginator, MatSnackBar } from '@angular/material';
import { CustomerLocation } from './models/customer-location';
import { CustomerLocationsService } from './services/customer-locations.service';
import { CustomerLocationsWithTotal } from './models/customer-location-with-total';
import { AddEditCustomerLocationDialogComponent } from './add-edit-customer-location-dialog/add-edit-customer-location-dialog.component';
import { DialogType } from 'src/app/models/dialog/dialogType';
import { DialogConfirmationData } from 'src/app/models/dialog/dialog-confirmation-data';
import { DialogConfirmationComponent } from 'src/app/components/dialog-confirmation/dialog-confirmation.component';
import * as _ from 'lodash';
import { CustomerLocationsFormData } from './models/customer-location-form-data';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CustomerLocationSearch } from './models/customer-location-search';
import { CustomerLocationFilter } from './models/customer-location-filter';
import { StreamApiService } from '../../bet-n-watch/stream/services/stream-api.service';
import { CustomerApiService } from '../../customer/customer/services/customer-api.service';
import { CustomerLocationApiService } from './services/customer-locations-api.service';
import { CustomerLocationUpload } from './models/customer-location-upload';
import { UploadCustomerLocationErrorComponent } from './dialogs/upload-customer-location-error-component/upload-customer-location-error.component';
import { EventLocationApiService } from '../../event/location/services/location-api.service';

@Component({
    selector: 'app-customer-locations',
    templateUrl: './customer-locations.component.html',
    styleUrls: ['./customer-locations.component.sass'],
})
export class CustomerLocationsComponent implements OnInit {
    @ViewChild('pagination') public pagination: MatPaginator;

    public customerLocationsSource = new MatTableDataSource<CustomerLocation>();
    public customerLocationsUploadSource = new MatTableDataSource<CustomerLocationUpload>();
    public customerLocationsCount: number;
    public searchCriteriaSubject = new Subject<string>();
    public customerCriteriaSubject = new Subject<string>();
    public locationCriteriaSubject = new Subject<string>();
    public streamCriteriaSubject = new Subject<string>();

    public searchCriteria = '';
    public customerCriteria = '';
    public locationCriteria = '';
    public streamCriteria = '';

    public data: CustomerLocationsFormData;

    public currentPageSize = 20;
    public currentPage = 0;

    public displayedColumns: string[] = [
        'id',
        'customer',
        'dataFeed',
        'sport',
        'country',
        'location',
        'locationCode',
        'uStreamId',
        'player',
        'stream',
        'isActive',
        'isRawEnabled',
        'menu',
    ];

    constructor(
        private customerLocationsApiService: CustomerLocationApiService,
        private customerLocationsService: CustomerLocationsService,
        private eventLocationService: EventLocationApiService,
        private streamService: StreamApiService,
        private customerService: CustomerApiService,
        private customerLocationApiService: CustomerLocationApiService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog
        ) {}

    public ngOnInit() {
        this.setupSearch();
        this.loadCustomerLocations();
        this.loadFormData();
    }

    public searchCriteriaHandler = (input: any) => {
        this.searchCriteriaSubject.next(input);
        this.searchCriteria = input;
    };

    public customerCriteriaHandler = (input: any) => {
        this.customerCriteriaSubject.next(input);
        this.customerCriteria = input;
    };

    public locationCriteriaHandler = (input: any) => {
        this.locationCriteriaSubject.next(input);
        this.locationCriteria = input;
    };

    public streamCriteriaHandler = (input: any) => {
        this.streamCriteriaSubject.next(input);
        this.streamCriteria = input;
    };

    public setupSearch = () => {
        this.searchCriteriaSubject.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe((value: string) => {
                if (this.pagination) {
                    this.pagination.firstPage();
                }

                this.loadCustomerLocations(0, this.currentPageSize);
        });

        this.customerCriteriaSubject.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe((value: string) => {
                if (this.pagination) {
                    this.pagination.firstPage();
                }

                this.loadCustomerLocations(0, this.currentPageSize);
            });

        this.locationCriteriaSubject.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe((value: string) => {
                if (this.pagination) {
                    this.pagination.firstPage();
                }

                this.loadCustomerLocations(0, this.currentPageSize);
            });

        this.streamCriteriaSubject.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe((value: string) => {
                if (this.pagination) {
                    this.pagination.firstPage();
                }

                this.loadCustomerLocations(0, this.currentPageSize);
            });
    };

    public loadCustomerLocations(page = 0, pageSize = 20) {
        const filterModel = this.getFilterModel(page, pageSize);
        this.customerLocationsService.getAll(filterModel).subscribe((customerLocations: CustomerLocationsWithTotal) => {
            this.customerLocationsSource.data = customerLocations.customerLocations;
            this.customerLocationsCount = customerLocations.count;
        });
    }

    public onDownloadLocations() {
        this.eventLocationService.downloadLocationsFile().subscribe((response) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
            downloadLink.download = 'locations.csv';
            downloadLink.click();
        });
    }

    public onDownloadStreams() {
        this.streamService.downloadStreamsFile().subscribe((response) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
            downloadLink.download = 'streams.csv';
            downloadLink.click();
        });
    }

    public onDownloadCustomers() {
        this.customerService.downloadCustomersFile().subscribe((response) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
            downloadLink.download = 'customers.csv';
            downloadLink.click();
        });
    }

    public onUploadCustomerLocations() {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.csv';
        input.onchange = (event: any) => {
            const file = event.target.files[0];
            console.log(file);

            const formData = new FormData();
            formData.append('file', file);

            this.customerLocationApiService.uploadCustomerLocationsCsvFile(formData).subscribe(
                ({ body: result }) => {
                    const { data } = this.customerLocationsUploadSource;
                    this.customerLocationsUploadSource.data = [...result.addedEvents, ...data];

                    const htmlWithLineBreaks = result.message.replace(/(\r\n|\n|\r)/g, '<br>');

                    this.snackBar.openFromComponent(UploadCustomerLocationErrorComponent, {
                        duration: 15000, panelClass: 'usual-snack-bar',
                        data: htmlWithLineBreaks
                    });
                },
                (error) => {
                    this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
                }
            );
        };
        input.click();
    }

    public changeCustomerLocationsPage(event: PageEvent) {
        this.currentPageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadCustomerLocations(event.pageIndex, event.pageSize);
    }

    public sortData(sort: Sort) {
        let isAscDirection = true;
        let column = 'id';
        if (sort.active && sort.direction) {
            isAscDirection = sort.direction === 'asc';
            column = sort.active;
        }

        this.customerLocationsSource.data = this.customerLocationsSource.data.sort((a, b) => {
            switch (column) {
                case 'customer':
                    return this.compare(a.customerName, b.customerName, isAscDirection);
                case 'dataFeed':
                    return this.compare(a.dataFeed, b.dataFeed, isAscDirection);
                case 'sport':
                    return this.compare(a.sport, b.sport, isAscDirection);
                case 'player':
                    return this.compare(a.playerName, b.playerName, isAscDirection);
                case 'stream':
                    return this.compare(a.streamName, b.streamName, isAscDirection);
                default:
                    return this.compare(a.customerLocationId, b.customerLocationId, isAscDirection);
            }
        });
    }

    public openAddDialog() {
        const addDialog = this.createEditDialog(DialogType.Add, new CustomerLocation());

        addDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed === true) {
                this.addCustomerLocation(result.customerLocation);
            }
        });
    }

    public openUpdateDialog(customerLocation: CustomerLocation) {
        const updateDialog = this.createEditDialog(DialogType.Edit, _.cloneDeep(customerLocation));

        updateDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed === true) {
                this.updateCustomerLocation(result.customerLocation);
            }
        });
    }

    public openRemoveDialog(customerLocation: CustomerLocation) {
        const removeDialog = this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: new DialogConfirmationData(`${customerLocation.customerName} location`),
        });

        removeDialog.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed && isRemovingConfirmed === true) {
                this.deleteCustomerLocation(customerLocation);
            }
        });
    }

    private createEditDialog(dialogType: DialogType, customerLocation: CustomerLocation) {
        return this.dialog.open(AddEditCustomerLocationDialogComponent, {
            width: '500px',
            data: {
                dialogType,
                customerLocation,
                isChangesConfirmed: false,
                formData: this.data,
                locationCount: this.customerLocationsCount,
            },
        });
    }

    private getFilterModel(page: number, pageSize: number) {
        const searchModel = new CustomerLocationSearch();
        searchModel.customerCriteria = this.customerCriteria;
        searchModel.locationCriteria = this.locationCriteria;
        searchModel.streamCriteria = this.streamCriteria;
        searchModel.searchWord = this.searchCriteria;

        const filterModel = new CustomerLocationFilter();
        filterModel.page = page;
        filterModel.pageSize = pageSize;
        filterModel.searchModel = searchModel;
        return filterModel;
    }

    private addCustomerLocation(customerLocation: CustomerLocation) {
        this.customerLocationsService.create(customerLocation).subscribe((isAdded: boolean) => {
            if (isAdded) {
                const page = Math.floor(this.customerLocationsCount / this.currentPageSize);
                this.loadCustomerLocations(page, this.currentPageSize);
                this.pagination.pageIndex = page;
            }
        });
    }

    private updateCustomerLocation(customerLocation: CustomerLocation) {
        this.customerLocationApiService.updateCustomerLocation(customerLocation).subscribe((response) => {
                this.snackBar.open(response.body.message, '', {duration: 3000});
                this.loadCustomerLocations(this.currentPage, this.currentPageSize);
            },
            (error) => {
                this.snackBar.open(error, '', {duration: 3000, panelClass: 'error-snack-bar'});
            });
    }

    private deleteCustomerLocation(customerLocation: CustomerLocation) {
        this.customerLocationsService.delete(customerLocation.customerLocationId).subscribe((isRemoved: boolean) => {
            if (isRemoved) {
                this.loadCustomerLocations(this.currentPage, this.currentPageSize);
            }
        });
    }

    private loadFormData() {
        this.customerLocationsService.getFormData().subscribe((data: CustomerLocationsFormData) => {
            this.data = data;
        });
    }

    private compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
