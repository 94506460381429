import { CustomerWithUid } from 'src/app/models/common/customer-with-uid';
import { McrScheduleService } from './services/mcr-schedule.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { EventData } from './models/event-data';
import { MatTableDataSource, MatDialog, PageEvent, MatPaginator } from '@angular/material';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { EventsFiltersDialogComponent } from './events-filters-dialog/events-filters-dialog.component';
import { CustomerLocationsService } from '../customer-locations/services/customer-locations.service';
import { FiltersDialogData } from './models/filters-dialog-data';
import { ManageEventsFilters } from './models/manage-events-filters';
import { FiltersStateService } from './services/filters-state.service';
import { EventIssuesDialogComponent } from './event-issues/event-issues-dialog.component';
import { Subscription } from 'rxjs';
import { CustomerStream } from 'src/app/models/common/customer-stream';
import { uniqBy } from 'lodash';
import { TestWebServicesDialogComponent } from './test-web-services-dialog/test-web-services-dialog.component';
import { environment } from 'src/environments/environment';
import { SignalRService } from 'src/app/services/signalr.service';
import { UpdatedStatus } from './models/updated-status';
import { p } from '@angular/core/src/render3';

@Component({
    selector: 'app-live-schedule',
    templateUrl: './live-schedule.component.html',
    styleUrls: ['./live-schedule.component.sass'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class LiveScheduleComponent implements OnInit, OnDestroy {
    @ViewChild('pagination')
    public pagination: MatPaginator;

    public eventsSource = new MatTableDataSource<EventData>();
    public subEventsSource = new MatTableDataSource<EventData>();
    public eventsCount: number;

    public isTwitchTabActive: boolean = false;
    public hasTwitchEvents: boolean = false;

    public currentPage = 0;
    public currentPageSize = 10;

    public expandedEventData: EventData;

    public displayedColumns: string[] = [
        'expand',
        'id',
        'dataFeed',
        'country',
        'sport',
        'number',
        'location',
        'title',
        'startTime',
        'status',
        'extra',
    ];

    public filtersData: FiltersDialogData;

    public displayedSubColumns: string[] = ['expand', 'id', 'customerNames', 'dataFeed', 'customerStreams', 'status', 'extra'];

    private subscription: Subscription = new Subscription();

    constructor(
        private service: McrScheduleService,
        private stateService: FiltersStateService,
        private signalRService: SignalRService,
        public dialog: MatDialog,
        public locationService: CustomerLocationsService
    ) { }

    public ngOnInit(): void {
        this._setupSignalR();
        this._loadEvents();
        this._loadAndCacheEventFilterFilters();
    }

    public ngOnDestroy(): void {
        this.signalRService.disconnect();
        this.subscription.unsubscribe();
    }

    public expandCollapse(data: EventData) {
        if (data.hasSubEvents) {
            this.expandedEventData = this.expandedEventData === data ? undefined : data;
            this.subEventsSource.data = data.subEvents;
        }
    }

    public onInspection(eventItem: EventData) {
        event.stopPropagation();
        window.open(
            `/mcr-monitoring/live-schedule/${eventItem.eventId}`,
            'Ivent Inspection ' + Math.random() * 100,
            `width=300, noopener`
        );
    }

    public collapseIcon(event: EventData) {
        return this.expandedEventData && this.expandedEventData.eventId === event.eventId ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    }

    public getStatus(event: EventData, status: string) {
        return event.eventStatusCode === status;
    }

    public getMainEventStatus(event: EventData, status: string) {
        if (event.subEvents) {
            return event.subEvents.every((e) => e.eventStatusCode === status);
        }
        return this.getStatus(event, status);
    }

    public getMainEventDesktopVerification(event: EventData): boolean {
        if (event.subEvents) {
            const flag = event.subEvents.every((e) => e.isDesktopVerified === true);
            if (flag) {
                event.isDesktopVerified = true;
            }
            return flag;
        }
        return event.isDesktopVerified;
    }

    public getMainEventMobileVerification(event: EventData): boolean {
        if (event.subEvents) {
            const flag = event.subEvents.every((e) => e.isMobileVerified === true);
            if (flag) {
                event.isMobileVerified = true;
            }
            return flag;
        }
        return event.isMobileVerified;
    }

    public checkDifferentStatus(event: EventData) {
        if (event.subEvents) {
            return !event.subEvents.every((e) => e.eventStatusCode === event.subEvents[0].eventStatusCode);
        }
        return false;
    }

    public changeEventsPage(event: PageEvent) {
        this.stateService.state.page = event.pageIndex + 1;
        this.stateService.state.pageSize = event.pageSize;

        if (this.isTwitchTabActive) {
            return this._loadTwitchEvents();
        }
        this._loadEvents();
    }

    public onOpenFiltersDialog() {
        const filtersDialog = this.dialog.open(EventsFiltersDialogComponent, {
            width: '500px',
            data: {
                filtersData: this.filtersData,
            },
        });

        this.subscription.add(
            filtersDialog.afterClosed().subscribe((filters: ManageEventsFilters) => {
                if (!filters) {
                    return;
                }

                this.stateService.state.filters = filters;
                this.stateService.state.page = 0;

                if (this.isTwitchTabActive) {
                    return this._loadTwitchEvents();
                }

                this._countTwitchEvents();
                this.service.getEvents(this.stateService.state).subscribe((data) => {
                    this.eventsSource.data = data.eventsData;

                    this.eventsCount = data.count;
                    if (this.pagination) {
                        this.pagination.pageIndex = 0;
                    }
                });
            })
        );
    }

    public onOpenEventIssuesDialog(id: string) {
        event.stopPropagation();
        this.dialog.open(EventIssuesDialogComponent, {
            width: '1200px',
            data: { id },
        });
    }

    public onIsAutoUpdating(eventItem: EventData) {
        event.stopPropagation();
        this.subscription.add(
            this.service.updateIsAutoUpdatingEventProperty(eventItem.eventId, !eventItem.isAutoUpdating).subscribe((res: boolean) => {
                if (res) {
                    const flag = !eventItem.isAutoUpdating;
                    eventItem.isAutoUpdating = flag;
                    if (eventItem.hasSubEvents) {
                        eventItem.subEvents.forEach((item) => {
                            item.isAutoUpdating = flag;
                        });
                    }
                }
            })
        );
    }

    public onMobileUpdate(eventItem: EventData) {
        event.stopPropagation();
        this.subscription.add(
            this.service.updateIsMobileVerifiedEventProperty(eventItem.eventId, !eventItem.isMobileVerified).subscribe((res: boolean) => {
                if (res) {
                    const flag = !eventItem.isMobileVerified;
                    eventItem.isMobileVerified = flag;
                    if (eventItem.hasSubEvents) {
                        eventItem.subEvents.forEach((item) => {
                            item.isMobileVerified = flag;
                        });
                    }
                }
            })
        );
    }

    public onDesktopUpdating(eventItem: EventData) {
        event.stopPropagation();
        this.subscription.add(
            this.service.updateIsDesktopVerifiedEventProperty(eventItem.eventId, !eventItem.isDesktopVerified).subscribe((res: boolean) => {
                if (res) {
                    const flag = !eventItem.isDesktopVerified;
                    eventItem.isDesktopVerified = flag;
                    if (eventItem.hasSubEvents) {
                        eventItem.subEvents.forEach((item) => {
                            item.isDesktopVerified = flag;
                        });
                    }
                }
            })
        );
    }

    public onSetStatus(eventItem: EventData, status: string) {
        event.stopPropagation();
        if (eventItem.isAutoUpdating) {
            return;
        }

        this.subscription.add(
            this.service.updateEventStatus(eventItem.eventId, status).subscribe((res: boolean) => {
                if (res) {
                    eventItem.eventStatusCode = status;
                    if (eventItem.hasSubEvents) {
                        eventItem.subEvents.forEach((item) => {
                            item.eventStatusCode = status;
                        });
                    }
                }
            })
        );
    }

    public onTWBOpen(eventItem: EventData) {
        event.stopPropagation();
        this.subscription.add(
            this.service.getCustomerStreamsByEvent(eventItem.eventId).subscribe((data: CustomerStream[]) => {
                if (!data) {
                    this._openTWBdialog([], eventItem.eventId);
                }
                const customers = uniqBy(
                    data.map((i) => new CustomerWithUid(i.customerId, i.customerName, i.customerUID)),
                    'customerId'
                );
                this._openTWBdialog(customers, eventItem.eventId);
            })
        );
    }

    public onTwitchToggle() {
        this.isTwitchTabActive = !this.isTwitchTabActive;

        if (this.isTwitchTabActive) {
            return this._loadTwitchEvents();
        }

        this._loadEvents();
    }

    private _openTWBdialog(customers: CustomerWithUid[], eventId: string) {
        this.dialog.open(TestWebServicesDialogComponent, {
            width: '1200px',
            data: { customers, eventId },
        });
    }

    private _loadEvents() {
        this.subscription.add(
            this.service.getEvents(this.stateService.state).subscribe((data) => {
                this.eventsSource.data = data.eventsData;
                this.eventsCount = data.count;
            })
        );
    }

    private _loadTwitchEvents() {
        this.hasTwitchEvents = false;
        this.subscription.add(
            this.service.getTwitchEvents(this.stateService.state).subscribe((data) => {
                this.eventsSource.data = data.eventsData.map((x) => {
                    x.eventNumber = x.eventId.split('-')[1];
                    return x;
                });
                this.eventsCount = data.count;
            })
        );
    }

    private _countTwitchEvents() {
        this.subscription.add(
            this.service.countTwitchEvents(this.stateService.state).subscribe((data) => {
                this.hasTwitchEvents = data !== 0;
            })
        );
    }

    private _setupSignalR(): void {
        this.signalRService.initialize('mcrHub');
        this.signalRService.on('statusesUpdate', this._handleStatusesUpdate.bind(this));
        this.signalRService.connect();
    }

    private _handleStatusesUpdate(data: UpdatedStatus[]): void {
        if (!data || data.length === 0) {
            return console.log('Empty data.');
        }

        const statuses = data.filter((x) => x.status);
        const statusesIds = statuses.map((s) => s.eventId);
        this._updateStatuses(this.eventsSource.data, statuses, statusesIds);
    }

    private _updateStatuses(data: EventData[], statuses: UpdatedStatus[], statusesIds: string[]) {
        data.forEach((x) => {
            this._markStatus(statusesIds, statuses, x);
            if (!x.subEvents) {
                return;
            }
            x.subEvents.forEach((y) => {
                this._markStatus(statusesIds, statuses, y);
            });
        });
    }

    private _markStatus(statusesIds: string[], statuses: UpdatedStatus[], event: EventData) {
        if (statusesIds.includes(event.eventId)) {
            event.eventStatusCode = statuses.find((status) => status.eventId === event.eventId).status;
        }
    }

    private _loadAndCacheEventFilterFilters() {
        let eventFiltersData = localStorage.getItem("EventFiltersData");
        if (eventFiltersData === undefined || eventFiltersData === null) {
            this.subscription.add(
                this.service.getEventFiltersData().subscribe((data: FiltersDialogData) => {
                    localStorage.setItem("EventFiltersData", JSON.stringify(data));
                    localStorage.setItem("EventFiltersDataExpireTime", new Date().toUTCString());
                    this.filtersData = data;
                })
            );
        } else {
            let expired = localStorage.getItem("EventFiltersDataExpireTime");

            let diff = Math.abs((new Date().getTime() - new Date(expired).getTime()) / 60000);
            if (diff >= 1440) {
                this.service.getEventFiltersData().subscribe((data: FiltersDialogData) => {
                    localStorage.setItem("EventFiltersData", JSON.stringify(data));
                    localStorage.setItem("EventFiltersDataExpireTime", new Date().toUTCString());
                    this.filtersData = data;
                })
            }
            else
                this.filtersData = JSON.parse(eventFiltersData);
        }
    }
}
