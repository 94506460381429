import { Component, OnInit } from '@angular/core';
import { RcsCustomerService } from '../customer/services/rcs-customer.service';
import { CustomerBaseInfo } from '../../../models/common/customer-base-info';
import { PushApiService } from './services/push-api.service';
import { NotificationSnackBarObserver } from '../../../services/notification-snack-bar-observer';
import { PushService } from './services/push.service';
import { CustomerRace } from './models/customer-race';
import { CustomerMeeting } from './models/customer-meeting';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Meeting } from './models/meeting';
import { DialogConfirmationData } from '../../../models/dialog/dialog-confirmation-data';
import { DialogConfirmationComponent } from '../../../components/dialog-confirmation/dialog-confirmation.component';
import { Race } from './models/race';
import { PageEvent } from '@angular/material';
import { PushRaces } from './models/push-races';
import { PushMeetings } from './models/push-meetings';

@Component({
    selector: 'app-push',
    templateUrl: './push.component.html',
    styleUrls: ['./push.component.sass']
})
export class PushComponent implements OnInit {
    public customersBaseInfo: CustomerBaseInfo[];
    public selectedCustomer: CustomerBaseInfo;

    public raceDataSource =  new MatTableDataSource<Race>();
    public meetingDataSource =  new MatTableDataSource<Meeting>();

    public racesCount: number;
    public meetingsCount: number;

    public pushService: PushService;

    public displayedRaceColumns: string[] = ['RaceId', 'RaceName', 'MeetingName', 'StartTime', 'PushButton'];
    public displayedMeetingColumns: string[] = ['MeetingId', 'MeetingName', 'Date', 'PushButton'];

    constructor(
        private customerService: RcsCustomerService,
        private pushApiService: PushApiService,
        private notificationSnackBarObserver: NotificationSnackBarObserver,
        public dialog: MatDialog
    ) {}

    public ngOnInit() {
        this.pushService = new PushService(this.pushApiService, this.notificationSnackBarObserver);

        this.loadCustomersBaseInfo();
    }

    public openPushMeetingConfirmationDialog(meeting: Meeting) {
        const confirmationDialog = this.createandOpenDialogWindow(
            new DialogConfirmationData(meeting.name, 'Are you sure you want to push meeting')
        );

        confirmationDialog.afterClosed().subscribe((isPushingConfirmed: boolean) => {
            if (isPushingConfirmed === true) {
                this.pushMeeting(meeting.meetingId);
            }
        });
    }

    public openPushRaceConfirmationDialog(race: Race) {
        const confirmationDialog = this.createandOpenDialogWindow(
            new DialogConfirmationData(race.name, 'Are you sure you want to push race')
        );

        confirmationDialog.afterClosed().subscribe((isPushingConfirmed: boolean) => {
            if (isPushingConfirmed === true) {
                this.pushRace(race.raceId);
            }
        });
    }

    public comparing(customer1: CustomerBaseInfo, customer2: CustomerBaseInfo) {
        return customer1 && customer2 ? customer1.customerId === customer2.customerId : customer1 === customer2;
    }

    public customerChanged() {
        if (this.selectedCustomer) {
            this.pushService
                .getSubscribedRaces(this.selectedCustomer)
                .subscribe((data: PushRaces) => {
                    this.raceDataSource.data = data.races;
                    this.racesCount = data.count;
                });
            this.pushService
                .getSubscribedMeetings(this.selectedCustomer)
                .subscribe((data: PushMeetings) => {
                    this.meetingDataSource.data = data.meetings;
                    this.racesCount = data.count;
                });
        }
    }

    public changeRacePage(event: PageEvent) {
        this.pushService
            .getSubscribedRaces(this.selectedCustomer, event.pageIndex, event.pageSize)
            .subscribe((data: PushRaces) => {
                this.raceDataSource.data = data.races;
                this.racesCount = data.count;
            });
    }

    public changeMeetingPage(event: PageEvent) {
        this.pushService
        .getSubscribedMeetings(this.selectedCustomer, event.pageIndex, event.pageSize)
        .subscribe((data: PushMeetings) => {
            this.meetingDataSource.data = data.meetings;
            this.racesCount = data.count;
        });
    }

    public loadCustomersBaseInfo() {
        this.customerService
            .getCustomersBaseInfo()
            .subscribe((customers: CustomerBaseInfo[]) => (this.customersBaseInfo = customers));
    }

    public isOneNotEmpty(firstItem, secondItem): boolean {
        return !firstItem && secondItem;
    }

    private pushMeeting(meetingId: number) {
        this.pushService.pushMeetingToCustomer(
            new CustomerMeeting(this.selectedCustomer.customerId, meetingId)
        );
    }

    private pushRace(raceId: number) {
        this.pushService.pushRaceToCustomer(new CustomerRace(this.selectedCustomer.customerId, raceId));
    }

    private createandOpenDialogWindow(dialogConfirmationData: DialogConfirmationData) {
        return this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: dialogConfirmationData
        });
    }

}
