import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { RaceRequest } from '../models/race-request';
import { RacesWithTotal } from '../models/races-with-total';
import { Race } from '../models/race';
import { RaceBaseInfo } from 'src/app/models/common/race-base-info';

@Injectable({
    providedIn: 'root'
})
export class RaceApiService {
    public routePrefix = '/api/rcs/race';

    constructor(private httpService: HttpInternalService) {}

    public getRaces(raceRequest: RaceRequest): Observable<HttpResponse<RacesWithTotal>> {
        return this.httpService.postFullRequest<RacesWithTotal>(`${this.routePrefix}/all`, raceRequest);
    }

    public getRacesBaseInfoByMeetingId(meetingId: number): Observable<HttpResponse<RaceBaseInfo[]>> {
        return this.httpService.getFullRequest(`${this.routePrefix}/byMeetingId`, { meetingId });
    }
}
