import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { cloneDeep } from 'lodash';
import { CustomerBaseInfo } from '../../../../models/common/customer-base-info';
import { MatSelectChange } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { XmlSchema } from 'src/app/models/common/xml-schema';

@Component({
  selector: 'app-add-edit-customer-setting-dialog',
  templateUrl: './add-edit-customer-setting-dialog.component.html',
  styleUrls: ['./add-edit-customer-setting-dialog.component.sass']
})
export class AddEditCustomerSettingDialogComponent implements OnInit {

    public externalUrl = new FormControl('', [Validators.required]);
    public secretKey = new FormControl('', [Validators.required]);

    public isSelected: boolean;

    constructor(
        public dialogRef: MatDialogRef<AddEditCustomerSettingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    public ngOnInit(): void {
        const { customerSetting } = this.data;
        this.externalUrl.setValue(customerSetting.externalPushServiceUrl || '');
        this.secretKey.setValue(customerSetting.secretKey || '');

        this.isSelected = !!customerSetting.customerInfo;
    }

    public onNo(): void {
        this.dialogRef.close(false);
        this.data.isChangesConfirmed = false;
    }

    public getErrorMessage(formControl: FormControl): string {

        if (formControl.hasError('required')) {
            return 'You must enter a value';
        }

        if (formControl.hasError('minlength')) {
            return 'Min length is 6 symbols';
        }
    }

    public onYes(): void {
        this.dialogRef.close();
        this.data.isChangesConfirmed = true;

        this.data.customerSetting.externalPushServiceUrl = this.externalUrl.value;
        this.data.customerSetting.secretKey = this.secretKey.value;

        this.dialogRef.close(cloneDeep(this.data));
    }

    public isInvalid(): boolean {
        return this.externalUrl.invalid
                || this.secretKey.invalid
                || !this.data.customerSetting.rC_XmlVersionID
                || !this.isSelected;
    }

    public onCustomerSelect(data: MatSelectChange): void {
        this.isSelected = true;
        this.data.customerSetting.customerInfo = data.value;
    }

    public comparingSettings(customerSetting1: CustomerBaseInfo, customerSetting2: CustomerBaseInfo): boolean {
        return customerSetting1 && customerSetting2
                ? customerSetting1.customerId === customerSetting2.customerId
                : customerSetting1 === customerSetting2;
    }

    public comparingXml(xml1: XmlSchema, xml2: XmlSchema): boolean {
        return xml1 && xml2
                ? xml1.xmlVersion === xml2.xmlVersion
                : xml1 === xml2;
    }

    public comparing(item1: string | number, item2: string | number): boolean {
        return item1 === item2;
    }

}
