import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSliderChange, MatTabChangeEvent } from '@angular/material';
import { FormControl } from '@angular/forms';
import { CustomerLocationsService } from '../services/customer-locations.service';
import { LocationWithFlag } from '../models/location-with-flag';
import * as _ from 'lodash';
import { MatSelectChange } from '@angular/material/select';
import { LocationFilteringState } from '../services/locations-filtering-state.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LocationBaseInfo } from 'src/app/models/common/location-base-info';
import { FilterCriteria } from '../models/filter-criteria';
import { LocationBaseInfos } from 'src/app/models/common/location-base-infos';
import { CustomerLocationsFormData } from '../models/customer-location-form-data';
import { Customer } from '../../../customer/customer/models/customer';
import { CustomerWithUid } from '../../../../models/common/customer-with-uid';
import { Country } from '../../../../models/common/country';
import { StreamBaseInfo } from '../../../../models/common/stream-base-info';
import { forEach } from '@angular/router/src/utils/collection';
import { st } from '@angular/core/src/render3';

@Component({
    selector: 'app-add-edit-customer-location-dialog',
    templateUrl: './add-edit-customer-location-dialog.component.html',
    styleUrls: ['./add-edit-customer-location-dialog.component.sass']
})
export class AddEditCustomerLocationDialogComponent {
    public ullStreamName = 'ULL';

    public UniqueStreamNames: string[] = [
        'HLS',
        this.ullStreamName,
    ];

    public customerFilters = new FormControl([]);
    public countriesFilters = new FormControl([]);
    public search: FormControl = new FormControl();
    public searchedLocations: Observable<LocationBaseInfo[]>;
    public locations: LocationWithFlag[] = [];
    public sourceLocations: LocationWithFlag[] = [];
    public selectedLocation: string;
    public filters: FilterCriteria = new FilterCriteria();
    public formData: CustomerLocationsFormData;

    public locationsCount = 0;
    public page = 0;
    public tab = 0;
    public customersSearchCriteria: string;
    public countriesSearchCriteria: string;
    public filteredCustomers: CustomerWithUid[];
    public filteredCountries: Country[];
    public selectedStream: string;
    public friendlyNames: string[];

    // public locationName: string;

    constructor(
        public dialogRef: MatDialogRef<AddEditCustomerLocationDialogComponent>,
        private service: CustomerLocationsService,
        private stateService: LocationFilteringState,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    public ngOnInit(): void {
        this._initializeSearch();
        if (this.stateService.isFilters) {
            this.filters = this.stateService.loadFilters();
            this._loadByFilters(this.filters);
        } else {
            this._loadLocations(0, 10000);
        }

        this.page = 1;
        this.selectedLocation = this.data.customerLocation.iGMLocationCode;
        this.formData = this.data.formData;

        this.filteredCustomers = this.data.formData.customers;
        this.filteredCountries = this.data.formData.countries;

        if(this.data.customerLocation && this.data.customerLocation.streamName)
            this.selectedStream = this.data.customerLocation.streamName;

        this.friendlyNames = Array.from(new Set<string>(this.data.formData.streams.map(x => x.name)));

        if(this.data.dialogType === 'Edit' && this.data.customerLocation.geoAllowCountries){
            const selectedAlphaCodes = this.data.customerLocation.geoAllowCountries.split(' ');
            const selectedCountries = this.filteredCountries.filter(x => selectedAlphaCodes.includes(x.alpha2Code));
            this.countriesFilters.setValue(selectedCountries);
        }
    }

    public onNo(): void {
        this.dialogRef.close(false);
        this.data.isChangesConfirmed = false;
    }

    public onSliderChange(data: MatSliderChange) {
        this.data.customerLocation.tokenExpire = data.value;
    }

    public handleCustomersSearch(text: string) {
        this.filteredCustomers = this.data.formData.customers.filter(x => x.customerName.includes(text));
    }

    public handleCountriesSearch(text: string) {
        const newFilteredCountries = this.data.formData.countries.filter(x => x.alpha2Code.toLowerCase().includes(text.toLowerCase())
            || x.englishName.toLowerCase().includes(text.toLowerCase()));
        const alreadySelectedCountries = this.countriesFilters.value;
        this.filteredCountries = alreadySelectedCountries.concat(newFilteredCountries.filter(x => !alreadySelectedCountries.includes(x)));
    }

    public onYes(): void {
        this.data.isChangesConfirmed = true;

        const { customerLocation } = this.data;

        if (this.data.dialogType === 'Add') {
            customerLocation.locations = this.locations.filter(
                (l) => l.isSelected
            );

            const selectedCustomer = this.data.formData.customers.filter(
                (x) => customerLocation.customerIds.includes(x.customerId));

            // Uses for message after creation
            customerLocation.customerName = selectedCustomer.map(c => c.customerName).join(', ');

        } else if (this.selectedLocation !== this.data.customerLocation.iGMLocationCode) {
            this.data.customerLocation.iGMLocationCode = this.selectedLocation;
            const foundLocation = this.locations.find(x => x.iGMLocationCode === this.data.customerLocation.iGMLocationCode);
            this.data.customerLocation.locationName = foundLocation.name;
            this.data.customerLocation.locationId = foundLocation.locationID;
        }

        if (this.data.dialogType === 'Edit') {
            customerLocation.applyChangesToCustomerIds = this.customerFilters.value.map((x) => x.customerId);
        }

        customerLocation.geoAllowCountries = this.countriesFilters.value.map(x => x.alpha2Code).join(' ');
        customerLocation.streamName = this.selectedStream;

        this.dialogRef.close(_.cloneDeep(this.data));
    }

    public allowNext(): boolean {
        const { customerLocation } = this.data;
        return !(
            (this.data.dialogType === 'Edit' ? customerLocation.customerId : (customerLocation.customerIds && customerLocation.customerIds.length > 0))
            && this.selectedStream
            && customerLocation.playerId
            && (this.data.dialogType === 'Edit' ? customerLocation.uniqueStreamNameId : (customerLocation.uniqueStreamNames && customerLocation.uniqueStreamNames.length > 0))
        );
    }

    public selectAllCustomers() {
        this.customerFilters.setValue(this.data.formData.customers);
    }

    public showSelectedsOnTop() {
        const alreadySelectedCountries = this.countriesFilters.value;
        this.filteredCountries = alreadySelectedCountries.concat(this.data.formData.countries.filter(x => !alreadySelectedCountries.includes(x)));
    }

    public selectAllCountries() {
        this.countriesFilters.setValue(this.data.formData.countries);
    }

    public onStreamNameChange(streamName) {
        if (streamName === this.ullStreamName) {
            this.locations = this.sourceLocations.filter(x => x.locationUllEnabled)
            this.filters.showUllEnabledLocations = true;
        } else {
            this.filters.showUllEnabledLocations = false;
            this.locations = this.sourceLocations;
        }
    }

    public deselectAllCustomers() {
        this.customerFilters.setValue([]);
    }

    public deselectAllCountries() {
        this.countriesFilters.setValue([]);
    }

    public onNext() {
        this.tab += 1;
    }

    public onTabChange(data: MatTabChangeEvent) {
        this.tab = data.index;

    }

    public isValid(): boolean {
        return !this.locations.some((l) => l.isSelected);
    }

    public onScroll() {
        if (!this.isFilterApplies()) {
            this._loadLocations(this.page);
            this.page += 1;
        }
    }

    public onFilterChange() {
        if (!this.isFilterApplies() && this.data.dialogType === 'Add') {
            this.locations = [];
            this.stateService.isFilters = false;
            this._loadLocations(0, 10000);
        } else {
            this._loadByFilters(this.filters);
        }
        this.stateService.isFilters = true;
        this.stateService.setFilters(this.filters);
    }

    public isFilterApplies(): boolean {
        return (
            !!this.filters.dataFeedIdFilter
            || !!this.filters.sportCodeFilter
            || !!this.filters.countryCodeFilter
            || this.filters.isShowInActiveLocations
            || this.filters.showUllEnabledLocations
            || !!this.filters.searchWord
        );
    }

    public onInactiveShowChange() {
        this.onFilterChange();
    }

    public onSelectionChange(data: MatSelectChange) {
        this.selectedLocation = data.value.iGMLocationCode;
        this.locations.find(
            (l) => l.iGMLocationCode === this.selectedLocation
        ).isSelected = true;
    }

    public comparing(o1: any, o2: any): boolean {
        return o1 && o2 && o1 === o2;
    }

    private _loadByFilters(filters: FilterCriteria) {
        this.service
            .getSortedEventLocations(filters)
            .subscribe((locations: LocationWithFlag[]) => {
                this.locations = locations;
            });
    }

    private _loadLocations(page = 0, pageSize = 100, searchWord = '') {
        this.service
            .getEventLocations(page, pageSize, searchWord)
            .subscribe((locations: LocationBaseInfos) => {
                this.locations = this.locations.concat(locations.locationBaseInfos as LocationWithFlag[]);
                this.sourceLocations = this.locations;
                this.locationsCount = locations.count;
                //this.locationName = this.sourceLocations.find(x => this.data.customerLocation.locationId === x.locationID).locationName;
            });
    }

    private _initializeSearch() {
        this.searchedLocations = this.search.valueChanges.pipe(
            debounceTime(1000),
            switchMap((value) => {
                if (value) {
                    this.filters.searchWord = value;
                    return this.service.getSortedEventLocations(this.filters);
                } else {
                    this.filters.searchWord = '';
                    this._loadLocations();
                    return of([]);
                }
            }
            )
        );

        this.searchedLocations.subscribe((data) => {
            this.locations = data as LocationWithFlag[];
        });
    }
}
