import { Injectable } from '@angular/core';
import { RawImportRequest } from '../models/raw-import-request';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { RawImportModel } from '../models/common-raw-import';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class RawImportApiService {
    public routePrefix = '/api/rawImport';

    constructor(private httpService: HttpInternalService) {}

    public getRawImportData(requestParams: RawImportRequest): Observable<HttpResponse<RawImportModel[]>> {
        return this.httpService.getFullRequest<RawImportModel[]>(`${this.routePrefix}`, requestParams);
    }
}
