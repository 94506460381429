import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { NotificationMessage } from 'src/app/models/notification/notificationMessage';
import { Observer } from 'rxjs';
import { NotificationType } from '../models/notification/notificationType';

@Injectable()
export class NotificationSnackBarObserver implements Observer<NotificationMessage> {

    constructor(public snackBar: MatSnackBar) {}

    // tslint:disable-next-line:member-ordering
    complete: () => {};

    next(notificationMessage: NotificationMessage) {
        if (!notificationMessage || !notificationMessage.message || !notificationMessage.type) {
            return;
        }

        const config = new MatSnackBarConfig();
        config.duration = 5000;

        if (notificationMessage.type === NotificationType.Error) {
            config.panelClass = ['error-snack-bar'];
        }

        this.snackBar.open(notificationMessage.message, '', config);
    }

    error(errorMessage: string) {
        this.snackBar.open(errorMessage, '', {
            duration: 3000
        });
    }
}
