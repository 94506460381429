import { DataFeedBaseInfo } from 'src/app/models/common/data-feed-base-info';
import { PlayerBaseInfo } from 'src/app/models/common/player-base-info';
import { StreamBaseInfo } from 'src/app/models/common/stream-base-info';
import { CustomerWithUid } from 'src/app/models/common/customer-with-uid';

export class AutoGenerateCustomerLocationModel {
    public id: number;
    public customer: CustomerWithUid;
    public dataFeed: DataFeedBaseInfo;
    public player: PlayerBaseInfo;
    public uniqueStreamId: string;
    public stream: StreamBaseInfo;
    public isActive: boolean;
    public rawEnabled: boolean;
}
