import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource, MatPaginator, Sort } from '@angular/material';
import { RoleService } from './services/role.service';
import { Role } from './models/role';
import { DialogConfirmationComponent } from '../../../components/dialog-confirmation/dialog-confirmation.component';
import * as _ from 'lodash';
import { DialogConfirmationData } from '../../../models/dialog/dialog-confirmation-data';
import { AddEditRoleDialogComponent } from './add-edit-role-dialog/add-edit-role-dialog.component';
import { RoleAddEditDialogData } from './models/role-add-edit-dialog-data';

@Component({
    selector: 'app-role',
    templateUrl: './role.component.html',
    styleUrls: ['./role.component.sass']
})
export class RoleComponent implements OnInit {
    @ViewChild('tablePaginator') public tablePaginator: MatPaginator;

    public rolesSource = new MatTableDataSource<Role>();
    public displayedColumns: string[] = ['RoleName', 'Menu'];

    constructor(private roleService: RoleService, public dialog: MatDialog) {}

    public ngOnInit() {
        this.getRoles();
        this.rolesSource.paginator = this.tablePaginator;
    }

    public getRoles() {
        this.roleService
            .getRoles()
            .subscribe(
                (value: Role[]) => (
                        this.rolesSource.data = _.orderBy(value, ['roleName'], ['asc'])
                    ));
    }

    public updateRole(updatedRole: Role) {
        this.roleService.updateRole(updatedRole).subscribe((isRoleUpdated: boolean) => {
            if (isRoleUpdated) {
                this.rolesSource.data = this.rolesSource.data.map((role) => {
                    return role.roleId === updatedRole.roleId ? updatedRole : role;
                });
            }
        });
    }

    public addRole(newRole: Role) {
        this.roleService.addRole(newRole).subscribe((addedRole: Role) => {
            if (addedRole) {
                this.rolesSource.data = _.concat(this.rolesSource.data, addedRole);
            }
        });
    }

    public removeRole(removedRole: Role) {
        this.roleService.deleteRole(removedRole).subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed) {
                this.rolesSource.data = _.remove(this.rolesSource.data, (r) => {
                    return r.roleId !== removedRole.roleId;
                });
            }
        });
    }

    public openAddDialog() {
        const dialogRemoving = this.createAddEditDialog(new Role(), 'Add');

        dialogRemoving.afterClosed().subscribe((result: RoleAddEditDialogData) => {
            if (result && result.isChangesConfirmed) {
                this.addRole(result.role);
            }
        });
    }

    public openEditDialog(role: Role) {
        const dialogRemoving = this.createAddEditDialog(_.cloneDeep(role), 'Edit');

        dialogRemoving.afterClosed().subscribe((result: RoleAddEditDialogData) => {
            if (result && result.isChangesConfirmed) {
                this.updateRole(result.role);
            }
        });
    }

    public openRemoveDialog(role: Role) {
        const dialogRemoving = this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: new DialogConfirmationData(role.roleName)
        });

        dialogRemoving.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed) {
                this.removeRole(role);
            }
        });
    }

    public createAddEditDialog(role: Role, dialogType: string) {
        return this.dialog.open(AddEditRoleDialogComponent, {
            width: '500px',
            data: { dialogType, role, isChangesConfirmed: false }
        });
    }

    public sortData(sort: Sort) {
        this.rolesSource.data = this.rolesSource.data.sort((a, b) => {
            return this.compare(a.roleName, b.roleName, sort.direction === 'asc' || !sort.direction ? true : false);
        });
    }

    private compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
