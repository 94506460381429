import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { UserEdit } from '../models/user-edit';
import { UserRead } from '../models/user-read';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserBaseInfo } from '../../../../models/common/user-base-info';

@Injectable()
export class UserApiService {
    routePrefix: string = '/api/user';

    constructor(private httpService: HttpInternalService) {}

    getUsers(): Observable<HttpResponse<UserRead>> {
        return this.httpService.getFullRequest(`${this.routePrefix}`);
    }

    getUsersBaseInfo(): Observable<HttpResponse<UserBaseInfo[]>> {
        return this.httpService.getFullRequest<UserBaseInfo[]>(`${this.routePrefix}/allBaseInfo`);
    }

    getUser(userId: number): Observable<HttpResponse<UserEdit>> {
        return this.httpService.getFullRequest(`${this.routePrefix}/${userId}`);
    }

    addUser(user: UserEdit): Observable<HttpResponse<UserRead>> {
        return this.httpService.postFullRequest(`${this.routePrefix}`, user);
    }

    updateUser(user: UserEdit): Observable<HttpResponse<UserRead>> {
        return this.httpService.putFullRequest(`${this.routePrefix}`, user);
    }

    deleteUser(userId: number): Observable<HttpResponse<any>> {
        return this.httpService.deleteFullRequest(`${this.routePrefix}/${userId}`);
    }
}
