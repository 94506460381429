import { Component } from '@angular/core';

@Component({
  selector: 'app-access-not-allowed',
  templateUrl: './access-not-allowed.component.html',
  styleUrls: ['./access-not-allowed.component.sass']
})
export class AccessNotAllowedComponent {

}
