import { DataFeedParameter } from './data-feed-parameter';
import { DataProvider } from 'src/app/models/common/data-provider';

export class DataFeed {
    public id: number;
    public name: string;
    public code: string;
    public importTimerSeconds: number;
    public pullDataTimerSeconds: number;
    public autoActivateLocations: boolean;
    public credentialStore: string;
    public isActive: boolean;

    public dataProvider: DataProvider;
    public parameters: DataFeedParameter[];
}
