import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpInternalService } from 'src/app/services/http.internal.service';
import { CustomerLocationsWithTotal } from '../models/customer-location-with-total';
import { CustomerLocation } from '../models/customer-location';
import { CustomerLocationsFormData } from '../models/customer-location-form-data';
import { CustomerLocationFilter } from '../models/customer-location-filter';

@Injectable({
    providedIn: 'root'
})
export class CustomerLocationApiService {
    public routePrefix = '/api/customerLocations';

    constructor(private httpService: HttpInternalService) { }

    public getCustomerLocations(filterModel: CustomerLocationFilter): Observable<HttpResponse<CustomerLocationsWithTotal>> {
        return this.httpService.postFullRequest<CustomerLocationsWithTotal>(`${this.routePrefix}/search`, filterModel);
    }

    public getCustomerLocationsByLocation(igmLocationCode: string): Observable<HttpResponse<CustomerLocation[]>> {
        return this.httpService.getFullRequest<CustomerLocation[]>(`${this.routePrefix}/byLocation`, { igmLocationCode });
    }

    public getCustomerLocationsByEvent(eventId: string): Observable<HttpResponse<CustomerLocation[]>> {
        return this.httpService.getFullRequest<CustomerLocation[]>(`${this.routePrefix}/byEvent`, { eventId });
    }

    public getFormData(): Observable<HttpResponse<CustomerLocationsFormData>> {
        return this.httpService.getFullRequest<CustomerLocationsFormData>(`${this.routePrefix}/formData`);
    }

    public getCustomerLocation(customerLocationId: number): Observable<HttpResponse<CustomerLocation>> {
        return this.httpService.getFullRequest(`${this.routePrefix}/${customerLocationId}`);
    }

    public createCustomerLocation(customerLocation: CustomerLocation): Observable<HttpResponse<boolean>> {
        return this.httpService.postFullRequest(`${this.routePrefix}`, customerLocation);
    }

    public updateCustomerLocation(customerLocation: CustomerLocation): Observable<HttpResponse<any>> {
        return this.httpService.putFullRequest(`${this.routePrefix}`, customerLocation);
    }

    public updateCustomerLocationIsActive(id: number, value: boolean): Observable<HttpResponse<boolean>> {
        return this.httpService.putFullRequest(`${this.routePrefix}/isActive`, { id, value });
    }

    public deleteCustomerLocation(customerLocationId: number): Observable<HttpResponse<any>> {
        return this.httpService.deleteFullRequest(`${this.routePrefix}/${customerLocationId}`);
    }

    public uploadCustomerLocationsCsvFile(formData: FormData): Observable<HttpResponse<any>> {
        return this.httpService.postFullRequest<FormData>(`${this.routePrefix}/upload`, formData);
    }
}
