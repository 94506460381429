import { Component, Inject, OnInit } from '@angular/core';
import { DialogConfirmationData } from '../../models/dialog/dialog-confirmation-data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogInformationData } from '../../models/dialog/dialog-information-data';

@Component({
  selector: 'app-dialog-information',
  templateUrl: './dialog-information.component.html',
  styleUrls: ['./dialog-information.component.sass']
})
export class DialogInformationComponent {
    constructor(
        public dialogRef: MatDialogRef<DialogInformationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogInformationData
    ) { }

    public onYes(): void {
        this.dialogRef.close();
    }
}
