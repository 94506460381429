import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventFlightBoard } from '../../../../../app/pages/event/event-flight-board/models/event-flight-board';

@Injectable()
export class EventFlightBoardApiService {
    routePrefix: string = '/api/eventFlightBoard';

    constructor(private httpService: HttpInternalService) {}

    getFlightBoardEvents(): Observable<HttpResponse<EventFlightBoard[]>> {
        return this.httpService.getFullRequest<EventFlightBoard[]>(`${this.routePrefix}/all`);
    }
}
