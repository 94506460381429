export class DetailedEvent {
    public eventId: string;
    public location: string;
    public eventStatusCode: string;
    public igmLocationCode: string;
    public eventNumber: number;
    public title: string;
    public startTime: Date;
    public estimatedEndTime: Date;
    public runningTime: number;
    public actualStartTime: Date;
    public dateModified: Date;
    public isAutoUpdating: boolean;

    public competitionId: number;
    public locationId: number;
    public geoBlockCountries: string;
    public locationGeoBlockCountries: string;    
}
