export class StreamBaseInfo {
    public id: number;
    public streamType: string;
    public name: string;

    constructor(id: number, streamType: string, name: string) {
        this.id = id;
        this.streamType = streamType;
        this.name = name;
    }
}
