import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatSnackBar } from '@angular/material';
import { Sport } from '../../../models/common/sport';
import { InitData } from './models/init-data';
import { TestEventGeneratorService } from './services/test-event-generator.service';
import { EventGeneratorParams } from './models/event-generator-params';
import { GeneratedEvent } from './models/generated-event';
import { ImportEvent } from './models/import-event';
import { ImportData } from './models/import-data';
import { HttpResponse } from '@angular/common/http';
import { CustomerBaseInfo } from '../../../models/common/customer-base-info';

@Component({
    selector: 'app-test-event-generator',
    templateUrl: './test-event-generator.component.html',
    styleUrls: ['./test-event-generator.component.sass']
})
export class TestEventGeneratorComponent implements OnInit {
    public timeValidatorPattern = '^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-9]|0[0-9]|[0-5][0-9]):([0-9]|0[0-9]|[0-5][0-9])$';
    public weekDaysOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    public customers: CustomerBaseInfo[];
    public sports: Sport[];
    public isListGenerated: boolean;
    public importableEvents: number;
    public generatedEvents: MatTableDataSource<GeneratedEvent>;
    public displayedColumns: string[] = ['eventId', 'locationCode', 'eventNumber', 'title', 'startTime', 'endTime'];

    public generatorOptions: FormGroup;
    public importerOptions: FormGroup;

    @ViewChild(MatPaginator) public paginator: MatPaginator;

    constructor(private generatorService: TestEventGeneratorService, private formBuilder: FormBuilder, private snackBar: MatSnackBar) {}

    public ngOnInit() {
        this.initEventsList(undefined);

        this.generatorService.getInitData().subscribe((value: InitData) => {
            this.customers = value.customers;
            this.sports = value.sports;
        });

        this.generatorOptions = this.formBuilder.group({
            eventTitle: ['', Validators.required],
            customerId: ['', Validators.required],
            sportCode: ['', Validators.required],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            weekDays: ['', Validators.required],
            eachDayStartTime: ['00:00:00', Validators.compose([Validators.required, Validators.pattern(this.timeValidatorPattern)])],
            eachDayEndTime: ['23:00:00', Validators.compose([Validators.required, Validators.pattern(this.timeValidatorPattern)])],
            timeChangeInterval: ['01:00:00', Validators.compose([Validators.required, Validators.pattern(this.timeValidatorPattern)])]
        });

        this.importerOptions = this.formBuilder.group({
            shouldCreateMissingLocationCodes: [false]
        });

        this.importerOptions.valueChanges.subscribe(() => {
            this.updateImportableEventsCount();
        });
    }

    public selectAllWeekDays() {
        this.generatorOptions.controls.weekDays.setValue(this.weekDaysOptions);
    }

    public deselectAllWeekDays() {
        this.generatorOptions.controls.weekDays.setValue([]);
    }

    public generateTestEvents() {
        if (!this.generatorOptions.valid) {
            return;
        }

        const params = new EventGeneratorParams();
        params.eventTitle = this.generatorOptions.value.eventTitle;
        params.eachDayStartTime = this.generatorOptions.value.eachDayStartTime;
        params.eachDayEndTime = this.generatorOptions.value.eachDayEndTime;
        params.timeChangeInterval = this.generatorOptions.value.timeChangeInterval;
        params.customerId = this.generatorOptions.value.customerId;
        params.sportCode = this.generatorOptions.value.sportCode;
        params.startDate = this.generatorOptions.value.startDate.toISOString();
        params.endDate = this.generatorOptions.value.endDate.toISOString();
        params.weekDays = this.generatorOptions.value.weekDays;

        this.generatorService.generateTestEvents(params).subscribe((events: GeneratedEvent[]) => {
            this.initEventsList(events);
        });
    }

    public importTestEvents() {
        const customer = this.customers.find((x) => x.customerId === this.generatorOptions.value.customerId);
        const sport = this.sports.find((x) => x.code === this.generatorOptions.value.sportCode);

        const events: ImportEvent[] = [];
        for (const generatedEvent of this.generatedEvents.data) {
            if (generatedEvent.isEventIdExists) {
                continue;
            }

            if (!generatedEvent.isLocationCodeExists && !this.importerOptions.value.shouldCreateMissingLocationCodes) {
                continue;
            }

            const importEvent = new ImportEvent();
            importEvent.eventId = generatedEvent.eventId;
            importEvent.locationCode = generatedEvent.locationCode;
            importEvent.eventNumber = generatedEvent.eventNumber;
            importEvent.title = generatedEvent.title;
            importEvent.startTime = generatedEvent.startTime;
            importEvent.endTime = generatedEvent.endTime;

            events.push(importEvent);
        }

        const importData = new ImportData();
        importData.customer = customer;
        importData.sport = sport;
        importData.events = events;

        this.generatorService
            .importTestEvents(importData)
            .subscribe(
                (response: HttpResponse<any>) => {
                    this.snackBar.open('Events have been imported successfully', '', { duration: 3000 });
                    this.initEventsList(undefined);
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.snackBar.open('Import failed', '', { duration: 3000, panelClass: 'error-snack-bar' });
                });
    }

    public updateImportableEventsCount() {
        this.importableEvents = this.generatedEvents.data.filter(
            (x) =>
                !x.isEventIdExists &&
                (x.isLocationCodeExists || (!x.isLocationCodeExists && this.importerOptions.value.shouldCreateMissingLocationCodes))
        ).length;
    }

    private initEventsList(events: GeneratedEvent[]) {
        let initData = events;
        if (!initData) {
            initData = [];
        }
        this.generatedEvents = new MatTableDataSource<GeneratedEvent>(initData);
        this.generatedEvents.paginator = this.paginator;
        this.isListGenerated = !!events;
        this.updateImportableEventsCount();
    }
}
