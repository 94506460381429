import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { EventList } from '../models/event-list';
import { TimeConverterService } from 'src/app/services/time.converter.service';

@Injectable()
export class ScheduleService {
    private baseUrl = '/api/schedule';

    constructor(private httpService: HttpInternalService, private timeConverter: TimeConverterService) {}

    public getLocations(customerUid: string): Observable<HttpResponse<Location[]>> {
        return this.httpService.getFullRequest(`${this.baseUrl}/location`, { customerUid });
    }

    public getEvents(
        customerUid: string,
        eventDate: Date,
        page = 0,
        pageSize = 0,
        orderBy = 'eventId',
        ascending = true
    ): Observable<HttpResponse<EventList>> {
        return this.httpService.getFullRequest(`${this.baseUrl}/all/event`, {
            customerUid,
            eventDate: this.timeConverter.getDate(eventDate),
            page,
            pageSize,
            orderBy,
            ascending
        });
    }

    public getEventsByLocationCode(customerUid: string, locationCode: string, eventDate: Date): Observable<HttpResponse<EventList>> {
        return this.httpService.getFullRequest(`${this.baseUrl}/all/event/locationCode`, {
            customerUid,
            locationCode,
            eventDate
        });
    }

    public getOpenEvents(customerUid: string, orderBy = 'eventId', ascending = true, page = 0, pageSize = 0): Observable<HttpResponse<EventList>> {
        return this.httpService.getFullRequest(`${this.baseUrl}/open/event`, { customerUid, page, pageSize, orderBy, ascending });
    }

    public getOpenEventsByLocationCode(customerUid: string, locationCode: string, eventDate: Date): Observable<HttpResponse<EventList>> {
        return this.httpService.getFullRequest(`${this.baseUrl}/open/event/locationCode`, {
            customerUid,
            locationCode,
            eventDate
        });
    }
}
