import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInternalService } from 'src/app/services/http.internal.service';
import { AutoGenerateCustomerLocationsModel } from '../models/auto-generate-customer-locations';
import { AutoGenerateCustomerLocationModel } from '../models/auto-generate-customer-location';
import { AutoGenerateCustomerLocationRelatedCollectionsModel } from '../models/auto-generate-customer-location-collections';

@Injectable()
export class AutoGenerateCustomerLocationApiService {
    public routePrefix = '/api/autoGenerateCustomerLocation';

    constructor(private httpService: HttpInternalService) {}

    public getAutoGenerateCustomerLocations(
        page?: number,
        pageSize?: number,
        ascending?: boolean
    ): Observable<HttpResponse<AutoGenerateCustomerLocationsModel>> {
        return this.httpService.getFullRequest<AutoGenerateCustomerLocationsModel>(`${this.routePrefix}/all`, { ascending, page, pageSize });
    }

    public getRelatedCollections(): Observable<HttpResponse<AutoGenerateCustomerLocationRelatedCollectionsModel>> {
        return this.httpService.getFullRequest<AutoGenerateCustomerLocationRelatedCollectionsModel>(`${this.routePrefix}/relatedCollections`);
    }

    public addAutoGenerateCustomerLocation(location: AutoGenerateCustomerLocationModel): Observable<HttpResponse<Response>> {
        return this.httpService.putFullRequest<Response>(`${this.routePrefix}`, location);
    }

    public updateAutoGenerateCustomerLocation(location: AutoGenerateCustomerLocationModel): Observable<HttpResponse<Response>> {
        return this.httpService.postFullRequest<Response>(`${this.routePrefix}`, location);
    }

    public deleteAutoGenerateCustomerLocation(locationId: number): Observable<HttpResponse<Response>> {
        return this.httpService.deleteFullRequest<Response>(`${this.routePrefix}`, { locationId });
    }
}
