import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSnackBar, MatDialog, MatPaginator, PageEvent, Sort, MatSort } from '@angular/material';
import { ProviderDataFeedsService } from './services/data-feed.service';
import { DataFeedInfo } from './models/data-feeds';
import { DataSource } from '@angular/cdk/collections';
import * as _ from 'lodash';

@Component({
    selector: 'app-provider-codes',
    templateUrl: './provider-codes.component.html',
    styleUrls: ['./provider-codes.component.sass'],
})
export class ProviderComponent implements OnInit {
    // ViewChild(MatPaginator) public paginator: MatPaginator;
    // @ViewChild(MatSort) public sort: MatSort;

    @ViewChild('tablePaginator') public tablePaginator: MatPaginator;

    public dataFeeds = new MatTableDataSource<DataFeedInfo>();

    public displayedColumns = ['Id', 'DataFeedName', 'DataProviderName'];

    public pageSizeOptions = [10, 20, 30];

    constructor(private dataFeedsService: ProviderDataFeedsService, public dialog: MatDialog) {}

    public ngOnInit() {
        this.getDataFeeds();
        this.dataFeeds.paginator = this.tablePaginator;
    }

    public getDataFeeds() {
        this.dataFeedsService.getDataFeedInfo().subscribe((value: DataFeedInfo[]) => (this.dataFeeds.data = _.orderBy(value, ['Id'], ['desc'])));
    }

    public sortData(sort: Sort) {
        this.dataFeeds.data = this.dataFeeds.data.sort((a, b) => {
            return this.compare(a.id, b.dataFeedName, sort.direction === 'asc' || !sort.direction ? true : false);
        });
    }

    private compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    // tslint:disable-next-line:member-ordering
    public applySearchFilter(filterValue: string) {
        this.dataFeeds.filter = filterValue.trim().toLowerCase();
    }
}
