import { RoleBaseInfo } from '../../../../models/common/role-base-info';

export class RcsUserRole implements RoleBaseInfo {
    roleId: number;
    roleName: string;
    isUserInRole: boolean;

    constructor(roleId: number, roleName: string, isUserInRole: boolean) {
        this.roleId = roleId;
        this.roleName = roleName;
        this.isUserInRole = isUserInRole;
    }
}
