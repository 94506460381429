import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { EventList } from '../models/event-list';
import { TimeConverterService } from 'src/app/services/time.converter.service';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class RestScheduleService {
    private streamApiUrl = environment.streamApiUrl;
    private baseUrl = '/api/streamServices';
    private eventRoutePrefix = '/api/event';

    constructor(private httpService: HttpInternalService, private timeConverter: TimeConverterService) {}

    public getLocations(customerUid: string): Observable<HttpResponse<Location[]>> {
        return this.httpService.getFullRequest(`${this.baseUrl}/location`, { customerUid });
    }

    public getEvents(
        customerGuid: string,
        eventDate: Date,
        page = 0,
        pageSize = 0,
        orderBy = 'eventId',
        ascending = true
    ): Observable<HttpResponse<EventList>> {
        return this.httpService.getFullRequest(`${this.streamApiUrl}${this.eventRoutePrefix}/all/${customerGuid}/${this.timeConverter.getDate(eventDate)}/paging`, {
            page,
            pageSize,
            orderBy,
            ascending
        });
    }

    public getEventsByLocationCode(customerGuid: string, locationCode: string, eventDate: Date): Observable<HttpResponse<EventList>> {
        return this.httpService.getFullRequest(`${this.baseUrl}/eventsByLocation`, {
            customerGuid,
            locationCode,
            eventDate
        });
    }

    public getOpenEvents(customerGuid: string, orderBy = 'eventId', ascending = true, page = 0, pageSize = 0): Observable<HttpResponse<EventList>> {
        return this.httpService.getFullRequest(`${this.streamApiUrl}${this.eventRoutePrefix}/open/${customerGuid}/paging`, { page, pageSize, orderBy, ascending });
    }

    public getOpenEventsByLocationCode(customerGuid: string, locationCode: string, eventDate: Date): Observable<HttpResponse<EventList>> {
        return this.httpService.getFullRequest(`${this.baseUrl}/openEventsByLocation`, {
            customerGuid,
            locationCode,
            eventDate
        });
    }
}
