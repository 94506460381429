import { Injectable } from '@angular/core';
import { Route } from '../models/navigation/route';
import { flatten } from 'lodash';

@Injectable()
export class RouteService {
    private readonly routes: Route[];

    constructor() {
        this.routes = this.fillRouteArray();
    }

    public getRouteNameByPath(routeName: string): string {
        let finalRoute;
        this.routes.forEach((_, index) => {
            const route = this.routes.concat(flatten(this.routes[index].childRoutes.map((x) => x.childRoutes))).find((x) => x.path === routeName);
            if (!route) {
                const midroute = this.routes.concat(flatten(this.routes.map((r) => r.childRoutes))).find((r) => r.path === routeName);
                if (midroute) {
                    finalRoute = midroute;
                }
            } else {
                finalRoute = route;
            }
        });
        return finalRoute ? finalRoute.name : undefined;
    }

    public getAllRoutes(): Route[] {
        return this.routes;
    }

    private fillRouteArray(): Route[] {
        const liveScheduleRoute = new Route('Live Schedule', '/streaming/live-schedule');

        const flightBoard = new Route('Flight Board', '/streaming/flight-board');

        const streamMonitorRoute = new Route('Stream Monitor', '/streaming/stream-monitor');

        const eventsRoute = new Route('Events management', undefined, [
            new Route('Events', '/streaming/events'),
            new Route('Locations', '/streaming/events/location'),
            new Route('Customer Locations', '/streaming/events/customer-locations'),
            new Route('Event History', '/streaming/events/history'),
            new Route('Test Event Generator', '/streaming/events/test-event-generator'),
            new Route('Twitch', undefined, [
                new Route('Streamers', '/streaming/events/streamers'),
                new Route('Sports', '/streaming/events/sports'),
                new Route('Streamer subscriptions', '/streaming/events/streamer-subscription')
            ]),
        ]);

        const streamManagementRoute = new Route('Stream management', undefined, [
            new Route('Auto Generate Customer Location', '/streaming/bet-n-watch/auto-generate-locations'),
            new Route('Streams', '/streaming/bet-n-watch/streams'),
            new Route('Players', '/streaming/bet-n-watch/players'),
            new Route('Virtual Players', '/streaming/bet-n-watch/virtual-players'),
            new Route('Test Web Services', '/streaming/bet-n-watch/test-web-services'),
        ]);

        const rcsRoute = new Route('RCS', undefined, [
            new Route('Customers', '/race-card/customer'),
            new Route('Data Feeds', '/race-card/data-feed'),
            new Route('Roles', '/race-card/role'),
            new Route('Users', '/race-card/user'),
            new Route('Push XML', '/race-card/push'),
            new Route('Race Audit', '/race-card/race-audit'),
            new Route('Customer Settings', '/race-card/customer-setting'),
            new Route('Providers Raw Data', '/race-card/raw-data'),
        ]);

        const systemWideRoute = new Route('System', undefined, [
            new Route('Users', '/system/users'),
            new Route('Roles', '/system/roles'),
            new Route('Customers', undefined, [
                new Route('Customers Main Info', '/system/customer/customers'),
                new Route('Customer Domains', '/system/customer/customer-domains'),
            ]),
            new Route('Data Feeds', undefined, [
                new Route('Import History', '/system/data-feed/import-history'),
                new Route('Queue Maintenance', '/system/data-feed/queues-maintaining'),
                // new Route('User Data Feeds', '/system/data-feed/user-datafeeds'),
                new Route('Data Feeds', '/system/data-feed/data-feeds'),
            ]),
            new Route('3rd Party Services', undefined, [
                new Route('Provider Codes', '/system/3rd-party-services/provider-codes')
            ]),
            new Route('Tools', '/tools'),
        ]);

        const logoutRoute = new Route('Logout', '/logout');

        return [liveScheduleRoute, eventsRoute, streamManagementRoute, streamMonitorRoute, rcsRoute, flightBoard, systemWideRoute, logoutRoute];
    }
}
