import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInternalService } from 'src/app/services/http.internal.service';
import { DetailedEventsModel } from '../models/events.model';
import { DetailedEvent } from '../../../../models/common/detailed-event';

@Injectable()
export class EventsApiService {
    public routePrefix = '/api/events';

    constructor(private httpService: HttpInternalService) { }

    public getAll(page = 0, pageSize = 25, searchWordValue?: string, sortFilter?: string, isAsc?: boolean)
    : Observable<HttpResponse<DetailedEventsModel>> {
        const searchWord = searchWordValue || '';
        const orderByColumn = sortFilter || 'startTime';
        const ascending = isAsc || false;
        return this.httpService.getFullRequest<DetailedEventsModel>(`${this.routePrefix}`, { page, pageSize, searchWord, orderByColumn, ascending });
    }

    public delete(eventId: string): Observable<HttpResponse<string>> {
        return this.httpService.deleteFullRequest<string>(`${this.routePrefix}`, { eventId });
    }

    public create(event: DetailedEvent): Observable<HttpResponse<DetailedEvent>> {
        return this.httpService.postFullRequest<DetailedEvent>(`${this.routePrefix}`, event);
    }

    public createMany(events: DetailedEvent[]): Observable<HttpResponse<DetailedEvent[]>> {
        return this.httpService.postFullRequest<DetailedEvent[]>(`${this.routePrefix}/many`, events);
    }

    public update(event: DetailedEvent): Observable<HttpResponse<DetailedEvent>> {
        return this.httpService.putFullRequest<DetailedEvent>(`${this.routePrefix}`, event);
    }

    public uploadEventsCsvFile(formData: FormData): Observable<HttpResponse<any>> {
        return this.httpService.postFullRequest<FormData>(`${this.routePrefix}/upload`, formData);
    }
}
