import { DataFeed } from './../models/data-feed';
import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { DataFeeds } from '../models/data-feeds';
import { DataFeedParameter } from '../models/data-feed-parameter';

@Injectable()
export class DataFeedApiService {
    public routePrefix = '/api/dataFeed';

    constructor(private httpService: HttpInternalService) {}

    public getDataFeeds(page = 0, pageSize = 20): Observable<HttpResponse<DataFeeds>> {
        return this.httpService.getFullRequest<DataFeeds>(`${this.routePrefix}`, { page, pageSize });
    }

    public addDataFeed(config: DataFeed): Observable<HttpResponse<DataFeed>> {
        return this.httpService.postFullRequest<DataFeed>(`${this.routePrefix}`, config);
    }

    public updateDataFeed(config: DataFeed): Observable<HttpResponse<boolean>> {
        return this.httpService.putFullRequest<boolean>(`${this.routePrefix}`, config);
    }

    public deleteDataFeed(configId: number): Observable<HttpResponse<boolean>> {
        return this.httpService.deleteFullRequest<boolean>(`${this.routePrefix}`, { configId });
    }
}
