import { FilterCriteria } from '../models/filter-criteria';
export class LocationFilteringState {
    public isFilters = false;

    private dataFeedIdFilter = 0;
    private sportCodeFilter = '';
    private countryCodeFilter = '';
    private showInActiveLocations = false;
    private showUllEnabledLocations = false;

    public loadFilters(): FilterCriteria {
        return new FilterCriteria(
            this.dataFeedIdFilter,
            this.sportCodeFilter,
            this.countryCodeFilter,
            this.showInActiveLocations,
            this.showUllEnabledLocations
        );
    }

    public setFilters(filters: FilterCriteria) {
        this.dataFeedIdFilter = filters.dataFeedIdFilter;
        this.sportCodeFilter = filters.sportCodeFilter;
        this.countryCodeFilter = filters.countryCodeFilter;
        this.showInActiveLocations = filters.isShowInActiveLocations;
        this.showUllEnabledLocations = filters.showUllEnabledLocations;
    }
}
