export class FilterCriteria {
    public dataFeedIdFilter = 0;
    public sportCodeFilter = '';
    public countryCodeFilter = '';
    public isShowInActiveLocations = false;
    public showUllEnabledLocations = false;
    public searchWord = '';

    constructor(dataFeedIdFilter = 0,
                sportCodeFilter = '',
                countryCodeFilter = '',
                isShowInActiveLocations = false,
                showUllEnabledLocations = false,
                searchWord = ''
    ) {
        this.dataFeedIdFilter = dataFeedIdFilter;
        this.sportCodeFilter = sportCodeFilter;
        this.countryCodeFilter = countryCodeFilter;
        this.isShowInActiveLocations = isShowInActiveLocations;
        this.showUllEnabledLocations = showUllEnabledLocations;
        this.searchWord = searchWord;
    }
}
