export class ManageEventsFilters {
    public customerIds: number[] = [];
    public dataFeedIds: number[] = [];
    public locationIds: number[] = [];
    public competitionIds: number[] = [];
    public sportCodes: string[] = [];
    public countryCodes: string[] = [];
    public statusCodes: string[] = [];
    public startTimeUtc: Date = new Date();
    public endTimeUtc: Date;
}
