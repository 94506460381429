import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource, PageEvent, Sort } from '@angular/material';
import { EventLocationModel } from './models/event-location';
import { EventLocationApiService } from './services/location-api.service';
import { Subject, Subscription } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { EventLocationsModel } from './models/event-locations';
import { LocationDialogComponent } from './location-dialog/location-dialog.component';
import { LocationDialogData } from './models/location-dialog-data';
import { EventLocationUpdateModel } from './models/event-location-update';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TwitchLocationModel } from './models/twitch-locations';
import { TwitchLocation } from './models/twitch-location';
import { TwitchLocationDialogComponent } from './twitch-location-dialog/twitch-location-dialog.component';
import { TwitchLocationDialogData } from './models/twitch-location-dialog-data';
import { Sport } from '../../../models/common/sport';
import { Country } from '../../../models/common/country';
import { DataFeedBaseInfo } from '../../../models/common/data-feed-base-info';
import { DialogType } from '../../../models/dialog/dialogType';

@Component({
    selector: 'app-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.sass'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class LocationComponent implements OnInit, OnDestroy {
    public locationDataSource = new MatTableDataSource<EventLocationModel>();
    public twitchLocationDataSource = new MatTableDataSource<TwitchLocation>();
    public twitchSimilarLocationDataSource = new MatTableDataSource<TwitchLocation>();
    public pageSizeOptions = [20, 50, 100];
    public searchCriteria = '';
    public searchCriteriaChanged = new Subject<string>();
    public hasTwitchEvents = false;
    public isTwitchTabActive = false;

    // have to be the same as names in the EventLocationModel except for 'menu'
    public locationTableColumns: string[] = [
        'locationId',
        'dataFeedName',
        'locationCode',
        'locationName',
        'countryCode',
        'sportCode',
        'dataFeedLocationName',
        'dataFeedLocationCode',
        'dataFeedCountryCode',
        'dataFeedSportCode',
        'ullEnabled',
        'menu',
    ];
    public similarLocationsTableColumns: string[] = [
        'locationId',
        'dataFeed',
        'locationCode',
        'locationName',
        'dataFeedLocationCode',
        'ullEnabled',
        'dataFeedCountryCode',
        'dataFeedSportCode',
    ];
    public twitchLocationTableColumns: string[] = ['locationId', 'igmLocationCode', 'locationName', 'customer', 'isActive', 'menu'];

    public twitchLocationsSubscription: Subscription;
    public twitchDialogSubscription: Subscription;
    public twitchLocationUpdateSubscription: Subscription;

    @ViewChild(MatPaginator) public paginator: MatPaginator;

    public expandedLocation: EventLocationModel;
    public twitchExpandedLocation: TwitchLocation;
    public currentSort: string;
    public currentDirection: string;

    private locationCreateSubscription: Subscription;
    private locationsSubscription: Subscription;
    private dialogSubscription: Subscription;
    private locationUpdateSubscription: Subscription;

    private draftLocation: EventLocationModel;

    private dataFeeds: DataFeedBaseInfo[];
    private sports: Sport[];
    private countries: Country[];

    constructor(
        private snackBar: MatSnackBar,
        private locationApiService: EventLocationApiService,
        private dialog: MatDialog,
        private twitchDialog: MatDialog
    ) {
        this.draftLocation = new EventLocationModel();
        this.draftLocation.country = new Country();
        this.draftLocation.dataFeed = new DataFeedBaseInfo();
        this.draftLocation.sport = new Sport();

        this.searchCriteriaChanged.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe((value: string) => {
                this.paginator.firstPage();
                !this.isTwitchTabActive
                ? this.getLocations(0, this.paginator.pageSize, this.currentSort, true, value)
                : this.getTwitchLocations(0, this.paginator.pageSize, this.currentSort, true, value);
                this.searchCriteria = value;
        });
    }

    public ngOnInit() {
        this.getLocations(0, this.pageSizeOptions[0]);
    }

    public expandCollapse(location: EventLocationModel) {
        this.expandedLocation = this.expandedLocation === location ? undefined : location;
    }

    public expandTwitchCollapse(location: TwitchLocation) {
        this.twitchExpandedLocation = this.twitchExpandedLocation === location ? undefined : location;
        this.twitchSimilarLocationDataSource.data = location.similarLocations;
    }

    public onSearchInputChanged(text: string) {
        this.searchCriteriaChanged.next(text);
    }

    public handlePaginator(event: PageEvent) {
        this.getLocations(event.pageIndex, event.pageSize, this.currentSort, true, this.searchCriteria);
    }

    public getLocations(page: number, pageSize: number, orderByColumn = '', ascending = true, searchCriteria = '') {
        this.locationsSubscription = this.locationApiService.getLocations(page, pageSize, orderByColumn, ascending, searchCriteria).subscribe(
            (response: HttpResponse<EventLocationsModel>) => {
                this.locationDataSource.data = response.body.locations;
                this.paginator.length = response.body.locationsCount;
                this.dataFeeds = response.body.dataFeeds;
                this.countries = response.body.countries;
                this.sports = response.body.sports;
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public getTwitchLocations(page: number, pageSize: number, orderByColumn?: string, ascending?: boolean, searchCriteria?: string) {
        this.twitchLocationsSubscription = this.locationApiService.getTwitchLocations(page, pageSize, orderByColumn, ascending, searchCriteria).subscribe(
            (response: HttpResponse<TwitchLocationModel>) => {
                this.twitchLocationDataSource.data = response.body.locations;
                this.paginator.length = response.body.locationsCount;
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public onTwitchToggle() {
        this.isTwitchTabActive = !this.isTwitchTabActive;
        if (this.isTwitchTabActive && this.twitchLocationDataSource.data.length === 0) {
            this.getTwitchLocations(0, this.pageSizeOptions[0]);
        }
    }

    public createLocation(locationModel: EventLocationUpdateModel) {
        this.locationCreateSubscription = this.locationApiService.createLocation(locationModel).subscribe(
            (result) => {
                const createdLocation = result.body;
                createdLocation.country = this.countries.find(x => x.countryId === locationModel.countryId);
                createdLocation.dataFeed = this.dataFeeds.find(x => x.id === locationModel.dataFeedId);
                createdLocation.sport = this.sports.find(x => x.id === locationModel.sportId);

                this.locationDataSource.data = this.locationDataSource.data.concat(createdLocation);
                this.sortCustomerLocationArray();
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public updateLocation(currentLocationUpdated: EventLocationModel, locationToBeSaved: EventLocationUpdateModel) {
        this.locationUpdateSubscription = this.locationApiService.updateLocation(locationToBeSaved).subscribe(
            () => {
                this.locationDataSource.data = this.locationDataSource.data
                    .filter((item) => item.locationId !== currentLocationUpdated.locationId)
                    .concat(currentLocationUpdated);

                this.sortCustomerLocationArray();
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
            }
        );
    }

    public updateTwitchLocation(currentLocationModel: TwitchLocation, locationToBeSaved: TwitchLocation) {
        this.twitchLocationUpdateSubscription = this.locationApiService.twitchUpdateLocation(locationToBeSaved).subscribe(() => {
            this.twitchLocationDataSource.data = this.twitchLocationDataSource.data
                .filter((item) => item.locationId !== currentLocationModel.locationId)
                .concat(currentLocationModel);
        });
    }

    public sortCustomerLocationArray() {
        this.locationDataSource.data = this.locationDataSource.data.sort((a, b) => this.compareItems(a, b));

        if (this.currentDirection === 'desc') {
            this.locationDataSource.data = this.locationDataSource.data.reverse();
        }
    }

    public compareItems(a: EventLocationModel, b: EventLocationModel): number {
        const sortColumn = this.currentSort || 'locationId';

        return a[sortColumn] < b[sortColumn] ? -1 : a[sortColumn] > b[sortColumn] ? 1 : 0;
    }

    public openAddDialog() {
        const dialog = this.dialog.open(LocationDialogComponent, {
            width: '500px',
            data: this.getLocationDialogData(this.draftLocation, DialogType.Add)
        });

        this.dialogSubscription = dialog.afterClosed().subscribe((result: LocationDialogData) => {
            if (result && result.isChangesConfirmed) {
                this.draftLocation = result.currentLocation;
                this.createLocation(result.updatedModel);
            }
        });
    }

    public openEditDialog(event: any, locationId: number) {
        event.stopPropagation();

        this.locationsSubscription = this.locationApiService.getLocation(locationId).subscribe((response: HttpResponse<EventLocationModel>) => {
            const dialog = this.dialog.open(LocationDialogComponent, {
                width: '500px',
                data: this.getLocationDialogData(response.body, DialogType.Edit)
            });

            this.dialogSubscription = dialog.afterClosed().subscribe((result: LocationDialogData) => {
                if (result && result.isChangesConfirmed) {
                    this.updateLocation(result.currentLocation, result.updatedModel);
                }
            });
        });
    }

    public openTwitchDialog(event: any, twitchLocationId: number) {
        event.stopPropagation();

        this.locationApiService.getTwitchLocation(twitchLocationId).subscribe((response: HttpResponse<TwitchLocation>) => {
            const dialog = this.twitchDialog.open(TwitchLocationDialogComponent, {
                width: '400px',
                data: {
                    currentLocation: response.body,
                    customers: response.body.availableCustomers,
                    updatedModel: {
                        locationId: response.body.locationId,
                        locationCode: response.body.locationCode,
                        locationName: response.body.locationName,
                        customer: response.body.customer,
                        isActive: response.body.isActive
                    },
                    availableCustomers: response.body.availableCustomers,
                },
            });

            this.twitchDialogSubscription = dialog.afterClosed().subscribe((result: TwitchLocationDialogData) => {
                if (result && result.isChangesConfirmed) {
                    this.updateTwitchLocation(result.currentLocation, result.updatedModel);
                }
            });
        });
    }

    public sortData(sort: Sort) {
        if (sort.active && sort.direction) {
            this.expandedLocation = undefined;
            const isAscDirection = sort.direction === 'asc';

            this.currentDirection = sort.direction;
            this.currentSort = sort.active;

            this.getLocations(this.paginator.pageIndex, this.paginator.pageSize, this.currentSort, isAscDirection, this.searchCriteria);
        } else {
            this.currentDirection = undefined;
            this.currentSort = undefined;
            this.getLocations(this.paginator.pageIndex, this.paginator.pageSize);
        }
    }

    public sortTwitchData(sort: Sort) {
        if (sort.active && sort.direction) {
            this.expandedLocation = undefined;
            const isAscDirection = sort.direction === 'asc';

            this.currentDirection = sort.direction;
            this.currentSort = sort.active;

            this.getTwitchLocations(this.paginator.pageIndex, this.paginator.pageSize, this.currentSort, isAscDirection, this.searchCriteria);
        } else {
            this.currentDirection = undefined;
            this.currentSort = undefined;
            this.getTwitchLocations(this.paginator.pageIndex, this.paginator.pageSize);
        }
    }

    public ngOnDestroy() {
        this.unsubscribe(this.locationCreateSubscription);
        this.unsubscribe(this.locationsSubscription);
        this.unsubscribe(this.locationUpdateSubscription);
        this.unsubscribe(this.dialogSubscription);

        this.unsubscribe(this.twitchLocationsSubscription);
        this.unsubscribe(this.twitchLocationUpdateSubscription);
        this.unsubscribe(this.twitchDialogSubscription);

        this.unsubscribe(this.dialogSubscription);
        this.unsubscribe(this.searchCriteriaChanged);
    }

    private unsubscribe(subscription: Subscription | Subject<any>) {
        if (subscription) {
            subscription.unsubscribe();
        }
    }

    private getLocationDialogData(location: EventLocationModel, dialogType: DialogType) {
        return {
            currentLocation: location,
            sports: this.sports,
            dataFeeds: this.dataFeeds,
            countries: this.countries,
            updatedModel: {
                locationId: location.locationId,
                igmLocationCode: location.igmLocationCode,
                locationName: location.locationName,
                countryId: location.country.countryId,
                dataFeedId: location.dataFeed.id,
                sportId: location.sport.id,
                ullEnabled: location.ullEnabled,
                dataFeedLocationCode: location.dataFeedLocationCode,
                dataFeedLocationName: location.dataFeedLocationName,
                dataFeedCountryCode: location.dataFeedCountryCode,
                dataFeedSportCode: location.dataFeedSportCode
            },
            dialogType
        };
    }
}
