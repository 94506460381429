import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { RawImportApiService } from './services/raw-import-api.service';
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { RawImportRequest } from './models/raw-import-request';
import { RawImportModel } from './models/common-raw-import';
import { HttpResponse } from '@angular/common/http';
import { ProviderWithDataFeedsModel } from './models/provider-with-data-feeds';
import { DataFeedBaseInfo } from 'src/app/models/common/data-feed-base-info';
import { FormControl } from '@angular/forms';
import { DataProviderService } from '../../../services/data-provider.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { CustomErrorStateMatcher } from '../../../helpers/error-state-matcher';
import { RawDataDialogComponent } from './raw-data-dialog/raw-data-dialog.component';

@Component({
    selector: 'app-raw-import',
    templateUrl: './raw-import.component.html',
    styleUrls: ['./raw-import.component.sass']
})
export class RawImportComponent implements OnInit, OnDestroy {
    @ViewChild('tablePaginator') public tablePaginator: MatPaginator;

    public displayCommonColumns: string[] = ['id', 'importDateTime', 'dataFeedId', 'rawData'];
    public displayTrniColumns: string[] = [...this.displayCommonColumns, 'operationDate', 'recordNumber', 'recordType'];
    public displayPrmColumns: string[] = [...this.displayCommonColumns, 'isDesktopEvents'];
    public displayEcsColumns: string[] = [...this.displayCommonColumns, 'date'];
    public displayImgColumns: string[] = [...this.displayCommonColumns, 'isLiveEvents'];
    public displayIasColumns: string[] = [...this.displayCommonColumns, 'date', 'meetingId', 'type'];
    public currentDisplayedColumns: string[];

    public windowWidth: number;

    public requestParams: RawImportRequest;
    public loading: boolean;

    public errorMatcher: CustomErrorStateMatcher;

    public dataSourceRawRecords = new MatTableDataSource<RawImportModel>();
    public numberOfRecords = [20, 50, 100];
    public numberOfRec = 20;
    public orderbydesc = false;

    public dataProviders: ProviderWithDataFeedsModel[];
    public dataFeeds: DataFeedBaseInfo[];
    public selectedDataFeeds: number[];
    public currentProviderName: string;

    public startImportDateControl = new FormControl();
    public endImportDateControl = new FormControl();
    public providerControl = new FormControl();

    public getDataProvidersSubscription: Subscription;
    public getRawDataSubscription: Subscription;

    constructor(
        private rawImportApiService: RawImportApiService,
        private snackBar: MatSnackBar,
        private dataProviderService: DataProviderService,
        private dialog: MatDialog
    ) { }

    @HostListener('window:resize', ['$event'])
    public onResize(event) {
        this.windowWidth = window.innerWidth;
    }

    public ngOnInit() {
        this.getDataProviders();
        this.errorMatcher = new CustomErrorStateMatcher();
        this.dataSourceRawRecords.paginator = this.tablePaginator;

        this.windowWidth = window.innerWidth;

        this.setDefaultDateFilters();
    }

    public getDataProviders() {
        this.loading = true;
        this.getDataProvidersSubscription = this.dataProviderService.getDataProvidersWithRelatedDataFeeds().subscribe(
            (response: HttpResponse<ProviderWithDataFeedsModel[]>) => {
                this.dataProviders = response.body;
                this.loading = false;
            },
            (error) => this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' })
        );
    }

    public getRawData() {
        this.fillRequestParams();
        const currentProvider = this.findProvider(this.providerControl.value);

        this.loading = true;
        this.getRawDataSubscription = this.rawImportApiService.getRawImportData(this.requestParams).subscribe(
            (response: HttpResponse<RawImportModel[]>) => {
                this.dataSourceRawRecords.data = response.body;
                this.loading = false;
                this.currentProviderName = currentProvider.dataProviderEnumCode;
                this.changeCurrentColumns(currentProvider.dataProviderEnumCode);
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
                this.loading = false;
            }
        );
    }

    public fillRequestParams() {
        this.requestParams = {
            dataProviderEnumCode: this.findProvider(this.providerControl.value).dataProviderEnumCode,
            startImportDate: this.startImportDateControl.value.toLocaleDateString("en-US"),
            endImportDate: this.endImportDateControl.value.toLocaleDateString("en-US"),
            dataFeedIds: this.selectedDataFeeds || [],
            amount: this.numberOfRec,
            orderbydesc: this.orderbydesc
        };
    }

    public isValidationSuccess(): boolean {
        return this.providerControl.valid && this.startImportDateControl.valid && this.endImportDateControl.valid && !this.loading;
    }

    public isColumnIncluded = (columnName: string) => _.includes(this.currentDisplayedColumns, columnName);

    public fillDatafeedArray() {
        this.selectedDataFeeds = [];
        const provider = this.findProvider(this.providerControl.value);

        if (provider) {
            this.dataFeeds = provider.dataFeedBaseInfoModels;
        }
    }

    public handleLongString(rawData: string) {
        return rawData.length > this.windowWidth / 10 ? `${rawData.slice(0, this.windowWidth / 10)}...` : rawData;
    }

    public createDialog(rawRecord: string, recId: number) {
        return this.dialog.open(RawDataDialogComponent, {
            data: { rawData: rawRecord, recordId: recId }
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe(this.getDataProvidersSubscription);
        this.unsubscribe(this.getRawDataSubscription);
    }

    private unsubscribe(subscription: Subscription) {
        if (subscription) {
            subscription.unsubscribe();
        }
    }

    private findProvider = (providerId: number): ProviderWithDataFeedsModel => _.find(this.dataProviders, (dP) => dP.dataProviderId === providerId);

    private changeCurrentColumns(providerEnumCode: string) {
        switch (providerEnumCode) {
            case 'TRNI':
                this.currentDisplayedColumns = this.displayTrniColumns;
                break;
            case 'IAS':
                this.currentDisplayedColumns = this.displayIasColumns;
                break;
            case 'ECS':
                this.currentDisplayedColumns = this.displayEcsColumns;
                break;
            case 'PRM':
                this.currentDisplayedColumns = this.displayPrmColumns;
                break;
            case 'IMG':
                this.currentDisplayedColumns = this.displayImgColumns;
                break;
            default:
                this.currentDisplayedColumns = this.displayCommonColumns;
                break;
        }
    }

    private setDefaultDateFilters() {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 14);

        this.startImportDateControl.setValue(startDate);
        this.endImportDateControl.setValue(endDate);
    }
}
