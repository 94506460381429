import { Injectable } from '@angular/core';
import { ProviderDataFeedApiService } from '../services/data-feed-api.service';
import { DataFeedInfo } from '../models/data-feeds';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProviderDataFeedsService {
    constructor(private dataFeedApiService: ProviderDataFeedApiService) {}

    public getDataFeedInfo(): Observable<DataFeedInfo[]> {
        return this.dataFeedApiService.getDataFeeds().pipe(
            map(
                (response: any) => {
                    if (response.ok) {
                        console.log(`Status: ${response.status}, StatusText ${response.statusText}`);

                        return response.body;
                    } else {
                        return null;
                    }
                },
                (error) => {
                    console.log(`Error: ${error}`);

                    return null;
                }
            )
        );
    }
}
