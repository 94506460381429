import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInternalService } from 'src/app/services/http.internal.service';
import { EventLocationsModel } from '../models/event-locations';
import { EventLocationUpdateModel } from '../models/event-location-update';
import { EventLocationModel } from '../models/event-location';
import { TwitchLocationModel } from '../models/twitch-locations';
import { TwitchLocation } from '../models/twitch-location';

@Injectable()
export class EventLocationApiService {
    public routePrefix = '/api/eventLocation';

    constructor(private httpService: HttpInternalService) {}

    public createLocation(locationModel: EventLocationUpdateModel): Observable<HttpResponse<EventLocationModel>> {
        return this.httpService.postFullRequest<EventLocationModel>(`${this.routePrefix}`, locationModel);
    }

    public getLocation(locationId: number): Observable<HttpResponse<EventLocationModel>> {
        return this.httpService.getFullRequest<EventLocationModel>(`${this.routePrefix}`, { locationId });
    }

    public getTwitchLocation(locationId: number): Observable<HttpResponse<TwitchLocation>> {
        return this.httpService.getFullRequest<TwitchLocation>(`/api/twitchLocations/${locationId}`);
    }

    public getLocations(
        page?: number,
        pageSize?: number,
        orderByColumn?: string,
        ascending?: boolean,
        searchCriteria = ''
    ): Observable<HttpResponse<EventLocationsModel>> {
        return this.httpService.getFullRequest<EventLocationsModel>(`${this.routePrefix}/all`, {
            page,
            pageSize,
            orderByColumn,
            ascending,
            searchCriteria,
        });
    }

    public getTwitchLocations(
        page?: number,
        pageSize?: number,
        orderByColumn?: string,
        ascending?: boolean,
        searchCriteria = ''
    ): Observable<HttpResponse<TwitchLocationModel>> {
        return this.httpService.getFullRequest<TwitchLocationModel>(`/api/twitchLocations/all`, {
            page,
            pageSize,
            orderByColumn,
            ascending,
            searchCriteria,
        });
    }

    public updateLocation(locationModel: EventLocationUpdateModel): Observable<HttpResponse<Response>> {
        return this.httpService.putFullRequest<Response>(`${this.routePrefix}`, locationModel);
    }

    public twitchUpdateLocation(locationModel: TwitchLocation): Observable<HttpResponse<Response>> {
        return this.httpService.postFullRequest<Response>(`/api/twitchLocations/update`, locationModel);
    }

    public downloadLocationsFile(): Observable<HttpResponse<Blob>> {
        return this.httpService.downloadFile(`${this.routePrefix}/download`);
    }
}
