import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { DetailedEvent } from 'src/app/models/common/detailed-event';
import { LocationBaseInfo } from 'src/app/models/common/location-base-info';
import { LocationBaseInfos } from 'src/app/models/common/location-base-infos';
import { CustomerLocationsService } from 'src/app/pages/mcr-monitoring/customer-locations/services/customer-locations.service';
import { FiltersDialogData } from 'src/app/pages/mcr-monitoring/mcr-schedule/models/filters-dialog-data';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Country } from '../../../../../models/common/country';

interface MultipleEventsCreationProps {
    isChangesConfirmed: boolean;
    objects: DetailedEvent[];
}

@Component({
    selector: 'app-multiple-events-creation-dialog',
    templateUrl: './multiple-events-creation-dialog.component.html',
    styleUrls: ['./multiple-events-creation-dialog.component.sass']
})

export class MultipleEventsCreationDialogComponent implements OnInit {

    public countriesFilters = new FormControl([]);

    public eventsCount: FormControl = new FormControl();
    public eventIds: FormControl[] = new Array(this.eventsCount).map((x) => new FormControl());
    public search: FormControl = new FormControl();
    public eventNumber: FormControl = new FormControl(1);
    public title: FormControl = new FormControl();

    public forms: FormGroup[] = new Array(this.eventsCount).map((x) => this.getDefaultDates());

    public locationFilter: FormControl = new FormControl();
    public dataFeedFilter = new FormControl();
    public countryFilter = new FormControl();
    public sportsFilter = new FormControl();
    public competitionFilter = new FormControl();
    public statusFilter = new FormControl();

    public statuses = ['Closed', 'Open', 'Pending', 'Void'];
    public locations: LocationBaseInfo[] = [];
    public locationsCount: number;
    public isAutoUpdating: boolean;
    public searchWord = '';

    public currentPage = 0;
    public sub: Subscription = new Subscription();

    private countries: Country[];
    public filteredCountries: Country[];
    public countriesSearchCriteria: string;

    constructor(
        private locationService: CustomerLocationsService,
        public dialogRef: MatDialogRef<MultipleEventsCreationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MultipleEventsCreationProps) {
        this.eventsCount.setValue(1);
    }

    public ngOnInit() {
        this.getLocations();
        this.initializeSearch();
        this.eventsCount.valueChanges.subscribe((updatedValue) => {
            if (!updatedValue) updatedValue = 1;
            const array = Array.from({ length: updatedValue });
            const valuesToAdd = updatedValue - this.forms.length;

            this.forms = valuesToAdd >= 0
                ? [...this.forms, ...Array.from({ length: valuesToAdd }).map((x) => this.getDefaultDates())]
                : this.removeLast(this.forms, valuesToAdd);
            this.eventIds = valuesToAdd >= 0
                ? [...this.eventIds, ...Array.from({ length: valuesToAdd }).map((x) => new FormControl())]
                : this.removeLast(this.eventIds, valuesToAdd);

            this.forms[0].controls.startDate.valueChanges.subscribe((value) => {
                if (this.forms[0].controls.startDate.value) {
                    this.eventIds.forEach((x, y) => x.setValue(
                        this.generateEventId(this.locationFilter.value.iGMLocationCode, value, y)));
                }
            });

            array.forEach((x, i) => {
                this.forms[i].controls.startDate.valueChanges.subscribe((value) => {
                    if (this.forms[i].controls.startDate.value && this.locationFilter.value) {
                        const date = this.forms[0].controls.startDate.value || value;
                        this.eventIds[i].setValue(
                            this.generateEventId(this.locationFilter.value.iGMLocationCode, date, i));
                    }
                });

                this.locationFilter.valueChanges.subscribe((value: LocationBaseInfo) => {
                    if (this.forms[i].controls.startDate.value && this.locationFilter.value) {
                        this.eventIds[i].setValue(
                            this.generateEventId(value.iGMLocationCode, this.forms[0].controls.startDate.value, i));
                    }
                });
            });

            this.autogenerateEventsDate();
        });

        this.forms[0].controls.startDate.valueChanges.subscribe(() => {
            this.autogenerateEventsDate();
        });

        this.forms[0].controls.startTime.valueChanges.subscribe(() => {
            this.autogenerateEventsDate();
        });

        this.forms[0].controls.runningTime.valueChanges.subscribe(() => {
            this.autogenerateEventsDate();
        });

        this.statusFilter = new FormControl(this.statuses[2]);
    }

    public autogenerateEventsDate() {
        if (this.eventsCount.value > 1 && this.forms[0].controls.startDate.value && this.forms[0].controls.startTime.value && this.forms[0].controls.runningTime.value) {

            const timeParts = this.forms[0].controls.startTime.value.split(":");

            const hours: number = +timeParts[0];
            const minutes: number = +timeParts[1];

            const totalSeconds = (hours * 60 * 60) + minutes * 60;

            const runningTimeSeconds = this.forms[0].controls.runningTime.value * 60;

            let startDate = new Date(this.forms[0].controls.startDate.value);

            startDate.setSeconds(totalSeconds);

            for (let i = 1; i < this.eventsCount.value; i++) {

                const date = new Date(startDate);

                date.setSeconds(date.getSeconds() + runningTimeSeconds);

                this.forms[i].controls.startDate.setValue(date);

                const timeStrings = date.toTimeString().split(":", 2);

                this.forms[i].controls.startTime.setValue(timeStrings[0] + ":" + timeStrings[1]);

                startDate = date;
            }
        }
    }

    public showSelectedsOnTop() {
        const alreadySelectedCountries = this.countriesFilters.value;
        this.filteredCountries = alreadySelectedCountries.concat(this.filteredCountries.filter(x => !alreadySelectedCountries.includes(x)));
    }

    public selectAllCountries() {
        this.countriesFilters.setValue(this.countries);
    }

    public deselectAllCountries() {
        this.countriesFilters.setValue([]);
    }

    public handleCountriesSearch(text: string) {
        const newFilteredCountries = this.countries.filter(x => x.alpha2Code.toLowerCase().includes(text.toLowerCase())
            || x.englishName.toLowerCase().includes(text.toLowerCase()));
        const alreadySelectedCountries = this.countriesFilters.value;
        this.filteredCountries = alreadySelectedCountries.concat(newFilteredCountries.filter(x => !alreadySelectedCountries.includes(x)));
    }

    public getDefaultDates() {
        return new FormGroup({
            startDate: new FormControl(),
            startTime: new FormControl(),
            actualStartTime: new FormControl(),
            actualStartDate: new FormControl(),
            runningTime: new FormControl(0, [Validators.min(0), Validators.max(9999999)])
        });
    }

    public removeLast(arr: any[], n: number) {
        arr.splice(arr.length + n, arr.length);
        return arr;
    }

    public ngOnDestroy() {
        this.sub.unsubscribe();
    }

    public generateEventId(igmLocationCode: string, date: Date, index: number): string {
        return `${igmLocationCode}${moment(date).format('YYYYMMDD')}-${index + 1}`;
    }

    public onCancel() {
        this.dialogRef.close(false);
    }

    public onYes() {
        this.forms.forEach((x, i) => {
            const { actualStartDate, actualStartTime, startDate, startTime, runningTime } = x.controls;

            this.data.isChangesConfirmed = true;
            this.data.objects.push({
                eventId: this.eventIds[i].value,
                startTime: this.getDateUtcInCorrectFormat(startDate.value, startTime.value),
                actualStartTime: actualStartDate.value
                    ? this.getDateUtcInCorrectFormat(actualStartDate.value, actualStartTime.value)
                    : undefined,
                estimatedEndTime: undefined,
                runningTime: runningTime.value,
                eventStatusCode: this.statusFilter.value,
                eventNumber: this.eventNumber.value + i,
                title: this.title.value + ' - Race ' + (i + 1),
                isAutoUpdating: this.isAutoUpdating,
                locationId: this.locationFilter.value.locationID,
                igmLocationCode: this.locationFilter.value.IGMLocationCode,
                competitionId: 2,
                dateModified: new Date(),
                location: '',
                geoBlockCountries: this.countriesFilters.value.map(x => x.alpha2Code).join(' '),
                locationGeoBlockCountries: null
            });
        });
        this.data.objects.shift();
        this.dialogRef.close(_.cloneDeep(this.data));
    }

    public isFormValid() {
        return this.forms[0].controls.startDate.value
            && this.eventIds[0].value
            && this.statusFilter.value
            && this.eventNumber.value
            && this.title.value
            && this.locationFilter.value.locationID
            && !this.forms.some(x => x.controls.startDate.invalid)
            && !this.forms.some(x => x.controls.startTime.invalid)
            && !this.forms.some(x => x.controls.runningTime.invalid);
    }

    public loadNextLocations() {
        this.getLocations(this.currentPage, undefined, this.searchWord);
        this.currentPage += 1;
    }

    public compare(location1: LocationBaseInfo, location2: LocationBaseInfo) {
        return location1 && location2 ? location1.locationID === location2.locationID : location1 === location2;
    }

    public compareString(str1: string, str2: string) {
        return str1 && str2 ? str1 === str2 : false;
    }

    private getDateUtcInCorrectFormat(dateValue: Date, timeValue: any) {
        let dateTime = moment(dateValue).utc(true);
        const [hoursStart, minutesStart] = timeValue ? timeValue.split(':') : [0, 0];

        dateTime = dateTime.hours(hoursStart);
        dateTime = dateTime.minutes(minutesStart);

        return dateTime.toDate();
    }

    private initializeSearch() {
        this.sub = this.search.valueChanges.pipe(
            debounceTime(1000),
            switchMap((value) => {
                this.searchWord = value;
                this.locations = [];
                this.getLocations(undefined, undefined, this.searchWord);
                return value;
            }
            )
        )
            .subscribe();
    }

    private getLocations(pageIndex = 0, pageSize = 100, searchWord = '') {
        this.locationService
            .getEventLocations(pageIndex, pageSize, searchWord, false)
            .subscribe((data: LocationBaseInfos) => {
                this.locationsCount = data.count;
                this.locations = this.locations.concat(data.locationBaseInfos.filter((x) => x.name !== this.data.objects[0].location));
                this.filteredCountries = data.countries;
                this.countries = data.countries;
            });
    }
}
