import { Component, OnInit } from '@angular/core';
import { VersionInfoService } from './services/version-info.service';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'version-info',
    templateUrl: './version-info.component.html',
    styleUrls: ['./version-info.component.sass']
})
export class VersionInfoComponent implements OnInit {
    public playerDevVersion = 'Loading...';
    public playerStagingVersion = 'Loading...';
    public playerTestVersion = 'Loading...';
    public playerLiveVersion = 'Loading...';

    public readerPushEndpointDevVersion = 'Loading...';
    public readerPushEndpointTestVersion = 'Loading...';
    public readerPushEndpointLiveVersion = 'Loading...';

    public legacyMcrDevVersion = 'Loading...';
    public legacyMcrTestVersion = 'Loading...';
    public legacyMcrLiveVersion = 'Loading...';

    public mcrDevVersion = 'Loading...';
    public mcrTestVersion = 'Loading...';
    public mcrLiveVersion = 'Loading...';

    public rcsTraderDevVersion = 'Loading...';
    public rcsTraderStagingVersion = 'Loading...';
    public rcsTraderTestVersion = 'Loading...';
    public rcsTraderLiveVersion = 'Loading...';

    public rcsSignalHubDevVersion = 'Loading...';
    public rcsSignalHubTestVersion = 'Loading...';
    public rcsSignalHubLiveVersion = 'Loading...';

    constructor(private versionInfoService: VersionInfoService) {}

    public ngOnInit() {
        // Forgive me for such copy-paste...

        this.versionInfoService.getPlayerDevVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.playerDevVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.playerDevVersion = 'Not OK';
                }
            },
            (error) => {
                this.playerDevVersion = 'Error';
            }
        );

        this.versionInfoService.getPlayerStagingVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.playerStagingVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.playerStagingVersion = 'Not OK';
                }
            },
            (error) => {
                this.playerStagingVersion = 'Error';
            }
        );

        this.versionInfoService.getPlayerTestVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.playerTestVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.playerTestVersion = 'Not OK';
                }
            },
            (error) => {
                this.playerTestVersion = 'Error';
            }
        );

        this.versionInfoService.getPlayerLiveVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.playerLiveVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.playerLiveVersion = 'Not OK';
                }
            },
            (error) => {
                this.playerLiveVersion = 'Error';
            }
        );

        this.versionInfoService.getReaderPushEndpointDevVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.readerPushEndpointDevVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.readerPushEndpointDevVersion = 'Not OK';
                }
            },
            (error) => {
                this.readerPushEndpointDevVersion = 'Error';
            }
        );

        this.versionInfoService.getReaderPushEndpointTestVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.readerPushEndpointTestVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.readerPushEndpointTestVersion = 'Not OK';
                }
            },
            (error) => {
                this.readerPushEndpointTestVersion = 'Error';
            }
        );

        this.versionInfoService.getReaderPushEndpointLiveVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.readerPushEndpointLiveVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.readerPushEndpointLiveVersion = 'Not OK';
                }
            },
            (error) => {
                this.readerPushEndpointLiveVersion = 'Error';
            }
        );

        this.versionInfoService.getLegacyMcrDevVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.legacyMcrDevVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.legacyMcrDevVersion = 'Not OK';
                }
            },
            (error) => {
                this.legacyMcrDevVersion = 'Error';
            }
        );

        this.versionInfoService.getLegacyMcrTestVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.legacyMcrTestVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.legacyMcrTestVersion = 'Not OK';
                }
            },
            (error) => {
                this.legacyMcrTestVersion = 'Error';
            }
        );

        this.versionInfoService.getLegacyMcrLiveVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.legacyMcrLiveVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.legacyMcrLiveVersion = 'Not OK';
                }
            },
            (error) => {
                this.legacyMcrLiveVersion = 'Error';
            }
        );

        this.versionInfoService.getMcrDevVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.mcrDevVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.mcrDevVersion = 'Not OK';
                }
            },
            (error) => {
                this.mcrDevVersion = 'Error';
            }
        );

        this.versionInfoService.getMcrTestVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.mcrTestVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.mcrTestVersion = 'Not OK';
                }
            },
            (error) => {
                this.mcrTestVersion = 'Error';
            }
        );

        this.versionInfoService.getMcrLiveVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.mcrLiveVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.mcrLiveVersion = 'Not OK';
                }
            },
            (error) => {
                this.mcrLiveVersion = 'Error';
            }
        );

        this.versionInfoService.getRcsTraderDevVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.rcsTraderDevVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.rcsTraderDevVersion = 'Not OK';
                }
            },
            (error) => {
                this.rcsTraderDevVersion = 'Error';
            }
        );

        this.versionInfoService.getRcsTraderStagingVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.rcsTraderStagingVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.rcsTraderStagingVersion = 'Not OK';
                }
            },
            (error) => {
                this.rcsTraderStagingVersion = 'Error';
            }
        );

        this.versionInfoService.getRcsTraderTestVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.rcsTraderTestVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.rcsTraderTestVersion = 'Not OK';
                }
            },
            (error) => {
                this.rcsTraderTestVersion = 'Error';
            }
        );

        this.versionInfoService.getRcsTraderLiveVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.rcsTraderLiveVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.rcsTraderLiveVersion = 'Not OK';
                }
            },
            (error) => {
                this.rcsTraderLiveVersion = 'Error';
            }
        );

        this.versionInfoService.getRcsSignalHubDevVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.rcsSignalHubDevVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.rcsSignalHubDevVersion = 'Not OK';
                }
            },
            (error) => {
                this.rcsSignalHubDevVersion = 'Error';
            }
        );

        this.versionInfoService.getRcsSignalHubTestVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.rcsSignalHubTestVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.rcsSignalHubTestVersion = 'Not OK';
                }
            },
            (error) => {
                this.rcsSignalHubTestVersion = 'Error';
            }
        );

        this.versionInfoService.getRcsSignalHubLiveVersion().subscribe(
            (response: HttpResponse<string>) => {
                if (response.ok) {
                    this.rcsSignalHubLiveVersion = VersionInfoComponent.getVersion(response.body);
                } else {
                    this.rcsSignalHubLiveVersion = 'Not OK';
                }
            },
            (error) => {
                this.rcsSignalHubLiveVersion = 'Error';
            }
        );
    }

    public getState(ver: string): string {
        if (ver === 'Error' || ver === 'Not OK' || ver === 'N/A') {
            return 'error';
        }

        if (ver === 'Loading...') {
            return 'loading';
        }

        return 'success';
    }

    // tslint:disable-next-line:member-ordering
    private static getVersion(response: string): string {
        if (!response || response === '' || response === '1.0.0.0 (0)') {
            return 'N/A';
        }

        return response;
    }
}
