import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { CustomerDomainDialogData } from '../models/customer-domain-dialog-data';
import { CustomerBaseInfo } from '../../../../models/common/customer-base-info';

@Component({
    selector: 'app-add-edit-customer-domain-dialog',
    templateUrl: './add-edit-customer-domain-dialog.component.html',
    styleUrls: ['./add-edit-customer-domain-dialog.component.sass']
})
export class AddEditCustomerDomainDialogComponent {
    selectedCustomer: CustomerBaseInfo;

    constructor(
        public dialogRef: MatDialogRef<AddEditCustomerDomainDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CustomerDomainDialogData
    ) {
        this.selectedCustomer = _.cloneDeep(data.customerDomain.customer);
    }

    onNo(): void {
        this.data.isChangesConfirmed = false;
        this.dialogRef.close(false);
    }

    onYes(): void {
        this.data.isChangesConfirmed = true;
        this.data.customerDomain.customer = _.clone(this.selectedCustomer);
        this.dialogRef.close(_.cloneDeep(this.data));
    }

    comparing(customer1: CustomerBaseInfo, customer2: CustomerBaseInfo) {
        return customer1 && customer2 ? customer1.customerId === customer2.customerId : customer1 === customer2;
    }

    selec() {
        this.selectedCustomer;
    }

    public isFormValid() {
        return this.data.customerDomain.customerIP &&
            this.selectedCustomer;
    }
}
