import { Injectable } from '@angular/core';
import { UserDatafeedsApiService } from './user-datafeeds-api.service';
import { NotificationMessage } from '../../../../models/notification/notificationMessage';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationType } from '../../../../models/notification/notificationType';
import { HttpResponse } from '@angular/common/http';
import { UserDataFeeds } from '../models/user-data-feeds';
import { CustomerUsers } from '../models/customer-users';
import { map } from 'rxjs/operators';
import { NotificationSnackBarObserver } from '../../../../services/notification-snack-bar-observer';

@Injectable()
export class UserDatafeedsService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);

    constructor(private userDatafeedApiService: UserDatafeedsApiService, private notificationObserver: NotificationSnackBarObserver) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getAllUsersDatafeeds(): Observable<CustomerUsers[]> {
        return this.userDatafeedApiService.getAllUsersDatafeeds().pipe(
            map(
                (response) => {
                    return response.body as CustomerUsers[];
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject
                        .next(new NotificationMessage('User DataFeeds were not updated', NotificationType.Error));
                }
            )
        );
    }

    public updateUserDataFeeds(userDataFeeds: UserDataFeeds) {
        return this.userDatafeedApiService.updateUserDataFeeds(userDataFeeds).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    if (response.body === true) {
                        this.notificationSubject
                            .next(new NotificationMessage('User DataFeeds were updated', NotificationType.Other));

                        return true;
                    } else {
                        this.notificationSubject
                            .next(new NotificationMessage('User DataFeeds were not updated', NotificationType.Error));

                        return false;
                    }
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject
                        .next(new NotificationMessage('User DataFeeds were not updated', NotificationType.Error));
                    return false;
                }
            )
        );
    }
}
