import { DataFeedBaseInfo } from 'src/app/models/common/data-feed-base-info';
import { Sport } from 'src/app/models/common/sport';
import { Country } from 'src/app/models/common/country';

export class EventLocationModel {
    public locationId: number;
    public igmLocationCode: string;
    public locationName: string;
    public country: Country;
    public dataFeed: DataFeedBaseInfo;
    public dataFeedLocationCode: string;
    public dataFeedLocationName: string;
    public ullEnabled: boolean;
    public dataFeedCountryCode: string;
    public dataFeedSportCode: string;
    public sport: Sport;
    public geoBlockCountries: string;
}
