import { SignalRService } from './../../../services/signalr.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Event } from './models/event';
import { EventStream } from './models/event-stream';
import { MatPaginator, MatTableDataSource, MatSnackBar, Sort } from '@angular/material';
import { HttpResponse } from '@angular/common/http';
import { CustomerService } from '../../customer/customer/services/customer.service';
import { FormControl } from '@angular/forms';
import { CustomerWithUid } from '../../../models/common/customer-with-uid';
import { EventList } from './models/event-list';
import { RestScheduleService } from './services/rest-schedule.service';
import { StreamApiService } from '../stream/services/stream-api.service';
import { EventStreamList } from './models/event-stream-list';
import { Observable, Subscription } from 'rxjs';
import { TimeConverterService } from '../../../services/time.converter.service';
import { PageEvent } from '@angular/material';
import { UpdatedStatus } from '../../mcr-monitoring/mcr-schedule/models/updated-status';
import { StreamLinkComponent } from 'src/app/components/stream-link/stream-link.component';

@Component({
    selector: 'app-test-web-services',
    templateUrl: './test-web-services.component.html',
    styleUrls: ['./test-web-services.component.sass']
})
export class TestWebServicesComponent implements OnInit, OnDestroy {

    public customers: CustomerWithUid[];
    public date = new FormControl(new Date());

    public currentCustomerUid: string;
    public currentEventId: string;
    public currentCustomerName: string;
    public currentStreamName: string;

    public getEventsSubscription: Subscription;
    public getOpenEventsSubscription: Subscription;
    public getStreamsSubscription: Subscription;
    public getStreamLinkSubscription: Subscription;

    @ViewChild('openEventsPaginator') public openEventsPaginator: MatPaginator;
    @ViewChild('eventsPaginator') public eventsPaginator: MatPaginator;
    @ViewChild('streamLinkChild') public streamLinkChild: StreamLinkComponent;
    public pageSizeOptions = [10, 15, 25];
    public dataSourceEvents = new MatTableDataSource<Event>();
    public dataSourceOpenEvents = new MatTableDataSource<Event>();
    public dataSourceStreams = new MatTableDataSource<EventStream>();

    public displayedColumnsForEvents: string[] = [
        'Action',
        'EventId',
        'LocationCode',
        'LocationName',
        'SportCode',
        'EventStatus',
        'EventNumber',
        'Title',
        'StartTime',
        'EstimatedEndTime'
    ];

    public displayedColumnsForOpenEvents: string[] = [
        'Action',
        'EventId',
        'LocationCode',
        'LocationName',
        'SportCode',
        'EventNumber',
        'Title',
        'StartTime',
        'EstimatedEndTime'
    ];

    public displayedColumnsForStreams: string[] = [
        'Action',
        'StreamName',
        'StreamType',
        'Description',
        'GeoCountryAllow',
        'GeoCountryBlock',
        'PlayerWidth',
        'PlayerHeight',
        'VideoBitRateKbps',
        'AudioBitRateKbps',
        'StreamStretching',
        'StreamWidth',
        'StreamHeight'
    ];

    constructor(
        private restScheduleService: RestScheduleService,
        private streamApiService: StreamApiService,
        private customerService: CustomerService,
        private snackBar: MatSnackBar,
        private timeConverter: TimeConverterService,
        private signalRService: SignalRService,
    ) {}

    public ngOnInit() {
        this.getCustomers();
        this.dataSourceOpenEvents.paginator = this.openEventsPaginator;
    }

    public ngOnDestroy() {
        this.signalRService.disconnect();
        this.unsubscribe(this.getEventsSubscription);
        this.unsubscribe(this.getOpenEventsSubscription);
        this.unsubscribe(this.getStreamsSubscription);
        this.unsubscribe(this.getStreamLinkSubscription);
    }

    public getCustomers() {
        this.customerService.getCustomersWithUid().subscribe((customersWithUid: CustomerWithUid[]) => {
            if (customersWithUid) {
                this.customers = customersWithUid;
                this.currentCustomerUid = this.customers[0].customerUid;
                this.currentCustomerName = this.customers[0].customerName;
                this._getEvents(this.customers[0].customerUid, this.date.value, this.restScheduleService);

                this._getOpenEvents(this.customers[0].customerUid, this.restScheduleService);
            }
        });
    }

    public handleGetEventsButtonClick() {
        this._getEvents(this.currentCustomerUid, this.date.value, this.restScheduleService);
        this._getOpenEvents(this.currentCustomerUid, this.restScheduleService);
        this.currentCustomerName = this.customers.find((x) => x.customerUid === this.currentCustomerUid).customerName;

        this.dataSourceStreams.data = [];
    }

    public handleGetStreamsButtonClick(eventId: string, element: any) {
        this._getStreams(this.currentCustomerUid, eventId);

        this.currentEventId = eventId;
        this.currentStreamName = '';

        element.scrollIntoView(false);
    }

    public handleGenerateStreamLinkButtonClick(streamName: string) {
        this.currentStreamName = streamName;

        this.streamLinkChild.getStreamLink({
            customerUid: this.currentCustomerUid,
            streamName: this.currentStreamName,
            eventId: this.currentEventId,
            redirectUrl: window.location.href,
            userIp: '10.10.10.10',
            userId: 'IGM-TEST'
        });
    }

    public getStringFromDate = (date: Date): string => !date ? 'Empty' : this.timeConverter.getDateWithTime(new Date(date));

    public sortEventsData(sort: Sort) {
        let isAscDirection = true;
        let column = 'EventId';

        if (sort.active && sort.direction) {
            isAscDirection = sort.direction === 'asc';
            column = sort.active;
        }

        this._getEvents(
            this.currentCustomerUid,
            this.date.value,
            this.restScheduleService,
            this.eventsPaginator.pageIndex,
            this.eventsPaginator.pageSize,
            column,
            isAscDirection
        );
    }

    public sortOpenEventsData(sort: Sort) {
        let isAscDirection = true;
        let column = 'EventId';

        if (sort.active && sort.direction) {
            isAscDirection = sort.direction === 'asc';
            column = sort.active;
        }

        this.dataSourceOpenEvents.data = this.dataSourceOpenEvents.data.sort((a, b) => {
            switch (column) {
                case 'EventId':
                    return this._compareString(a.eventId, b.eventId, isAscDirection);
                case 'LocationCode':
                    return this._compareString(a.locationCode, b.locationCode, isAscDirection);
                case 'LocationName':
                    return this._compareString(a.locationName, b.locationName, isAscDirection);
                case 'EventStatus':
                    return this._compareString(a.eventStatusCode, b.eventStatusCode, isAscDirection);
                case 'EventNumber':
                    return this._compareNumber(a.eventNumber, b.eventNumber, isAscDirection);
                case 'StartTime':
                    return this._compareString(a.startTime.toString(), b.startTime.toString(), isAscDirection);
                default:
                    return 0;
            }
        });
    }

    public handleRefresh() {
        this._getOpenEvents(this.currentCustomerUid, this.restScheduleService);
    }

    public handleRefreshAll() {
        this._getEvents(this.currentCustomerUid, this.date.value, this.restScheduleService);
    }

    public changeEventsPage(event: PageEvent) {
        this._getEvents(this.currentCustomerUid, this.date.value, this.restScheduleService, event.pageIndex, event.pageSize);
    }

    public onError(error: string) {
        this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
    }

    public changeCountriesColumnsClass(element: HTMLDivElement, elementClass: string) {
        return element.classList.contains(elementClass)
            ? element.classList.remove(elementClass)
            : element.classList.add(elementClass);
    }

    private unsubscribe(subscription: Subscription) {
        if (subscription) {
            subscription.unsubscribe();
        }
    }

    private _handleStatusesUpdate(data: UpdatedStatus[]): void {
        if (!data || data.length === 0) {
            console.log('Empty data.');
            return;
        }

        const eventsIds = this.dataSourceEvents.data.map((x) => x.eventId);
        const openEventsIds = this.dataSourceOpenEvents.data.map((x) => x.eventId);

        data.forEach((item) => {
            if (eventsIds.includes(item.eventId)) {
                this.dataSourceEvents.data.find((x) => x.eventId === item.eventId).eventStatusCode = item.status;
            }
        });

        const refreshRequired = data.some((item) => item.status === 'O' && !openEventsIds.includes(item.eventId));

        if (refreshRequired) {
            return this._getOpenEvents(this.currentCustomerUid, this.restScheduleService);
        }

        const isEventOpened = (event) => !data.find((update) => update.eventId === event.eventId && update.status !== 'O');

        this.dataSourceOpenEvents.data = this.dataSourceOpenEvents.data.filter(isEventOpened);
    }

    private _getEvents(
        customerUid: string, eventDate: Date,
        service: RestScheduleService, page = 0, pageSize = 10, orderBy = 'starttime', ascending = true) {
        this.getEventsSubscription = service.getEvents(customerUid, eventDate, page, pageSize, orderBy, ascending)
            .subscribe(
                (response: HttpResponse<EventList>) => {
                    this.dataSourceEvents.data = response.body.events;
                    this.eventsPaginator.length = response.body.eventsCount;
                },
                (error) => {
                    this.onError(error);
                }
            );
    }

    private _getOpenEvents(
        customerUid: string, service: RestScheduleService, orderBy = 'starttime', ascending = true) {
        this.getOpenEventsSubscription = service.getOpenEvents(customerUid, orderBy, ascending).subscribe(
            (response: HttpResponse<EventList>) => {
                this.dataSourceOpenEvents.data = response.body.events;
            },
            (error) => {
                this.onError(error);
            }
        );
    }

    private _getStreams(customerUid: string, eventId: string) {
        this.getStreamsSubscription = this.streamApiService.getStreamsByEvent(customerUid, eventId).subscribe(
            (response: HttpResponse<EventStreamList>) => {
                this.dataSourceStreams.data = response.body.eventStreams;
            },
            (error) => {
                this.onError(error);
            }
        );
    }

    // tslint:disable-next-line:variable-name
    private _compareString = (a: string, b: string, isAsc: boolean): number => (a < b ? -1 : 1) * (isAsc ? 1 : -1);

    // tslint:disable-next-line:variable-name
    private _compareNumber = (a: number, b: number, isAsc: boolean): number => (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
