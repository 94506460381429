import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationMessage } from '../../../../models/notification/notificationMessage';
import { CountryApiService } from './country-api.service';
import { map } from 'rxjs/operators';
import { Country } from '../../../../models/common/country';
import { HttpResponse } from '@angular/common/http';
import { NotificationSnackBarObserver } from '../../../../services/notification-snack-bar-observer';
import { NotificationType } from 'src/app/models/notification/notificationType';

@Injectable({
    providedIn: 'root'
})
export class CountryService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);

    constructor(private countryApiService: CountryApiService, private notificationObserver: NotificationSnackBarObserver) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getCountries(): Observable<Country[]> {
        return this.countryApiService.getCountries().pipe(
            map(
                (response: HttpResponse<Country[]>) => {
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject
                        .next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }
}
