import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { CustomerSetting } from '../models/customer-setting';
import { CustomerSettingWithTotal } from '../models/customer-setting-with-total';

@Injectable()
export class CustomerSettingApiService {
    private routePrefix = '/api/rcs/customerSetting';

    constructor(private httpService: HttpInternalService) {}

    public getCustomersSetting(page = 0, pageIndex = 10): Observable<HttpResponse<CustomerSettingWithTotal>> {
        return this.httpService.getFullRequest(`${this.routePrefix}`, { page, pageIndex });
    }

    public getCustomerSetting(customerSettingId: number): Observable<HttpResponse<CustomerSetting>> {
        return this.httpService.getFullRequest(`${this.routePrefix}`, { customerSettingId });
    }

    public createCustomerSetting(customerSetting: CustomerSetting): Observable<HttpResponse<CustomerSetting>> {
        return this.httpService.postFullRequest(`${this.routePrefix}`, customerSetting);
    }

    public updateCustomerSetting(customerSetting: CustomerSetting): Observable<HttpResponse<any>> {
        return this.httpService.putFullRequest(`${this.routePrefix}`, customerSetting);
    }

    public deleteCustomerSetting(customerSettingId: number): Observable<HttpResponse<any>> {
        return this.httpService.deleteFullRequest(`${this.routePrefix}/${customerSettingId}`);
    }
}
