import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource, MatPaginator, Sort } from '@angular/material';
import { RcsRoleService } from './services/rcs-role.service';
import { RcsRole } from './models/rcs-role';
import { DialogConfirmationComponent } from '../../../components/dialog-confirmation/dialog-confirmation.component';
import * as _ from 'lodash';
import { DialogConfirmationData } from '../../../models/dialog/dialog-confirmation-data';
import { AddEditRcsRoleDialogComponent } from './add-edit-rcs-role-dialog/add-edit-rcs-role-dialog.component';
import { RcsRoleAddEditDialogData } from './models/rcs-role-add-edit-dialog-data';

@Component({
    selector: 'app-rcs-role',
    templateUrl: './rcs-role.component.html',
    styleUrls: ['./rcs-role.component.sass']
})
export class RcsRoleComponent implements OnInit {
    public rolesSource = new MatTableDataSource<RcsRole>();
    public displayedColumns: string[] = ['RoleName', 'Menu'];

    constructor(private roleService: RcsRoleService, public dialog: MatDialog) {}

    public ngOnInit() {
        this.getRoles();
    }

    public getRoles() {
        this.roleService
            .getRoles()
            .subscribe((value: RcsRole[]) => (this.rolesSource.data = _.orderBy(value, ['roleName'], ['asc'])));
    }

    public updateRole(updatedRole: RcsRole) {
        this.roleService.updateRole(updatedRole).subscribe((isRoleUpdated: boolean) => {
            if (isRoleUpdated) {
                this.rolesSource.data = this.rolesSource.data.map((role) => {
                    return role.roleId === updatedRole.roleId ? updatedRole : role;
                });
            }
        });
    }

    public addRole(newRole: RcsRole) {
        this.roleService.addRole(newRole).subscribe((addedRole: RcsRole) => {
            if (addedRole) {
                this.rolesSource.data = _.concat(this.rolesSource.data, addedRole);
            }
        });
    }

    public removeRole(removedRole: RcsRole) {
        this.roleService.deleteRole(removedRole).subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed) {
                this.rolesSource.data = _.remove(this.rolesSource.data, (r) => {
                    return r.roleId !== removedRole.roleId;
                });
            }
        });
    }

    public openAddDialog() {
        const dialogRemoving = this.createAddEditDialog(new RcsRole(), 'Add');

        dialogRemoving.afterClosed().subscribe((result: RcsRoleAddEditDialogData) => {
            if (result && result.isChangesConfirmed) {
                this.addRole(result.role);
            }
        });
    }

    public openEditDialog(role: RcsRole) {
        const dialogRemoving = this.createAddEditDialog(_.cloneDeep(role), 'Edit');

        dialogRemoving.afterClosed().subscribe((result: RcsRoleAddEditDialogData) => {
            if (result && result.isChangesConfirmed) {
                this.updateRole(result.role);
            }
        });
    }

    public openRemoveDialog(role: RcsRole) {
        const dialogRemoving = this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: new DialogConfirmationData(role.roleName)
        });

        dialogRemoving.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed) {
                this.removeRole(role);
            }
        });
    }

    public createAddEditDialog(role: RcsRole, dialogType: string) {
        return this.dialog.open(AddEditRcsRoleDialogComponent, {
            width: '500px',
            data: { dialogType, role, isChangesConfirmed: false }
        });
    }

    public sortData(sort: Sort) {
        this.rolesSource.data = this.rolesSource.data.sort((a, b) => {
            return this.compare(a.roleName, b.roleName, sort.direction === 'asc' || !sort.direction ? true : false);
        });
    }

    private compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
