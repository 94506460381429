import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { QueueModel } from '../models/queue-model';
import { ApiResponse } from 'src/app/models/http/apiResponse';
import { QueueDialogData } from '../models/queue-dialog-data';

@Injectable()
export class QueueMaintainApiService {
    public routePrefix = '/api/queueMaintain';

    constructor(private httpService: HttpInternalService) {}

    public getQueues(): Observable<HttpResponse<QueueModel[]>> {
        return this.httpService.getFullRequest(`${this.routePrefix}/allQueues`);
    }

    public pushToQueue(queueData: QueueDialogData): Observable<HttpResponse<ApiResponse>> {
        return this.httpService.postFullRequest(`${this.routePrefix}/pushToQueue`, queueData);
    }

    public purgeQueue(vhost: string, queueName: string): Observable<HttpResponse<ApiResponse>> {
        return this.httpService.deleteFullRequest(`${this.routePrefix}/purgeQueue`, { vhost, queueName });
    }
}
