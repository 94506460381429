import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-customer-locations',
    templateUrl: './upload-customer-location-error.component.html',
    styleUrls: ['./upload-customer-location-error.component.sass']
})

export class UploadCustomerLocationErrorComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        private snackBarRef: MatSnackBarRef<UploadCustomerLocationErrorComponent>,

    ) { }

    dismiss() {
        this.snackBarRef.dismiss();
    }
}
