import { Injectable } from '@angular/core';
import { HttpInternalService } from '../../../../services/http.internal.service';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomerUsers } from '../models/customer-users';
import { UserDataFeeds } from '../models/user-data-feeds';

@Injectable()
export class UserDatafeedsApiService {
    public routePrefix = '/api/userdatafeed/';

    constructor(private httpService: HttpInternalService) {}

    public getAllUsersDatafeeds(): Observable<HttpResponse<CustomerUsers[]>> {
        return this.httpService.getFullRequest<CustomerUsers[]>(`${this.routePrefix}`);
    }

    public updateUserDataFeeds(userDataFeeds: UserDataFeeds): Observable<HttpResponse<boolean>> {
        return this.httpService.postFullRequest<boolean>(`${this.routePrefix}`, userDataFeeds);
    }
}
