export class CustomerStream {
    public customerId: number;
    public customerName: string;
    public customerUID: string;
    public streamId: number;
    public streamName: string;
    public streamLink: string;
    public streamType: string;
    public playerType: string;
    public tokenExpire: number;
    public countryAllow: string;
    public countryDeny: string;
    public refAllow: string;
    public refDeny: string;
    public urlAllow: string;
    public uniqueStreamNameID: string;
    public cdn_Code: string;
}
