import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StreamBaseInfo } from '../models/stream-base-info';
import { HttpInternalService } from '../../../services/http.internal.service';
import { StreamWithStreamLink } from '../models/stream-with-stream-link';
import { StreamFilter } from '../models/stream-filter';

@Injectable({
    providedIn: 'root'
})
export class StreamMonitorApiService {
    public routePrefix = '/api/streamMonitor';

    constructor(private httpService: HttpInternalService) {}

    public getStreamsBaseInfo(): Observable<HttpResponse<StreamBaseInfo[]>> {
        return this.httpService.getFullRequest<StreamBaseInfo[]>(`${this.routePrefix}/streams`);
    }

    public getStreamsDetails(streamFilter: StreamFilter): Observable<HttpResponse<StreamWithStreamLink[]>> {
        return this.httpService.postFullRequest<StreamWithStreamLink[]>(`${this.routePrefix}/streamsDetails`, streamFilter);
    }
}
