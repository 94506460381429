import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StreamApiService } from './stream-api.service';
import { Stream } from '../models/stream';
import { NotificationSnackBarObserver } from 'src/app/services/notification-snack-bar-observer';
import { NotificationMessage } from 'src/app/models/notification/notificationMessage';
import { Streams } from '../models/streams';
import { HttpResponse } from '@angular/common/http';
import { NotificationType } from 'src/app/models/notification/notificationType';

@Injectable()
export class StreamService {
    private notificationSubject = new BehaviorSubject<NotificationMessage>(undefined);
    constructor(private streamApiService: StreamApiService, private notificationObserver: NotificationSnackBarObserver) {
        this.notificationSubject.subscribe(this.notificationObserver);
    }

    public getAll(page = 0, pagesize = 10, searchWord?: string): Observable<Streams> {
        return this.streamApiService.getStreams(page, pagesize, searchWord).pipe(
            map(
                (response: HttpResponse<Streams>) => response.body as Streams,
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public get(streamsId: number): Observable<Stream> {
        return this.streamApiService.getStream(streamsId).pipe(
            map(
                (response: HttpResponse<Stream>) => response.body as Stream,
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Error: ${error}`, NotificationType.Error));
                }
            )
        );
    }

    public create(newStream: Stream): Observable<Stream> {
        return this.streamApiService.createStream(newStream).pipe(
            map(
                (response: HttpResponse<Stream>) => {
                    this.notificationSubject.next(new NotificationMessage(`'${newStream.friendlyName}' stream was added`, NotificationType.Adding));
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`'${newStream.friendlyName}' stream was not added`, NotificationType.Error)
                    );
                }
            )
        );
    }

    public update(updatedStream: Stream): Observable<boolean> {
        return this.streamApiService.updateStream(updatedStream).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    this.notificationSubject.next(
                        new NotificationMessage(`'${updatedStream.friendlyName}' stream was updated`, NotificationType.Updating)
                    );
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(
                        new NotificationMessage(`'${updatedStream.friendlyName}' stream was not updated`, NotificationType.Error)
                    );
                }
            )
        );
    }

    public delete(streamsId: number): Observable<boolean> {
        return this.streamApiService.deleteStream(streamsId).pipe(
            map(
                (response: HttpResponse<boolean>) => {
                    this.notificationSubject.next(new NotificationMessage(`Stream was removed`, NotificationType.Removing));
                    return response.body;
                },
                (error) => {
                    console.log(`Error: ${error}`);
                    this.notificationSubject.next(new NotificationMessage(`Stream was not removed`, NotificationType.Error));
                }
            )
        );
    }
}
