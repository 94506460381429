export class EventGeneratorParams {
    eventTitle: string;
    eachDayStartTime: string;
    eachDayEndTime: string;
    timeChangeInterval: string;
    customerId: number;
    sportCode: string;
    startDate: string;
    endDate: string;
    weekDays: string[];
}
