import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { TwitchLocationDialogData } from '../models/twitch-location-dialog-data';

@Component({
    selector: 'app-twitch-location-dialog',
    templateUrl: './twitch-location-dialog.component.html',
    styleUrls: ['./twitch-location-dialog.component.sass'],
})
export class TwitchLocationDialogComponent implements OnInit {
    public locationCodeControl: FormControl;
    public locationNameControl: FormControl;
    public customersControl: FormControl;

    public isActive: boolean;

    constructor(public dialogRef: MatDialogRef<TwitchLocationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: TwitchLocationDialogData) {}

    public ngOnInit() {
        this.isActive = this.data.currentLocation.isActive;

        this.locationCodeControl = new FormControl(this.data.currentLocation.locationCode);
        this.locationNameControl = new FormControl(this.data.currentLocation.locationName);
        this.customersControl = new FormControl(this.data.currentLocation.customer.customerName);
    }

    public onYes(): void {
        this.data.isChangesConfirmed = true;
        this.data.currentLocation.customer = this.data.updatedModel.customer = this.data.availableCustomers.find(
            (x) => x.customerName === this.customersControl.value
        );
        this.data.currentLocation.locationCode = this.data.updatedModel.locationCode = this.locationCodeControl.value;
        this.data.currentLocation.isActive = this.data.updatedModel.isActive = this.isActive;
        this.data.currentLocation.locationName = this.data.updatedModel.locationName = this.locationNameControl.value;

        this.dialogRef.close(_.cloneDeep(this.data));
    }

    public onNo(): void {
        this.dialogRef.close(false);
    }

    public isFormValid() {
        return this.locationCodeControl.valid && this.locationNameControl.valid && this.customersControl.valid;
    }
}
