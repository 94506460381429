export class VirtualPlayer {
    public playerId: number;
    public customerId: number;
    public customerName: string;
    public customerAlias: string;
    public name: string;
    public streamType: string;
    public streamLink: string;
    public sport: string;
    public quality: string;
}
