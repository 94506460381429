import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Token } from '../../models/common/token';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
    public returnUrl: string;
    public loading = false;
    public email: FormControl;
    public password: FormControl;
    public error: string;

    constructor(private authService: AuthenticationService, private route: ActivatedRoute, private router: Router) {}

    public ngOnInit() {
        this.email = new FormControl('', [Validators.required, Validators.email]);
        this.password = new FormControl('', [Validators.required]);
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/streaming/mcr-schedule';

        if (localStorage.getItem('accessToken')) {
            this.router.navigate(['/']);
        }
    }

    public getErrorMessage() {
        return this.email.hasError('required') ? 'You must enter a value' : this.email.hasError('email') ? 'Not a valid email' : '';
    }

    public login() {
        if (this.email.valid && this.password.valid) {
            this.loading = true;
            this.authService.login(this.email.value, this.password.value).subscribe(
                (response: HttpResponse<Token>) => {
                    localStorage.setItem('accessToken', response.body.accessToken);
                    localStorage.setItem('refreshToken', response.body.refreshToken);
                    this.router.navigate([this.returnUrl]);
                    this.loading = false;
                },
                (error) => {
                    console.log(error);
                    this.error = error;
                    this.loading = false;
                }
            );
        }
    }
}
