import { Customer } from './customer';
import { DialogType } from '../../../../models/dialog/dialogType';

export class CustomerDialogData {
    dialogType: DialogType;
    customer: Customer;
    isChangesConfirmed: boolean = false;

    constructor(dialogType: DialogType, customer: Customer, isChangesConfirmed?: boolean) {
        this.dialogType = dialogType;
        this.customer = customer;
        this.isChangesConfirmed = isChangesConfirmed;
    }
}
