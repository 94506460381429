import { Component, OnInit, ViewChild } from '@angular/core';
import { Sort, MatTableDataSource, MatDialog, PageEvent, MatPaginator } from '@angular/material';
import { Stream } from './models/stream';
import { StreamService } from './services/stream.service';
import { Streams } from './models/streams';
import { DialogType } from 'src/app/models/dialog/dialogType';
import { DialogConfirmationComponent } from 'src/app/components/dialog-confirmation/dialog-confirmation.component';
import { DialogConfirmationData } from 'src/app/models/dialog/dialog-confirmation-data';
import * as _ from 'lodash';
import { AddEditStreamDialogComponent } from './add-edit-stream-dialog/add-edit-stream-dialog.component';
import { ContentDeliveryNetwork } from './models/content-delivery-network';
import { ContentDeliveryNetworkService } from './services/content-delivery-network.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-stream',
    templateUrl: './stream.component.html',
    styleUrls: ['./stream.component.sass']
})
export class StreamComponent {
    @ViewChild('pagination') public pagination: MatPaginator;

    public streamsSource = new MatTableDataSource<Stream>();
    public streamsCount: number;
    public searchCriteriaSubject = new Subject<string>();
    public searchCriteria = '';
    public cdnCodes: ContentDeliveryNetwork[];
    public currentPage = 0;
    public currentPageSize = 20;

    public displayedColumns: string[] = [
        'id',
        'type',
        'cdn',
        'name',
        'description',
        'link',
        'videoBitRate',
        'audioBitRate',
        'sWidth',
        'sHeight',
        'isExternal',
        'menu'
    ];

    constructor(
        private streamsService: StreamService,
        private cdnService: ContentDeliveryNetworkService,
        public dialog: MatDialog
    ) { }

    public ngOnInit() {
        this.setupSearchStream();
        this.loadStreams();
        this.loadCDNs();
    }

    public changeStreamPage(event: PageEvent) {
        this.currentPageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadStreams(event.pageIndex, event.pageSize, this.searchCriteria);
    }

    public searchCriteriaHandler = (input: any) => {
        this.searchCriteriaSubject.next(input);
        this.searchCriteria = input;
    };

    public setupSearchStream = () => {
        this.searchCriteriaSubject.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe((value: string) => {
                this.searchCriteria = value;
                if (this.pagination) {
                    this.pagination.firstPage();
                }
                this.loadStreams(0, this.currentPageSize, this.searchCriteria);

            });
    };

    public sortData(sort: Sort) {
        let isAscDirection = true;
        let column = 'id';

        if (sort.active && sort.direction) {
            isAscDirection = sort.direction === 'asc';
            column = sort.active;
        }

        this.streamsSource.data = this.streamsSource.data.sort((a, b) => {
            switch (column) {
                case 'cdn':
                    return this.compare(a.cdn.cdnCode, b.cdn.cdnCode, isAscDirection);
                case 'name':
                    return this.compare(a.friendlyName, b.friendlyName, isAscDirection);
                default:
                    return this.compare(a.streamId, b.streamId, isAscDirection);
            }
        });
    }

    public openAddDialog() {
        const addDialog = this.createEditDialog(DialogType.Add, new Stream());

        addDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed === true) {
                this.addStream(result.stream);
            }
        });
    }

    public openUpdateDialog(stream: Stream) {
        const updateDialog = this.createEditDialog(DialogType.Edit, _.cloneDeep(stream));

        updateDialog.afterClosed().subscribe((result) => {
            if (result && result.isChangesConfirmed === true) {
                this.updateStream(result.stream);
            }
        });
    }

    public openRemoveDialog(stream: Stream) {
        const removeDialog = this.dialog.open(DialogConfirmationComponent, {
            width: '400px',
            data: new DialogConfirmationData(`${stream.friendlyName} stream`)
        });

        removeDialog.afterClosed().subscribe((isRemovingConfirmed: boolean) => {
            if (isRemovingConfirmed && isRemovingConfirmed === true) {
                this.deleteStream(stream);
            }
        });
    }

    private createEditDialog(dialogType: DialogType, stream: Stream) {
        return this.dialog.open(AddEditStreamDialogComponent, {
            width: '500px',
            data: {
                dialogType,
                stream,
                cdnCodes: this.cdnCodes
            }
        });
    }

    private addStream(stream: Stream) {
        this.streamsService.create(stream).subscribe((newItem: Stream) => {
            if (newItem) {
                const page = Math.floor(this.streamsCount / this.currentPageSize);
                this.loadStreams(page, this.currentPageSize, this.searchCriteria);
                this.pagination.pageIndex = page;
            }
        });
    }

    private updateStream(stream: Stream) {
        this.streamsService.update(stream).subscribe((isUpdate: boolean) => {
            if (isUpdate) {
                this.loadStreams(this.currentPage, this.currentPageSize, this.searchCriteria);
            }
        });
    }

    private deleteStream(stream: Stream) {
        this.streamsService.delete(stream.streamId).subscribe((isRemoved: boolean) => {
            if (isRemoved) {
                this.loadStreams(this.currentPage, this.currentPageSize, this.searchCriteria);
            }
        });
    }

    private loadStreams(page = 0, pageIndex = 20, searchWord = '') {
        this.streamsService.getAll(page, pageIndex, searchWord).subscribe((data: Streams) => {
            this.streamsSource.data = data.streams;
            this.streamsCount = data.streamsTotal;
        });
    }

    private loadCDNs() {
        this.cdnService.getAllCDNCodes().subscribe((data: ContentDeliveryNetwork[]) => {
            this.cdnCodes = data;
        });
    }

    private compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
