import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventHistoryApiService } from './services/event-history-api.service';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { EventHistoryModel } from './models/event-history';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { EventAuditModel } from './models/event-audit';
import { EventIssueModel } from './models/event-issue';

@Component({
    selector: 'app-event-history',
    templateUrl: './event-history.component.html',
    styleUrls: ['./event-history.component.sass']
})
export class EventHistoryComponent implements OnDestroy {
    public eventIdControl = new FormControl();

    public eventHistorySubscription: Subscription;
    public eventAuditSubscription: Subscription;

    public eventHistory: EventHistoryModel;
    public eventAudit: EventAuditModel;

    public currentEventId: string;
    public eventAuditSource = new MatTableDataSource<EventAuditModel>();

    public eventAuditColumns: string[] = ['eventStatusAuditId', 'propertyName', 'value', 'timeChanged', 'isAutoUpdating', 'changedBy'];

    constructor(private eventHistoryApiService: EventHistoryApiService, private snackBar: MatSnackBar) {}

    public getData() {
        this.getEventHistory();
        this.getEventAudits();
    }

    public getEventHistory() {
        this.eventHistorySubscription = this.eventHistoryApiService.getEventHistory(this.eventIdControl.value).subscribe(
            (response: HttpResponse<EventHistoryModel>) => {
                this.eventHistory = response.body;
                this.currentEventId = this.eventIdControl.value;
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
                this.eventHistory = undefined;
            }
        );
    }

    public getEventAudits() {
        this.eventHistorySubscription = this.eventHistoryApiService.getEventAudits(this.eventIdControl.value).subscribe(
            (response: HttpResponse<EventAuditModel[]>) => {
                this.eventAuditSource.data = response.body;
            },
            (error) => {
                this.snackBar.open(error, '', { duration: 3000, panelClass: 'error-snack-bar' });
                this.eventAuditSource.data = [];
            }
        );
    }

    public ngOnDestroy(): void {
        this.unsubscribe(this.eventHistorySubscription);
        this.unsubscribe(this.eventAuditSubscription);
    }

    private unsubscribe(subscription: Subscription) {
        if (subscription) {
            subscription.unsubscribe();
        }
    }
}
